import { AlphaModal, TextArea, TextInput } from '@components'
import { TrashIcon } from '@heroicons/react/24/outline'
import deepEqual from 'fast-deep-equal'
import { get, isEmpty, noop, omit } from 'lodash-es'
import { useEffect, useMemo, useState } from 'react'
import Skeleton from 'react-loading-skeleton'

import { useAppSelector, useAppThunkDispatch } from '../../app/hooks'
import { randomId } from '../../common/utils'
import { FileUpload } from '../../components/QuotingTool/components'
import {
  ItemTemplate,
  ItemTemplateFile,
  uploadItemTemplateFile,
  upsertItemTemplate,
} from '../../redux/itemTemplateSlice'

const initialData = {
  name: '',
  notes: '',
  width: '',
  height: '',
  length: '',
  weight: '',
  files: [],
}

export const ItemTemplateModal = ({
  isVisible,
  setVisible,
  item,
  onSaved = () => {},
}: {
  isVisible: boolean
  setVisible: (value: boolean) => void
  item?: ItemTemplate | null
  onSaved?: (item: Required<ItemTemplate>) => void
}) => {
  const dispatch = useAppThunkDispatch()
  const upserting = useAppSelector(state => state.itemTemplate.loading.upsertItemTemplate)
  const fetching = useAppSelector(state => state.itemTemplate.loading.getItemTemplate)
  const [data, setData] = useState<ItemTemplate>(initialData)
  const [filesProgress, setFilesProgress] = useState<Record<string, number>>({})
  const isValid = useMemo(
    () =>
      data.name &&
      data.width &&
      data.height &&
      data.length &&
      data.weight &&
      isEmpty(filesProgress),
    [data, filesProgress],
  )

  useEffect(() => {
    if (isVisible) setData(item || initialData)
  }, [isVisible, item])

  const hasChanges = useMemo(() => !deepEqual(data, item), [data, item])

  const setDataProperty = (value: Partial<ItemTemplate>) => setData(data => ({ ...data, ...value }))

  const setFileProgress = (id: string, progress: number) =>
    setFilesProgress(filesProgress =>
      progress === 100 ? omit(filesProgress, id) : { ...filesProgress, [id]: progress },
    )

  const handleFileUpload = async (file: File) => {
    const id = randomId()
    setDataProperty({ files: [...(data.files || []), { id, file }] })
    dispatch(
      uploadItemTemplateFile({ file, onProgress: progress => setFileProgress(id, progress) }),
    )
      .unwrap()
      .then(itemFile => {
        setData(data => ({
          ...data,
          files: (data.files || []).map(file => (file.id === id ? { ...file, ...itemFile } : file)),
        }))
      })
      .catch(noop)
  }

  const onFileRemoved = (id: number | string) => {
    setData(data => ({
      ...data,
      files: (data.files || []).filter(file => file.id !== id),
    }))
  }

  const handleUpsertItem = (item: ItemTemplate) => {
    dispatch(upsertItemTemplate(item))
      .unwrap()
      .then((item: Required<ItemTemplate>) => {
        onSaved(item)
        setVisible(false)
      })
      .catch(noop)
  }

  return (
    <AlphaModal
      className='!overflow-visible w-[400px]'
      confirmButtonLabel={item?.createdAt ? 'Update' : 'Create'}
      isConfirmButtonDisabled={!isValid || !hasChanges}
      isConfirmButtonLoading={upserting || fetching || !isEmpty(filesProgress)}
      isVisible={isVisible}
      setVisible={setVisible}
      title={`${item?.createdAt ? 'Edit' : 'Create'} Item`}
      onConfirm={() => handleUpsertItem(data)}
    >
      <div className='p-6 flex flex-col gap-4'>
        <TextInput
          autoFocus
          required
          sm
          label='Name'
          loading={fetching}
          value={data.name}
          onChange={name => setDataProperty({ name })}
        />
        <div className='grid grid-cols-2 gap-4'>
          <TextInput
            required
            sm
            label='Length (ft)'
            loading={fetching}
            type='number'
            value={data.length}
            onChange={length => setDataProperty({ length })}
          />
          <TextInput
            required
            sm
            label='Width (ft)'
            loading={fetching}
            type='number'
            value={data.width}
            onChange={width => setDataProperty({ width })}
          />
          <TextInput
            required
            sm
            label='Height (ft)'
            loading={fetching}
            type='number'
            value={data.height}
            onChange={height => setDataProperty({ height })}
          />
          <TextInput
            required
            sm
            label='Weight (lbs)'
            loading={fetching}
            type='number'
            value={data.weight}
            onChange={weight => setDataProperty({ weight })}
          />
        </div>
        <TextArea
          sm
          label='Notes'
          loading={fetching}
          value={data.notes}
          onChange={notes => setDataProperty({ notes })}
        />
        <div className='flex flex-col gap-1'>
          <FileUpload
            className='text-xs font-poppins font-regular'
            label='Documents/Photos'
            value={null}
            onChange={file => file && handleFileUpload(file)}
          />
          {fetching ? (
            <Skeleton count={2} height={30} />
          ) : isEmpty(data.files) ? (
            <div className='text-xs text-gray-400 italic'>No files uploaded</div>
          ) : (
            (data.files || []).map(file => (
              <File
                key={file.id}
                file={file}
                progress={filesProgress[file.id]}
                onFileRemoved={() => onFileRemoved(file.id)}
              />
            ))
          )}
        </div>
      </div>
    </AlphaModal>
  )
}

const File = ({
  file,
  progress,
  onFileRemoved,
}: {
  file: ItemTemplateFile
  progress: number
  onFileRemoved: () => void
}) => {
  const name = get(file, 'file.name', file.fileName || '')
  const link = typeof file.file === 'string' ? file.file : null

  return (
    <div key={file.id} className='flex flex-col gap-1 mb-2'>
      <div className='flex items-center justify-between'>
        {link ? (
          <a
            className='text-blue-400 overflow-hidden text-ellipsis'
            href={link}
            rel='noreferrer'
            target='_blank'
          >
            {name}
          </a>
        ) : (
          <div className='overflow-hidden text-ellipsis'>{name}</div>
        )}
        {file.createdAt && (
          <TrashIcon
            className='w-4 h-4 text-error cursor-pointer'
            onClick={() => onFileRemoved()}
          />
        )}
      </div>
      {typeof progress === 'number' && (
        <div className='relative bg-gray-100 rounded'>
          <Skeleton
            baseColor='#C8E6C9'
            height={15}
            highlightColor='#A5D6A7'
            style={{ width: `${progress}%` }}
          />
          <div className='absolute inset-0 flex items-center justify-center z-10 text-[0.5rem] font-bold'>
            {`${progress < 99 ? 'Uploading' : 'Processing'} (${progress})%`}
          </div>
        </div>
      )}
    </div>
  )
}
