import { AppLogo } from '@components'
import { ArrowRightIcon } from '@heroicons/react/24/solid'
import { ReactNode } from 'react'
import tw from 'tailwind-styled-components'

import people from '../../../assets/images/login/people.png'
import screen from '../../../assets/images/login/screen.png'
import stars from '../../../assets/images/login/stars.png'

const Benefit = ({ children }: { children: string }) => (
  <div className='flex items-center gap-3 text-white text-lg font-semibold'>
    <ArrowRightIcon className='w-5 min-w-5 stroke-2' />
    {children}
  </div>
)

export const LoginPageContainer = ({ children }: { children: ReactNode }) => (
  <Container>
    <Wrap>
      <div className='py-10 px-10 lg:px-20'>
        <div className='text-white flex justify-between items-center'>
          <AppLogo />
          <RightSideContainer>
            <img alt='users' className='w-[136px] -mb-4' src={people} />
            <span className='font-poppins font-semibold'>1K+ customers used our platform</span>
            <img alt='stars' className='w-[140px]' src={stars} />
          </RightSideContainer>
        </div>
        <Text>
          Centralize your logistics in a single platform.{' '}
          <span className='text-warning'>Increase collaboration across your company</span>
        </Text>
        <div className='grid xl:grid-cols-2 gap-4'>
          <Benefit>Onboard Carriers and Manage Relationships</Benefit>
          <Benefit>Manage BOL’s and Attach Documents</Benefit>
          <Benefit>Analyze Reporting and Carrier Performance</Benefit>
          <Benefit>Access Real-Time Freight Market Pricing Data</Benefit>
          <Benefit>Tender and Track Loads</Benefit>
          <Benefit>Request and Manage Quotes</Benefit>
        </div>
      </div>
      <img alt='screen' className='max-lg:hidden pl-20 w-full' src={screen} />
    </Wrap>
    {children}
  </Container>
)

const RightSideContainer = tw.div`
  flex
  items-center
  gap-6
  max-lg:hidden
  max-xl:gap-3
`

const Container = tw.div`
  block
  fixed md:!static
  h-screen
  md:flex
  top-0
  w-full
  overflow-y-auto
  max-lg:h-[calc(100vh-82px)]
  max-lg:pb-10
`

const Wrap = tw.div`
  bg-gradient-to-r from-shiny-blue to-dark-blue
  w-1/2
  lg:w-7/12
  flex
  flex-col
  justify-between
  max-md:hidden
`

const Text = tw.div`
  text-white
  text-4xl
  font-poppins
  font-semibold
  mt-[60px]
  mb-10
  leading-snug
`
