import { CatchError, formatAxiosErrorToPayload, getErrorString, jsonToFormData } from '@common'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import { toast } from 'react-toastify'

import { api } from '../api/api'
import { RootState } from '../app/store'
import { formatDateForBackend, keysToCamelCase } from '../common/utils'

type CommonState = {
  pageTab: number
  loading: {
    file: boolean
    singleLaneQuote: boolean
    carrierQuotes: boolean
  }
  file: File | null
  fileName: string
  newFileName: string
  isFileModalVisible: boolean
  fileError: string
  // TODO: type these vars
  multiLaneQuote: any
  singleLaneQuote: any
  batchPricingUploadId: null | number
  fileSuccess: boolean
  locations: any
  doNotShowEquipmentBanner: boolean
  carrierQuotes: {
    count: number
    quotes: Array<{
      id: number
      createdAt: string
      updatedAt: string
      quoteStatusDisplay: string
      carrierCompany: { id: number; name: string }
      carrierBid: string
      equipmentType: string
      load: { id: number; miles: number }
      customerCompany: { id: number; name: string }
      shipper: {
        name: string
        state: string
        city: string
        latitude: number
        longitude: number
      }
      consignee: {
        name: string
        state: string
        city: string
        latitude: number
        longitude: number
      }
      originDistance: number
      destinationDistance: number
    }>
  }
  ordersColumns: string[]
}

const initialState: CommonState = {
  pageTab: 0,
  loading: {
    file: false,
    singleLaneQuote: false,
    carrierQuotes: false,
  },
  file: null,
  fileName: '',
  newFileName: '',
  isFileModalVisible: true,
  fileError: '',
  multiLaneQuote: {},
  singleLaneQuote: null,
  batchPricingUploadId: null,
  fileSuccess: false,
  locations: null,
  doNotShowEquipmentBanner: false,
  carrierQuotes: {
    count: 0,
    quotes: [],
  },
  ordersColumns: [],
}

export const getMultiLaneQuote = createAsyncThunk(
  'common/getMultiLaneQuote',
  async (file: any, { getState, rejectWithValue }) => {
    const { newFileName } = (getState() as RootState).common
    const formData = new FormData()
    formData.append('file', file, newFileName)

    try {
      const response = await api.post('/pricing/batch-ml-prediction/', formData)
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const checkBatchPredictionStatus = createAsyncThunk(
  'common/checkBatchPredictionStatus',
  async (_, { rejectWithValue, getState }) => {
    try {
      const { batchPricingUploadId } = (getState() as RootState).common
      if (!batchPricingUploadId) return { routes: [], uploads: [] }

      const response = await api.get(
        `/pricing/check-batch-prediction-status/${batchPricingUploadId}/`,
      )
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const getPriceEstimation = createAsyncThunk(
  'common/getPriceEstimation',
  async (_, { getState, rejectWithValue }) => {
    const { searchParams: data } = (getState() as RootState).quotingTool

    const pickupDate = new Date()
    // Move the pickup date to two Mondays from now..
    pickupDate.setDate(pickupDate.getDate() + ((8 + 7 - pickupDate.getDay()) % 14 || 14))

    const payload = {
      origin: {
        city: data.origin?.location?.city,
        state: data.origin?.location?.state,
        country: data.origin?.location?.country,
        lat: data.origin?.location?.latitude,
        lng: data.origin?.location?.longitude,
      },
      destination: {
        city: data.destination?.location?.city,
        state: data.destination?.location?.state,
        country: data.destination?.location?.country,
        lat: data.destination?.location?.latitude,
        lng: data.destination?.location?.longitude,
      },
      equipmentType: data.equipmentType || 'Flatbed',
      loadPickupDate: formatDateForBackend(pickupDate as any),
      customerReferenceId: data.refId || '',
      stopovers: data.stops?.map(stop => ({
        city: stop.location?.city,
        state: stop.location?.state,
        country: stop.location?.country,
        lat: stop.location?.latitude,
        lng: stop.location?.longitude,
        stopType: stop.stopType?.toLowerCase() || 'pickup',
      })),
    }

    try {
      const response = await api.post('/pricing/price-estimate/', payload)
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const getSingleLaneQuote = createAsyncThunk(
  'common/getSingleLaneQuote',
  async (_, { getState, rejectWithValue }) => {
    const { searchParams: data } = (getState() as RootState).quotingTool

    const payload = {
      origin: {
        city: data.origin?.location?.city,
        state: data.origin?.location?.state,
        country: data.origin?.location?.country,
        lat: data.origin?.location?.latitude,
        lng: data.origin?.location?.longitude,
      },
      destination: {
        city: data.destination?.location?.city,
        state: data.destination?.location?.state,
        country: data.destination?.location?.country,
        lat: data.destination?.location?.latitude,
        lng: data.destination?.location?.longitude,
      },
      equipmentType: data.equipmentType || 'Flatbed',
      loadPickupDate: formatDateForBackend(data.pickupDate as any),
      customerReferenceId: data.refId || '',
      stopovers: data.stops?.map(stop => ({
        city: stop.location?.city,
        state: stop.location?.state,
        country: stop.location?.country,
        lat: stop.location?.latitude,
        lng: stop.location?.longitude,
        stopType: stop.stopType?.toLowerCase() || 'pickup',
      })),
      ...(data.isOD && {
        manualQuote: {
          notes: data.notes || '',
          pieces: data.item?.quantity || '',
          commodity: data.item?.name || '',
          weight: data.item?.weight || '',
          height: data.item?.height || '',
          width: data.item?.width || '',
          length: data.item?.length || '',
          isInsuredByCustomer: data.isInsured || false,
          insuranceValue: data.loadValue,
          files: [
            ...(data.specs ? [{ file: data.specs, fileType: 'SPEC' }] : []),
            ...(data.commodity || []).map(commodityFile => ({
              file: commodityFile.file,
              fileType: 'COMMODITY',
            })),
          ],
        },
      }),
    }

    try {
      const response = await api.post(
        '/pricing/single-quote-prediction/',
        data.isOD ? jsonToFormData(payload) : payload,
      )
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const setSingleLaneQuote = createAsyncThunk(
  'common/setSingleLaneQuote',
  async (payload: any) => payload,
)

export const getCarrierQuotes = createAsyncThunk(
  'common/getCarrierQuotes',

  async (_, { getState, rejectWithValue }) => {
    const { searchParams: data } = (getState() as RootState).quotingTool

    const payload = {
      limit: 50,
      offset: 0,
      equipment_type: data.equipmentType || 'Flatbed',
      origin_lat: data.origin.location?.latitude,
      origin_lon: data.origin.location?.longitude,
      destination_lat: data.destination.location?.latitude,
      destination_lon: data.destination.location?.longitude,
      created_at__gte: formatDateForBackend(dayjs().subtract(180, 'd')),
      quote_status__in: '1,2,8',
    }

    try {
      const response = await api.get('/customer/api/relevant-carrier-quotes/', { params: payload })
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

const commonSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    setPageTab(state, { payload }) {
      state.pageTab = payload
    },
    setFile(state, { payload }) {
      state.file = payload
      state.fileName = payload?.name ?? ''
      if (!payload) {
        state.loading.file = false
        state.locations = []
      }
    },
    setFileName(state, { payload }) {
      state.newFileName = payload
    },
    setFileModalVisible(state, { payload }) {
      state.isFileModalVisible = payload
    },
    deleteSingleLaneQuote(state) {
      state.singleLaneQuote = null
    },
    setDoNotShowEquipmentBanner(state, { payload }) {
      state.doNotShowEquipmentBanner = payload
    },
    setOrdersColumns(state, { payload }) {
      state.ordersColumns = payload
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getMultiLaneQuote.pending, state => {
        state.loading.file = true
        state.isFileModalVisible = true
        state.fileName = state.newFileName
        state.fileError = ''
        state.multiLaneQuote = {}
      })
      .addCase(getMultiLaneQuote.fulfilled, (state, { payload }) => {
        state.batchPricingUploadId = payload.id
      })
      .addCase(getMultiLaneQuote.rejected, (state, { payload }) => {
        state.loading.file = false
        state.fileError = getErrorString(payload, 'Error processing file')
      })
      .addCase(checkBatchPredictionStatus.pending, state => {
        state.loading.file = true
      })
      .addCase(checkBatchPredictionStatus.fulfilled, (state, { payload = [] }) => {
        if (payload.routes.length)
          state.locations = payload.routes.map((location: any) => [
            { lat: location.originLat, lng: location.originLon },
            { lat: location.destinationLat, lng: location.destinationLon },
          ])
        if (payload.uploads.length)
          state.multiLaneQuote =
            payload.uploads.find((status: any) => status.id === state.batchPricingUploadId) || {}
        if (state.multiLaneQuote?.status === 'Processed') {
          state.loading.file = false
          state.batchPricingUploadId = null
        }
        if (state.multiLaneQuote?.id) state.fileSuccess = true
      })
      .addCase(checkBatchPredictionStatus.rejected, (_, { payload }) => {
        toast.error(getErrorString(payload, 'Error getting prediction status'))
      })
      .addCase(getSingleLaneQuote.pending, state => {
        state.loading.singleLaneQuote = true
      })
      .addCase(getSingleLaneQuote.fulfilled, (state, { payload }) => {
        state.loading.singleLaneQuote = false
        state.singleLaneQuote = payload
      })
      .addCase(getSingleLaneQuote.rejected, (state, { payload }) => {
        state.loading.singleLaneQuote = false
        state.singleLaneQuote = null
        toast.error(getErrorString(payload, 'Error getting quote'))
      })
      .addCase(setSingleLaneQuote.fulfilled, (state, { payload }) => {
        state.singleLaneQuote = payload
      })
      .addCase(getPriceEstimation.pending, state => {
        state.loading.singleLaneQuote = true
      })
      .addCase(getPriceEstimation.fulfilled, (state, { payload }) => {
        state.loading.singleLaneQuote = false
        state.singleLaneQuote = payload
      })
      .addCase(getPriceEstimation.rejected, (state, { payload }) => {
        state.loading.singleLaneQuote = false
        state.singleLaneQuote = null
        toast.error(getErrorString(payload, 'Error getting price estimate'))
      })
      .addCase(getCarrierQuotes.pending, state => {
        state.loading.carrierQuotes = true
      })
      .addCase(getCarrierQuotes.fulfilled, (state, action) => {
        const { count, results } = action.payload
        state.carrierQuotes.quotes = keysToCamelCase(results)
        state.carrierQuotes.count = count
        state.loading.carrierQuotes = false
      })
      .addCase(getCarrierQuotes.rejected, state => {
        state.loading.carrierQuotes = false
        toast.error('Failed to get quotes')
      })
  },
})

export const {
  setPageTab,
  setFileModalVisible,
  setFileName,
  setFile,
  deleteSingleLaneQuote,
  setDoNotShowEquipmentBanner,
  setOrdersColumns,
} = commonSlice.actions

export default commonSlice.reducer
