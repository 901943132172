// Transient representation object
export type Transient<T> = Partial<{
  [K in keyof T as `$${string & K}`]: T[K]
}>

/**
 * Returns an object with the keys prefixed with $.
 * Used for creating an object that can be passed as transiept props to a styled component.
 * @param obj some object { one: 1, two: 2 }
 * @returns transient object { $one: 1, $two: 2 }
 */
export const toTransient = <T extends object>(obj: T): Partial<Transient<T>> =>
  Object.keys(obj).reduce(
    (acc, key) => ({
      ...acc,
      [`$${key}`]: (obj as any)[key],
    }),
    {} as Partial<Transient<T>>,
  )
