import { Button } from '@components'
import { CheckCircleIcon } from '@heroicons/react/24/solid'
import tw from 'tailwind-styled-components'

import { useAppSelector, useAppThunkDispatch } from '../../app/hooks'
import { setCurrentStep, setTutorialWizardVisible } from '../../redux/userSlice'
import imageBackground from './images/imageBackground.jpg'

const levelTexts: { [key: string]: string } = {
  Beginner: 'Explore more tutorials below to boost your skills and progress to the next level.',
  Novice: 'Check out the tutorials below to enhance your skills and move up to the next level.',
  Skilled: 'Explore the tutorials below to sharpen your skills and advance to the next level.',
  Proficient: 'Browse the tutorials below to refine your abilities and advance to the next level.',
  Expert: 'You’ve mastered everything — there’s nothing more to learn. Keep up the great work!',
}

export const StepsList = ({ currentLevel }: { currentLevel: string }) => {
  const dispatch = useAppThunkDispatch()

  const availableSteps = useAppSelector(state => state.user.availableSteps)
  const completedSteps = useAppSelector(state => state.user.userCompletedSteps)

  return (
    <div>
      <Header>
        <div>You’re at the {currentLevel} level!</div>
        <div>{levelTexts[currentLevel]}</div>
        <div>You can return to this screen and resume the tutorial at any time.</div>
      </Header>
      <div className='relative p-6 lg:w-[800px]'>
        <img
          alt='Background'
          className='absolute inset-0 w-full h-full object-contain'
          src={imageBackground}
        />
        <div className='relative flex flex-col items-center'>
          {availableSteps.map((step, i) => {
            const stepKey = Object.keys(step)?.[0]
            const stepLabel = Object.values(step)
            const isCompleted = completedSteps.includes(stepKey)

            return (
              <Step key={stepKey}>
                <StepLabel>
                  <div className='w-6'>
                    {isCompleted ? (
                      <CheckCircleIcon className='text-sky w-6' />
                    ) : (
                      <div className='ml-1.5'>{i + 1}.</div>
                    )}
                  </div>
                  {stepLabel}
                </StepLabel>
                <Button
                  noBackground={isCompleted}
                  type='light-blue'
                  onClick={() => dispatch(setCurrentStep(stepKey))}
                  {...(isCompleted && { innerClassName: '!border-none' })}
                >
                  Start {isCompleted ? 'Again' : 'Now'}
                </Button>
              </Step>
            )
          })}
        </div>
      </div>
      <div className='mt-10 flex items-center justify-center mb-10'>
        <Button type='secondary' onClick={() => dispatch(setTutorialWizardVisible(false))}>
          Close
        </Button>
      </div>
    </div>
  )
}

const Header = tw.div`
  flex
  flex-col
  items-center
  mb-8
  text-sm
  leading-6
  font-semibold
`

const Step = tw.div`
  mb-4
  last:mb-0
  font-semibold
  flex
  items-center
  justify-between
`

const StepLabel = tw.div`
  whitespace-nowrap
  flex
  items-center
  gap-4
  min-w-[200px]
  w-[200px]
`
