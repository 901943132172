import { AppLogo, Modal, ProgressCircle } from '@components'
import { useCallback, useMemo } from 'react'
import tw from 'tailwind-styled-components'

import { useAppSelector, useAppThunkDispatch } from '../../app/hooks'
import { setTutorialWizardVisible } from '../../redux/userSlice'
import { Step } from './Step'
import { StepsList } from './StepsList'
import { WelcomeStep } from './WelcomeStep'

export const WalkthroughWizard = () => {
  const dispatch = useAppThunkDispatch()

  const isVisible = useAppSelector(state => state.user.isTutorialWizardVisible)
  const availableSteps = useAppSelector(state => state.user.availableSteps)
  const completedSteps = useAppSelector(state => state.user.userCompletedSteps)
  const currentStep = useAppSelector(state => state.user.currentStep)

  const Tab = useCallback(() => {
    if (!completedSteps.length && !currentStep) return <WelcomeStep />
    if (currentStep === 'START') return <StepsList currentLevel={currentLevel} />
    return <Step />
  }, [completedSteps, currentStep])

  const progress = useMemo(
    () => Math.round((completedSteps.length / availableSteps.length) * 100),
    [completedSteps.length, availableSteps.length],
  )

  const currentLevel = useMemo(() => {
    if (progress >= 0 && progress <= 33) return 'Beginner'
    if (progress > 33 && progress <= 50) return 'Novice'
    if (progress > 50 && progress <= 75) return 'Skilled'
    if (progress > 75 && progress <= 99) return 'Proficient'
    if (progress === 100) return 'Expert'

    return 'Beginner'
  }, [progress])

  return (
    <Modal
      className='!max-w-none'
      isVisible={isVisible}
      setVisible={value => dispatch(setTutorialWizardVisible(value))}
      showCloseIcon={false}
    >
      <div className='font-poppins'>
        <div className='flex justify-between p-8 pb-0'>
          <AppLogo type='dark' />
          {!!currentStep && (
            <div className='flex gap-4 items-center'>
              <div className='font-semibold'>{currentLevel}</div>
              <ProgressCircle progress={progress} size={45} strokeWidth={7} />
            </div>
          )}
        </div>
        <TabContainer>
          <Tab />
        </TabContainer>
      </div>
    </Modal>
  )
}

const TabContainer = tw.div`
  flex
  flex-col
  items-center
  justify-center
  mt-6
  px-2
`
