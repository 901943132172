import { Button } from '@components'
import tw from 'tailwind-styled-components'

export const MobileItemButtons = ({
  isEditing,
  setEditing = () => {},
  className,
  deletable,
  onCancel = () => {},
  onSave,
  loading,
  isSaveButtonDisabled,
  onDelete,
}: {
  isEditing: boolean
  setEditing?: (value: boolean) => void
  className?: string
  deletable?: boolean
  onCancel?: () => void
  onSave?: () => void
  loading?: boolean
  isSaveButtonDisabled?: boolean
  onDelete?: () => void
}) => (
  <Container className={className}>
    {isEditing ? (
      <div className='flex gap-4'>
        <Button
          fullWidth
          className='w-full'
          type='secondary'
          onClick={() => {
            onCancel()
            setEditing(false)
          }}
        >
          Cancel
        </Button>
        {deletable && (
          <Button fullWidth className='w-full' type='danger' onClick={onDelete}>
            Delete
          </Button>
        )}
        <Button
          fullWidth
          className='w-full'
          disabled={isSaveButtonDisabled}
          loading={loading}
          type='success'
          onClick={onSave}
        >
          Save
        </Button>
      </div>
    ) : (
      <Button fullWidth onClick={() => setEditing(true)}>
        Edit
      </Button>
    )}
  </Container>
)

const Container = tw.div`
  mt-4
  lg:hidden
`
