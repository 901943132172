import {
  CatchError,
  cleanFilters,
  formatAxiosErrorToPayload,
  formatDateFilterForBackend,
  formatDateForBackend,
  getErrorString,
  getObjectDifferences,
  keysToSnakeCase,
  randomString,
} from '@common'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'

import { api } from '../api/api'
import { RootState } from '../app/store'
import { initialFilters } from '../common/constants'
import { NewProject, ProjectItem, SearchFilters, TableOrder } from '../common/types'
import { keysToCamelCase } from '../common/utils'
import { getProjectLoads } from './loadsSlice'

export type ProjectDocument = {
  id: number
  file: string
  uploadedAt: string
  fileName: string
  isPublic: boolean
}

export const initialProject = {
  name: '',
  referenceNumber: '',
  files: [],
  notes: '',
  startDate: null,
  endDate: null,
}

type ProjectsState = {
  loading: {
    projects: boolean
    createProject: boolean
    getProjectDetails: boolean
    archiveProject: boolean
    updateProject: boolean
    updateProjectStatus: boolean
    getProjectDocuments: boolean
    uploadProjectDocuments: boolean
    deleteProjectDocument: boolean
    changeLoadOrder: boolean
    bulkRequestQuotes: boolean
    getProjectCarriers: boolean
  }
  projects: ProjectItem[]
  count: number
  offset: number
  limit: number
  filters: SearchFilters
  order: TableOrder
  newProject: NewProject
  updatedProject: NewProject
  projectDetails: ProjectItem
  backupProjectDetails: ProjectItem
  projectDocuments: ProjectDocument[]
  projectCarriers: {
    carrierName: string
    contactName: string
    contactEmail: string
    contactPhone: string
    pending: number
    quoted: number
    rejected: number
    quoteTotal: number
    status: string
  }[]
  projectCarriersCount: number
  projectCarriersLimit: number
  projectCarriersOffset: number
  initialProjectsCount: number
}

const initialState: ProjectsState = {
  loading: {
    projects: false,
    createProject: false,
    getProjectDetails: false,
    archiveProject: false,
    updateProject: false,
    updateProjectStatus: false,
    getProjectDocuments: false,
    uploadProjectDocuments: false,
    deleteProjectDocument: false,
    changeLoadOrder: false,
    bulkRequestQuotes: false,
    getProjectCarriers: false,
  },
  count: 0,
  projects: [],
  offset: 0,
  limit: 50,
  filters: initialFilters,
  order: { label: '', direction: '', key: '' },
  newProject: initialProject,
  updatedProject: initialProject,
  projectDetails: {},
  backupProjectDetails: {},
  projectDocuments: [],
  projectCarriers: [],
  projectCarriersCount: 0,
  projectCarriersLimit: 50,
  projectCarriersOffset: 0,
  initialProjectsCount: 0,
}

export const getProjects = createAsyncThunk(
  'projects/getProjects',
  async (_, { getState, rejectWithValue }) => {
    const { filters } = (getState() as RootState).projects

    try {
      const response = await api.get('/loads/api/list-create-project/', {
        params: {
          ...cleanFilters({
            name__icontains: filters.name,
            id: filters.id,
            reference_number: filters.refId,
            status:
              filters.projectStatus === 'Draft' ? 'Draft' : filters.projectStatus?.toUpperCase(),
            ...formatDateFilterForBackend(filters.startDate, 'start_date'),
            ...formatDateFilterForBackend(filters.endDate, 'end_date'),
          }),
        },
      })
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const getProjectDetails = createAsyncThunk(
  'projects/getProjectDetails',
  async (id: string | number, { rejectWithValue }) => {
    try {
      const response = await api.get(`/loads/api/project-rud/${id}/`)
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const getProjectDocuments = createAsyncThunk(
  'projects/getProjectDocuments',
  async (id: string | number, { rejectWithValue }) => {
    try {
      const response = await api.get(`/loads/api/list-create-project-document/${id}/`, {
        params: { limit: 50 },
      })
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const deleteProjectDocument = createAsyncThunk(
  'projects/deleteProjectDocument',
  async (id: string | number, { getState, dispatch, rejectWithValue }) => {
    const { projectDetails } = (getState() as RootState).projects

    try {
      const response = await api.delete(`/loads/api/delete-project-document/${id}/`)
      dispatch(getProjectDocuments(projectDetails.id || ''))
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const uploadProjectDocuments = createAsyncThunk(
  'projects/uploadProjectDocuments',
  async (isPublic: boolean, { getState, dispatch, rejectWithValue }) => {
    const {
      projectDetails: { id },
      newProject,
    } = (getState() as RootState).projects

    const formData = new FormData()

    newProject.files?.forEach(doc => {
      formData.append('files', doc.file)
    })

    // @ts-ignore
    formData.append('is_public', isPublic)

    try {
      const response = await api.post(`/loads/api/list-create-project-document/${id}/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      dispatch(getProjectDocuments(id || ''))
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const updateProject = createAsyncThunk(
  'projects/updateProject',
  async (_, { getState, dispatch, rejectWithValue }) => {
    const { projectDetails, updatedProject } = (getState() as RootState).projects

    const payload = getObjectDifferences(updatedProject, projectDetails)
    if (payload.startDate) payload.startDate = formatDateForBackend(payload.startDate)
    if (payload.endDate) payload.endDate = formatDateForBackend(payload.endDate)

    try {
      const response = await api.patch(
        `/loads/api/project-rud/${projectDetails.id}/`,
        keysToSnakeCase({
          ...payload,
          customerCompany: null,
          referenceNumber: payload.referenceNumber || randomString(),
          budget: parseFloat(payload.budget || '0'),
        }),
      )
      dispatch(getProjectDetails(projectDetails.id || ''))
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const updateProjectStatus = createAsyncThunk(
  'projects/updateProjectStatus',
  async (
    { id, status }: { id?: number; status: string },
    { getState, dispatch, rejectWithValue },
  ) => {
    const { projectDetails } = (getState() as RootState).projects

    try {
      const response = await api.patch(`/loads/api/project-rud/${id || projectDetails.id}/`, {
        status,
      })
      dispatch(getProjectDetails(id || projectDetails.id || ''))
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const archiveProject = createAsyncThunk(
  'projects/archiveProject',
  async (id: string | number, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.delete(`/loads/api/project-rud/${id}/`)
      dispatch(getProjectDetails(id))
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const createProject = createAsyncThunk(
  'projects/createProject',
  async (_, { getState, rejectWithValue }) => {
    const { newProject } = (getState() as RootState).projects

    const payload = {
      name: newProject.name,
      referenceNumber: newProject.referenceNumber || randomString(),
      customerCompany: null,
    }

    try {
      const response = await api.post('/loads/api/list-create-project/', keysToSnakeCase(payload))
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const changeLoadOrder = createAsyncThunk(
  'projects/changeLoadOrder',
  async (
    { projectLoadId, newPosition }: { projectLoadId: number; newPosition: number },
    { getState, dispatch, rejectWithValue },
  ) => {
    const { projectDetails } = (getState() as RootState).projects

    try {
      const response = await api.post(
        '/loads/api/project-load-order-change/',
        keysToSnakeCase({ projectLoadId, newPosition: newPosition - 1 }),
      )
      dispatch(getProjectLoads(projectDetails?.id || ''))
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const bulkRequestQuotes = createAsyncThunk(
  'projects/bulkRequestQuotes',
  async (
    { carrierIds, loadIds }: { carrierIds: number[]; loadIds: number[] },
    { getState, dispatch, rejectWithValue },
  ) => {
    const { projectDetails } = (getState() as RootState).projects

    try {
      const response = await api.post(
        '/customer/api/bulk-load-carrier-quotes/',
        keysToSnakeCase({ carrierIds, loadIds }),
      )
      setTimeout(() => {
        dispatch(getProjectDetails(projectDetails?.id || ''))
        dispatch(getProjectLoads(projectDetails?.id || ''))
      }, 1000)
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const getLoadCarriers = createAsyncThunk(
  'projects/getLoadCarriers',
  async (id: string | number, { rejectWithValue }) => {
    try {
      const response = await api.get(`/shipper/api/load-quotes-carriers/${id}/`)
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const getProjectCarriers = createAsyncThunk(
  'projects/geProjectCarriers',
  async (id: string | number, { getState, rejectWithValue }) => {
    const { carriersLimit: limit, carriersOffset: offset } = (getState() as RootState).rfp

    try {
      const response = await api.get(`/shipper/api/project-quote-carriers/${id}/`, {
        params: { limit, offset },
      })
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const getCarrierLoads = createAsyncThunk(
  'projects/getCarrierLoads',
  async (id: string | number, { getState, rejectWithValue }) => {
    const { projectDetails } = (getState() as RootState).projects

    try {
      const response = await api.get(
        `/shipper/api/project/${projectDetails?.id}/quote-carrier-loads/${id}/`,
      )
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

const projectsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    setLimit(state, { payload }) {
      state.limit = payload
    },
    setOffset(state, { payload }) {
      state.offset = payload
    },
    setFilters(state, { payload }) {
      state.filters = payload
    },
    setOrder(state, { payload }) {
      state.order = payload
    },
    setNewProject(state, { payload }) {
      state.newProject = payload
    },
    setUpdatedProject(state, { payload }) {
      state.updatedProject = payload
    },
    setProjectDetails(state, { payload }) {
      state.projectDetails = payload
    },
    resetUpdatedProject(state) {
      state.updatedProject = state.projectDetails
    },
    setProjectCarriersLimit(state, { payload }) {
      state.projectCarriersLimit = payload
    },
    setProjectCarriersOffset(state, { payload }) {
      state.projectCarriersOffset = payload
    },
    reset: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(getProjects.pending, state => {
        state.loading.projects = true
      })
      .addCase(getProjects.fulfilled, (state, { payload }) => {
        const { count, results } = payload
        state.loading.projects = false
        state.count = count
        if (count) state.initialProjectsCount = count
        state.projects = results
      })
      .addCase(getProjects.rejected, (state, { payload }) => {
        state.loading.projects = false
        toast.error(getErrorString(payload, 'Failed to get projects'))
      })
      .addCase(createProject.pending, state => {
        state.loading.createProject = true
      })
      .addCase(createProject.fulfilled, state => {
        state.loading.createProject = false
        state.newProject = initialProject
        toast.success('Successfully created new project')
      })
      .addCase(createProject.rejected, (state, { payload }) => {
        state.loading.createProject = false
        toast.error(getErrorString(payload, 'Failed to create new project'))
      })
      .addCase(getProjectDetails.pending, state => {
        state.loading.getProjectDetails = true
      })
      .addCase(getProjectDetails.fulfilled, (state, { payload }) => {
        state.loading.getProjectDetails = false
        state.projectDetails = payload
        state.backupProjectDetails = payload
        state.updatedProject = payload
      })
      .addCase(getProjectDetails.rejected, (state, { payload }) => {
        state.loading.getProjectDetails = false
        toast.error(getErrorString(payload, 'Failed to get project details'))
      })
      .addCase(archiveProject.pending, state => {
        state.loading.archiveProject = true
      })
      .addCase(archiveProject.fulfilled, state => {
        state.loading.archiveProject = false
        toast.success('Successfully archived project')
      })
      .addCase(archiveProject.rejected, (state, { payload }) => {
        state.loading.archiveProject = false
        toast.error(getErrorString(payload, 'Failed to archive project'))
      })
      .addCase(updateProject.pending, state => {
        state.loading.updateProject = true
      })
      .addCase(updateProject.fulfilled, state => {
        state.loading.updateProject = false
        toast.success('Successfully updated project')
      })
      .addCase(updateProject.rejected, (state, { payload }) => {
        state.loading.updateProject = false
        toast.error(getErrorString(payload, 'Failed to update project'))
      })
      .addCase(updateProjectStatus.pending, state => {
        state.loading.updateProjectStatus = true
      })
      .addCase(updateProjectStatus.fulfilled, state => {
        state.loading.updateProjectStatus = false
        toast.success('Successfully updated status')
      })
      .addCase(updateProjectStatus.rejected, (state, { payload }) => {
        state.loading.updateProjectStatus = false
        toast.error(getErrorString(payload, 'Failed to update status'))
      })
      .addCase(getProjectDocuments.pending, state => {
        state.loading.getProjectDocuments = true
      })
      .addCase(getProjectDocuments.fulfilled, (state, { payload }) => {
        state.loading.getProjectDocuments = false
        state.projectDocuments = payload.results
      })
      .addCase(getProjectDocuments.rejected, (state, { payload }) => {
        state.loading.getProjectDocuments = false
        toast.error(getErrorString(payload, 'Failed to get documents'))
      })
      .addCase(uploadProjectDocuments.pending, state => {
        state.loading.uploadProjectDocuments = true
      })
      .addCase(uploadProjectDocuments.fulfilled, state => {
        state.loading.uploadProjectDocuments = false
        state.newProject = initialProject
        toast.success('Successfully uploaded document(s)')
      })
      .addCase(uploadProjectDocuments.rejected, (state, { payload }) => {
        state.loading.uploadProjectDocuments = false
        toast.error(getErrorString(payload, 'Failed to upload document(s)'))
      })
      .addCase(deleteProjectDocument.pending, state => {
        state.loading.deleteProjectDocument = true
      })
      .addCase(deleteProjectDocument.fulfilled, state => {
        state.loading.deleteProjectDocument = false
        toast.success('Successfully deleted document')
      })
      .addCase(deleteProjectDocument.rejected, (state, { payload }) => {
        state.loading.deleteProjectDocument = false
        toast.error(getErrorString(payload, 'Failed to delete document'))
      })
      .addCase(changeLoadOrder.pending, state => {
        state.loading.changeLoadOrder = true
      })
      .addCase(changeLoadOrder.fulfilled, state => {
        state.loading.changeLoadOrder = false
        toast.success('Successfully changed load order')
      })
      .addCase(changeLoadOrder.rejected, (state, { payload }) => {
        state.loading.changeLoadOrder = false
        toast.error(getErrorString(payload, 'Failed to changed load order'))
      })
      .addCase(bulkRequestQuotes.pending, state => {
        state.loading.bulkRequestQuotes = true
      })
      .addCase(bulkRequestQuotes.fulfilled, state => {
        state.loading.bulkRequestQuotes = false
        toast.success('Successfully requested quotes')
      })
      .addCase(bulkRequestQuotes.rejected, (state, { payload }) => {
        state.loading.bulkRequestQuotes = false
        toast.error(getErrorString(payload, 'Failed to request quotes'))
      })
      .addCase(getProjectCarriers.pending, state => {
        state.loading.getProjectCarriers = true
      })
      .addCase(getProjectCarriers.fulfilled, (state, { payload }) => {
        state.loading.getProjectCarriers = false
        state.projectCarriersCount = payload.length
        state.projectCarriers = payload
      })
      .addCase(getProjectCarriers.rejected, (state, { payload }) => {
        state.loading.getProjectCarriers = false
        toast.error(getErrorString(payload, 'Failed to get carriers'))
      })
      .addCase(getLoadCarriers.rejected, (_, { payload }) => {
        toast.error(getErrorString(payload, 'Failed to get carriers'))
      })
      .addCase(getCarrierLoads.rejected, (_, { payload }) => {
        toast.error(getErrorString(payload, 'Failed to get loads'))
      })
  },
})

export const {
  setLimit,
  setOffset,
  setFilters,
  setOrder,
  setNewProject,
  reset,
  setProjectDetails,
  resetUpdatedProject,
  setUpdatedProject,
  setProjectCarriersLimit,
  setProjectCarriersOffset,
} = projectsSlice.actions

export default projectsSlice.reducer
