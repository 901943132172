import { formatCountAndPluralize, randomString } from '@common'
import { DeleteButton, Form, Select, TextInput } from '@components'
import { ExclamationTriangleIcon, PlusCircleIcon } from '@heroicons/react/24/solid'
import { useEffect, useMemo } from 'react'
import tw from 'tailwind-styled-components'

import { useAppSelector } from '../../../../app/hooks'
import { initialDropoff } from '../../../../common/constants'
import { NewManifestItem } from '../../../../common/types'

export const DropsList = ({
  item,
  setItem,
  availableQuantity,
}: {
  item: NewManifestItem
  setItem: (value: NewManifestItem) => void
  availableQuantity: number
}) => {
  const loadStops = useAppSelector(state => state.loads.loadStops)
  const destination = useAppSelector(state => state.loads.destination)

  const dropoffLocations = useMemo(
    () => [
      ...loadStops
        .filter(stop => stop.type === 'Drop-off')
        .map(stop => ({ value: (stop.order || 0) + 1, label: `Stop | ${stop.location.name}` })),
      { value: 'Destination', label: `Destination | ${destination.location.name}` },
    ],
    [loadStops.length],
  )

  const usedDropoffLocations = (item?.dropoffs || []).map(location => location.value)

  const availableDropoffLocations: any = dropoffLocations.filter(
    drop => !usedDropoffLocations.includes(drop.value as string),
  )

  const selectAdditionalDropoff = () =>
    setItem({
      ...item,
      dropoffs: [
        ...(item.dropoffs || []),
        {
          ...initialDropoff,
          id: randomString(),
          ...(availableDropoffLocations.length === 1 && {
            ...availableDropoffLocations[0],
            quantity: String(availableQuantity),
          }),
        },
      ],
    })

  useEffect(() => {
    if (dropoffLocations.length === 1) {
      const temp = [...(item.dropoffs || [])]
      temp[0] = { ...temp[0], quantity: item.quantity || '' }
      setItem({ ...item, dropoffs: temp })
    }
  }, [item.quantity, dropoffLocations.length])

  return (
    <>
      {item.dropoffs?.map((dropoff, i) => (
        <div key={dropoff.id} className='border-t border-light-gray mt-4 pt-4'>
          <Form
            className='flex gap-4'
            setFormValid={isValid => {
              if (dropoff.isValid !== isValid) {
                const temp = [...(item.dropoffs || [])]
                temp[i] = { ...temp[i], isValid }
                setItem({ ...item, dropoffs: temp })
              }
            }}
          >
            <Select
              required
              sm
              choices={availableDropoffLocations}
              className='w-full'
              field='label'
              label='Where should it be dropped off?'
              value={dropoff.label ? dropoff : null}
              onChange={(location: { value: string; label: string }) => {
                const temp = [...(item.dropoffs || [])]
                temp[i] = {
                  ...temp[i],
                  value: location ? location.value : '',
                  label: location ? location.label : '',
                }
                setItem({ ...item, dropoffs: temp })
              }}
            />
            <TextInput
              required
              sm
              label='Quantity'
              type='number'
              value={dropoff.quantity}
              onChange={quantity => {
                const temp = [...(item.dropoffs || [])]
                temp[i] = { ...temp[i], quantity }
                setItem({ ...item, dropoffs: temp })
              }}
            />
          </Form>
          <DeleteButton
            onClick={() =>
              setItem({
                ...item,
                dropoffs: (item.dropoffs || []).filter(item => item.id !== dropoff.id),
              })
            }
          />
        </div>
      ))}
      {(item?.dropoffs || []).every(dropoff => dropoff.quantity) && availableQuantity !== 0 && (
        <div className='flex justify-end text-exo-orange mt-4 font-semibold items-center gap-2'>
          <ExclamationTriangleIcon className='w-5' />
          {availableQuantity < 0
            ? 'Check item quantity'
            : formatCountAndPluralize(availableQuantity, 'Unassigned Item')}
        </div>
      )}
      {(!!loadStops.length || !(item.dropoffs || []).length) && (
        <div className='flex justify-end mt-4'>
          <SelectDropoffButton onClick={selectAdditionalDropoff}>
            <PlusCircleIcon className='w-5' />
            Select{!!(item.dropoffs || []).length && ' Additional'} Drop Off
          </SelectDropoffButton>
        </div>
      )}
    </>
  )
}

const SelectDropoffButton = tw.div`
  cursor-pointer
  hover:opacity-80
  flex
  items-center
  gap-2
  font-semibold
`
