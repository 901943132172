/*
  This is our custom Form component which supports input validation. To use:

  1. Wrap your inputs in this component like this:
  <Form setFormValid={setFormValid}>...</Form>
  2. Set the correct props on each of your inputs to validate that input. Validation props are explained below.
  This component will take care of running validations on each input and on the form as a whole.
  When an input field has an error, that error is rendered, and when the form as a whole becomes valid, setFormValid will be called.

  ####### Supported Validation props #########

  Validation by type:
  1. type='email'
  2. type='tel'
  3. type='time'
  4. type='number'
  5. type='address' (use for AddressForm)

  Validation by value length:
  1. minLength
  2. maxLength
  3. length (exact)

  Validation by regex:
  1. regex={/^[a-z]{2}(?:,[a-z]{2})*$/i}

  Validation by value
  1. min (if the value should be more than a certain number, e.g. min={1})
  2. max (if the value should be less than a certain number, e.g. max={5})
  3. min, max (if the value should be between two numbers)

  Custom validation
  1. For custom validation, provide the validator prop, e.g.
  validator={(value: string) => {
    // return true if the value is valid and false otherwise
  }}

  New validation types and props can be added in handleFieldError.
*/

import { isArray, isEmpty } from 'lodash-es'
import React, { cloneElement, ReactNode, useEffect, useMemo } from 'react'
import { IMask } from 'react-imask'

import {
  getCurrencyMaskOptions,
  getPhoneMaskOptions,
  getSimpleNumberMaskOptions,
  getTimeMaskOptions,
  validateEmail,
  validatePhoneNumber,
} from '../../common/utils'

type ValidationProps = {
  value: any
  minLength?: number
  maxLength?: number
  length?: number
  type?: string
  min?: number
  max?: number
  validator?: (value: string) => boolean | string | void
  regex?: any
  error?: string
  maskOptions?: IMask.AnyMaskedOptions
  label?: string
  required?: boolean
  sign?: string
}

const handleFieldError = (props: ValidationProps) => {
  const {
    value = '',
    minLength,
    maxLength,
    length,
    type = 'text',
    min,
    max,
    validator,
    regex,
    error,
    required,
    sign,
  } = props || {}

  if (isEmpty(value) && Object.prototype.toString.call(value) !== '[object Date]' && required)
    return ''

  if (isEmpty(value) && !validator) return ''

  const errorString = (text: string) => error || text

  // minLength, maxLength, exact length, min and max value validation
  if (minLength && value.length < minLength)
    return errorString(`Please enter at least ${minLength} characters`)
  if (maxLength && value.length > maxLength)
    return errorString(`Please enter less than ${maxLength} characters`)
  if (length && value.length !== length)
    return errorString(`Please enter exactly ${length} characters`)
  if (min && max && (Number(value) < min || Number(value) > max))
    return errorString(`The value should be between ${min} and ${max}`)
  if (min && !max && Number(value) < min)
    return errorString(`The value should be more or equal to ${min}`)
  if (max && !min && Number(value) > max)
    return errorString(`The value should be less or equal to ${max}`)
  if (type === 'date' && !value.toString()) return errorString('Please enter a valid date')

  // validation by type
  if (type === 'email' && !validateEmail(value, true))
    return errorString('Please enter a valid email')
  if (type === 'tel' && !validatePhoneNumber(value))
    return errorString('Please enter a valid phone number')
  if (type === 'time' && value.length < 4) return errorString('Please enter a valid time')
  if (
    type === 'address' &&
    (!value.city ||
      !value.address ||
      !value.country ||
      (!['MEX', 'MX'].includes(value.country) &&
        ((!value.state && !value.stateProvinceRegion && !value.caProvince) ||
          (!value.usZipcode && !value.postalCode && !value.caPostalCode))))
  )
    return Object.keys(value)
      .filter(key => !['id', 'isValid', 'name'].includes(key))
      .every(key => !value[key])
      ? ' '
      : errorString('Please enter a valid address')

  if (type === 'margin') {
    const margin = Math.abs(Number(value))
    if (sign === '-' && margin > 50) return 'The value should be less or equal to 50'
    if (sign === '+' && margin > 400) return 'The value should be less than 400'
  }

  // custom validator
  if (validator) {
    const result = validator(value)
    return typeof result === 'boolean' && !result
      ? errorString('Please enter a valid value')
      : typeof result === 'string'
        ? result
        : ''
  }

  // custom regex
  if (regex && !regex.test(value)) return errorString('Please enter a valid value')

  return ''
}

/*
  Update input props:
  1. If handleFieldError returns an error, we send this error to the input as prop
  2. If type is 'time' or 'number', we replace it with 'text' to avoid modifications made to inputs when these default types are provided
  3. If type is 'tel' or 'time' we add corresponding maskOptions, otherwise provided maskOptions are used
  4. If type is 'number', we add { mask: Number } to ensure only digits can be entered in this field
 */
const getUpdatedProps = (props: ValidationProps) => ({
  error: handleFieldError(props),
  type: ['time', 'number'].includes(props?.type || '') ? 'text' : props?.type,
  ...(props?.type === 'tel' && !props?.maskOptions && { maskOptions: getPhoneMaskOptions() }),
  ...(props?.type === 'time' && { maskOptions: getTimeMaskOptions(), inputMode: 'numeric' }),
  ...(props?.type === 'number' && {
    maskOptions: props?.maskOptions || getSimpleNumberMaskOptions(),
    inputMode: 'decimal',
  }),
  ...(props?.type === 'currency' && {
    maskOptions: getCurrencyMaskOptions(),
    inputMode: 'decimal',
  }),
})

const noChildrenInChild = (child: any) =>
  !child.props.children || typeof child.props.children === 'string'

// This function checks whether there's a single child inside the div and converts it to array
const convertChildrenToArray = (child: any) =>
  isArray(child.props.children) ? child.props.children : [child.props.children]

const isDivOrTWComponent = (child: any) =>
  child?.type === 'div' ||
  child?.type?.toString() === React.Fragment.toString() ||
  (child?.type &&
    Object.getOwnPropertySymbols(child.type).find(sym => String(sym).includes('isTwElement')))

const isStringOrBoolean = (child: any) => typeof child === 'boolean' || typeof child === 'string'

// This function recursively maps through the provided array of children to find inputs nested inside divs and update their props using the getUpdatedProps function
const updateInputProps = (array: any) =>
  array.map((child: any, index: number) => {
    if (!child) return
    if (isStringOrBoolean(child)) return child
    if (isDivOrTWComponent(child)) {
      if (noChildrenInChild(child)) return child
      const children = convertChildrenToArray(child)
      return {
        ...child,
        props: {
          ...child.props,
          children: updateInputProps(children),
        },
      }
    }
    return cloneElement(child, { ...getUpdatedProps(child?.props), key: index })
  })

// This function recursively maps through the provided array of children to find inputs nested inside divs and flatten the array
const flattenChildren = (array: any) =>
  array
    .map((child: any) => {
      if (isStringOrBoolean(child)) return
      if (isDivOrTWComponent(child)) {
        if (noChildrenInChild(child)) return child
        const children = convertChildrenToArray(child)
        return flattenChildren(children)
      }
      return child
    })
    .flat()
    .filter(Boolean)

export const Form = ({
  children = [],
  setFormValid = () => {},
  autoComplete = 'on',
  className,
}: {
  children: ReactNode
  setFormValid?: (value: boolean) => void
  autoComplete?: string
  className?: string
}) => {
  useEffect(() => {
    setFormValid(isValid)
  }, [children])

  const updatedChildren = isArray(children) ? children : [children]

  // the array to be rendered
  const childrenToRender = useMemo(() => updateInputProps(updatedChildren), [children])

  // the array to iterate through to validate fields
  const flatChildren = useMemo(() => flattenChildren(childrenToRender), [children])

  // fields with required={true}
  const requiredChildren = useMemo(
    () => flatChildren.filter((child: any) => !!child?.props?.required),
    [children],
  )

  // check if all required children are filled out and none of the fields have errors
  const isValid = useMemo(
    () =>
      requiredChildren.every(
        (child: any) => child?.props?.value?.toString() && !isEmpty(String(child?.props?.value)),
      ) && flatChildren.every((child: any) => !child?.props?.error),
    [children],
  )

  // render children with updated props
  return (
    <form autoComplete={autoComplete} className={className} onSubmit={e => e.preventDefault()}>
      {childrenToRender}
    </form>
  )
}
