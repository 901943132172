import { Button } from '@components'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { useAppSelector, useAppThunkDispatch } from '../../../app/hooks'
import { trackEvent } from '../../../common/tracking'
import { CustomerQuote } from '../../../common/types'
import { setQuoteDetails, setRateBookingDetails } from '../../../redux/createNewLoadSlice'
import { QuoteDetails } from './QuoteDetails'

export const LoadCreationDetails = ({ quote }: { quote: CustomerQuote }) => {
  const navigate = useNavigate()

  const dispatch = useAppThunkDispatch()

  const rateBookingDetails = useAppSelector(state => state.createNewLoad.rateBookingDetails)
  const potrEdiId = useAppSelector(state => state.user.user?.potrEdiId)
  const currentQuote = useAppSelector(state => state.quotingTool.currentQuote)

  useEffect(() => {
    trackEvent('Quotes - Clicked Book This Rate on Quote', {
      quoteId: quote?.id,
      isEDIUser: !!potrEdiId,
    })
  }, [])

  return (
    <div className='p-4 lg:p-10'>
      <div className='font-bold border-b border-brand-dark text-xl pb-4 lg:pb-6'>
        Confirm Booking
      </div>
      <QuoteDetails
        containerClassName='border-none w-full px-0 m-0 min-w-none'
        currentQuote={currentQuote}
        loadPickupDate={quote.pickupDate}
        showButtons={false}
        showOtherQuotes={false}
      />
      <div className='font-bold text-base my-4 max-lg:-mt-4'>Choose your preferred way to book</div>
      <div className='flex justify-between gap-4'>
        <Button
          fullWidth
          className='w-full'
          type='secondary'
          onClick={() => {
            dispatch(setQuoteDetails(quote))
            dispatch(setRateBookingDetails({ ...rateBookingDetails, modalStep: 1 }))
          }}
        >
          Upload Load Document(s)
        </Button>
        <Button
          fullWidth
          className='w-full'
          type='secondary'
          onClick={() => {
            dispatch(setQuoteDetails(quote))
            navigate('/loads/new-load')
          }}
        >
          Enter Load Details
        </Button>
        {potrEdiId && (
          <Button
            fullWidth
            className='w-full'
            type='secondary'
            onClick={() => {
              dispatch(setQuoteDetails(quote))
              dispatch(setRateBookingDetails({ ...rateBookingDetails, modalStep: 2 }))
            }}
          >
            EDI
          </Button>
        )}
      </div>
    </div>
  )
}
