import { Menu } from '@headlessui/react'
import { ChevronDownIcon, EllipsisVerticalIcon } from '@heroicons/react/24/solid'
import { ReactNode, useCallback } from 'react'
import tw from 'tailwind-styled-components'

import { EaseInOutTransition } from '../transitions'

export const MoreDropdown = ({
  items,
  withBorder,
  className,
  icon,
  dropdownClassName,
  iconColor = 'dark-blue',
}: {
  items: Array<{ label: ReactNode; onClick: () => void; disabled?: boolean; className?: string }>
  withBorder?: boolean
  className?: string
  icon?: string
  dropdownClassName?: string
  iconColor?: string
}) => {
  const Icon = useCallback(() => {
    const iconClassName = `w-4 text-${iconColor}`
    switch (icon) {
      case 'chevron':
        return <ChevronDownIcon className={iconClassName} />
      default:
        return <EllipsisVerticalIcon className={iconClassName} />
    }
  }, [icon])

  if (!items.length) return null

  return (
    <MenuContainer as='div' className={className}>
      <Menu.Item>
        <MainButton $withBorder={withBorder} data-testid='menu-button'>
          <Icon />
        </MainButton>
      </Menu.Item>
      <EaseInOutTransition>
        <Dropdown className={dropdownClassName}>
          {items.map((item, i) => (
            <MenuItem key={i} disabled={item.disabled} onClick={item.onClick}>
              <div>{item.label}</div>
            </MenuItem>
          ))}
        </Dropdown>
      </EaseInOutTransition>
    </MenuContainer>
  )
}

const MenuContainer = tw(Menu)`
  relative
  inline-block
  text-left
  text-xs
`

const MenuItem = tw(Menu.Button)<{ disabled?: boolean }>`
  transition-all
  whitespace-nowrap
  py-3
  px-4
  w-full
  text-left
  ${({ disabled }) => (disabled ? 'text-dark-gray' : 'hover:bg-lighter-blue cursor-pointer')}
`

const MainButton = tw(Menu.Button)<{ $withBorder?: boolean }>`
  hover:opacity-80
  transition-all
  cursor-pointer
  ${({ $withBorder }) =>
    $withBorder && 'border border-dark-blue rounded-md w-8 h-8 flex items-center justify-center'}
`

const Dropdown = tw(Menu.Items)`
  origin-top-right
  mt-2
  rounded-md
  shadow-lg
  ring-1
  ring-black
  ring-opacity-5
  focus:outline-none
  bg-white
  left-auto
  bottom-auto
  absolute
  right-10
  py-1
  -mt-[33px]
  z-[999]
`
