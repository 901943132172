import tw from 'tailwind-styled-components'

import placeholder from '../../../assets/images/quotingTool/quotePlaceholder.png'

export const QuotesPlaceholder = () => (
  <Container>
    <img alt='empty' className='w-56 opacity-30 mb-8' src={placeholder} />
    <div className='text-2xl'>Ready to use the tool?</div>
    <div className='text-base mt-1 max-lg:text-center max-lg:px-4'>
      Enter your load details and route, then click “Get Quote”
    </div>
  </Container>
)

const Container = tw.div`
  flex
  flex-col
  justify-center
  items-center
  text-dark-gray
  font-semibold
  poppins
  h-full
  max-lg:py-8
`
