import {
  CatchError,
  formatAxiosErrorToPayload,
  getErrorString,
  getObjectDifferences,
  HereMapLocation,
  keysToCamelCase,
  keysToSnakeCase,
} from '@common'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { isEmpty } from 'lodash-es'
import { toast } from 'react-toastify'

import { api } from '../api/api'
import { RootState } from '../app/store'
import { initialFilters } from '../common/constants'
import {
  AddressData,
  LatitudeLongitude,
  LatLng,
  NewLocation,
  SearchFilters,
  ShipperLocation,
  TableOrder,
} from '../common/types'
import { getOrderingString } from '../common/utils'

type LocationsState = {
  loading: {
    addresses: boolean
    createLocation: boolean
    cities: boolean
    routeDistance: boolean
    getShipperLocations: boolean
    getLocationDetails: boolean
    updateFacilityDetails: boolean
    archiveFacility: boolean
  }
  addresses: HereMapLocation[]
  cities: (LatitudeLongitude & { city: string; state: string; country: string })[]
  popularCities: [string, string, string, number, number][]
  requestTimestamps: {
    cities: number | null
  }
  routeDistance: number
  routeSegments: Array<{
    polyline: any
    distance: number
    origin: LatLng
    destination: LatLng
  }>
  count: number
  offset: number
  limit: number
  order: TableOrder
  filters: SearchFilters
  shipperLocations: ShipperLocation[]
  locationDetails: ShipperLocation
  backupLocationDetails: ShipperLocation
  facilityAddressData: AddressData
  backupFacilityAddressData: AddressData
  initialFacilitiesCount: number
}

const initialState: LocationsState = {
  loading: {
    addresses: false,
    createLocation: false,
    cities: false,
    routeDistance: false,
    getShipperLocations: false,
    getLocationDetails: false,
    updateFacilityDetails: false,
    archiveFacility: false,
  },
  addresses: [],
  cities: [],
  popularCities: [],
  requestTimestamps: {
    cities: null,
  },
  routeDistance: 0,
  routeSegments: [],
  count: 0,
  filters: initialFilters,
  limit: 50,
  offset: 0,
  order: { label: '', direction: '', key: '' },
  shipperLocations: [],
  locationDetails: {},
  backupLocationDetails: {},
  facilityAddressData: {},
  backupFacilityAddressData: {},
  initialFacilitiesCount: 0,
}

export const createLocation = createAsyncThunk(
  'locations/createLocation',
  async (
    { data, addressData }: { data: NewLocation; addressData: AddressData },
    { dispatch, rejectWithValue },
  ) => {
    const payload = keysToSnakeCase({
      ...data,
      location: {
        ...addressData,
        addressLines: addressData.address,
        state: addressData.state || addressData.stateProvinceRegion,
        ...(addressData.country === 'USA' && { country: 'US' }),
        ...(addressData.country === 'CAN' && { country: 'CA' }),
        ...(addressData.country === 'MEX' && { country: 'MX' }),
      },
    })

    try {
      const response = await api.post('/customer/api/shipper-locations/', payload)
      dispatch(getShipperLocations({}))
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const getShipperLocations = createAsyncThunk(
  'locations/getShipperLocations',
  async (
    {
      query,
      limit: limitOverride,
      signal,
    }: { query?: string; limit?: number; signal?: AbortSignal },
    { getState, rejectWithValue },
  ) => {
    const {
      limit,
      offset,
      filters,
      order: { label, direction, key },
    } = (getState() as RootState).locations

    const ordering = getOrderingString(label, direction, key, '-id')

    try {
      const response = await api.get('/customer/api/shipper-locations/', {
        params: {
          limit: limitOverride ?? limit,
          offset,
          ordering,
          name: filters.name,
          city: filters.city,
          state: filters.state,
          postal_code: filters.postalCode,
          search: query,
        },
        signal,
      })
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      if (err.name === 'AbortError') {
        return rejectWithValue(formatAxiosErrorToPayload(err))
      }
    }
  },
)

export const getLocationDetails = createAsyncThunk(
  'locations/getLocationDetails',
  async (id: string | number, { rejectWithValue }) => {
    try {
      const response = await api.get('/customer/api/shipper-locations/', { params: { id } })
      return keysToCamelCase(response.data.results?.[0])
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const archiveFacility = createAsyncThunk(
  'locations/archiveFacility',
  async (id: string | number, { rejectWithValue }) => {
    try {
      const response = await api.delete(`/customer/api/shipper-locations/${id}`)
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const updateFacilityDetails = createAsyncThunk(
  'locations/updateFacilityDetails',
  async (_, { getState, dispatch, rejectWithValue }) => {
    const {
      locationDetails,
      backupLocationDetails,
      facilityAddressData,
      backupFacilityAddressData,
    } = (getState() as RootState).locations

    // TODO: fix...

    const addressData = getObjectDifferences(facilityAddressData, backupFacilityAddressData)
    const locationData = {
      ...addressData,
      addressLines: addressData.address,
      state: addressData.stateProvinceRegion,
      ...(facilityAddressData.country === 'USA' && { country: 'US' }),
      ...(facilityAddressData.country === 'CAN' && { country: 'CA' }),
      ...(facilityAddressData.country === 'MEX' && { country: 'MX' }),
    }

    const location = Object.fromEntries(Object.entries(locationData).filter(([, value]) => !!value))

    delete location.addressDisplay
    delete location.caPostalCode
    delete location.currentPlace
    delete location.usZipcode
    delete location.caProvince
    delete location.country

    const payload = {
      ...getObjectDifferences(locationDetails, backupLocationDetails),
      ...(!isEmpty(location) && {
        location: {
          ...location,
          addressLines: addressData.address || locationDetails.location?.addressLines,
          city: addressData.city || locationDetails.location?.city,
          state: addressData.stateProvinceRegion || locationDetails.location?.state,
          postalCode: addressData.usZipcode || locationDetails.location?.postalCode,
          country: locationData.country,
        },
      }),
    }

    try {
      const response = await api.patch(
        `/customer/api/shipper-locations/${locationDetails.id}/`,
        keysToSnakeCase(payload),
      )
      dispatch(getLocationDetails(locationDetails.id || ''))
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const geocode = createAsyncThunk(
  'locations/geocode',
  async (
    {
      address,
      citiesOnly,
      callback = () => {},
      signal,
    }: { address: string; citiesOnly?: boolean; callback?: any; signal?: AbortSignal },
    { rejectWithValue },
  ) => {
    if (!address) return []

    try {
      const response = await api.get('/locations/api/here-map-geocode-proxy/', {
        params: { query: encodeURI(address) },
        signal,
      })
      if (citiesOnly) {
        return keysToCamelCase(
          response.data.items.filter((item: any) => item.resultType === 'locality'),
        )
      }
      callback && callback(response.data.items)
      return keysToCamelCase(response.data.items)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const getPopularCities = createAsyncThunk('locations/getPopularCities', async () =>
  api.get('/locations/geocode/popular-cities-for-autocomplete-v5/').then(({ data }) => data),
)

export const getCities = createAsyncThunk(
  'locations/getCities',
  async (
    {
      query,
      countries = [],
      signal,
    }: {
      query: string
      countries?: string[]
      signal?: AbortSignal
    },
    { rejectWithValue },
  ) => {
    const requestTimestamp = Date.now()
    try {
      const response = await api.get('/locations/geocode/search/', {
        params: { query, countries_filter: countries.join(',') },
        signal,
      })

      return { requestTimestamp, cities: keysToCamelCase(response.data) }
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

const locationsSlice = createSlice({
  name: 'locations',
  initialState,
  reducers: {
    setLimit(state, { payload }) {
      state.limit = payload
    },
    setOffset(state, { payload }) {
      state.offset = payload
    },
    setOrder(state, { payload }) {
      state.order = payload
    },
    setFilters(state, { payload }) {
      state.filters = payload
    },
    setLocationDetails(state, { payload }) {
      state.locationDetails = payload
    },
    setFacilityAddressData(state, { payload }) {
      state.facilityAddressData = payload
    },
  },
  extraReducers(builder) {
    builder
      .addCase(geocode.pending, state => {
        state.loading.addresses = true
      })
      .addCase(geocode.fulfilled, (state, { payload }) => {
        state.loading.addresses = false
        state.addresses = payload
      })
      .addCase(geocode.rejected, state => {
        state.loading.addresses = false
      })
      .addCase(createLocation.pending, state => {
        state.loading.createLocation = true
      })
      .addCase(createLocation.fulfilled, state => {
        state.loading.createLocation = false
        toast.success('Successfully created location')
      })
      .addCase(createLocation.rejected, (state, { payload }: any) => {
        state.loading.createLocation = false
        toast.error(getErrorString(payload, 'Failed to create location'))
      })
      .addCase(getCities.pending, state => {
        state.loading.cities = true
      })
      .addCase(getCities.fulfilled, (state, { payload }) => {
        // @ts-ignore
        const { requestTimestamp, cities } = payload
        // NOTE: this request is intentionally un-debounced for a more responsive feel
        // To handle race conditions - we store/compare timestamps from when the request was started
        if (!state.requestTimestamps.cities || requestTimestamp > state.requestTimestamps.cities) {
          state.loading.cities = false
          state.cities = cities
          state.requestTimestamps.cities = requestTimestamp
        }
      })
      .addCase(getCities.rejected, state => {
        state.loading.cities = false
      })
      .addCase(getPopularCities.pending, state => {
        state.loading.cities = true
      })
      .addCase(getPopularCities.fulfilled, (state, { payload }) => {
        state.loading.cities = false
        state.popularCities = payload
      })
      .addCase(getPopularCities.rejected, state => {
        state.loading.cities = false
      })
      .addCase(getShipperLocations.pending, state => {
        state.loading.getShipperLocations = true
      })
      .addCase(getShipperLocations.fulfilled, (state, { payload }) => {
        const { count = 0, results = [] } = payload || {}
        state.loading.getShipperLocations = false
        state.count = count
        if (count) state.initialFacilitiesCount = count
        state.shipperLocations = results.map((result: ShipperLocation) => ({
          ...result,
          title: result.name,
        }))
      })
      .addCase(getShipperLocations.rejected, state => {
        state.loading.getShipperLocations = false
      })
      .addCase(getLocationDetails.pending, state => {
        state.loading.getLocationDetails = true
      })
      .addCase(getLocationDetails.fulfilled, (state, { payload }) => {
        state.loading.getLocationDetails = false
        const data = {
          ...payload,
          addressLines: payload.location?.addressLines,
          location: {
            ...payload.location,
            addressLines: payload.location?.addressLines,
            country:
              payload.location.country === 'US'
                ? 'USA'
                : payload.location.country === 'CA'
                  ? 'CAN'
                  : payload.location.country,
          },
        }
        const addressData = {
          ...payload.location,
          address: payload.location?.addressLines,
          addressDisplay: payload.location?.display,
          addressLines: payload.location?.addressLines,
          country:
            payload.location.country === 'US'
              ? 'USA'
              : payload.location.country === 'CA'
                ? 'CAN'
                : payload.location.country,
          state: payload.location?.state,
          stateProvinceRegion: payload.location?.state,
          postalCode: payload.location?.postalCode,
        }
        state.facilityAddressData = addressData
        state.backupFacilityAddressData = addressData
        state.locationDetails = data
        state.backupLocationDetails = data
      })
      .addCase(getLocationDetails.rejected, state => {
        state.loading.getLocationDetails = false
      })
      .addCase(updateFacilityDetails.pending, state => {
        state.loading.updateFacilityDetails = true
      })
      .addCase(updateFacilityDetails.fulfilled, state => {
        state.loading.updateFacilityDetails = false
        toast.success('Successfully updated facility')
      })
      .addCase(updateFacilityDetails.rejected, (state, { payload }) => {
        state.loading.updateFacilityDetails = false
        toast.error(getErrorString(payload, 'Failed to update facility details'))
      })
      .addCase(archiveFacility.pending, state => {
        state.loading.archiveFacility = true
      })
      .addCase(archiveFacility.fulfilled, state => {
        state.loading.archiveFacility = false
        toast.success('Successfully archived facility')
      })
      .addCase(archiveFacility.rejected, (state, { payload }) => {
        state.loading.archiveFacility = false
        toast.error(getErrorString(payload, 'Failed to archive facility'))
      })
  },
})

export const {
  setLimit,
  setOffset,
  setOrder,
  setFilters,
  setLocationDetails,
  setFacilityAddressData,
} = locationsSlice.actions

export default locationsSlice.reducer
