/**
 * Convert a JSON object to FormData so that it can be sent as a multipart req
 */

// const test_input = {
//   obj1: {
//       obj2: [
//           {
//               prop1: "value1",
//               prop2: "value2",
//           },
//           {
//               prop1: "value1",
//               prop2: "value2",
//           },
//       ],
//       obj3: {
//           prop1: "value1",
//       },
//   }
// }

// const test_output = {
//   "obj1.obj2[0]prop1": "value1",
//   "obj1.obj2[0]prop2": "value2",
//   "obj1.obj2[1]prop1": "value1",
//   "obj1.obj2[1]prop2": "value2",
//   "obj1.obj3.prop1": "value1",
// }

function flatten(obj: Record<string, any>, prefix = '') {
  const result: Record<string, any> = {}

  for (const [key, value] of Object.entries(obj)) {
    const connector = prefix.at(-1) === ']' ? '' : '.'
    const newKey = prefix ? `${prefix}${connector}${key}` : key

    if (Array.isArray(value)) {
      value.forEach((item, index) => {
        Object.assign(result, flatten(item, `${newKey}[${index}]`))
      })
    } else if (typeof value === 'object' && value !== null && value.constructor === Object) {
      Object.assign(result, flatten(value, newKey))
    } else {
      result[newKey] = value
    }
  }

  return result
}

export function jsonToFormData(json: Record<string, any>) {
  const formData = new FormData()

  for (const [key, value] of Object.entries(flatten(json))) {
    formData.append(key, value)
  }

  return formData
}
