import Lottie from 'lottie-react'

import logoAnimation from './logo-animation.json'

export type LoaderLogoParams = {
  className?: string
  size?: number
}

export const LoaderLogo = ({ className = '', size = 20 }: LoaderLogoParams) => (
  <div className={`flex items-center justify-center w-full h-full ${className}`}>
    <Lottie loop animationData={logoAnimation} className={`w-${size} h-${size}`} />
  </div>
)
