import { store } from '../../app/store'
import { addNewNotif } from '../../redux/notificationsSlice'
import { TMSEvent } from './socket'

export default {
  carrierQuotedLoad(notification: TMSEvent) {
    store.dispatch(addNewNotif(notification))
  },
  carrierEditedLoadQuote(notification: TMSEvent) {
    store.dispatch(addNewNotif(notification))
  },
  newUserJoined(notification: TMSEvent) {
    store.dispatch(addNewNotif(notification))
  },
  carrierRejectedInvitation(notification: TMSEvent) {
    store.dispatch(addNewNotif(notification))
  },
  carrierAcceptedInvitation(notification: TMSEvent) {
    store.dispatch(addNewNotif(notification))
  },
  carrierUploadedLoadDocument(notification: TMSEvent) {
    store.dispatch(addNewNotif(notification))
  },
  loadDelivered(notification: TMSEvent) {
    store.dispatch(addNewNotif(notification))
  },
  loadOnTrack(notification: TMSEvent) {
    store.dispatch(addNewNotif(notification))
  },
  loadOffTrack(notification: TMSEvent) {
    store.dispatch(addNewNotif(notification))
  },
  carrierRejectedLoadQuote(notification: TMSEvent) {
    store.dispatch(addNewNotif(notification))
  },
  carrierRejectedLoadTender(notification: TMSEvent) {
    store.dispatch(addNewNotif(notification))
  },
  carrierAcceptedLoadTender(notification: TMSEvent) {
    store.dispatch(addNewNotif(notification))
  },
}
