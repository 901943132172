import { ArrowLeftIcon } from '@heroicons/react/24/outline'
import { ReactElement, useContext } from 'react'
import { NavLink } from 'react-router-dom'
import tw from 'tailwind-styled-components'

import { BackStackContext } from '../../common'

export type PageHeaderProps = {
  // accept hero icons type
  icon?: any
  title: string
  bottomBordered?: boolean
  rightContent?: () => ReactElement
  middleContent?: () => ReactElement
}

export type LinkPageHeaderProps = {
  title: string | number | JSX.Element
  subtitle?: string | number
  loading?: boolean
  bottomBordered?: boolean
  to?: string
  rightContent?: () => ReactElement
  middleContent?: () => ReactElement
  href?: string
  onClick?: () => void
}

export type MultiLinkPageHeaderProps = {
  title: string | number
  secondTitle?: string | number
  subtitle?: string | number
  secondSubtitle?: string | number
  loading?: boolean
  bottomBordered?: boolean
  arrowTo?: string
  titleTo?: string
  secondTitleTo?: string
  rightContent?: () => ReactElement
  middleContent?: () => ReactElement
  onBackHistory?: boolean
}

export const PageHeader = ({
  title,
  bottomBordered = false,
  icon: Icon,
  middleContent: MiddleContent,
  rightContent: RightContent,
}: PageHeaderProps) => (
  <Container $bottomBordered={bottomBordered}>
    <header className='flex items-center'>
      {Icon && <Icon className='w-6 md:w-7 mr-4' stroke='black' />}
      <h1 className='text-base md:text-xl font-semibold capitalize'>{title}</h1>
    </header>
    {MiddleContent && <MiddleContent />}
    {RightContent && <RightContent />}
  </Container>
)

export const LinkPageHeader = ({
  title,
  subtitle,
  bottomBordered = false,
  to = '',
  middleContent: MiddleContent,
  rightContent: RightContent,
  href,
  onClick,
}: LinkPageHeaderProps) => {
  const { prevRoute } = useContext(BackStackContext)

  const Title = onClick ? PageTitleDiv : PageTitle

  return (
    <Container $bottomBordered={bottomBordered}>
      {/*@ts-ignore*/}
      <Title {...(!onClick ? { to: href || prevRoute || to } : { onClick })}>
        <ArrowLeftIcon className='w-5 md:w-6' />
        <div className='flex flex-col'>
          <div className='text-base md:text-xl font-semibold ml-4 max-lg:max-w-[30vw] max-lg:overflow-ellipsis max-lg:truncate'>
            {title}
          </div>
          {subtitle && (
            <div className='text-base font-semibold text-dark-gray ml-4'>{subtitle}</div>
          )}
        </div>
      </Title>
      {MiddleContent && <MiddleContent />}
      {RightContent && <RightContent />}
      {MiddleContent && !RightContent && <div />}
    </Container>
  )
}

export const MultiLinkPageHeader = ({
  title,
  subtitle,
  bottomBordered = false,
  arrowTo = '',
  titleTo = '',
  middleContent: MiddleContent,
  rightContent: RightContent,
  secondTitle,
  secondSubtitle,
  secondTitleTo = '',
  onBackHistory = false,
}: MultiLinkPageHeaderProps) => (
  <Container $bottomBordered={bottomBordered}>
    <div className='flex items-center'>
      <PageTitle
        to={onBackHistory ? '' : arrowTo}
        onClick={onBackHistory ? () => history.back() : () => {}}
      >
        <ArrowLeftIcon className='w-6 h-6' />
      </PageTitle>
      <PageTitle className='ml-4' target='_blank' to={titleTo}>
        <div className='flex flex-col'>
          <div className='text-2xl font-semibold'>{title}</div>
          {subtitle && <div className='text-base font-semibold text-dark-gray'>{subtitle}</div>}
        </div>
      </PageTitle>
      {secondTitle && (
        <PageTitle className='ml-2' target='_blank' to={secondTitleTo}>
          <div className='flex flex-col'>
            <div className='text-2xl font-semibold'>{`/ ${secondTitle}`}</div>
            {secondSubtitle && (
              <div className='text-base font-semibold text-dark-gray ml-4'>{secondSubtitle}</div>
            )}
          </div>
        </PageTitle>
      )}
    </div>

    {MiddleContent && <MiddleContent />}
    {RightContent && <RightContent />}
  </Container>
)

const PageTitle = tw(NavLink)`
  flex
  items-center
  hover:text-link
  cursor-pointer
  transition-all
  w-fit
`

const PageTitleDiv = tw.div`
  flex
  items-center
  hover:text-link
  cursor-pointer
  transition-all
  w-fit
`

const Container = tw.div<{ $bottomBordered: boolean }>`
  flex
  items-center
  justify-between
  p-4
  h-14
  max-h-14
  md:h-16
  md:max-h-16
  w-full
  bg-white
  ${({ $bottomBordered }) => $bottomBordered && 'border-b-2 border-light-gray'}
`
