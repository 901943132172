import { NewLoadDimensions } from '../../redux/createNewLoadSlice'
import { NewLoadLocation, NewManifestItem, QuoteLocation } from '../types'
import { formatDateForBackend, randomString } from './text-helpers'

export const mapLoadStatus = (loadStatus = '') => {
  const status = loadStatus.toLowerCase()
  if (['claim'].includes(status)) return 'Billing In Progress'
  if (['available', 'booked'].includes(status)) return 'Tender Accepted'
  return loadStatus
}

// Returns remaining available quantity for the provided pickup item based on its related dropoff items' quantities
export const getAvailableQuantity = (
  pickupItem: NewManifestItem,
  dropoffItems: Array<NewManifestItem>,
) => {
  const relatedItems = dropoffItems.filter(dropItem => dropItem.manifest === pickupItem.id)

  let quantity = 0

  relatedItems.forEach(item => (quantity += parseInt((item.quantity || 0).toString() || '0')))

  return parseInt(pickupItem.quantity?.toString() || '0') - quantity
}

const formatLocation = (
  location: NewLoadLocation & {
    address: string | null
    stateProvinceRegion: string | null
    latitude: string | null
    longitude: string | null
    name: string | null
    country: string | null
    state: string | null
    addressLines: string | null
    geoData: { lat: number; lng: number }
  },
) => ({
  ...(location.location || location),
  address: location.location?.addressLines || location.address || location.addressLines || '',
  stateProvinceRegion: location.location?.state || location.stateProvinceRegion || location.state,
  latitude: location.location?.geoData?.lat || location?.latitude || location.geoData.lat,
  longitude: location.location?.geoData?.lng || location?.longitude || location.geoData.lng,
  name: location.location?.name || location.name,
  ...((location.location?.country === 'US' || location?.country === 'US') && { country: 'USA' }),
  ...((location.location?.country === 'CA' || location?.country === 'CA') && { country: 'CAN' }),
})

export const formatLoadStop = (stop: Partial<NewLoadLocation>, i?: number) => ({
  location: {
    ...formatLocation(stop.location),
    contactName: stop.contactName,
    contactPhone: stop.contactPhone,
  },
  phone: stop.contactPhone,
  contactName: stop.contactName,
  notes: stop.notes,
  stopType: ['Pick-up', 'Pick Up', 'pickup'].includes(stop.type || '') ? 1 : 2,
  shipmentNumber: stop.shipmentNumber,
  earlyTime: stop.earlyTime || null,
  stopEarlyTime: stop.earlyTime || null,
  lateTime: stop.lateTime || null,
  stopLateTime: stop.lateTime || null,
  ...(stop.date && { stopDate: formatDateForBackend(stop.date) }),
  ...(i !== undefined && { order: i }),
})

export const normalizeLoadPayload = ({
  origin,
  destination,
  loadStops,
  equipmentType,
  specialRequirements = [],
  hazmatUhn,
  highValueAmount,
  customerReferenceId,
  emptyLoad,
  documents,
  dimensions,
  mode,
  orders,
}: {
  origin: Partial<NewLoadLocation>
  destination: Partial<NewLoadLocation>
  loadStops: Partial<NewLoadLocation>[]
  equipmentType?: string
  specialRequirements?: Array<number>
  hazmatUhn?: string
  highValueAmount?: string
  customerReferenceId?: string
  emptyLoad?: boolean
  documents?: { file: string; name: string }[]
  dimensions?: NewLoadDimensions
  mode?: number | null
  orders?: any[]
}) => ({
  customerReferenceId: customerReferenceId || randomString(),
  poNumber: null,
  equipmentType: equipmentType || 'Unknown',
  pieces: '0',
  shipperPickupNumber: origin.shipmentNumber,
  shipperPickupNotes: origin.notes || null,
  consigneeDeliveryNumber: destination.shipmentNumber,
  consigneeDeliveryNotes: destination.notes || null,
  customerPrice: 0,
  description: dimensions?.description || null,
  loadLength: dimensions?.length,
  loadWidth: dimensions?.width,
  loadHeight: dimensions?.height,
  loadWeight: dimensions?.weight,
  temperature: dimensions?.temperature,
  mode: mode || null,
  loadstopSet: loadStops.map((stop, i) => formatLoadStop(stop, i)),
  isHazmat: specialRequirements.includes(0),
  isHighValue: specialRequirements.includes(1),
  isTeamLoad: specialRequirements.includes(2),
  isOversize: specialRequirements.includes(3),
  pickupEarlyTime: origin.earlyTime || null,
  pickupLateTime: origin.lateTime || null,
  deliveryEarlyTime: destination.earlyTime || null,
  deliveryLateTime: destination.lateTime || null,
  pickupDate: formatDateForBackend(origin.date),
  deliveryDate: formatDateForBackend(destination.date),
  ...(specialRequirements.includes(0) && { hazmatUhn }),
  ...(specialRequirements.includes(1) && { highValueAmount }),
  shipper: {
    ...formatLocation(origin.location),
    contactName: origin.contactName,
    contactPhone: origin.contactPhone,
  },
  consignee: {
    ...formatLocation(destination.location),
    contactName: destination.contactName,
    contactPhone: destination.contactPhone,
  },
  ...(emptyLoad && {
    documents: (documents || []).map(doc => ({
      documentType: 3,
      file: doc.file,
      filename: doc.name,
    })),
    isCreatedWithPdf: true,
  }),
  createMethod: emptyLoad ? 9 : 2,
  orders,
})

export const formatQuoteLocation = (location: QuoteLocation) => {
  const countries: { [key: string]: string } = { USA: 'US', CAN: 'CA', MEX: 'MX' }

  const formatCountry = (country: string) => countries[country]

  const country = formatCountry(location.country)
  const name = `${location.city}, ${location.state}, ${country}`

  return {
    ...location,
    country,
    latitude: location.lat,
    longitude: location.lng,
    name,
    title: name,
  }
}
