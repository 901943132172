import {
  CatchError,
  cleanFilters,
  formatAxiosErrorToPayload,
  formatDateFilterForBackend,
  formatDateForBackend,
  getErrorString,
  getObjectDifferences,
  keysToCamelCase,
  keysToSnakeCase,
  toTitleCase,
  toUpperCaseUnderscore,
} from '@common'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import { toast } from 'react-toastify'

import { api } from '../api/api'
import { RootState } from '../app/store'
import { countriesMapped, initialFilters, RFPStatusOptions } from '../common/constants'
import {
  Lane,
  LaneCarrier,
  LaneLocation,
  NewRFP,
  RFPCarrier,
  RFPDocument,
  RFPItem,
  SearchFilters,
  TableOrder,
} from '../common/types'
import { getOrderingString } from '../common/utils'

type BulkActionError = { id: number; error: string }

type LaneItemLocation = {
  id: number
  addressLines: null
  city: string
  state: string
  country: string
  postalCode: string
  display: string
  geoData: {
    lat: number
    lng: number
  }
}

type RFPState = {
  loading: {
    getRFPs: boolean
    createRFP: boolean
    getRFPDetails: boolean
    updateRFP: boolean
    getDocuments: boolean
    deleteDocument: boolean
    uploadDocuments: boolean
    archiveRFP: boolean
    createOrUpdateLane: boolean
    getLanes: boolean
    getLaneDetails: boolean
    archiveLane: boolean
    importLane: boolean
    downloadSampleFile: boolean
    bulkArchiveLanes: boolean
    bulkUnarchiveLanes: boolean
    getCarriers: boolean
    inviteCarriers: boolean
    publishOrUnpublishRFP: boolean
    bulkArchiveCarriers: boolean
    bulkInviteCarriers: boolean
    getLanesByCarrier: boolean
    getCarriersByLane: boolean
    awardLaneToCarrier: boolean
    changeCarrierOrder: boolean
    removeContractedCarrierFromLane: boolean
    awardCarriers: boolean
    updateRFPStatus: boolean
    convertLanesToContractLanes: boolean
  }
  rfps: RFPItem[]
  rfpDetails: NewRFP
  newRfp: NewRFP
  count: {
    rfp: number
    lanes: number
    carriers: number
  }
  limit: number
  offset: number
  order: {
    rfp: TableOrder
    lanes: TableOrder
  }
  filters: { rfp: SearchFilters; lanes: SearchFilters; carriers: SearchFilters }
  documents: RFPDocument[]
  isLaneModalVisible: boolean
  currentLaneId: string | number | null
  lanesCount: number
  lanes: {
    id: number
    pickup: LaneItemLocation
    drop: LaneItemLocation
    volume: number
    mode: string
    equipmentType: string
    targetRate: string
    requestedBid: number
    receivedBid: number
    notes: string
  }[]
  laneDetails: Lane
  fileError: string
  lanesBulkActionErrors: BulkActionError[]
  carriers: RFPCarrier[]
  carriersCount: number
  carriersByLane: LaneCarrier[]
  initialCarriersCount: number
  lanesLimit: number
  lanesOffset: number
  carriersLimit: number
  carriersOffset: number
  initialRFPCount: number
}

const initialState: RFPState = {
  loading: {
    getRFPs: false,
    createRFP: false,
    getRFPDetails: false,
    updateRFP: false,
    getDocuments: false,
    deleteDocument: false,
    uploadDocuments: false,
    archiveRFP: false,
    createOrUpdateLane: false,
    getLanes: false,
    getLaneDetails: false,
    archiveLane: false,
    importLane: false,
    downloadSampleFile: false,
    bulkArchiveLanes: false,
    bulkUnarchiveLanes: false,
    getCarriers: false,
    inviteCarriers: false,
    publishOrUnpublishRFP: false,
    bulkArchiveCarriers: false,
    bulkInviteCarriers: false,
    getLanesByCarrier: false,
    getCarriersByLane: false,
    awardLaneToCarrier: false,
    changeCarrierOrder: false,
    removeContractedCarrierFromLane: false,
    awardCarriers: false,
    updateRFPStatus: false,
    convertLanesToContractLanes: false,
  },
  rfps: [],
  rfpDetails: { status: RFPStatusOptions.DRAFT },
  newRfp: { status: RFPStatusOptions.DRAFT },
  count: { rfp: 0, lanes: 0, carriers: 0 },
  limit: 50,
  offset: 0,
  order: {
    rfp: { direction: '', key: '', label: '' },
    lanes: { direction: '', key: '', label: '' },
  },
  filters: { rfp: initialFilters, lanes: initialFilters, carriers: initialFilters },
  documents: [],
  isLaneModalVisible: false,
  currentLaneId: '',
  lanesCount: 0,
  lanes: [],
  laneDetails: {},
  fileError: '',
  lanesBulkActionErrors: [],
  carriers: [],
  carriersCount: 0,
  carriersByLane: [],
  initialCarriersCount: 0,
  lanesLimit: 50,
  lanesOffset: 0,
  carriersLimit: 50,
  carriersOffset: 0,
  initialRFPCount: 0,
}

const getLanePayload = (data: Lane) => {
  const setLaneLocation = (location?: LaneLocation) => ({
    city: location?.city,
    state: location?.state,
    postalCode: location?.postalCode,
    country: countriesMapped[location?.country || ''] || location?.country,
  })

  return {
    ...(data?.origin && { pickup: setLaneLocation(data.origin) }),
    ...(data?.destination && { drop: setLaneLocation(data.destination) }),
    ...(data?.equipmentType && { equipmentType: data.equipmentType }),
    ...(data?.volume && { volume: Number(data.volume) }),
    ...(data?.mode && { mode: data.mode }),
    ...(data?.totalVolume && { totalVolume: Number(data.totalVolume) }),
    targetRate: parseFloat(data.targetRate || '0'),
    notes: data.notes,
  }
}

export const getRFPs = createAsyncThunk('rfp/getRFPs', async (_, { getState, rejectWithValue }) => {
  const {
    filters: { rfp: filters },
  } = (getState() as RootState).rfp

  try {
    const response = await api.get('/shipper/api/list-create-rfp/', {
      params: cleanFilters({
        id: filters.rfpId,
        name__icontains: filters.name,
        ...formatDateFilterForBackend(filters.startDate, 'start_date'),
        ...formatDateFilterForBackend(filters.endDate, 'end_date'),
        ...formatDateFilterForBackend(filters.carrierBidDeadline, 'carrier_bid_deadline'),
        ...formatDateFilterForBackend(filters.awardAcceptanceDeadline, 'award_acceptance_deadline'),
        fuel_setting: toUpperCaseUnderscore(filters.fuelSetting),
        distance_unit: toUpperCaseUnderscore(filters.distanceUnit),
        volume_frequency: toUpperCaseUnderscore(filters.volumeFrequency),
        bidType: toUpperCaseUnderscore(filters.bidType),
        status: toUpperCaseUnderscore(filters.rfpStatus),
        archived: filters.archived,
      }),
    })
    return keysToCamelCase(response.data)
  } catch (err: CatchError) {
    return rejectWithValue(formatAxiosErrorToPayload(err))
  }
})

export const getRFPDetails = createAsyncThunk(
  'rfp/getRFPDetails',
  async (id: string | number, { rejectWithValue }) => {
    try {
      const response = await api.get(`/shipper/api/rfp-rud/${id}/`)
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const createRFP = createAsyncThunk(
  'rfp/createRFP',
  async (_, { getState, dispatch, rejectWithValue }) => {
    const { newRfp } = (getState() as RootState).rfp

    const formData = new FormData()

    // @ts-ignore
    formData.append('is_public', true)
    formData.append('contact_name', newRfp.contactName ? String(newRfp.contactName) : '')
    formData.append('contact_email', newRfp.contactEmail ? String(newRfp.contactEmail) : '')
    formData.append('contact_phone', newRfp.contactPhone ? String(newRfp.contactPhone) : '')
    formData.append('carrier_bid_deadline', String(formatDateForBackend(newRfp.deadlineDate || '')))
    formData.append('bid_type', String(toUpperCaseUnderscore(newRfp.bidType)))
    formData.append('volume_frequency', String(toUpperCaseUnderscore(newRfp.volumeFrequency)))
    formData.append('start_date', String(formatDateForBackend(newRfp.startDate || '')))
    formData.append('end_date', String(formatDateForBackend(newRfp.endDate || '')))
    formData.append('distance_unit', String(toUpperCaseUnderscore(newRfp.distanceUnit)))
    formData.append('fuel_setting', String(toUpperCaseUnderscore(newRfp.fuelSettings)))
    formData.append('name', String(newRfp.name))
    formData.append('notes', String(newRfp.notes || ''))
    newRfp.files?.forEach(doc => formData.append('files', doc.file))

    try {
      const response = await api.post('/shipper/api/list-create-rfp/', formData)
      dispatch(getRFPs())
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const updateRFP = createAsyncThunk(
  'rfp/updateRFP',
  async (data: NewRFP, { getState, dispatch, rejectWithValue }) => {
    const {
      rfpDetails: { id = '' },
    } = (getState() as RootState).rfp

    const payload = {
      ...data,
      carrier_bid_deadline: data.deadlineDate,
      fuelSetting: toUpperCaseUnderscore(data.fuelSettings),
      bidType: toUpperCaseUnderscore(data.bidType),
      volumeFrequency: toUpperCaseUnderscore(data.volumeFrequency),
      distanceUnit: toUpperCaseUnderscore(data.distanceUnit),
    }

    try {
      const response = await api.patch(`/shipper/api/rfp-rud/${id}/`, keysToSnakeCase(payload))
      dispatch(getRFPDetails(id))
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const archiveRFP = createAsyncThunk(
  'rfp/archiveRFP',
  async (_, { getState, dispatch, rejectWithValue }) => {
    const {
      rfpDetails: { id = '' },
    } = (getState() as RootState).rfp

    try {
      const response = await api.delete(`/shipper/api/rfp-rud/${id}/`)
      dispatch(getRFPDetails(id))
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const getDocuments = createAsyncThunk(
  'rfp/getDocuments',
  async (id: string | number, { rejectWithValue }) => {
    try {
      const response = await api.get(`/shipper/api/list-create-rfp-document/${id}/`, {
        params: { limit: 100 },
      })
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const uploadDocuments = createAsyncThunk(
  'rfp/uploadDocuments',
  async (files: { file: File; id: string }[], { getState, dispatch, rejectWithValue }) => {
    const {
      rfpDetails: { id = '' },
    } = (getState() as RootState).rfp

    const formData = new FormData()

    files?.forEach(doc => {
      // @ts-ignore
      formData.append('files', doc.file)
    })
    // @ts-ignore
    formData.append('is_public', true)

    try {
      const response = await api.post(`/shipper/api/list-create-rfp-document/${id}/`, formData)
      dispatch(getDocuments(id))
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const deleteDocument = createAsyncThunk(
  'rfp/deleteDocument',
  async (documentId: string | number, { getState, dispatch, rejectWithValue }) => {
    const {
      rfpDetails: { id = '' },
    } = (getState() as RootState).rfp

    try {
      const response = await api.delete(`/shipper/api/delete-rfp-document/${documentId}/`, {
        params: { limit: 100 },
      })
      dispatch(getDocuments(id))
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const createLane = createAsyncThunk(
  'rfp/createLane',
  async (data: Lane, { getState, dispatch, rejectWithValue }) => {
    const {
      rfpDetails: { id = '' },
    } = (getState() as RootState).rfp

    try {
      const response = await api.post(
        `/shipper/api/list-create-rfp-lane/${id}/`,
        keysToSnakeCase(getLanePayload(data)),
      )
      dispatch(getLanes(id))
      dispatch(getRFPDetails(id))
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const getLanes = createAsyncThunk(
  'rfp/getLanes',
  async (id: string | number, { getState, rejectWithValue }) => {
    const {
      order: {
        lanes: { label, direction, key },
      },
      filters: { lanes: filters },
      lanesLimit: limit,
      lanesOffset: offset,
    } = (getState() as RootState).rfp

    const ordering = getOrderingString(label, direction, key, '-id')

    try {
      const response = await api.get(`/shipper/api/list-create-rfp-lane/${id}/`, {
        params: cleanFilters({
          limit,
          ordering,
          offset,
          id: filters.id,
          pickup_city: filters.originCity,
          pickup_state: filters.originState,
          drop_city: filters.destinationCity,
          drop_state: filters.destinationState,
          equipment_type: filters.equipmentType,
          mode: filters.mode,
        }),
      })
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const getLaneDetails = createAsyncThunk(
  'rfp/getLaneDetails',
  async (id: string | number, { rejectWithValue }) => {
    try {
      const response = await api.get(`/shipper/api/rfp-lane-rud/${id}/`)
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const archiveLane = createAsyncThunk(
  'rfp/archiveLane',
  async (id: string | number | null, { rejectWithValue }) => {
    try {
      const response = await api.delete(`/shipper/api/rfp-lane-rud/${id}/`)
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const updateLane = createAsyncThunk(
  'rfp/updateLane',
  async (lane: Lane, { getState, rejectWithValue }) => {
    const { currentLaneId, laneDetails } = (getState() as RootState).rfp

    try {
      const response = await api.patch(
        `/shipper/api/rfp-lane-rud/${currentLaneId}/`,
        keysToSnakeCase(getLanePayload(getObjectDifferences(lane, laneDetails))),
      )
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const importLane = createAsyncThunk(
  'rfp/importLane',
  async (file: File | null, { getState, rejectWithValue }) => {
    const {
      rfpDetails: { id = '' },
    } = (getState() as RootState).rfp

    const formData = new FormData()

    if (file) formData.append('file', file)

    try {
      const response = await api.post(`/shipper/api/import-rfp-lane/${id}/`, formData)
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const inviteCarriers = createAsyncThunk(
  'rfp/inviteCarriers',
  async (carrierIds: number[], { getState, dispatch, rejectWithValue }) => {
    const {
      rfpDetails: { id = '' },
    } = (getState() as RootState).rfp

    try {
      const response = await api.post(
        `/shipper/api/invite-carrier-rfp/${id}/`,
        keysToSnakeCase({ carrierIds }),
      )
      dispatch(getCarriers(id))
      dispatch(getInitialCarriersCount(id))
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const getCarriers = createAsyncThunk(
  'rfp/getCarriers',
  async (id: string | number, { getState, rejectWithValue }) => {
    const {
      filters: { carriers: filters },
    } = (getState() as RootState).rfp

    try {
      const response = await api.get(`/shipper/api/list-rfp-invited-carrier/${id}/`, {
        params: cleanFilters({ limit: 500, carrier_name: filters.name }),
      })
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const getInitialCarriersCount = createAsyncThunk(
  'rfp/getInitialCarriersCount',
  async (id: string | number, { rejectWithValue }) => {
    try {
      const response = await api.get(`/shipper/api/list-rfp-invited-carrier/${id}/`, {
        params: cleanFilters({ limit: 500 }),
      })
      return keysToCamelCase(response.data.count)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const publishOrUnpublishRFP = createAsyncThunk(
  'rfp/publishOrUnpublishRFP',
  async ({ unpublish }: { unpublish?: boolean }, { getState, dispatch, rejectWithValue }) => {
    const {
      rfpDetails: { id = '' },
    } = (getState() as RootState).rfp

    try {
      await api.post(`/shipper/api/publish-rfp/${id}/`)
      dispatch(getRFPDetails(id))
      dispatch(getCarriers(id))
      dispatch(getInitialCarriersCount(id))
      return keysToCamelCase(unpublish)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const downloadSampleFile = createAsyncThunk(
  'rfp/downloadSampleFile',
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetch(
        'https://docs.google.com/spreadsheets/d/e/2PACX-1vT3Ku5fNCmmdLB_jZ8nLrAOjcMpTtlSShis0DehihO4bs0bhW9rp_KMtN0hNxnN_XkcZvzZMqb9ZYP3/pub?gid=0&single=true&output=csv',
      )
      if (!response.ok) throw new Error('Network error')
      const blob = await response.blob()
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.download = 'sample.csv'
      document.body.appendChild(link)
      link.click()
      window.URL.revokeObjectURL(url)
      document.body.removeChild(link)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const bulkArchiveLanes = createAsyncThunk(
  'rfp/bulkArchiveLanes',
  async (laneIds: number[], { getState, dispatch, rejectWithValue }) => {
    const {
      rfpDetails: { id = '' },
    } = (getState() as RootState).rfp

    try {
      const response = await api.post(
        `/shipper/api/delete-bulk-rfp-lanes/${id}/`,
        keysToSnakeCase({ laneIds }),
      )
      dispatch(getLanes(id))
      dispatch(getRFPDetails(id))
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const bulkArchiveCarriers = createAsyncThunk(
  'rfp/bulkArchiveCarriers',
  async (rfpCarrierIds: number[], { getState, dispatch, rejectWithValue }) => {
    const {
      rfpDetails: { id = '' },
    } = (getState() as RootState).rfp

    try {
      await api.post(
        `/shipper/api/rfp-bulk-carrier-action/${id}/`,
        keysToSnakeCase({ action: 'ARCHIVE', rfpCarrierIds }),
      )
      dispatch(getRFPDetails(id))
      dispatch(getCarriers(id))
      dispatch(getInitialCarriersCount(id))
      return rfpCarrierIds.length
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const bulkInviteCarriers = createAsyncThunk(
  'rfp/bulkInviteCarriers',
  async (rfpCarrierIds: number[], { getState, dispatch, rejectWithValue }) => {
    const {
      rfpDetails: { id = '' },
    } = (getState() as RootState).rfp

    try {
      await api.post(
        `/shipper/api/rfp-bulk-carrier-action/${id}/`,
        keysToSnakeCase({ action: 'INVITE', rfpCarrierIds }),
      )
      dispatch(getRFPDetails(id))
      setTimeout(() => {
        dispatch(getCarriers(id))
        dispatch(getInitialCarriersCount(id))
      }, 500)
      return rfpCarrierIds.length
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const getLanesByCarrier = createAsyncThunk(
  'rfp/getLanesByCarrier',
  async (carrierId: number, { getState, rejectWithValue }) => {
    const {
      rfpDetails: { id = '' },
    } = (getState() as RootState).rfp

    try {
      const response = await api.get(`/shipper/api/carrier-submitted-bids/${id}/`, {
        params: { carrier_id: carrierId, limit: 500 },
      })
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const getCarriersByLane = createAsyncThunk(
  'rfp/getCarriersByLane',
  async (
    { laneId, isAwarded = null }: { laneId: string | number; isAwarded?: boolean | null },
    { rejectWithValue },
  ) => {
    try {
      const response = await api.get(`/shipper/api/lane-carrier-list/${laneId}/`, {
        params: {
          ...(isAwarded !== null && { is_awarded: isAwarded }),
        },
      })
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const awardLaneToCarrier = createAsyncThunk(
  'rfp/awardLaneToCarrier',
  async (
    { laneId, volume, bidId }: { laneId: string | number; volume: string; bidId: string | number },
    { getState, dispatch, rejectWithValue },
  ) => {
    const {
      rfpDetails: { id = '', awardedLanesCount },
    } = (getState() as RootState).rfp

    try {
      const response = await api.post(
        `/shipper/api/award-carrier-lane/${id}/`,
        keysToSnakeCase({ id: Number(bidId), volume: Number(volume) }),
      )
      dispatch(getCarriersByLane({ laneId }))
      if (!awardedLanesCount) dispatch(getRFPDetails(id))
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const removeContractedCarrierFromLane = createAsyncThunk(
  'rfp/removeContractedCarrierFromLane',
  async (
    { laneId, bidId }: { laneId: string | number; bidId: string | number },
    { getState, dispatch, rejectWithValue },
  ) => {
    const {
      rfpDetails: { id = '' },
    } = (getState() as RootState).rfp

    try {
      const response = await api.post(`/shipper/api/rfp/${id}/remove-contract-bid/${bidId}/`)
      dispatch(getCarriersByLane({ laneId }))
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const changeCarrierOrder = createAsyncThunk(
  'rfp/changeCarrierOrder',
  async (
    {
      laneId,
      bidId,
      newPosition,
    }: { laneId: string | number; bidId: string | number; newPosition: number },
    { getState, dispatch, rejectWithValue },
  ) => {
    const {
      rfpDetails: { id = '' },
    } = (getState() as RootState).rfp

    try {
      const response = await api.post(
        `/shipper/api/lane-bid-order-change/${id}/`,
        keysToSnakeCase({ laneBidId: bidId, newPosition }),
      )
      dispatch(getCarriersByLane({ laneId }))
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const awardCarriers = createAsyncThunk(
  'rfp/awardCarriers',
  async (_, { getState, dispatch, rejectWithValue }) => {
    const {
      rfpDetails: { id = '' },
    } = (getState() as RootState).rfp

    try {
      const response = await api.post(`shipper/api/rfp-awarded-final/${id}/`)
      dispatch(getRFPDetails(id))
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const updateRFPStatus = createAsyncThunk(
  'rfp/updateRFPStatus',
  async (
    { status, id }: { status: RFPStatusOptions; id: string | number },
    { rejectWithValue },
  ) => {
    try {
      await api.post(`shipper/api/rfp-status-update/${id}/`, { status })
      return { status, id }
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const convertLanesToContractLanes = createAsyncThunk(
  'rfp/convertLanesToContractLanes',
  async (_, { getState, rejectWithValue }) => {
    const {
      rfpDetails: { id = '' },
    } = (getState() as RootState).rfp

    try {
      const response = await api.post(`shipper/api/rfp-to-contract-lane/${id}/`)
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

const rfpSlice = createSlice({
  name: 'rfp',
  initialState,
  reducers: {
    setLimit(state, { payload }) {
      state.limit = payload
    },
    setOffset(state, { payload }) {
      state.offset = payload
    },
    setOrder(state, { payload }) {
      state.order = { ...state.order, ...payload }
    },
    setFilters(state, { payload }) {
      state.filters = { ...state.filters, ...payload }
    },
    setNewRfp(state, { payload }) {
      state.newRfp = payload
    },
    setLaneModalVisible(state, { payload }) {
      state.isLaneModalVisible = payload
    },
    setCurrentLaneId(state, { payload }) {
      state.currentLaneId = payload
    },
    setFileError(state, { payload }) {
      state.fileError = payload
    },
    setLanesLimit(state, { payload }) {
      state.lanesLimit = payload
    },
    setLanesOffset(state, { payload }) {
      state.lanesOffset = payload
    },
    setCarriersLimit(state, { payload }) {
      state.carriersLimit = payload
    },
    setCarriersOffset(state, { payload }) {
      state.carriersOffset = payload
    },
    reset: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(getRFPs.pending, state => {
        state.loading.getRFPs = true
      })
      .addCase(getRFPs.fulfilled, (state, { payload }) => {
        const { count, results } = payload
        state.loading.getRFPs = false
        state.count.rfp = count
        if (count) state.initialRFPCount = count
        state.rfps = results
      })
      .addCase(getRFPs.rejected, (state, { payload }) => {
        state.loading.getRFPs = false
        toast.error(getErrorString(payload, 'Failed to get RFPs'))
      })
      .addCase(createRFP.pending, state => {
        state.loading.createRFP = true
      })
      .addCase(createRFP.fulfilled, state => {
        state.loading.createRFP = false
        toast.success('Successfully created new RFP')
      })
      .addCase(createRFP.rejected, (state, { payload }) => {
        state.loading.createRFP = false
        toast.error(getErrorString(payload, 'Failed to create new RFP'))
      })
      .addCase(getRFPDetails.pending, state => {
        state.loading.getRFPDetails = true
      })
      .addCase(getRFPDetails.fulfilled, (state, { payload }) => {
        state.loading.getRFPDetails = false
        state.rfpDetails = {
          ...payload,
          fuelSettings: toTitleCase(payload.fuelSetting),
          deadlineDate: payload.carrierBidDeadline,
          distanceUnit: payload.distanceUnit === 'KM' ? 'KM' : toTitleCase(payload.distanceUnit),
          volumeFrequency: toTitleCase(payload.volumeFrequency),
          bidType: toTitleCase(payload.bidType),
        }
      })
      .addCase(getRFPDetails.rejected, (state, { payload }) => {
        state.loading.getRFPDetails = false
        toast.error(getErrorString(payload, 'Failed to get RFP details'))
      })
      .addCase(updateRFP.pending, state => {
        state.loading.updateRFP = true
      })
      .addCase(updateRFP.fulfilled, state => {
        state.loading.updateRFP = false
        toast.success('Successfully updated RFP details')
      })
      .addCase(updateRFP.rejected, (state, { payload }) => {
        state.loading.updateRFP = false
        toast.error(getErrorString(payload, 'Failed to update RFP details'))
      })
      .addCase(getDocuments.pending, state => {
        state.loading.getDocuments = true
      })
      .addCase(getDocuments.fulfilled, (state, { payload }) => {
        state.loading.getDocuments = false
        state.documents = payload.results
      })
      .addCase(getDocuments.rejected, (state, { payload }) => {
        state.loading.getDocuments = false
        toast.error(getErrorString(payload, 'Failed to get RFP documents'))
      })
      .addCase(deleteDocument.pending, state => {
        state.loading.deleteDocument = true
      })
      .addCase(deleteDocument.fulfilled, state => {
        state.loading.deleteDocument = false
        toast.success('Successfully deleted document')
      })
      .addCase(deleteDocument.rejected, (state, { payload }) => {
        state.loading.deleteDocument = false
        toast.error(getErrorString(payload, 'Failed to delete document'))
      })
      .addCase(uploadDocuments.pending, state => {
        state.loading.uploadDocuments = true
      })
      .addCase(uploadDocuments.fulfilled, state => {
        state.loading.uploadDocuments = false
        toast.success('Successfully uploaded document(s)')
      })
      .addCase(uploadDocuments.rejected, (state, { payload }) => {
        state.loading.uploadDocuments = false
        toast.error(getErrorString(payload, 'Failed to upload document(s)'))
      })
      .addCase(archiveRFP.pending, state => {
        state.loading.archiveRFP = true
      })
      .addCase(archiveRFP.fulfilled, state => {
        state.loading.archiveRFP = false
        toast.success('Successfully archived RFP')
      })
      .addCase(archiveRFP.rejected, (state, { payload }) => {
        state.loading.archiveRFP = false
        toast.error(getErrorString(payload, 'Failed to archive RFP'))
      })
      .addCase(createLane.pending, state => {
        state.loading.createOrUpdateLane = true
      })
      .addCase(createLane.fulfilled, state => {
        state.loading.createOrUpdateLane = false
        toast.success('Successfully created new lane')
      })
      .addCase(createLane.rejected, (state, { payload }) => {
        state.loading.createOrUpdateLane = false
        toast.error(getErrorString(payload, 'Failed to create new lane'))
      })
      .addCase(getLanes.pending, state => {
        state.loading.getLanes = true
      })
      .addCase(getLanes.fulfilled, (state, { payload }) => {
        const { count, results } = payload
        state.loading.getLanes = false
        state.count.lanes = count
        state.lanes = results
      })
      .addCase(getLanes.rejected, (state, { payload }) => {
        state.loading.getLanes = false
        toast.error(getErrorString(payload, 'Failed to get lanes'))
      })
      .addCase(getLaneDetails.pending, state => {
        state.loading.getLaneDetails = true
      })
      .addCase(getLaneDetails.fulfilled, (state, { payload }) => {
        state.loading.getLaneDetails = false

        const setLocation = (location: any) => ({
          city: location.city,
          state: location.state,
          country: location.country,
          latitude: location.geoData.lat,
          longitude: location.geoData.lng,
          title: location.display,
          name: location.display,
          postalCode: location.postalCode,
        })

        state.laneDetails = {
          ...payload,
          origin: setLocation(payload.pickup),
          destination: setLocation(payload.drop),
          volume: String(payload.volume),
          targetRate:
            payload.targetRate === '0.00'
              ? ''
              : payload.targetRate.endsWith('.00')
                ? String(parseInt(payload.targetRate, 10))
                : String(payload.targetRate),
        }
      })
      .addCase(getLaneDetails.rejected, (state, { payload }) => {
        state.loading.getLaneDetails = false
        toast.error(getErrorString(payload, 'Failed to get lane details'))
      })
      .addCase(archiveLane.pending, state => {
        state.loading.archiveLane = true
      })
      .addCase(archiveLane.fulfilled, state => {
        state.loading.archiveLane = false
        toast.success('Successfully archived lane')
      })
      .addCase(archiveLane.rejected, (state, { payload }) => {
        state.loading.archiveLane = false
        toast.error(getErrorString(payload, 'Failed to archive lane'))
      })
      .addCase(updateLane.pending, state => {
        state.loading.createOrUpdateLane = true
      })
      .addCase(updateLane.fulfilled, state => {
        state.loading.createOrUpdateLane = false
        toast.success('Successfully updated lane')
      })
      .addCase(updateLane.rejected, (state, { payload }) => {
        state.loading.createOrUpdateLane = false
        toast.error(getErrorString(payload, 'Failed to update lane'))
      })
      .addCase(importLane.pending, state => {
        state.loading.importLane = true
        state.fileError = ''
      })
      .addCase(importLane.fulfilled, state => {
        state.loading.importLane = false
        toast.success('We are processing your file. Please refresh the page in a few minutes')
      })
      .addCase(importLane.rejected, (state, { payload }) => {
        state.loading.importLane = false
        state.fileError = String((payload as any).message)
        toast.error('Failed to process file')
      })
      .addCase(downloadSampleFile.pending, state => {
        state.loading.downloadSampleFile = true
      })
      .addCase(downloadSampleFile.fulfilled, state => {
        state.loading.downloadSampleFile = false
        toast.success('Successfully downloaded sample file')
      })
      .addCase(downloadSampleFile.rejected, state => {
        state.loading.downloadSampleFile = false
        toast.error('Failed to download sample file')
      })
      .addCase(getCarriers.pending, state => {
        state.loading.getCarriers = true
      })
      .addCase(getCarriers.fulfilled, (state, { payload }) => {
        const { count, results } = payload
        state.loading.getCarriers = false
        state.carriers = results
        state.count.carriers = count
      })
      .addCase(getCarriers.rejected, (state, { payload }) => {
        state.loading.getCarriers = false
        toast.error(getErrorString(payload, 'Failed to get carriers'))
      })
      .addCase(inviteCarriers.pending, state => {
        state.loading.inviteCarriers = true
      })
      .addCase(inviteCarriers.fulfilled, state => {
        state.loading.inviteCarriers = false
        toast.success('Successfully invited carriers')
      })
      .addCase(inviteCarriers.rejected, (state, { payload }) => {
        state.loading.inviteCarriers = false
        toast.error(getErrorString(payload, 'Failed to invite carriers'))
      })
      .addCase(publishOrUnpublishRFP.pending, state => {
        state.loading.publishOrUnpublishRFP = true
      })
      .addCase(publishOrUnpublishRFP.fulfilled, (state, { payload }) => {
        state.loading.publishOrUnpublishRFP = false
        toast.success(`Successfully ${payload ? 'un' : ''}published RFP`)
      })
      .addCase(publishOrUnpublishRFP.rejected, (state, { payload }) => {
        state.loading.publishOrUnpublishRFP = false
        toast.error(getErrorString(payload, 'Failed to publish RFP'))
      })
      .addCase(bulkArchiveCarriers.pending, state => {
        state.loading.bulkArchiveCarriers = true
      })
      .addCase(bulkArchiveCarriers.fulfilled, (state, { payload }) => {
        state.loading.bulkArchiveCarriers = false
        toast.success(`Successfully removed carrier${payload > 1 ? 's' : ''}`)
      })
      .addCase(bulkArchiveCarriers.rejected, (state, { payload }) => {
        state.loading.bulkArchiveCarriers = false
        toast.error(getErrorString(payload, 'Failed to remove carriers'))
      })
      .addCase(bulkInviteCarriers.pending, state => {
        state.loading.bulkInviteCarriers = true
      })
      .addCase(bulkInviteCarriers.fulfilled, (state, { payload }) => {
        state.loading.bulkInviteCarriers = false
        toast.success(`Successfully resent invite${payload > 1 ? 's' : ''}`)
      })
      .addCase(bulkInviteCarriers.rejected, (state, { payload }) => {
        state.loading.bulkInviteCarriers = false
        toast.error(getErrorString(payload, 'Failed to resend invites'))
      })
      .addCase(getLanesByCarrier.pending, state => {
        state.loading.getLanesByCarrier = true
      })
      .addCase(getLanesByCarrier.fulfilled, state => {
        state.loading.getLanesByCarrier = false
      })
      .addCase(getLanesByCarrier.rejected, (state, { payload }) => {
        state.loading.getLanesByCarrier = false
        toast.error(getErrorString(payload, 'Failed to get lanes'))
      })
      .addCase(getCarriersByLane.pending, state => {
        state.loading.getCarriersByLane = true
      })
      .addCase(getCarriersByLane.fulfilled, (state, { payload }) => {
        state.loading.getCarriersByLane = false
        state.carriersByLane = payload
      })
      .addCase(getCarriersByLane.rejected, (state, { payload }) => {
        state.loading.getCarriersByLane = false
        toast.error(getErrorString(payload, 'Failed to get carriers'))
      })
      .addCase(awardLaneToCarrier.pending, state => {
        state.loading.awardLaneToCarrier = true
      })
      .addCase(awardLaneToCarrier.fulfilled, state => {
        state.loading.awardLaneToCarrier = false
        toast.success('Successfully awarded lane to carrier')
      })
      .addCase(awardLaneToCarrier.rejected, (state, { payload }) => {
        state.loading.awardLaneToCarrier = false
        toast.error(getErrorString(payload, 'Failed to award lane to carrier'))
      })
      .addCase(changeCarrierOrder.pending, state => {
        state.loading.changeCarrierOrder = true
      })
      .addCase(changeCarrierOrder.fulfilled, state => {
        state.loading.changeCarrierOrder = false
        toast.success('Successfully changed carrier order')
      })
      .addCase(changeCarrierOrder.rejected, (state, { payload }) => {
        state.loading.changeCarrierOrder = false
        toast.error(getErrorString(payload, 'Failed to change carrier order'))
      })
      .addCase(removeContractedCarrierFromLane.pending, state => {
        state.loading.removeContractedCarrierFromLane = true
      })
      .addCase(removeContractedCarrierFromLane.fulfilled, state => {
        state.loading.removeContractedCarrierFromLane = false
        toast.success('Successfully removed carrier')
      })
      .addCase(removeContractedCarrierFromLane.rejected, (state, { payload }) => {
        state.loading.removeContractedCarrierFromLane = false
        toast.error(getErrorString(payload, 'Failed to remove carrier'))
      })
      .addCase(awardCarriers.pending, state => {
        state.loading.awardCarriers = true
      })
      .addCase(awardCarriers.fulfilled, state => {
        state.loading.awardCarriers = false
        toast.success('Successfully awarded carriers')
      })
      .addCase(awardCarriers.rejected, (state, { payload }) => {
        state.loading.awardCarriers = false
        toast.error(getErrorString(payload, 'Failed to award carriers'))
      })
      .addCase(updateRFPStatus.pending, state => {
        state.loading.updateRFPStatus = true
      })
      .addCase(updateRFPStatus.fulfilled, (state, { payload }) => {
        const { status, id } = payload
        state.loading.updateRFPStatus = false
        state.rfpDetails.status = status
        const index = state.rfps.findIndex(rfp => rfp.id === id)
        state.rfps[index].status = status
        state.rfps[index].carrierBidDeadline = dayjs().format('YYYY-MM-DD')
        toast.success(`Successfully updated status to ${toTitleCase(payload.status)}`)
      })
      .addCase(updateRFPStatus.rejected, (state, { payload }) => {
        state.loading.updateRFPStatus = false
        toast.error(getErrorString(payload, 'Failed to update status'))
      })
      .addCase(convertLanesToContractLanes.pending, state => {
        state.loading.convertLanesToContractLanes = true
      })
      .addCase(convertLanesToContractLanes.fulfilled, (state, { payload }) => {
        state.loading.convertLanesToContractLanes = false
        toast.success(payload)
      })
      .addCase(convertLanesToContractLanes.rejected, (state, { payload }) => {
        state.loading.convertLanesToContractLanes = false
        toast.error(getErrorString(payload, 'Failed to convert lanes'))
      })
      .addCase(getInitialCarriersCount.fulfilled, (state, { payload }) => {
        state.initialCarriersCount = payload
      })
  },
})

export const {
  setLimit,
  setOffset,
  setOrder,
  setFilters,
  setNewRfp,
  reset,
  setLaneModalVisible,
  setCurrentLaneId,
  setFileError,
  setLanesLimit,
  setLanesOffset,
  setCarriersOffset,
  setCarriersLimit,
} = rfpSlice.actions

export default rfpSlice.reducer
