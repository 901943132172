import styled, { keyframes } from 'styled-components'
import tw from 'tailwind-styled-components'

export type LoaderProps = {
  className?: string
}

export const Loader = ({ className = '' }: LoaderProps): JSX.Element => (
  <Container className={className} data-testid='loader'>
    <LoaderAnimation className='bg-brand-accent' />
  </Container>
)

const Container = tw.div`
  bg-light-yellow
  absolute
  h-1
  block
  w-full
  top-0
  overflow-hidden
  bg-clip-padding	
  z-10
`

const loading = keyframes`
  0% {
    left: -35%;
    right: 100%;
  }
  60% {
    left: 100%;
    right: -90%;
  }
  100% {
    left: 100%;
    right: -90%;
  }
`

const loadingShort = keyframes`
  0% {
    left: -200%;
    right: 100%;
  }
  60% {
    left: 107%;
    right: -8%;
  }
  100% {
    left: 107%;
    right: -8%;
  }
`

const LoaderAnimation = styled.div`
  &:before {
    content: '';
    position: absolute;
    background-color: inherit;
    top: 0;
    left: 0;
    bottom: 0;
    will-change: left, right;
    -webkit-animation: ${loading} 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
    animation: ${loading} 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
  }

  &:after {
    content: '';
    position: absolute;
    background-color: inherit;
    top: 0;
    left: 0;
    bottom: 0;
    will-change: left, right;
    -webkit-animation: ${loadingShort} 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
    animation: ${loadingShort} 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
    -webkit-animation-delay: 1.15s;
    animation-delay: 1.15s;
  }
`
