import { Modal } from '@components'
import { useCallback, useEffect } from 'react'

import { useAppSelector, useAppThunkDispatch } from '../../../app/hooks'
import { CustomerQuote } from '../../../common/types'
import { setRateBookingDetails } from '../../../redux/createNewLoadSlice'
import { EDIModal } from './EDIModal'
import { LoadCreationDetails } from './LoadCreationDetails'
import { LoadCreationWithPDF } from './LoadCreationWithPDF'

export const LoadCreationModal = ({
  isModalVisible,
  setModalVisible,
  quote,
}: {
  isModalVisible: boolean
  setModalVisible: (value: boolean) => void
  quote: CustomerQuote
}) => {
  const dispatch = useAppThunkDispatch()

  const rateBookingDetails = useAppSelector(state => state.createNewLoad.rateBookingDetails)
  const modalStep = rateBookingDetails.modalStep

  useEffect(() => {
    if (!isModalVisible)
      dispatch(
        setRateBookingDetails({ ...rateBookingDetails, documents: [], refId: '', modalStep: 0 }),
      )
  }, [isModalVisible])

  const ModalContent = useCallback(() => {
    switch (modalStep) {
      case 0:
        return <LoadCreationDetails quote={quote} />
      case 1:
        return <LoadCreationWithPDF quote={quote} />
      case 2:
        return <EDIModal quote={quote} />
      default:
        return <></>
    }
  }, [rateBookingDetails.modalStep])

  return (
    <Modal
      className='lg:min-w-[60vw] max-lg:!m-0'
      isVisible={isModalVisible}
      setVisible={setModalVisible}
    >
      <ModalContent />
    </Modal>
  )
}
