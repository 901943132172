import { ReactNode } from 'react'
import tw from 'tailwind-styled-components'

export const TD = ({
  children,
  onClick,
  className,
}: {
  children: ReactNode
  onClick?: () => void
  className?: string
}) => (
  <Container className={className} onClick={onClick}>
    {children}
  </Container>
)

const Container = tw.td`
  p-2
  text-left
  text-xs
  transition-all
  first:rounded-tl-lg
  first:rounded-bl-lg
  last:rounded-tr-lg
  last:rounded-br-lg
`
