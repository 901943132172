import { LinkPageHeader } from '@components'
import { useEffect } from 'react'

import { getDocumentTitle } from '../../common/utils'
import { PageContainer } from '../../components/PageContainer'
import { ODQuoteRequestForm } from '../../components/QuotingTool/components'

export const NewODQuoteScreen = () => {
  useEffect(() => {
    document.title = getDocumentTitle('New OD Quote')
  }, [])

  return (
    <PageContainer className='relative bg-lighter-blue'>
      <LinkPageHeader bottomBordered title='Back to Route' to='/quotes?tab=Quoting+Tool' />
      <ODQuoteRequestForm />
    </PageContainer>
  )
}
