import { EyeIcon } from '@heroicons/react/24/outline'
import tw from 'tailwind-styled-components'

export const ImagePreview = ({
  image,
  onClick,
  className,
}: {
  image: any
  onClick: () => void
  className?: string
}) => (
  <Container className={className}>
    <Image alt='manifest item' src={image} />
    <IconContainer onClick={onClick}>
      <EyeIcon className='w-4' />
    </IconContainer>
  </Container>
)

const Container = tw.div`
  relative
  w-32
`

const Image = tw.img`
  mt-4
  rounded-lg
  w-32
  h-12
  object-cover
  transition-all
`

const IconContainer = tw.div`
  absolute
  bottom-1
  right-1
  bg-white
  rounded-md p-0.5
  cursor-pointer
  hover:opacity-80
  transition-all
`
