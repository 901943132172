import { DeleteButton, Form, Select } from '@components'
import tw from 'tailwind-styled-components'

import { QuotingLocation, QuotingLocationItem } from '../../../common/types'
import { CityAutocomplete } from '../../Autocomplete'

export const LocationItem = ({
  value,
  setLocation,
  type,
  label,
  className,
  onDelete = () => {},
  setFormValid,
  countries = ['US'],
  disabled,
  id,
  setStopType = () => {},
}: {
  value: QuotingLocationItem
  setLocation: (location: QuotingLocation | null) => void
  type: 'origin' | 'destination' | 'stop'
  label: string
  className?: string
  required?: boolean
  onDelete?: () => void
  setFormValid: (value: boolean) => void
  countries?: string[]
  disabled?: boolean
  id?: string
  setStopType?: (stopType: string) => void
}) => (
  <Container className={className} setFormValid={setFormValid}>
    <Bullet $type={type} />
    {type.includes('stop') && (
      <Select
        sm
        choices={['Pickup', 'Drop']}
        className='mb-3'
        label='Stop Type'
        value={value.stopType}
        onChange={setStopType}
      />
    )}
    <CityAutocomplete
      required
      className={disabled ? 'pb-4' : ''}
      countries={countries}
      disabled={disabled}
      id={id}
      label={label}
      placeholder='City or Zip Code'
      showAsterisk={false}
      value={value?.location?.title ? value.location : null}
      onChange={setLocation}
    />
    {type === 'stop' && !disabled && <DeleteButton className='mt-2' onClick={onDelete} />}
  </Container>
)

const Container = tw(Form)`
  relative
`

const Bullet = tw.div<{ $type: 'origin' | 'destination' | 'stop' }>`
  rounded-full
  border
  border-brand-dark
  absolute
  w-4
  h-4
  bg-success
  -left-6
  ${({ $type }) =>
    ($type === 'stop' && 'bg-brand-accent') || ($type === 'destination' && 'bg-error')}
`
