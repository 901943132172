import { broadcastLogout } from '@common'
import { Button } from '@components'

import { useAppSelector } from '../../../app/hooks'
import { Error, Help } from '../components'
import { CustomerCompanySearch } from '../CustomerCompanySearch'

export const SelectCompanyStep = () => {
  const error = useAppSelector(state => state.user.error)

  return (
    <>
      <CustomerCompanySearch authenticate />
      <Error className='mt-0'>{error}</Error>
      <Button fullWidth lg className='w-full mt-6' type='secondary' onClick={broadcastLogout}>
        Back
      </Button>
      <Help className='mt-6' />
    </>
  )
}
