import { FileInput } from '@components'
import { TrashIcon } from '@heroicons/react/24/outline'
import { ArrowUpTrayIcon, DocumentIcon } from '@heroicons/react/24/solid'
import tw from 'tailwind-styled-components'

export const FileUpload = ({
  value,
  onChange,
  label,
  className,
  disabled,
}: {
  value: File | null
  onChange: (file: File | null) => void
  label: string | JSX.Element
  className?: string
  disabled?: boolean
}) => (
  <Container className={className}>
    <div className='font-semibold text-dark-gray'>{label}</div>
    {value && (
      <div className='flex items-center font-semibold max-lg:mt-3'>
        <DocumentIcon className='w-5 mr-2' />
        {value.name}
        <DeleteIcon onClick={() => onChange(null)} />
      </div>
    )}
    {!value && (
      <FileInput
        disabled={disabled}
        mimeType='application/pdf,image/png,image/jpg,image/jpeg'
        value={value}
        onChange={onChange}
      >
        <FileInputContainer $disabled={disabled}>
          <ArrowUpTrayIcon className='w-5 mr-2' />
          <div>Upload</div>
        </FileInputContainer>
      </FileInput>
    )}
  </Container>
)

const DeleteIcon = tw(TrashIcon)`
  text-error
  cursor-pointer
  transition-all
  hover:opacity-80
  w-5
  ml-2
`

const Container = tw.div`
  text-sm
  flex
  max-lg:flex-col
  lg:items-center
  justify-between
  lg:h-[25px]
`

const FileInputContainer = tw.div<{ $disabled?: boolean }>`
  flex
  max-lg:mt-3
  items-center
  text-link
  font-semibold
  ${({ $disabled }) => $disabled && 'text-dark-gray'}
`
