import { Button, Form, RadioInput, TextArea, TextInput } from '@components'
import { TrashIcon } from '@heroicons/react/24/outline'
import { DocumentIcon } from '@heroicons/react/24/solid'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import tw from 'tailwind-styled-components'

import { useAppSelector, useAppThunkDispatch } from '../../../app/hooks'
import { initialSearchParams } from '../../../common/constants'
import { MultiStopQuotingParams, MultiStopQuotingParamsItem } from '../../../common/types'
import { getDocumentTitle, randomString } from '../../../common/utils'
import { setSearchParams } from '../../../redux/quotingToolSlice'
import { FileUpload } from './FileUpload'
import { ODQuoteBanner } from './ODQuoteBanner'
import { ODQuoteHeader } from './ODQuoteHeader'
import { ODQuoteReviewModal } from './ODQuoteReviewModal'

export const ODQuoteRequestForm = () => {
  const [isFormValid, setFormValid] = useState(false)
  const [isReviewModalVisible, setReviewModalVisible] = useState(false)

  const navigate = useNavigate()

  const dispatch = useAppThunkDispatch()

  const data = useAppSelector(state => state.quotingTool.searchParams)

  useEffect(() => {
    document.title = getDocumentTitle('New OD Quote')
  }, [])

  const setData = (newData: Partial<MultiStopQuotingParams>) =>
    dispatch(setSearchParams({ ...data, ...newData }))

  const setItem = (newData: Partial<MultiStopQuotingParamsItem>) =>
    setData({ item: { ...data.item, ...newData } })

  const cancelRequest = () => {
    dispatch(setSearchParams(initialSearchParams))
    navigate('/quotes?tab=Quoting+Tool')
  }

  return (
    <FlexWrapper>
      <div className='mb-6 max-w-[1000px] min-w-[50vw]'>
        <Container>
          <ODQuoteHeader />
          <ODQuoteBanner />
          <SectionTitle className='mt-6'>
            Enter Item Details & Transportation Dims <span className='text-error'>*</span>
          </SectionTitle>
          <FileUpload
            className='mt-6'
            label='Upload specs (Instead of manual entry)'
            value={data.specs || null}
            onChange={specs => setData({ specs })}
          />
          <Form setFormValid={setFormValid}>
            <Grid className='mt-6'>
              <TextInput
                sm
                label='Item Name'
                required={!data.specs}
                value={data.item?.name}
                onChange={name => setItem({ name })}
              />
              <Grid className='grid-cols-2'>
                <TextInput
                  sm
                  label='Weight (lbs)'
                  required={!data.specs}
                  type='number'
                  value={data.item?.weight}
                  onChange={weight => setItem({ weight })}
                />
                <TextInput
                  sm
                  label='Quantity'
                  type='number'
                  value={data.item?.quantity}
                  onChange={quantity => setItem({ quantity })}
                />
              </Grid>
              <Grid className='grid-cols-3'>
                <TextInput
                  sm
                  label='Width (ft)'
                  required={!data.specs}
                  type='number'
                  value={data.item?.width}
                  onChange={width => setItem({ width })}
                />
                <TextInput
                  sm
                  label='Length (ft)'
                  required={!data.specs}
                  type='number'
                  value={data.item?.length}
                  onChange={length => setItem({ length })}
                />
                <TextInput
                  sm
                  label='Height (ft)'
                  required={!data.specs}
                  type='number'
                  value={data.item?.height}
                  onChange={height => setItem({ height })}
                />
              </Grid>
            </Grid>
            <FileUpload
              className='mt-6'
              disabled={(data.commodity?.length || 0) >= 20}
              label='Upload Commodity Photos (optional)'
              value={null}
              onChange={file =>
                setData({ commodity: [...(data.commodity || []), { file, id: randomString() }] })
              }
            />
            {data.commodity?.length && (
              <div className='mt-3'>
                {data.commodity.map(file => (
                  <FileItem key={file.id}>
                    <div className='flex items-center'>
                      <DocumentIcon className='w-5 mr-2' />
                      <div>{file.file?.name}</div>
                    </div>
                    <DeleteIcon
                      onClick={() =>
                        setData({ commodity: data.commodity?.filter(item => item.id !== file.id) })
                      }
                    />
                  </FileItem>
                ))}
              </div>
            )}
            <div className='mt-4 flex max-lg:flex-col justify-between'>
              <div>
                <Label>Is Your Load Insured?</Label>
                <div className='flex gap-4'>
                  <RadioInput
                    checked={!data.isInsured}
                    label='No'
                    onChange={() => setData({ isInsured: false })}
                  />
                  <RadioInput
                    checked={!!data.isInsured}
                    label='Yes'
                    onChange={() => setData({ isInsured: true })}
                  />
                </div>
              </div>
              <TextInput
                required
                sm
                className='lg:w-1/2 max-lg:mt-4'
                label='Load Value'
                type='currency'
                value={data.loadValue}
                onChange={loadValue => setData({ loadValue })}
              />
            </div>
            <TextArea
              sm
              className='mt-4'
              label='Notes'
              value={data.notes}
              onChange={notes => setData({ notes })}
            />
          </Form>
          <ButtonsWrap>
            <Button fullWidth className='w-full' type='secondary' onClick={cancelRequest}>
              Cancel
            </Button>
            <Button
              fullWidth
              className='w-full'
              disabled={!isFormValid}
              type='warn'
              onClick={() => setReviewModalVisible(true)}
            >
              Review Request
            </Button>
          </ButtonsWrap>
        </Container>
        <ODQuoteReviewModal
          isReviewModalVisible={isReviewModalVisible}
          setReviewModalVisible={setReviewModalVisible}
        />
      </div>
    </FlexWrapper>
  )
}

const Container = tw.div`
  p-4
  pb-0
  lg:pt-6
  bg-white
  rounded-lg
  m-4
  border
  border-border-gray
  lg:px-10
  max-h-[70vh]
  lg:max-h-[83vh]
  overflow-auto
`

const ButtonsWrap = tw.div`
  sticky
  bottom-0
  bg-white
  flex
  gap-4
  py-4
  lg:py-6
  lg:mt-6
`

const Label = tw.div`
  font-semibold
  text-dark-gray
  text-sm
  mb-2
`

const Grid = tw.div`
  grid
  gap-4
`

const SectionTitle = tw.div`
  font-bold
  text-base
  mb-3
`

const FlexWrapper = tw.div`
  mb-6
  flex
  items-center
  justify-center
`

const DeleteIcon = tw(TrashIcon)`
  text-error
  cursor-pointer
  transition-all
  hover:opacity-80
  w-5
  ml-2
`

const FileItem = tw.div`
  flex
  justify-between
  items-center
  mb-3
`
