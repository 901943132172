import { AxiosInstance } from 'axios'
import tw from 'tailwind-styled-components'

import { formatCountAndPluralize } from '../../common'
import { useMapEvents, useRouteRequest } from './hooks'
import { MapProvider, useMap } from './MapContext'

type MapProps = {
  children?: React.ReactNode
  className?: string
  hideMiles?: boolean
  mapClassName?: string
  mapControlsClassName?: string // className to be added in the mapControl element
  mapControlsPosition?: H.ui.LayoutAlignment
  mileageOverride?: number
  routeAPI?: AxiosInstance
  scalebarControlsPosition?: H.ui.LayoutAlignment
  zoom?: number
  zoomControlsPosition?: H.ui.LayoutAlignment
}

export const Map = ({
  children,
  className = '',
  hideMiles = false,
  mapClassName,
  mapControlsClassName = '',
  mapControlsPosition,
  mileageOverride,
  routeAPI,
  scalebarControlsPosition,
  zoom = 4,
  zoomControlsPosition,
}: MapProps) => (
  <MapProvider
    mapControlsClassName={mapControlsClassName}
    mapControlsPosition={mapControlsPosition}
    scalebarControlsPosition={scalebarControlsPosition}
    zoom={zoom}
    zoomControlsPosition={zoomControlsPosition}
  >
    <MapBody
      className={className}
      hideMiles={hideMiles}
      mapClassName={mapClassName}
      mileageOverride={mileageOverride}
      routeAPI={routeAPI}
      zoom={zoom}
    >
      {children}
    </MapBody>
  </MapProvider>
)

const MapBody = ({
  children,
  className = '',
  hideMiles,
  mapClassName,
  mileageOverride,
  routeAPI,
  zoom = 4,
}: MapProps) => {
  const { map, mapRef } = useMap()
  const { markersByType } = useMapEvents(map, zoom)
  const { routeDistance } = useRouteRequest(map, markersByType, routeAPI)

  const miles = hideMiles ? 0 : (mileageOverride ?? routeDistance)

  return (
    <Container className={className}>
      <div className='absolute bottom-2 z-[2] flex items-center'>
        {!!miles && <Box>{miles.toLocaleString()} miles</Box>}
        {!!markersByType.stops.length && (
          <Box>{formatCountAndPluralize(markersByType.stops.length, 'stop')}</Box>
        )}
      </div>
      <div ref={mapRef} className={mapClassName} />
      {children}
    </Container>
  )
}

const Container = tw.div`
  relative
`

const Box = tw.div`
  bg-dark-blue
  text-white
  ml-3
  py-1.5
  px-3
  font-bold
  bottom-0
  text-xs
  rounded-lg
  uppercase
  h-fit
`
