import { Transition as TailWindTransition } from '@headlessui/react'
import { ReactNode } from 'react'

export const EaseInOutTransition = ({
  children,
  show,
}: {
  children: ReactNode
  show?: boolean
}) => (
  <TailWindTransition
    enter='transition ease-out duration-100'
    enterFrom='transform opacity-0 scale-95'
    enterTo='transform opacity-100 scale-100'
    leave='transition ease-in duration-75'
    leaveFrom='transform opacity-100 scale-100'
    leaveTo='transform opacity-0 scale-95'
    show={show}
  >
    {children}
  </TailWindTransition>
)
