import { Form, Modal, TextInput } from '@components'
import { noop } from 'lodash-es'
import { useEffect, useState } from 'react'

import { useAppSelector, useAppThunkDispatch } from '../../../../app/hooks'
import {
  setCurrentCarrier,
  setTenderLoadModalVisible,
  tenderLoad,
} from '../../../../redux/loadsSlice'
import { ModalFooter } from './ModalFooter'

export const TenderLoadModal = () => {
  const [carrierPrice, setCarrierPrice] = useState('')

  const dispatch = useAppThunkDispatch()

  const isTenderLoadModalVisible = useAppSelector(state => state.loads.isTenderLoadModalVisible)
  const loading = useAppSelector(state => state.loads.loading.tenderLoad)
  const currentCarrier = useAppSelector(state => state.loads.currentCarrier)

  useEffect(() => {
    if (isTenderLoadModalVisible && currentCarrier?.quote?.carrierBid)
      setCarrierPrice(currentCarrier?.quote?.carrierBid)
    else setCarrierPrice('')
  }, [isTenderLoadModalVisible])

  const tenderLoadToCarrier = () =>
    dispatch(tenderLoad({ carrierId: currentCarrier?.carrierId, amount: carrierPrice }))
      .unwrap()
      .then(() => {
        dispatch(setTenderLoadModalVisible(false))
        dispatch(setCurrentCarrier(null))
        setCarrierPrice('')
      })
      .catch(noop)

  return (
    <Modal
      className='!max-w-[600px]'
      isVisible={isTenderLoadModalVisible}
      setVisible={value => dispatch(setTenderLoadModalVisible(value))}
      showCloseIcon={false}
    >
      <Form className='p-6 text-center' setFormValid={() => {}}>
        <div className='font-bold text-lg mb-4'>Load Tender</div>
        <div className='mb-4'>
          This action will send the carrier a notification that the load has been tendered to them.
        </div>
        <TextInput
          autoFocus
          sm
          label='Carrier Rate'
          type='currency'
          value={carrierPrice}
          onChange={setCarrierPrice}
          onEnter={tenderLoadToCarrier}
        />
        {currentCarrier?.quote?.carrierNotes && (
          <div className='text-left'>
            <div className='text-dark-gray text-xs font-poppins mt-3'>Carrier Notes</div>
            <div className='mt-1'>{currentCarrier?.quote?.carrierNotes}</div>
          </div>
        )}
      </Form>
      <ModalFooter
        confirmButtonLabel='Submit'
        loading={loading}
        setModalVisible={value => dispatch(setTenderLoadModalVisible(value))}
        onSend={tenderLoadToCarrier}
      />
    </Modal>
  )
}
