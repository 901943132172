import { XMarkIcon } from '@heroicons/react/24/outline'
import { Fragment, ReactNode, useMemo } from 'react'
import Draggable from 'react-draggable'
import Modal from 'react-modal'
import styled from 'styled-components'
import tw from 'tailwind-styled-components'

import { Button } from '../button'

type ModalProps = {
  isVisible: boolean
  setVisible: (value: boolean) => void
  children: ReactNode
  title?: string
  showFooter?: boolean
  onCancel?: () => void
  onConfirm?: () => void
  isConfirmButtonDisabled?: boolean
  isConfirmButtonLoading?: boolean
  footer?: JSX.Element | null
  className?: string
  confirmButtonLabel?: string
  closeOnOverlayClick?: boolean
  focusTrapped?: boolean
  showCloseIcon?: boolean
  onClose?: () => void
  shouldCloseOnEsc?: boolean
  withOverlay?: boolean
  titleBackgroundColor?: string
}

export const DraggableAlphaModal = ({
  isVisible,
  setVisible,
  children,
  closeOnOverlayClick = true,
  title,
  showFooter = true,
  showCloseIcon = true,
  footer,
  isConfirmButtonDisabled = false,
  isConfirmButtonLoading = false,
  className = '',
  confirmButtonLabel = 'Ok',
  focusTrapped = false,
  shouldCloseOnEsc = true,
  withOverlay = true,
  onClose = () => {},
  onCancel = () => {
    setVisible(false)
    onClose()
  },
  onConfirm = () => {
    setVisible(false)
    onClose()
  },
  titleBackgroundColor,
}: ModalProps) => {
  const styleOverrides = useMemo(
    () => ({
      overlay: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        transition: 'opacity 0.2s ease-in-out',
        background: withOverlay ? 'rgba(0, 0, 0, 0.5)' : 'transparent',
      },
      content: {
        bottom: 'unset',
        overflow: 'visible',
        padding: 0,
        border: 'none',
        borderRadius: 0,
        position: 'static',
        background: 'none',
        pointerEvents: 'none',
      },
    }),
    [withOverlay],
  )

  const closeModal = () => {
    setVisible(false)
    if (onClose) {
      onClose()
    }
  }

  return (
    <ModalContainer
      className={className}
      isOpen={isVisible}
      shouldCloseOnEsc={shouldCloseOnEsc}
      shouldCloseOnOverlayClick={closeOnOverlayClick}
      shouldFocusAfterRender={focusTrapped}
      style={styleOverrides as any}
      onRequestClose={closeModal}
    >
      <Draggable bounds='body' defaultClassName='!overflow-visible' handle='.handle'>
        <ModalContent titleBackgroundColor={titleBackgroundColor}>
          <Fragment>
            <div className='handle'>
              <div>{title}</div>
              {showCloseIcon && (
                <CloseIcon
                  onClick={() => {
                    setVisible(false)
                    onClose()
                    onCancel()
                  }}
                />
              )}
            </div>
            <div className='border border-light-gray'>{children}</div>
            {showFooter && (
              <AlphaModalFooter>
                {footer || (
                  <>
                    <Button
                      short
                      sm
                      label='Cancel'
                      type='secondary'
                      onClick={() => {
                        onCancel()
                        onClose()
                      }}
                    />
                    <Button
                      short
                      sm
                      disabled={isConfirmButtonDisabled}
                      label={confirmButtonLabel}
                      loading={isConfirmButtonLoading}
                      preIcon='arrow-right'
                      type='warn'
                      onClick={onConfirm}
                    />
                  </>
                )}
              </AlphaModalFooter>
            )}
          </Fragment>
        </ModalContent>
      </Draggable>
    </ModalContainer>
  )
}

const ModalContainer = tw(Modal)`
  lg:min-w-[500px]
`

const CloseIcon = tw(XMarkIcon)`
  transition
  cursor-pointer
  hover:opacity-70
  icon
  w-5
  h-5
`

const AlphaModalFooter = tw.div`
  text-white
  flex
  justify-between
  bg-dark-blue
  p-3
  sticky
  -bottom-px
`

const ModalContent = styled.div<{ titleBackgroundColor?: string }>`
  background: white;
  pointer-events: all;
  overflow: hidden;
  border-radius: 8px;

  .handle {
    background: ${({ titleBackgroundColor }) => titleBackgroundColor || '#152437'};
    display: flex;
    justify-content: space-between;
    color: white;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 14px;
    padding: 12px;
    cursor: move;
    ${({ titleBackgroundColor }) => !titleBackgroundColor && 'border-left: 8px solid #3f6aa6;'}
  }
`
