import { Button, ButtonType } from '@components'
import tw from 'tailwind-styled-components'

export const ModalFooter = ({
  onSend,
  isConfirmButtonDisabled,
  setModalVisible,
  loading,
  confirmButtonLabel = 'Send',
  cancelButtonLabel = 'Cancel',
  confirmButtonType = 'warn',
}: {
  onSend: () => void
  isConfirmButtonDisabled?: boolean
  setModalVisible: (value: boolean) => void
  loading?: boolean
  confirmButtonLabel?: string
  cancelButtonLabel?: string
  confirmButtonType?: ButtonType
}) => (
  <Container>
    <Button fullWidth className='w-full' type='secondary' onClick={() => setModalVisible(false)}>
      {cancelButtonLabel}
    </Button>
    <Button
      fullWidth
      className='w-full'
      disabled={isConfirmButtonDisabled}
      loading={loading}
      type={confirmButtonType}
      onClick={onSend}
    >
      {confirmButtonLabel}
    </Button>
  </Container>
)

const Container = tw.div`
  flex
  justify-between
  bg-lighter-blue
  px-6
  py-4
  gap-4
`
