import { ArrowLongDownIcon, ArrowLongUpIcon, ArrowsUpDownIcon } from '@heroicons/react/24/outline'
import { useMemo } from 'react'
import tw from 'tailwind-styled-components'

import { TableOrder } from '../../common/types'

export const OrderButton = ({
  isSorted,
  order = { label: '', direction: '', key: '' },
}: {
  isSorted: boolean
  order?: TableOrder
}) => {
  const ArrowIcon = useMemo(
    () => (order.direction === 'ascending' ? ArrowLongUpIcon : ArrowLongDownIcon),
    [order],
  )

  return <div className='w-5'>{isSorted ? <ArrowIcon className='w-4 ml-1' /> : <OrderIcon />}</div>
}

const OrderIcon = tw(ArrowsUpDownIcon)`
  w-4
  ml-2
  text-icon-gray
  hover:text-dark-blue
  transition-all
`
