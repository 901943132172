import { validateEmail } from '@common'
import { Button, Form, TextInput } from '@components'
import { noop } from 'lodash-es'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useAppSelector, useAppThunkDispatch } from '../../../app/hooks'
import {
  checkContact,
  patchCredentials,
  sendOTP,
  setError,
  setRegister,
  setStep,
} from '../../../redux/userSlice'
import { Help, Link } from '../components'
import { Error } from '../components'

export const LoginStep = () => {
  const [userId, setUserId] = useState('')
  const [isFormValid, setFormValid] = useState(false)

  const navigate = useNavigate()

  const dispatch = useAppThunkDispatch()

  const error = useAppSelector(state => state.user.error)
  const isCheckingEmail = useAppSelector(state => state.user.loading.checkEmail)
  const isSendingOTP = useAppSelector(state => state.user.loading.sendOTP)
  const isLoadingCompanyList = useAppSelector(state => state.user.loading.customerCompanyList)

  const loading = isCheckingEmail || isSendingOTP || isLoadingCompanyList

  const sanitizePhone = (userId: string) => userId.replace(/\D/g, '').slice(-10)

  useEffect(() => {
    if (validateEmail(userId)) {
      dispatch(patchCredentials({ email: userId, phone: '' }))
    } else {
      const phone = sanitizePhone(userId)
      if (phone.length === 10) {
        dispatch(patchCredentials({ phone, email: '' }))
      }
    }
  }, [userId])

  const onContinue = async () => {
    dispatch(setRegister({ email: '' })) // reset sign up email so there are no clashes
    dispatch(checkContact())
      .unwrap()
      .then(async ({ exists }) => {
        if (exists) {
          const response = await dispatch(sendOTP())
          if (response.meta.requestStatus === 'rejected') return
          dispatch(setStep('CONFIRM'))
        } else {
          dispatch(
            setError(
              'Account not found. Please check the email you entered and make sure there are no typos.',
            ),
          )
        }
      })
      .catch(noop)
  }

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) =>
    e.code === 'Enter' && isFormValid && onContinue()

  return (
    <Form autoComplete='off' setFormValid={setFormValid}>
      <TextInput
        required
        sm
        className='mb-4 w-full'
        label='Email or Phone Number'
        value={userId}
        onChange={setUserId}
        onKeyDown={onKeyDown}
      />
      {error && <Error className='text-sm'>{error}</Error>}
      <Button
        fullWidth
        lg
        className='mt-6 w-full'
        disabled={!isFormValid || loading}
        loading={loading}
        type='primary'
        onClick={onContinue}
      >
        Continue
      </Button>
      <div className='text-center text-sm'>
        <div className='my-5'>
          New to Pulse? <Link onClick={() => navigate('/register')}>Sign up</Link>
        </div>
        <Help />
      </div>
    </Form>
  )
}
