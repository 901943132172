import { ArrowLongRightIcon, MapPinIcon, TruckIcon } from '@heroicons/react/24/solid'
import Skeleton from 'react-loading-skeleton'
import tw from 'tailwind-styled-components'

import { displayCityAndState, formatDate } from '../../common'
import { Counter } from '../counter'
import { Tooltip } from '../tooltip'

type Stop = { stopType: string; location?: string; address?: string; city?: string; state?: string }

type Location = {
  address?: string | null
  city?: string | null
  state?: string | null
  stopType?: string
}

type Route = {
  className?: string
  isQuote?: boolean
  origin?: Location | string
  destination?: Location | string
  stops?: Stop[]
  miles?: number
  showMiles?: boolean
  routeDistance?: number
  minimized?: boolean
  pickupDate?: string
  deliveryDate?: string
  iconClassName?: string
}

const LocationItem = ({
  type,
  location,
  minimized,
  date,
  iconClassName,
}: {
  type: 'Origin' | 'Destination'
  location?: Location | string
  minimized?: boolean
  date?: string
  iconClassName?: string
}) => (
  <div>
    <div className='flex items-center whitespace-nowrap'>
      {!minimized && (
        <>
          <div className='hidden lg:flex items-center'>
            <StyledPinIcon $type={type} className={iconClassName} />
            <div className='font-semibold mx-1'>{type}</div>
          </div>
          {typeof location !== 'string' && location?.address ? `${location?.address}, ` : ''}
        </>
      )}
      {typeof location === 'string'
        ? location
        : displayCityAndState(location?.city, location?.state)}
    </div>
    {date && <div className='text-dark-gray lg:hidden'>{formatDate(date)}</div>}
  </div>
)

const DistanceItem = ({ routeDistance = 0 }: { routeDistance?: number }) => (
  <div className='flex items-center whitespace-nowrap'>
    <Truck />
    <span className='max-md:ml-4'>
      {routeDistance > 0 ? (
        <span className='font-semibold'>{routeDistance.toLocaleString()} miles</span>
      ) : (
        <Skeleton width={60} />
      )}
    </span>
  </div>
)

export const LoadStopsCounter = ({ stops }: { stops: Stop[] }) => (
  <Tooltip
    content={stops.map((stop, i) => (
      <div key={i} className='pb-2 last:pb-0 capitalize'>
        {stop.stopType}: {stop.address ? `${stop.address}, ` : ''}
        {typeof stop.location === 'string'
          ? stop.location
          : displayCityAndState(stop.city, stop.state)}
      </div>
    ))}
  >
    <Counter count={stops.length} />
  </Tooltip>
)

export const LoadRoute = ({
  className,
  origin,
  destination,
  stops = [],
  miles,
  showMiles = true,
  routeDistance,
  minimized,
  pickupDate,
  deliveryDate,
  iconClassName,
}: Route) => {
  if (!origin || !destination) return <></>

  return (
    <Container className={className}>
      <LocationItem
        date={pickupDate}
        iconClassName={iconClassName}
        location={origin}
        minimized={minimized}
        type='Origin'
      />
      <ArrowLongRightIcon className='w-5 min-w-5 mx-2' />
      {!!stops.length && (
        <>
          <LoadStopsCounter stops={stops} />
          <ArrowLongRightIcon className='min-w-5 w-5 mx-2' />
        </>
      )}
      <LocationItem
        date={deliveryDate}
        iconClassName={iconClassName}
        location={destination}
        minimized={minimized}
        type='Destination'
      />
      {showMiles && !minimized && <DistanceItem routeDistance={miles || routeDistance} />}
    </Container>
  )
}

const Truck = tw(TruckIcon)`
  w-5
  ml-6
  lg:mr-1
  hidden
  lg:block
`

const Container = tw.div`
  flex
  items-center
  pb-4
  mb-4
  lg:border-b
  border-border-gray
`

const StyledPinIcon = tw(MapPinIcon)<{ $type: string }>`
  w-5
  ${({ $type }) => ($type === 'Origin' ? 'text-success' : 'text-red-500')}
`
