import { CatchError, formatAxiosErrorToPayload, getErrorString, keysToSnakeCase } from '@common'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'

import { api } from '../api/api'
import { RootState } from '../app/store'
import { initialFilters } from '../common/constants'
import { SearchFilters, TableOrder } from '../common/types'
import { formatDateForBackend, getOrderingString, keysToCamelCase } from '../common/utils'

type LoadsInvoice = {
  loading: {
    loads: boolean
    getLoadDetails: boolean
    markLoadAsPaid: boolean
    uploadDocument: boolean
    deletePayment: boolean
  }
  count: {
    loads: number
  }
  loads: Array<{
    id: number
    customerReferenceId: string
    invoicedDate: Date
    invoiceAmount: number
    isReceived: boolean
    receivedAmount: number
  }>
  offset: number
  limit: number
  order: TableOrder
  filters: SearchFilters
  loadDetails: {
    id?: number
    carrierName?: string
    carrierInvoiceNumber?: string
    carrierPrice?: string
    carrierDue?: string | number
    notes?: string
    paidAmount?: string | number
    paidDate?: string
    isPaid?: boolean
    documents?: {
      timeStamp: string
      id: number
      load: number
      uploadedBy: string | number
      file: string
      fileName: string
      documentTypeDisplay: string
      documentType: number
      freightOrFinance: any
    }[]
  }
}

const initialState: LoadsInvoice = {
  loading: {
    loads: false,
    getLoadDetails: false,
    markLoadAsPaid: false,
    uploadDocument: false,
    deletePayment: false,
  },
  count: {
    loads: 0,
  },
  loads: [],
  offset: 0,
  limit: 50,
  order: { label: '', direction: '', key: '' },
  filters: initialFilters,
  loadDetails: {},
}

export const getLoadsInvoice = createAsyncThunk(
  'invoice/getLoadsInvoice',
  async (_, { getState, rejectWithValue }) => {
    const {
      limit,
      offset,
      filters,
      order: { label, direction, key },
    } = (getState() as RootState).invoices

    const ordering = getOrderingString(label, direction, key, '-id')
    try {
      const response = await api.get('/customer/api/customer-invoiced-loads/', {
        params: {
          limit,
          offset,
          ordering,
          archived: false,
          id: filters.loadId,
          is_received: filters.isReceived,
          customer_reference_id__icontains: filters.refId,
          invoiced_date__gte: formatDateForBackend(filters.invoicedStartDate),
          invoiced_date__lte: formatDateForBackend(filters.invoicedEndDate),
        },
      })
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const getLoadInvoiceURL = createAsyncThunk(
  'invoice/getLoadInvoiceURL',
  async ({ id, isReceived }: { id?: number; isReceived?: boolean }, { rejectWithValue }) => {
    try {
      const response = await api.get(`/customer/api/customer-invoice-url/${id}/`, {
        params: {
          link_source: isReceived ? 'TMS' : 'QB',
        },
      })
      window.open(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const getLoadDetails = createAsyncThunk(
  'invoice/getLoadDetails',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await api.get(`/shipper/api/load-invoice-detail/${id}/`)
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const markLoadAsPaid = createAsyncThunk(
  'invoice/markLoadAsPaid',
  async (
    {
      id,
      data,
    }: {
      id?: number
      data: {
        paidDate: Date | string
        paidAmount: string | number
        notes: string
        carrierInvoiceNumber: string
      }
    },
    { dispatch, rejectWithValue },
  ) => {
    try {
      const response = await api.post(
        `/shipper/api/load-mark-paid-unpaid/${id}/`,
        keysToSnakeCase({ ...data, paidDate: formatDateForBackend(data.paidDate) }),
      )
      dispatch(getLoadsInvoice())
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const deletePayment = createAsyncThunk(
  'invoice/deletePayment',
  async ({ id }: { id?: number }, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.delete(`/shipper/api/load-mark-paid-unpaid/${id}/`)
      dispatch(getLoadsInvoice())
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const uploadDocument = createAsyncThunk(
  'invoice/uploadDocument',
  async ({ id, file }: { id?: number; file: File | null }, { rejectWithValue }) => {
    const flData = new FormData()
    if (file) flData.append('file', file)
    flData.append('document_type', '5')

    try {
      await api.post(`/customer/api/load-upload-list-create/${id}/`, flData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      const response = await api.get(`/shipper/api/load-invoice-detail/${id}/`)
      return keysToCamelCase(response.data.documents)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

const invoiceSlice = createSlice({
  name: 'invoices',
  initialState,
  reducers: {
    setLimit(state, { payload }) {
      state.limit = payload
    },
    setOffset(state, { payload }) {
      state.offset = payload
    },
    setOrder(state, { payload }) {
      state.order = payload
    },
    setFilters(state, { payload }) {
      state.filters = payload
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getLoadsInvoice.pending, state => {
        state.loading.loads = true
      })
      .addCase(getLoadsInvoice.fulfilled, (state, { payload }) => {
        const { count, results } = payload
        state.loading.loads = false
        state.count.loads = count
        state.loads = results
      })
      .addCase(getLoadsInvoice.rejected, (state, { payload }) => {
        state.loading.loads = false
        toast.error(getErrorString(payload, 'Error getting loads'))
      })
      .addCase(getLoadInvoiceURL.rejected, (state, { payload }) => {
        toast.error(getErrorString(payload, 'Error getting invoice'))
      })
      .addCase(getLoadDetails.pending, state => {
        state.loading.getLoadDetails = true
      })
      .addCase(getLoadDetails.fulfilled, (state, { payload }) => {
        state.loading.getLoadDetails = false
        state.loadDetails = payload
      })
      .addCase(getLoadDetails.rejected, (state, { payload }) => {
        state.loading.getLoadDetails = false
        toast.error(getErrorString(payload, 'Failed to get load details'))
      })
      .addCase(markLoadAsPaid.pending, state => {
        state.loading.markLoadAsPaid = true
      })
      .addCase(markLoadAsPaid.fulfilled, state => {
        state.loading.markLoadAsPaid = false
        toast.success('Successfully marked load as paid')
      })
      .addCase(markLoadAsPaid.rejected, (state, { payload }) => {
        state.loading.markLoadAsPaid = false
        toast.error(getErrorString(payload, 'Failed to mark load as paid'))
      })
      .addCase(uploadDocument.pending, state => {
        state.loading.uploadDocument = true
      })
      .addCase(uploadDocument.fulfilled, (state, { payload }) => {
        state.loading.uploadDocument = false
        state.loadDetails = { ...state.loadDetails, documents: payload }
      })
      .addCase(uploadDocument.rejected, (state, { payload }) => {
        state.loading.uploadDocument = false
        toast.error(getErrorString(payload, 'Failed to upload carrier invoice'))
      })
      .addCase(deletePayment.pending, state => {
        state.loading.deletePayment = true
      })
      .addCase(deletePayment.fulfilled, state => {
        state.loading.deletePayment = false
        toast.success('Successfully deleted payment')
      })
      .addCase(deletePayment.rejected, (state, { payload }) => {
        state.loading.deletePayment = false
        toast.error(getErrorString(payload, 'Failed to delete payment'))
      })
  },
})

export const { setLimit, setOffset, setOrder, setFilters } = invoiceSlice.actions

export default invoiceSlice.reducer
