import Skeleton from 'react-loading-skeleton'

export const TableSkeleton = ({ length }: { length: number }) => (
  <>
    {[...Array(3)].map((_, i) => (
      <tr key={i}>
        {[...Array(length)].map((_, i) => (
          <td key={i} className='p-2'>
            <Skeleton height={32} />
          </td>
        ))}
      </tr>
    ))}
  </>
)
