import { ConfirmDialog } from '@components'
import { TrashIcon } from '@heroicons/react/24/outline'
import { useState } from 'react'
import tw from 'tailwind-styled-components'

import { NewManifestItem } from '../../../../common/types'
import { ImageModal } from '../../../ImageModal'
import { ImagePreview } from '../../../ImagePreview'
import { PickupManifestItem } from './PickupManifestItem'

export const ManifestItem = ({
  manifestItem,
  setFormValid,
  setManifestItem,
  onDelete = () => {},
}: {
  manifestItem: NewManifestItem
  setFormValid: (value: boolean) => void
  setManifestItem: (newData: Partial<NewManifestItem>) => void
  onDelete?: () => void
  isFirst: boolean
}) => {
  const [isModalVisible, setModalVisible] = useState(false)
  const [isImageModalVisible, setImageModalVisible] = useState(false)

  const props = { manifestItem, setFormValid, setManifestItem }

  return (
    <>
      <PickupManifestItem {...props} />
      {manifestItem.file && (
        <ImagePreviewContainer>
          <ImagePreview image={manifestItem.file} onClick={() => setImageModalVisible(true)} />
          <DeleteIcon onClick={() => setManifestItem({ file: null })} />
        </ImagePreviewContainer>
      )}
      <ImageModal isVisible={isImageModalVisible} setVisible={setImageModalVisible}>
        <img alt='item' src={manifestItem.file} />
      </ImageModal>
      <ConfirmDialog
        isVisible={isModalVisible}
        setVisible={setModalVisible}
        text={manifestItem.name?.toString() || 'this item'}
        onConfirm={onDelete}
      />
    </>
  )
}

const ImagePreviewContainer = tw.div`
  flex
  items-center
  justify-between
  mt-4
`

const DeleteIcon = tw(TrashIcon)`
  w-5
  text-error
  hover:opacity-80
  transition-all
  cursor-pointer
`
