import { Form, Modal, TextInput } from '@components'
import { useEffect, useState } from 'react'

import { useAppSelector, useAppThunkDispatch } from '../../../../app/hooks'
import {
  createCarrierQuote,
  setCurrentCarrier,
  setEnterQuoteModalVisible,
} from '../../../../redux/loadsSlice'
import { ModalFooter } from './ModalFooter'

export const EnterQuoteModal = () => {
  const [carrierPrice, setCarrierPrice] = useState('')
  const [isFormValid, setFormValid] = useState(false)

  const dispatch = useAppThunkDispatch()

  const isEnterQuoteModalVisible = useAppSelector(state => state.loads.isEnterQuoteModalVisible)
  const loading = useAppSelector(state => state.loads.loading.createCarrierQuote)
  const currentCarrier = useAppSelector(state => state.loads.currentCarrier)

  useEffect(() => {
    if (!isEnterQuoteModalVisible) setCarrierPrice('')
  }, [isEnterQuoteModalVisible])

  const enterQuote = () =>
    isFormValid &&
    dispatch(
      createCarrierQuote({
        carrierId: currentCarrier?.carrierId || 0,
        carrierBid: carrierPrice,
      }),
    )
      .unwrap()
      .then(() => {
        dispatch(setEnterQuoteModalVisible(false))
        dispatch(setCurrentCarrier(null))
        setCarrierPrice('')
      })
      .catch(() => {})

  return (
    <Modal
      focusTrapped
      className='!max-w-[500px]'
      isVisible={isEnterQuoteModalVisible}
      setVisible={value => dispatch(setEnterQuoteModalVisible(value))}
      showCloseIcon={false}
    >
      <Form className='p-6 text-center' setFormValid={setFormValid}>
        <div className='font-bold text-lg mb-4'>Enter quote for this carrier</div>
        <TextInput
          autoFocus
          required
          sm
          label='Carrier Price'
          type='currency'
          value={carrierPrice}
          onChange={setCarrierPrice}
          onEnter={enterQuote}
        />
      </Form>
      <ModalFooter
        confirmButtonLabel='Submit'
        isConfirmButtonDisabled={!isFormValid}
        loading={loading}
        setModalVisible={value => dispatch(setEnterQuoteModalVisible(value))}
        onSend={enterQuote}
      />
    </Modal>
  )
}
