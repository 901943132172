import { createContext, useMemo } from 'react'
import { createBrowserRouter, Location } from 'react-router-dom'

import { useBrowserBackStack } from './utils'

export const BackStackContext = createContext<{
  backStack: Location[]
  prevRoute: Location | null
}>({
  backStack: [],
  prevRoute: null,
})

export const BackStackProvider = ({
  children,
  router,
}: {
  children: React.ReactNode
  router: ReturnType<typeof createBrowserRouter>
}) => {
  const backStack = useBrowserBackStack(router)
  const prevRoute = useMemo(() => {
    const currentRoute = backStack.at(-1) as Location

    // find the first route that is of a different path than the current path
    return (
      backStack
        .slice()
        .reverse()
        .find(route => route.pathname !== currentRoute.pathname) || null
    )
  }, [backStack])

  return (
    <BackStackContext.Provider value={{ backStack, prevRoute }}>
      {children}
    </BackStackContext.Provider>
  )
}
