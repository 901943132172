import { camelCase } from 'lodash-es'
import { useCallback } from 'react'
import { singletonHook } from 'react-singleton-hook'
import useWebSocket from 'react-use-websocket'
import { v4 as uuid } from 'uuid'

// import { isLocal } from '../constants'
import { keysToCamelCase, keysToSnakeCase } from '../utils'
import handlers from './handlers'

export const getClientId = () => {
  const hasId = sessionStorage.getItem('client-id')
  if (!hasId) sessionStorage.setItem('client-id', uuid())
  return sessionStorage.getItem('client-id') as string
}

export type TMSEvent = {
  event: string
  [key: string]: any
}

const getWebSocketUrl = () => {
  const appBaseUrl = (import.meta as any).env.VITE_BASE_URL
  // const appBaseUrl = isLocal ? location.origin : (import.meta as any).env.VITE_BASE_URL
  const url = new URL(appBaseUrl)
  return `${url.protocol === 'https:' ? 'wss' : 'ws'}://${url.host}/shipper/ws/events`
}

export const useSocketImpl = () => {
  const { sendMessage } = useWebSocket(getWebSocketUrl(), {
    share: true,
    onMessage(event) {
      const eventData = keysToCamelCase(JSON.parse(event.data))
      const handlerName = camelCase(eventData.event)
      const handler = handlers[handlerName as keyof typeof handlers]
      if (handler) handler(eventData)
    },
    queryParams: {
      client_id: getClientId(),
    },
    retryOnError: true,
    reconnectAttempts: Infinity,
    shouldReconnect: () => true,
  })

  const sendEvent = useCallback(
    ({ event, ...data }: TMSEvent) => {
      const payload = data ? { event, ...keysToSnakeCase(data) } : { event }
      sendMessage(JSON.stringify(payload))
    },
    [sendMessage],
  )

  return { sendEvent }
}

export const useSocket = singletonHook(() => ({ sendEvent: () => {} }), useSocketImpl)
