import { ReactNode } from 'react'
import tw from 'tailwind-styled-components'

export const PageContainer = ({
  children,
  className = '',
}: {
  children: ReactNode
  className?: string
}) => <Container className={className}>{children}</Container>

const Container = tw.div`
  flex
  flex-col
  md:pl-[200px]
  h-full
  md:!h-[calc(100vh-72px)]
  pt-[72px]
  md:pt-0
`
