export enum LoadStatus {
  PLAN = 'PLAN',
  TENDERED = 'TENDERED',
  TENDER_ACCEPTED = 'TENDER_ACCEPTED',
  TENDER_REJECTED = 'TENDER_REJECTED',
  AVAILABLE = 'AVAILABLE',
  BOOKED = 'BOOKED',
  ON_TRACK = 'ON_TRACK',
  OFF_TRACK = 'OFF_TRACK',
  DELIVERED = 'DELIVERED',
  READY_TO_BILL = 'READY_TO_BILL',
  BILLING_IN_PROGRESS = 'BILLING_IN_PROGRESS',
  CLAIM = 'CLAIM',
  COMPLETED = 'COMPLETED',
}

export enum OnTrackTransitStatus {
  EN_ROUTE = 'EN_ROUTE',
  AT_STOP = 'AT_STOP',
  LOADING = 'LOADING',
  UNLOADING = 'UNLOADING',
}

export enum OffTrackTransitStatus {
  BREAK_DOWN = 'BREAK_DOWN',
  DELAYED_AT_PREVIOUS_STOP = 'DELAYED_AT_PREVIOUS_STOP',
  TRAFFIC = 'TRAFFIC',
  LOCATION_CLOSED = 'LOCATION_CLOSED',
  HOURS_OF_SERVICE = 'HOURS_OF_SERVICE',
  WEATHER = 'WEATHER',
  CUSTOM_OR_INSPECTIONS = 'CUSTOM_OR_INSPECTIONS',
  OTHER = 'OTHER',
}
