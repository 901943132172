import React, { forwardRef } from 'react'
import Skeleton from 'react-loading-skeleton'
import tw from 'tailwind-styled-components'

import { toTransient, Transient } from '../../common/utils'

type TextAreaProps = {
  autoFocus?: boolean
  blue?: boolean
  className?: string
  error?: string
  id?: string
  label?: string
  labelClassName?: any
  labelIcon?: JSX.Element
  labelRightContent?: any
  name?: string
  onBlur?: (value: string) => void
  onChange?: (value: string) => void
  onFocus?: () => void
  placeholder?: string
  required?: boolean
  sm?: boolean
  value?: any
  validator?: (value: string) => boolean | string | void
  noStyles?: boolean
  loading?: boolean
  rows?: number
}

type $TextAreaProps = Transient<TextAreaProps>

export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (
    {
      autoFocus = false,
      blue = false,
      className = '',
      error = '',
      id = '',
      label = '',
      labelClassName = '',
      labelIcon,
      name = '',
      onBlur,
      onChange,
      onFocus,
      placeholder = '',
      required = false,
      labelRightContent,
      sm = false,
      value = '',
      noStyles,
      loading,
      rows = 3,
    }: TextAreaProps,
    ref,
  ): JSX.Element => {
    const $props = toTransient({ blue, sm, noStyles })

    return (
      <Container className={className}>
        {label && (
          <LabelContainer>
            {labelIcon}
            <Label htmlFor={id} {...$props}>
              <span className={labelClassName}>
                {label} {required && <span className='text-error'>*</span>}
              </span>
            </Label>
            {labelRightContent}
          </LabelContainer>
        )}
        <div className='relative h-full'>
          {!loading && (
            <TextAreaInput
              ref={ref}
              autoFocus={autoFocus}
              id={id}
              name={name}
              placeholder={placeholder}
              rows={rows}
              value={value?.toString()} // ensure input is always string
              onBlur={() => onBlur && onBlur(value?.toString())}
              onFocus={() => onFocus && onFocus()}
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                onChange && onChange(e.target.value)
              }
              {...$props}
            />
          )}
          {loading && <Skeleton className='h-[70px]' />}
        </div>
        {!!error && <Error>{error}</Error>}
      </Container>
    )
  },
)

const TextAreaInput = tw.textarea<$TextAreaProps>`
  appearance-none
  rounded-lg
  px-3
  py-2.5
  leading-tight
  focus:outline-none
  font-[inherit]
  w-full
  h-full
  resize-none
  transition-all
  placeholder-dark-gray
  box-border
  border-solid
  ${({ $noStyles }) => !$noStyles && 'border focus:border-dark-blue border-border-gray'}
`

const Container = tw.div`
  relative
  flex
  flex-col
`

const LabelContainer = tw.div`
  flex
  items-center
  mb-1
`

const Label = tw.label`
  font-poppins
  block
  text-dark-gray
  mb-0
  self-start
  mr-auto
  ${({ $sm }: $TextAreaProps) => ($sm ? 'text-xs' : 'text-sm')}
`

const Error = tw.div`
  text-error
  ml-1
  text-xs
  absolute
  -bottom-[30px]
  whitespace-nowrap
`
