import { createContext, ReactNode, useContext, useLayoutEffect, useRef, useState } from 'react'

import { useIsMobile, useWindowLoaded, useWindowSize } from '../../common/utils'

type MapContextType = {
  map: H.Map | null
  mapRef: React.RefObject<HTMLDivElement> | null
}

const MapContext = createContext<MapContextType>({
  map: null,
  mapRef: null,
})

const INITIAL_MAP_CENTER = { lat: 39.833333, lng: -98.585522 } // Middle of contiguous US

export const useMap = () => useContext(MapContext)

export const MapProvider = ({
  children,
  mapControlsClassName = '',
  mapControlsPosition = H.ui.LayoutAlignment.TOP_RIGHT,
  scalebarControlsPosition = H.ui.LayoutAlignment.TOP_LEFT,
  zoom = 4,
  zoomControlsPosition = H.ui.LayoutAlignment.TOP_RIGHT,
}: {
  children: ReactNode
  mapControlsClassName?: string
  mapControlsPosition?: H.ui.LayoutAlignment
  scalebarControlsPosition?: H.ui.LayoutAlignment
  zoom?: number
  zoomControlsPosition?: H.ui.LayoutAlignment
}) => {
  const [map, setMap] = useState<H.Map | null>(null)
  const mapRef = useRef<HTMLDivElement | null>(null)

  const isMobile = useIsMobile()
  const windowLoaded = useWindowLoaded()
  const [width] = useWindowSize()

  // Initialize map
  useLayoutEffect(() => {
    if (!mapRef.current || !windowLoaded || map) return
    const initializedMap = initMap(
      mapRef.current,
      isMobile,
      zoom,
      mapControlsClassName,
      mapControlsPosition,
      zoomControlsPosition,
      scalebarControlsPosition,
    )
    setMap(initializedMap)
    return () => initializedMap?.dispose()
  }, [windowLoaded])

  // Resize map when window size changes
  useLayoutEffect(() => {
    if (!map || !map.getElement() || !width) return
    map.getViewPort().resize()
  }, [map, width])

  return <MapContext.Provider value={{ map, mapRef }}>{children}</MapContext.Provider>
}

const initMap = (
  mapRef: HTMLDivElement,
  isMobile: boolean,
  zoom: number,
  mapControlsClassName: string,
  mapControlsPosition: H.ui.LayoutAlignment,
  zoomControlsPosition: H.ui.LayoutAlignment,
  scalebarControlsPosition: H.ui.LayoutAlignment,
) => {
  const platform = new H.service.Platform({
    apikey: import.meta.env.VITE_HERE_MAP_KEY,
  })

  const defaultLayers = platform.createDefaultLayers() as any

  const newMap = new H.Map(mapRef, defaultLayers.vector.normal.map, {
    center: INITIAL_MAP_CENTER,
    zoom,
    pixelRatio: window.devicePixelRatio || 1,
  })

  const behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(newMap))
  if (isMobile) behavior.disable(H.mapevents.Behavior.Feature.WHEEL_ZOOM)

  const ui = H.ui.UI.createDefault(newMap, defaultLayers)

  const mapControls = ui.getControl('mapsettings')
  const scalebarControls = ui.getControl('scalebar')
  const zoomControls = ui.getControl('zoom')

  if (mapControls) {
    mapControls.setAlignment(mapControlsPosition)
    mapControls.addClass(mapControlsClassName)
  }

  zoomControls?.setAlignment(zoomControlsPosition)
  scalebarControls?.setAlignment(scalebarControlsPosition)

  return newMap
}
