import tw from 'tailwind-styled-components'

import { ButtonIconType } from '../button'
import { getStatusBackground } from './helpers'

type StatusProps = {
  status: string
  className?: string
  preIcon?: ButtonIconType
  postIcon?: ButtonIconType
  background?: string
}

export const StatusButton = ({
  status = '',
  className = '',
  background,
  ...props
}: StatusProps): JSX.Element => {
  const backgroundColor = background || getStatusBackground(status)

  return (
    <Container
      $backgroundColor={backgroundColor}
      className={`${backgroundColor} ${className}`}
      {...props}
    >
      {status}
    </Container>
  )
}

const Container = tw.div<{ $backgroundColor?: string }>`
  rounded-lg
  h-8
  flex
  items-center
  justify-center
  px-4
  font-semibold
  text-sm
  uppercase
  ${({ $backgroundColor }) => $backgroundColor}
`
