import tw from 'tailwind-styled-components'

import { Button, ButtonType } from '../button'

export const ModalFooter = ({
  onSend,
  isConfirmButtonDisabled,
  setModalVisible,
  loading,
  confirmButtonLabel = 'Send',
  cancelButtonLabel = 'Cancel',
  confirmButtonType = 'warn',
  onlyCancelButton = false,
}: {
  onSend: () => void
  isConfirmButtonDisabled?: boolean
  setModalVisible: (value: boolean) => void
  loading?: boolean
  confirmButtonLabel?: string
  cancelButtonLabel?: string
  confirmButtonType?: ButtonType
  onlyCancelButton?: boolean
}) => (
  <Container>
    <Button fullWidth className='w-full' type='secondary' onClick={() => setModalVisible(false)}>
      {cancelButtonLabel}
    </Button>
    {!onlyCancelButton && (
      <Button
        fullWidth
        className='w-full'
        disabled={isConfirmButtonDisabled}
        loading={loading}
        type={confirmButtonType}
        onClick={onSend}
      >
        {confirmButtonLabel}
      </Button>
    )}
  </Container>
)

const Container = tw.div`
  flex
  justify-between
  bg-lighter-blue
  px-6
  py-4
  gap-4
`
