import { statusColorMap } from './colors'

export const getStatusBackground = (status: string | null, active = true, gray = false): string => {
  if (!active) return 'bg-light-gray'
  if (gray) return 'bg-status-gray'

  if (status) {
    const formattedStatus = status.toLowerCase().replace(/[_-]/g, ' ')
    return statusColorMap.get(formattedStatus) ?? 'bg-white'
  }

  return 'bg-white'
}
