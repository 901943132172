import { getConfirmation, MoreDropdown } from '@components'
import { toast } from 'react-toastify'
import tw from 'tailwind-styled-components'

import { useAppSelector, useAppThunkDispatch } from '../../../app/hooks'
import { archiveLoad, updateLoad } from '../../../redux/loadsSlice'

export const LoadActions = ({ setTab = () => {} }: { setTab?: (tab: string) => void }) => {
  const dispatch = useAppThunkDispatch()

  const load = useAppSelector(state => state.loads.loadDetails)

  const copyTrackingLink = () => {
    navigator.clipboard.writeText(`${location.origin}/tracking/${load?.trackingNumber}`)
    toast.success('Successfully copied tracking link')
  }

  const actions = !(load && load.archived)
    ? [
        {
          label: 'Copy Tracking Link',
          onClick: copyTrackingLink,
        },

        {
          label: 'Archive Load',
          onClick: async () => {
            const carrierAssignedMessage = load?.carrier?.id
              ? 'A carrier is already assigned to this load'
              : ''
            const response = await getConfirmation({
              message: `Are you sure you want to archive this load? ${carrierAssignedMessage}`,
              type: 'danger',
              actionLabel: 'Yes, archive',
            })
            if (response) dispatch(archiveLoad(load?.id))
          },
        },
      ]
    : [
        {
          label: 'Unarchive Load',
          onClick: async () => {
            const response = await getConfirmation({
              message: 'Are you sure you want to unarchive this load?',
              type: 'success',
              actionLabel: 'Yes, unarchive',
            })
            if (response) dispatch(updateLoad({ patchPayload: { archived: false } }))
          },
        },
      ]

  return (
    <>
      <div className='hidden lg:block'>
        <MoreDropdown withBorder className='!z-1' dropdownClassName='right-10' items={actions} />
      </div>
      <div className='lg:hidden'>
        {actions.map(action => (
          <MobileItem
            key={action.label}
            onClick={() => {
              action.onClick()
              setTab('main')
            }}
          >
            {action.label}
          </MobileItem>
        ))}
      </div>
    </>
  )
}

const MobileItem = tw.div`
  py-4
  px-4
  border-b
  border-light-gray
  last:border-none
  font-semibold
`
