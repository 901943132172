import { InformationCircleIcon } from '@heroicons/react/24/solid'
import tw from 'tailwind-styled-components'

export const ODQuoteBanner = () => (
  <Container>
    <InformationCircleIcon className='min-w-5 w-5 text-link mt-0.5' />
    <div className='ml-3 leading-5'>
      <div className='font-semibold mb-1'>
        Please provide item details to receive a quote from our experts.
      </div>
      <div>
        Some items are so large that they require review by our specialty shipment staff. Our Exo
        Freight expert will review the information and respond within 24 hours.
      </div>
    </div>
  </Container>
)

const Container = tw.div`
  mt-6
  lg:mt-10
  rounded-lg
  bg-lighter-blue
  border-l-[12px]
  border-r
  border-t
  border-b
  border-link
  p-3
  flex
  items-start
  text-sm
  font-poppins
`
