import tw from 'tailwind-styled-components'

import { Checkbox } from './Checkbox'

export type CheckboxSelectProps = {
  data: {
    title?: string
    description?: string
    disabled?: boolean
  }[]
  onSelect: Function
  selected: number
  className?: string
  noDescription?: boolean
  loading?: number
}

export const CheckboxSelect = ({
  data,
  onSelect,
  selected,
  className = '',
  noDescription = false,
  loading = -1,
}: CheckboxSelectProps) => (
  <Container className={className}>
    {data.map((item, index) => (
      <CheckboxSelectItem key={index} onClick={() => !item.disabled && onSelect(index)}>
        <Checkbox
          className='p-4 mt-0'
          description={noDescription ? '' : item.description}
          disabled={item.disabled}
          hoverable={false}
          isChecked={index === selected}
          isLoading={index === loading}
          title={item.title}
        />
      </CheckboxSelectItem>
    ))}
  </Container>
)

const Container = tw.div`
  flex
  flex-col
  md:flex-row
  justify-between
  hover:opacity-70
  transition-all
  cursor-pointer
`

const CheckboxSelectItem = tw.div`
  w-full
  md:w-1/2
  mb-4
  md:mb-0
  rounded-md
  border
  border-light-blue
  border-solid
  mr-4
  last:mr-0
`
