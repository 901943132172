import { CatchError, CityLocation, formatAxiosErrorToPayload, getErrorString } from '@common'
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'

import { api } from '../api/api'
import { RootState } from '../app/store'
import { Rate } from '../common/types'
import { initializeTracking } from './trackingSlice'

const initialNewQuote = {
  origin: null,
  destination: null,
  equipmentType: null,
  email: '',
  phone: '',
  name: '',
  companyName: '',
}

export enum InstantQuoteStep {
  INSTANT_QUOTE_FORM,
  RATES,
  CONTACT_FORM,
  CONTACT_FORM_SUBMITTED,
}

export type InstantQuoteEquipmentType = 'Flatbed' | 'Van' | 'Reefer' | null

export type NewInstantQuote = {
  origin: CityLocation | null
  destination: CityLocation | null
  equipmentType: InstantQuoteEquipmentType
  email: string
  phone: string
  name: string
  companyName: string
}

type InstantQuoteState = {
  loading: {
    createQuote: boolean
    bookSelectedPrice: boolean
  }
  selectedRate: any
  step: InstantQuoteStep
  newQuote: NewInstantQuote
  rates: Rate[]
}

const initialState: InstantQuoteState = {
  loading: {
    createQuote: false,
    bookSelectedPrice: false,
  },
  selectedRate: null,
  step: InstantQuoteStep.INSTANT_QUOTE_FORM,
  newQuote: initialNewQuote,
  rates: [],
}

export const createInstantQuote = createAsyncThunk(
  'instantQuote/createInstantQuote',
  async ({ isQuickQuote }: { isQuickQuote?: boolean }, { getState, rejectWithValue }) => {
    const { newQuote } = (getState() as RootState).instantQuote

    try {
      const response = await api.post('/instant-quotes/', {
        quoteRequest: {
          origin: {
            city: newQuote.origin?.city,
            state: newQuote.origin?.state,
            country: newQuote.origin?.country,
          },
          destination: {
            city: newQuote.destination?.city,
            state: newQuote.destination?.state,
            country: newQuote.destination?.country,
          },
          equipmentType: newQuote.equipmentType,
        },
        email: newQuote.email,
        companyName: newQuote.companyName,
      })
      // We technically get their name from the contact form, but we want
      // the email ASAP and so name people generically.
      initializeTracking({ email: newQuote.email })
      return { ...response.data, isQuickQuote }
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const bookSelectedPrice = createAsyncThunk(
  'instantQuote/bookSelectedPrice',
  async (_, { getState, rejectWithValue }) => {
    const { selectedRate, newQuote } = (getState() as RootState).instantQuote

    try {
      const response = await api.post(`/instant-quotes/contact-book/${selectedRate.id}/`, {
        name: newQuote.name,
        phoneNumber: newQuote.phone,
        email: newQuote.email,
      })
      return response.data
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

const instantQuotesSlice = createSlice({
  name: 'instantQuote',
  initialState,
  reducers: {
    setStep(state, { payload }: PayloadAction<InstantQuoteStep>) {
      state.step = payload
    },
    setSelectedRate(state, { payload }) {
      state.selectedRate = payload
      state.step = InstantQuoteStep.CONTACT_FORM
    },
    setNewQuote(state, { payload }) {
      state.newQuote = payload
    },
    goBack(state) {
      if (state.step) state.step -= 1
    },
    resetTool(state) {
      state.newQuote = initialNewQuote
      state.step = InstantQuoteStep.INSTANT_QUOTE_FORM
    },
  },
  extraReducers(builder) {
    builder
      .addCase(createInstantQuote.pending, state => {
        state.loading.createQuote = true
      })
      .addCase(createInstantQuote.fulfilled, (state, { payload }) => {
        state.loading.createQuote = false
        state.rates = payload.quoteRequest.quotes
        state.step = payload.isQuickQuote
          ? InstantQuoteStep.CONTACT_FORM_SUBMITTED
          : InstantQuoteStep.RATES
        if (payload.isQuickQuote) state.selectedRate = state.rates[0]
      })
      .addCase(createInstantQuote.rejected, (state, { payload }) => {
        state.loading.createQuote = false
        toast.error(getErrorString(payload, 'Failed to create quote'))
      })
      .addCase(bookSelectedPrice.pending, state => {
        state.loading.bookSelectedPrice = true
      })
      .addCase(bookSelectedPrice.fulfilled, state => {
        state.loading.bookSelectedPrice = false
        state.step = InstantQuoteStep.CONTACT_FORM_SUBMITTED
      })
      .addCase(bookSelectedPrice.rejected, (state, { payload }) => {
        state.loading.bookSelectedPrice = false
        toast.error(getErrorString(payload, 'Failed to book selected rate'))
      })
  },
})

export const { setStep, setSelectedRate, setNewQuote, goBack, resetTool } =
  instantQuotesSlice.actions

export default instantQuotesSlice.reducer
