import 'react-toastify/dist/ReactToastify.css'

import { TopLevelConfirmDialog } from '@components'
import * as Sentry from '@sentry/react'
import { ReactNode } from 'react'
import { ToastContainer, TypeOptions } from 'react-toastify'

import { FallBackScreen } from './components/FallbackScreen'
import { SwitchRouter } from './components/SwitchRouter'

type ContextClassType = {
  [key in TypeOptions | 'default']?: string // Include 'default' in the type
}

const contextClass: ContextClassType = {
  success: 'bg-success',
  error: 'bg-error',
  warning: 'bg-brand-accent',
  info: 'bg-link',
}

const App = () => (
  <CustomErrorBoundary fallback={<FallBackScreen />}>
    <div className='relative h-full'>
      <ToastContainer
        autoClose={2000}
        closeButton={false}
        limit={3}
        pauseOnFocusLoss={false}
        position='bottom-right'
        theme='colored'
        toastClassName={({ type } = {}) =>
          `${
            contextClass[type || 'default']
          } relative p-3 min-h-10 rounded-md overflow-hidden cursor-pointer`
        }
      />
      <TopLevelConfirmDialog />
      <SwitchRouter />
    </div>
  </CustomErrorBoundary>
)

const CustomErrorBoundary = ({
  children,
  fallback,
}: {
  children: ReactNode
  fallback: JSX.Element
}) => {
  const beforeCapture = (scope: Sentry.Scope, error: Error | null) => {
    if (error && error.message.includes('Failed to fetch dynamically imported module'))
      location.reload()
  }

  return (
    <Sentry.ErrorBoundary beforeCapture={beforeCapture} fallback={fallback}>
      {children}
    </Sentry.ErrorBoundary>
  )
}

export default App
