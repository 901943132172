import { Button } from '@components'
import { Menu } from '@headlessui/react'
import { ChevronDownIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { isEmpty } from 'lodash'
import React, { useEffect, useMemo } from 'react'
import tw from 'tailwind-styled-components'

import { useAppSelector, useAppThunkDispatch } from '../../../app/hooks'
import { initialFilters } from '../../../common/constants'
import { SearchFilters } from '../../../common/types'
import { getPresetKey } from '../../../common/utils'
import {
  setActivePresets,
  setMultipleActivePresets,
  setSelectedPreset,
  setShowFilters,
} from '../../../redux/userSlice'
import { EaseInOutTransition } from '../../EaseInOutTransition'
import { IndeterminateCheckboxInput } from '../../IndeterminateCheckboxInput'

const countActiveFilters = (obj: Record<string, any>) =>
  Object.keys(obj).reduce((count, key) => {
    const val = obj[key]
    if (Array.isArray(val) ? val.some(el => !!el) : !isEmpty(val)) return count + 1
    return count
  }, 0)

export const FiltersHeader = ({
  filters,
  setFilters,
  showPresets = true,
}: {
  filters: SearchFilters
  setFilters: (value: SearchFilters) => void
  showPresets?: boolean
}) => {
  const dispatch = useAppThunkDispatch()

  const showFilters = useAppSelector(state => state.user.showFilters)
  const preset = useAppSelector(state => state.user.presets?.[getPresetKey()]) || {}

  const selectedPresetId = preset?.selectedPreset || ''
  const activePresets = preset?.activePresets || []
  const currentPresets = preset?.presets || {}
  const presetKeys = Object.keys(currentPresets ?? [])

  const filtersLength = useMemo(() => countActiveFilters(filters), [filters])

  // on mount and unmount, reset selected preset to ensure query params take precedence
  useEffect(() => {
    dispatch(setSelectedPreset(''))
    return () => void dispatch(setSelectedPreset(''))
  }, [])

  const checkSelectedPresetUponRemoval = (id: string) => {
    if (id === selectedPresetId) {
      dispatch(setSelectedPreset(''))
      setFilters(initialFilters)
    }
  }

  const handleRemoveActivePreset = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    dispatch(setSelectedPreset(''))
    setFilters(initialFilters)
  }

  const handleSelectPreset = (activePresetId: string) => {
    setFilters(currentPresets[activePresetId].filters)
    dispatch(setSelectedPreset(activePresetId))
  }

  const handleToggleActivePreset = (id: string) => {
    dispatch(setActivePresets(id))
    checkSelectedPresetUponRemoval(id)
  }

  return (
    <Container>
      <Button
        sm
        className='hidden lg:block'
        innerClassName='min-w-[133px]'
        type={showFilters ? 'secondary' : 'primary'}
        onClick={() => dispatch(setShowFilters(!showFilters))}
      >
        {showFilters ? 'Hide' : 'Show'} Filters{' '}
        {!!filtersLength && !showFilters && <span>({filtersLength})</span>}
      </Button>
      <Button
        fullWidth
        sm
        className='w-[calc(100vw-32px)] lg:hidden'
        innerClassName='!w-full lg:!w-[108px]'
        type='warn'
        onClick={() => dispatch(setShowFilters(true))}
      >
        Filters {!!filtersLength && <span>({filtersLength})</span>}
      </Button>
      {showPresets && (
        <Menu as='div' className='z-[8] hidden lg:block'>
          <MenuButton data-testid='menu-button'>
            <div>Presets</div>
            <ChevronDownIcon className='w-4 ml-4' />
          </MenuButton>
          <EaseInOutTransition>
            <Dropdown>
              {!presetKeys.length ? (
                <EmptyText>No available presets</EmptyText>
              ) : (
                <div>
                  <div className='text-dark-gray my-2'>
                    Select which preset(s) you want to enable:
                  </div>
                  <IndeterminateCheckboxInput
                    primary
                    className='py-2'
                    isChecked={activePresets.length === presetKeys.length}
                    someSelected={activePresets.length}
                    title='All'
                    onChange={() => {
                      dispatch(
                        setMultipleActivePresets(
                          activePresets.length === presetKeys.length ? [] : presetKeys,
                        ),
                      )
                    }}
                  />
                  <div className='ml-4'>
                    {presetKeys.map(key => (
                      <PresetOption key={key}>
                        <IndeterminateCheckboxInput
                          contentClassName='overflow-ellipsis truncate w-[180px]'
                          isChecked={activePresets.includes(key)}
                          title={currentPresets[key].title}
                          onChange={() => handleToggleActivePreset(key)}
                        />
                      </PresetOption>
                    ))}
                  </div>
                </div>
              )}
            </Dropdown>
          </EaseInOutTransition>
        </Menu>
      )}
      <PresetsList $length={activePresets.length}>
        {activePresets.map((activePresetId: string) => (
          <PresetButton
            key={activePresetId}
            $selected={activePresetId === selectedPresetId}
            onClick={() => handleSelectPreset(activePresetId)}
          >
            <div className='whitespace-nowrap'>{currentPresets[activePresetId]?.title}</div>
            {activePresetId === selectedPresetId && (
              <CloseIcon onClick={handleRemoveActivePreset} />
            )}
          </PresetButton>
        ))}
      </PresetsList>
    </Container>
  )
}

const PresetsList = tw.div<{ $length: number }>`
  overflow-x-auto
  hide-scrollbar
  mt-3
  border-t
  border-border-gray
  lg:border-none
  lg:pt-0
  lg:mt-0
  lg:ml-4
  pl-4
  lg:pl-0
  pr-4
  flex
  w-screen
  ${({ $length }) => $length && 'pb-3 lg:pb-0 border-t-0 border-b'}
`

const EmptyText = tw.div`
  text-dark-gray
  text-center
  py-2
  whitespace-nowrap
`

const CloseIcon: any = tw(XMarkIcon)`
  w-4
  ml-4
  cursor-pointer
  hover:opacity-70
`

const PresetButton = tw.div<{ $selected: boolean }>`
  flex
  items-center
  h-8
  rounded-lg
  bg-lighter-blue
  px-4
  mr-4
  cursor-pointer
  text-brand-dark
  hover:bg-link
  hover:text-white
  border
  transition-all
  ${({ $selected }) => ($selected ? 'bg-link border-link text-white' : 'border-brand-light')}
`

const PresetOption = tw.div`
  py-2
  flex
  w-full
  whitespace-nowrap
`

const MenuButton = tw(Menu.Button)`
  rounded-lg
  h-8
  ml-4
  bg-brand-dark
  text-white
  font-semibold
  flex
  items-center
  whitespace-nowrap
  px-4
  cursor-pointer
  font-poppins
`

const Dropdown = tw(Menu.Items)`
  origin-top-right
  absolute
  bg-white
  shadow-lg
  border
  border-brand-light
  px-4
  py-2
  rounded-lg
  mt-2
  ml-4
  w-fit
  min-w-[270px]
  max-h-[300px]
  overflow-auto
`

const Container = tw.div`
  flex
  flex-col
  lg:flex-row
  w-full
  lg:mx-4
  lg:mb-4
  items-center
  relative
`
