// Returns the initial text with matching substring highlighted in bold
export const HighlightedMatchingText = ({
  text,
  query,
  className,
}: {
  text: string
  query: string
  className?: string
}) => {
  const normalizeText = (str: string) =>
    str
      .trim() // Trim white spaces from the beginning and the end
      .normalize('NFD') // Decompose accented characters
      .replace(/[\u0300-\u036f]/g, '') // Remove decomposed accents
      .replace(/,/g, '')
      .replace(/\s+/g, ' ') // Replace multiple spaces with a single space
      .toLowerCase()

  const originalText = String(text)
  const cleanText = normalizeText(originalText)
  const cleanQuery = normalizeText(query)

  const startIndexInCleanText = cleanText.indexOf(cleanQuery)

  if (!query || startIndexInCleanText === -1) return <>{text}</>

  const countCommasBeforeStart = (originalText.slice(0, startIndexInCleanText).match(/,/g) || [])
    .length
  const startIndexInOriginal = startIndexInCleanText + countCommasBeforeStart

  const countCommasInMatch = (
    originalText
      .slice(startIndexInOriginal, startIndexInOriginal + cleanQuery.length)
      .match(/,/g) || []
  ).length
  const endIndexInOriginal = startIndexInOriginal + cleanQuery.length + countCommasInMatch

  const preMatchText = originalText.slice(0, startIndexInOriginal)
  const matchingText = originalText.slice(startIndexInOriginal, endIndexInOriginal)
  const postMatchText = originalText.slice(endIndexInOriginal)

  return (
    <div className={className}>
      {preMatchText}
      <span className='font-bold'>{matchingText}</span>
      {postMatchText}
    </div>
  )
}
