import { formatDate, useIsMobile } from '@common'
import { EaseInOutTransition, LoaderSpinner } from '@components'
import { Menu } from '@headlessui/react'
import { BellIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { Cog8ToothIcon } from '@heroicons/react/24/solid'
import dayjs from 'dayjs'
import { noop } from 'lodash-es'
import { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import tw from 'tailwind-styled-components'

import { useAppSelector, useAppThunkDispatch } from '../../app/hooks'
import {
  getNotifications,
  getUnreadNotificationsCount,
  loadMoreNotifications,
  markNotificationsAsRead,
} from '../../redux/notificationsSlice'
import { Tabs } from '../Tabs'
import { Notification } from './Notification'

const userTimezone = dayjs.tz?.guess()

type GroupedNotifications = Record<string, Notification[]>

export const NotificationsContent = ({ onClose }: { onClose?: () => void }) => {
  const [activeTab, setActiveTab] = useState(0)
  const [page, setPage] = useState(1)
  const [loading, setLoading] = useState(false)

  const navigate = useNavigate()

  const dispatch = useAppThunkDispatch()

  const notifications = useAppSelector(state => state.notifications.notifications)
  const count = useAppSelector(state => state.notifications.count)
  const unreadNotificationsCount = useAppSelector(
    state => state.notifications.unreadNotificationsCount,
  )
  const isLoadMoreButtonVisible = useAppSelector(
    state => state.notifications.isLoadMoreButtonVisible,
  )

  const groupedNotifications = notifications.reduce(
    (acc: GroupedNotifications, notification: any) => {
      const date = dayjs(notification.createdAt).tz(userTimezone).format('YYYY-MM-DD')
      if (!acc[date]) acc[date] = []
      acc[date].push(notification)
      return acc
    },
    {} as GroupedNotifications,
  )

  const loadMore = async () => {
    setLoading(true)
    await dispatch(loadMoreNotifications({ page: page + 1 }))
      .unwrap()
      .then(() => setPage(page + 1))
      .catch(noop)
    setLoading(false)
  }

  return (
    <>
      <Header>
        <BellIcon className='w-5 stroke-2' />
        <div className='flex-1 font-semibold text-base'>Notifications</div>
        <Menu.Button>
          <XMarkIcon className='w-5 stroke-2' onClick={onClose} />
        </Menu.Button>
      </Header>
      <div className='flex justify-between items-center px-4 h-[45px]'>
        <div className='w-1/5'>
          {count ? (
            <Tabs
              activeTab={activeTab}
              containerClassName='!bg-white'
              setActiveTab={setActiveTab}
              tabs={[{ title: 'All', counter: count }]}
            />
          ) : (
            <div className='text-dark-gray'>No notifications found</div>
          )}
        </div>
        <div className='flex items-center gap-4'>
          {!!unreadNotificationsCount && (
            <>
              <LinkButton onClick={() => dispatch(markNotificationsAsRead({}))}>
                Mark all as read
              </LinkButton>
              <div className='h-5 w-px bg-brand-dark' />
            </>
          )}
          <Menu.Button>
            <Cog8ToothIcon
              className='hover:opacity-80 transition-all'
              onClick={() => navigate('/account?tab=Notifications')}
            />
          </Menu.Button>
        </div>
      </div>
      {!!count && (
        <div className='max-h-screen lg:max-h-[50vh] overflow-auto pt-2'>
          {Object.keys(groupedNotifications).map(date => (
            <div key={date}>
              <div className='font-bold p-4'>
                {dayjs.tz(date, userTimezone).format('dddd')} {formatDate(date)}
              </div>
              {(groupedNotifications[date] || []).map((notification: any) => (
                <Notification key={notification.id} notification={notification} />
              ))}
            </div>
          ))}
          {notifications.length < count &&
            isLoadMoreButtonVisible &&
            (loading ? (
              <LoaderSpinner size={4} />
            ) : (
              <LinkButton className='text-center py-4' onClick={loadMore}>
                Load More
              </LinkButton>
            ))}
        </div>
      )}
    </>
  )
}

export const Notifications = () => {
  const isMobile = useIsMobile()

  const location = useLocation()

  const navigate = useNavigate()

  const dispatch = useAppThunkDispatch()

  const unreadNotificationsCount = useAppSelector(
    state => state.notifications.unreadNotificationsCount,
  )

  useEffect(() => {
    dispatch(getNotifications())
    dispatch(getUnreadNotificationsCount())
  }, [])

  const NotificationsButton = useCallback(
    () => (
      <>
        <NotificationsButtonContainer>
          <BellIcon className='w-5' />
          {!!unreadNotificationsCount && <Counter>{unreadNotificationsCount}</Counter>}
        </NotificationsButtonContainer>
      </>
    ),
    [unreadNotificationsCount],
  )

  return (
    <>
      <MenuContainer as='div'>
        {isMobile ? (
          <div
            onClick={() => {
              if (location.pathname === '/notifications') navigate(-1)
              else navigate('/notifications')
            }}
          >
            <NotificationsButton />
          </div>
        ) : (
          <Menu.Item>
            <Menu.Button data-testid='menu-button'>
              <NotificationsButton />
            </Menu.Button>
          </Menu.Item>
        )}
        <EaseInOutTransition>
          <Dropdown>
            <NotificationsContent />
          </Dropdown>
        </EaseInOutTransition>
      </MenuContainer>
    </>
  )
}

const LinkButton = tw.div`
  font-semibold
  text-link
  hover:opacity-80
  transition-all
  cursor-pointer
`

const Header = tw.div`
  flex
  items-center
  justify-between
  p-4
  bg-lighter-blue
  rounded-t-lg
  gap-3
`

const Counter = tw.div`
  absolute
  -top-1
  -right-1
  bg-brand-accent
  rounded-full
  flex
  items-center
  justify-center
  h-4
  w-4
  !text-xxs
  font-bold
  text-brand-dark
`

const MenuContainer = tw(Menu)`
  relative
  inline-block
  text-left
  text-xs
`

const NotificationsButtonContainer = tw.div`
  hover:opacity-80
  transition-all
  cursor-pointer
  bg-brand-dark
  rounded-full
  text-white
  w-10
  h-10
  flex
  items-center
  justify-center
  relative
`

const Dropdown = tw(Menu.Items)`
  origin-top-right
  rounded-md
  shadow-lg
  ring-1
  ring-black
  ring-opacity-5
  focus:outline-none
  bg-white
  left-auto
  bottom-auto
  absolute
  right-0
  z-10
  lg:min-w-[840px]
  mt-2
  z-[999]
`
