import './Tabs.scss'

import React, { Fragment } from 'react'
import tw from 'tailwind-styled-components'

import type { ILTabs, ITab } from '../../common/types'

const Tabs = ({
  tabs,
  activeTab,
  setActiveTab = () => {},
  gliderStyle,
  disabled = false,
  containerClassName,
}: ILTabs): JSX.Element => (
  <div className='tabs-wrap'>
    <Container className={containerClassName}>
      {disabled && <div className='overlay' />}
      {tabs.map((tab: ITab, i) => (
        <Fragment key={tab.title}>
          <input
            defaultChecked={i === activeTab}
            id={`radio-${i + 1}`}
            name='tabs'
            type='radio'
            onClick={() => setActiveTab(i)}
          />
          <label className='l-tab' htmlFor={`radio-${i + 1}`}>
            {tab.title}
            {tab.counter ? <span className='counter'>{tab.counter}</span> : null}
          </label>
        </Fragment>
      ))}
      <span className='l-glider' style={{ width: `${100 / tabs.length}%`, ...gliderStyle }} />
    </Container>
  </div>
)

const Container = tw.div`
  l-tabs
`

export { Tabs }
