import tw from 'tailwind-styled-components'

export const Error = ({ className, children }: { className?: string; children: string }) => (
  <ErrorContainer className={className}>{children}</ErrorContainer>
)

const ErrorContainer = tw.div`
  text-error
  mb-4
  font-semibold
  text-center
`
