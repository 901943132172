import { Checkbox, Modal, ModalFooter, MoreDropdown, Status } from '@components'
import { ReactNode, useCallback, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import tw from 'tailwind-styled-components'

import { useAppSelector, useAppThunkDispatch } from '../../../../app/hooks'
import { EligibleCarrier, ShipperCarrier } from '../../../../common/types'
import { displayDateTime, formatCurrency, formatPhone } from '../../../../common/utils'
import {
  createCarrierQuote,
  setCancelTenderModalVisible,
  setCurrentCarrier,
  setEnterQuoteModalVisible,
  setRetenderModalVisible,
  setTenderLoadModalVisible,
  tenderLoad,
  updateLoad,
} from '../../../../redux/loadsSlice'
import { DropdownItemTooltip } from './DropdownItemTooltip'

export const Carrier = ({
  carrier,
  selectCarrier,
  isSelected,
  hideActions,
}: {
  carrier: EligibleCarrier | ShipperCarrier
  selectCarrier: (id: number) => void
  isSelected: boolean
  hideActions?: boolean
}) => {
  const { id } = useParams()
  const dispatch = useAppThunkDispatch()

  const isTenderDisabled = useAppSelector(state => state.loads.isTenderDisabled)
  const load = useAppSelector(state => state.loads.loadDetails)

  const isExo = carrier.carrierId === -1

  const isTendered = load?.carrier?.name
  const isTenderedToThisCarrier = load?.carrier?.name === carrier.name
  const isTenderedToExo = load?.network === 2
  const isTenderPending = load?.carrier?.tenderPending

  const [isQuoteModalVisible, setQuoteModalVisible] = useState(false)

  const tenderLoadToCarrier = async () => {
    if (isTenderedToThisCarrier) {
      await dispatch(
        tenderLoad({
          carrierId: carrier?.carrierId,
          amount: load?.carrierPrice,
        }),
      )
      return
    }
    if (!isExo) {
      dispatch(setCurrentCarrier(carrier))
      if (load.carrier) dispatch(setRetenderModalVisible(true))
      else dispatch(setTenderLoadModalVisible(true))
    } else dispatch(updateLoad({ id, patchPayload: { newLoadStatus: 'PLAN', network: 2 } }))
  }

  const tenderLabel = useMemo(
    () =>
      isTenderedToExo || (isTenderDisabled && !isExo) || (isTendered && !isTenderPending) ? (
        <DropdownItemTooltip text='Tender Load' />
      ) : isTenderedToThisCarrier || (isExo && isTenderedToExo) ? (
        'Resend Tender'
      ) : (
        'Tender Load'
      ),
    [
      isTenderedToExo,
      isTenderDisabled,
      isExo,
      isTendered,
      isTenderPending,
      isTenderedToThisCarrier,
      isTenderedToExo,
    ],
  )

  const menuItems: {
    label: ReactNode
    onClick: () => void
    disabled?: boolean
    className?: string
  }[] = useMemo(
    () => [
      ...(!(isExo && isTenderedToExo)
        ? [
            {
              label: tenderLabel,
              onClick: tenderLoadToCarrier,
              disabled:
                (isTenderDisabled && !isExo) || isTenderedToExo || (isTendered && !isTenderPending),
              className: 'bg-brand-accent border-brand-accent',
            },
          ]
        : []),
      ...(isTenderedToThisCarrier || (isExo && isTenderedToExo)
        ? [
            {
              label:
                isExo && isTenderedToExo ? (
                  <DropdownItemTooltip text='Cancel Tender' />
                ) : (
                  'Cancel Tender'
                ),
              onClick: () => {
                dispatch(setCurrentCarrier(carrier))
                dispatch(setCancelTenderModalVisible(true))
              },
              disabled: isExo && isTenderedToExo,
              className: 'bg-error text-white border-error',
            },
          ]
        : []),
      ...(!isExo && !isTendered
        ? [
            {
              label: 'Enter Quote',
              onClick: () => {
                dispatch(setCurrentCarrier(carrier))
                dispatch(setEnterQuoteModalVisible(true))
              },
              className: 'bg-white border-brand-dark',
            },
          ]
        : []),
      ...(!isExo && !isTendered
        ? [
            {
              label: carrier?.quote ? 'Resend quote request' : 'Request Quote',
              onClick: () =>
                dispatch(
                  createCarrierQuote({
                    carrierId: carrier?.carrierId || 0,
                  }),
                ),
              className: 'bg-brand-dark border-brand-dark text-white',
            },
          ]
        : []),
    ],
    [
      carrier,
      isExo,
      isTenderedToExo,
      isTenderDisabled,
      isExo,
      isTenderedToExo,
      isTendered,
      isTenderPending,
      isTenderedToThisCarrier,
    ],
  )

  const CarrierActionAndStatus = useCallback(
    () => (
      <>
        {hideActions && (
          <Checkbox
            disabled={!carrier.contact?.email}
            isChecked={isSelected}
            title='Select this carrier'
            onChange={() => selectCarrier(carrier.carrierId)}
          />
        )}
        {!hideActions && !isExo && !carrier.quote && !isTenderedToThisCarrier && (
          <Checkbox
            disabled={!carrier.contact?.email}
            isChecked={isSelected}
            title='Select this carrier'
            onChange={() => selectCarrier(carrier.carrierId)}
          />
        )}
        {!hideActions && (
          <>
            {((load?.network === 2 && isExo) || isTenderedToThisCarrier) && (
              <QuoteStatus $status='Load Tendered'>Load Tendered</QuoteStatus>
            )}
            {carrier.quote && !isTenderedToThisCarrier && (
              <QuoteStatus $status={carrier.quote.quoteStatusDisplay}>
                {carrier.quote.quoteStatusDisplay}{' '}
                {carrier.quote.quoteStatusDisplay === 'Quoted' &&
                  formatCurrency(carrier.quote.carrierBid)}
              </QuoteStatus>
            )}
          </>
        )}
      </>
    ),
    [isExo, carrier, isTenderedToThisCarrier, isSelected, selectCarrier, load, hideActions],
  )

  const statusBg = useMemo(() => {
    switch (carrier.emailStatus?.event?.toLowerCase()) {
      case 'sent':
        return 'bg-link text-white'
      case 'open':
        return 'bg-success text-white'
      case 'click':
        return 'bg-tendered'
      case 'bounce':
        return 'bg-lilac'
      case 'blocked':
        return 'bg-error-bg'
      case 'spam':
        return 'bg-light-yellow'
      case 'unsub':
        return 'bg-white border border-dark-blue'
      default:
        return ''
    }
  }, [carrier.emailStatus?.event])

  return (
    <Container>
      <CarrierName className={hideActions ? 'lg:w-[40%]' : 'lg:w-[18%]'}>
        <div className='flex justify-between'>
          {carrier.name}
          <div className='lg:hidden whitespace-nowrap'>
            <CarrierActionAndStatus />
          </div>
        </div>
      </CarrierName>
      <Contact className={hideActions ? 'lg:w-[40%]' : 'lg:w-[30%]'}>
        <div>
          <span className='font-semibold'>{carrier.contact?.name || '—'}</span>
        </div>
        <div>
          <span className='font-semibold'>{formatPhone(carrier.contact?.phone)}</span>
        </div>
        <div>
          <span className='font-semibold'>{carrier.contact?.email || '—'}</span>
        </div>
      </Contact>
      {!hideActions && (
        <div className='w-[16%]'>
          {carrier.emailStatus?.event && (
            <>
              <Status background={statusBg} status={carrier.emailStatus?.event} />
              <div className='mt-2'>{displayDateTime(carrier.emailStatus?.time)}</div>
            </>
          )}
        </div>
      )}
      <div className='w-[20%] max-lg:hidden'>
        <CarrierActionAndStatus />
      </div>
      {!hideActions && (
        <>
          <div className='w-[14%]'>
            {carrier.quote?.carrierNotes && (
              <CarrierNotes
                style={{ textOverflow: 'ellipsis' }}
                onClick={() => setQuoteModalVisible(true)}
              >
                {carrier.quote?.carrierNotes}
              </CarrierNotes>
            )}
          </div>
          <div className='max-lg:hidden w-[4%] mt-1'>
            <span className='bg-lighter-blue p-1 pt-2 rounded'>
              <MoreDropdown dropdownClassName='right-6' items={menuItems} />
            </span>
          </div>
        </>
      )}
      <Modal
        className='w-[500px]'
        isVisible={isQuoteModalVisible}
        setVisible={setQuoteModalVisible}
        showCloseIcon={false}
      >
        <div className='p-6'>
          <div className='text-black font-bold text-lg'>{carrier.name}</div>
          <div className='text-dark-gray mt-3 font-bold'>Rate</div>
          <div className='text-black mt-1 font-bold text-lg'>
            {formatCurrency(carrier.quote?.carrierBid)}
          </div>
          <div className='text-dark-gray mt-3 font-bold'>Notes</div>
          <div className='text-black mt-1 text-sm'>{carrier.quote?.carrierNotes}</div>
        </div>
        <ModalFooter
          cancelButtonLabel='Close'
          onlyCancelButton={true}
          setModalVisible={setQuoteModalVisible}
          onSend={() => {}}
        />
      </Modal>
    </Container>
  )
}

const CarrierName = tw.div`
  font-semibold
  max-lg:w-full
  max-lg:mb-2
`

const Contact = tw.div`
  leading-5
  w-full
`

const QuoteStatus = tw.div<{ $status: string }>`
  font-bold
  ${({ $status }) =>
    ($status === 'Requested' && 'text-exo-orange') ||
    ($status === 'Load Tendered' && 'text-success') ||
    ($status === 'Rejected' && 'text-error') ||
    ($status === 'Quoted' && 'text-link') ||
    ($status === 'Cancelled' && 'text-dark-gray')}
`

const Container = tw.div`
  flex
  max-lg:flex-col
  w-full
  lg:pb-6
  lg:first:pt-6
  max-lg:border
  border-light-gray
  rounded-lg
  max-lg:p-4
  max-lg:mb-4
`

const CarrierNotes = tw.div`
  w-[80%]
  
  text-black
  font-normal
  bg-lighter-blue
  rounded
  border-2
  border-border-gray
  border-dotted
  p-1
  whitespace-nowrap
  overflow-hidden
  hover:bg-link
  hover:text-white
  cursor-pointer
`
