import tw from 'tailwind-styled-components'

export const DeleteButton = ({
  onClick,
  className,
  buttonText = 'Delete',
}: {
  onClick: () => void
  className?: string
  buttonText?: string
}) => (
  <OuterContainer className={className}>
    <InnerContainer onClick={onClick}>{buttonText}</InnerContainer>
  </OuterContainer>
)

const OuterContainer = tw.div`
  flex
  justify-end
  mt-4
`

const InnerContainer = tw.button`
  text-error
  font-semibold
  hover:opacity-80
  transition-all
  cursor-pointer
`
