import { Button, Modal } from '@components'
import { useNavigate } from 'react-router-dom'
import tw from 'tailwind-styled-components'

import { useAppSelector, useAppThunkDispatch } from '../../../app/hooks'
import { initialSearchParams } from '../../../common/constants'
import { getSingleLaneQuote } from '../../../redux/commonSlice'
import { setSearchParams } from '../../../redux/quotingToolSlice'
import { ODQuoteHeader } from './ODQuoteHeader'
import { ODQuoteInfo } from './ODQuoteInfo'

export const ODQuoteReviewModal = ({
  isReviewModalVisible,
  setReviewModalVisible,
}: {
  isReviewModalVisible: boolean
  setReviewModalVisible: (value: boolean) => void
}) => {
  const navigate = useNavigate()

  const dispatch = useAppThunkDispatch()

  const data = useAppSelector(state => state.quotingTool.searchParams)
  const loading = useAppSelector(state => state.common.loading.singleLaneQuote)

  const bookRate = () =>
    dispatch(getSingleLaneQuote())
      .unwrap()
      .then(data => {
        navigate(`/quotes/${data.id}`)
        dispatch(setSearchParams(initialSearchParams))
        setReviewModalVisible(false)
      })
      .catch(() => {})

  return (
    <Modal
      isVisible={isReviewModalVisible}
      setVisible={setReviewModalVisible}
      showCloseIcon={false}
    >
      <div className='px-10 py-8 min-w-[700px]'>
        <ModalHeader>Review Your OD Quote Request</ModalHeader>
        <ODQuoteHeader className='my-6' showStatus={false} />
        <ODQuoteInfo data={data} />
        <div className='flex gap-x-4 mt-6'>
          <Button
            fullWidth
            className='w-full'
            type='secondary'
            onClick={() => setReviewModalVisible(false)}
          >
            Cancel
          </Button>
          <Button fullWidth className='w-full' loading={loading} type='success' onClick={bookRate}>
            Request Quote
          </Button>
        </div>
      </div>
    </Modal>
  )
}

const ModalHeader = tw.div`
  font-bold
  text-2xl
  poppins
  border-b
  border-brand-dark
  pb-6
`
