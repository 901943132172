import { formatCountAndPluralize } from '@common'
import { Button, ConfirmationDialog, DragAndDropFileInput, Modal, TextInput } from '@components'
import { DocumentIcon } from '@heroicons/react/24/solid'
import { useEffect, useState } from 'react'
import tw from 'tailwind-styled-components'

import { useAppSelector, useAppThunkDispatch } from '../../../app/hooks'
import { trackEvent } from '../../../common/tracking'
import { displayDateTime } from '../../../common/utils'
import { getMultiLaneQuote, setFile, setFileName } from '../../../redux/commonSlice'
import { deleteUploadOrQuote, getContacts, getUploadsList } from '../../../redux/quotingToolSlice'
import { Ellipsis } from '../../Table'

export const MultiLaneQuote = ({
  isVisible,
  setVisible,
}: {
  isVisible: boolean
  setVisible: (value: boolean) => void
}) => {
  const [isNameModalVisible, setNameModalVisible] = useState(false)
  const [isConfirmationModalVisible, setConfirmationModalVisible] = useState(false)
  const [localFile, setLocalFile] = useState<any>(null)

  const dispatch = useAppThunkDispatch()

  const quote = useAppSelector(state => state.common.multiLaneQuote)
  const file = useAppSelector(state => state.common.file)
  const fileName = useAppSelector(state => state.common.fileName)
  const fileLoading = useAppSelector(state => state.common.loading.file)
  const error = useAppSelector(state => state.common.fileError)
  const newFileName = useAppSelector(state => state.common.newFileName)
  const deleteFileLoading = useAppSelector(state => state.quotingTool.loading.deleteUploadOrQuote)
  const uploadsList = useAppSelector(state => state.quotingTool.uploadsList)

  const fileNames = uploadsList.map((upload: any) => upload.file)
  const success = !fileLoading && !error

  useEffect(() => {
    dispatch(getUploadsList())
  }, [])

  useEffect(() => {
    if (!isVisible && !fileLoading) dispatch(setFile(null))
  }, [isVisible])

  const handleConfirm = async () => {
    if (!isConfirmDisabled) {
      await dispatch(setFile(localFile))
      dispatch(getMultiLaneQuote(localFile))
      setNameModalVisible(false)
      dispatch(getUploadsList())
      dispatch(getContacts())
      trackEvent('Searched for multi lane quote(s)')
    }
  }

  const handleSetFile = async (file: File | null) => {
    setLocalFile(file)
    if (file) {
      dispatch(setFileName(file.name.replaceAll(' ', '_')))
      if (fileNames.includes(file.name.replaceAll(' ', '_'))) setNameModalVisible(true)
      else {
        dispatch(setFile(file))
        dispatch(getMultiLaneQuote(file))
        trackEvent('Searched for multi lane quote(s)')
      }
    }
  }

  const handleDeleteFile = async () => {
    const response = await dispatch(deleteUploadOrQuote({ batchPricingUploadIds: [quote.id] }))
    if (response.meta.requestStatus === 'rejected') return
    dispatch(setFile(null))
    setConfirmationModalVisible(false)
  }

  const isConfirmDisabled = !newFileName || fileNames.includes(newFileName)

  return (
    <Modal
      className='max-lg:w-screen max-lg:!m-0'
      isVisible={isVisible}
      setVisible={setVisible}
      showCloseIcon={false}
      onClose={() => dispatch(setFile(null))}
    >
      <div className='p-6 lg:min-w-[700px]'>
        <div className='font-bold mb-4 text-base'>Upload Multi-Lane CSV</div>
        {file && (
          <div className='mt-8 mb-4'>
            <div className='flex justify-between'>
              <TableHeader>File Name</TableHeader>
              <TableHeader>Number Of Lanes</TableHeader>
              <TableHeader className='max-lg:hidden'>Date Uploaded</TableHeader>
            </div>
            <div className='flex justify-between relative'>
              <TableValue className='flex items-center'>
                <DocumentIcon className='w-4 min-w-4' />
                <span className='ml-2 font-semibold'>{fileName}</span>
              </TableValue>
              <TableValue>
                {fileLoading && 'File is processing...'}
                {error && 'Error'}
                {success && quote.numLanes}
              </TableValue>
              <TableValue className='max-lg:hidden'>
                {fileLoading || error ? '' : displayDateTime(quote.createdAt)}
              </TableValue>
              <div className='absolute -right-0.5 top-2 max-lg:hidden'>
                <Ellipsis
                  dropdownClassName='-top-20'
                  items={[
                    {
                      label: 'Hide File',
                      onClick: () => dispatch(setFile(null)),
                    },
                    {
                      label: 'Delete file',
                      onClick: () => setConfirmationModalVisible(true),
                    },
                  ]}
                />
              </div>
            </div>
          </div>
        )}
        {!fileLoading && (
          <DragAndDropFileInput
            className='mt-2'
            fileTypes={['CSV', 'DOT']}
            helpText='Available formats: csv, dot'
            setFile={handleSetFile}
          />
        )}
        <ConfirmationDialog
          focusTrapped
          confirmButtonText='Save'
          isConfirmButtonDisabled={isConfirmDisabled}
          isVisible={isNameModalVisible}
          setVisible={setNameModalVisible}
          text='File name is already in use. Please enter a different file name.'
          title='Rename File'
          onConfirm={handleConfirm}
        >
          <TextInput
            sm
            className='mt-4'
            value={newFileName}
            onChange={(fileName: string) => dispatch(setFileName(fileName))}
            onKeyDown={(event: any) => event.code === 'Enter' && handleConfirm()}
          />
        </ConfirmationDialog>
        <ConfirmationDialog
          confirmButtonLoading={deleteFileLoading}
          confirmButtonText='Yes, Delete'
          confirmButtonType='danger'
          isVisible={isConfirmationModalVisible}
          setVisible={setConfirmationModalVisible}
          title='Delete File'
          text={`Are you sure you want to delete this file with ${formatCountAndPluralize(
            quote.numLanes,
            'quote',
          )}?`}
          onConfirm={handleDeleteFile}
        />
      </div>
      <Button
        fullWidth
        className='w-full p-6 pt-2'
        type='secondary'
        onClick={() => {
          setVisible(false)
          if (!fileLoading) dispatch(setFile(null))
        }}
      >
        Close
      </Button>
    </Modal>
  )
}

const TableValue = tw.div`
  py-4
  w-1/3
`

const TableHeader = tw.div`
  font-semibold
  w-1/3
  whitespace-nowrap
`
