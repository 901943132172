import { CheckIcon, MinusIcon } from '@heroicons/react/24/outline'
import { ReactNode } from 'react'
import tw from 'tailwind-styled-components'

import { LoaderSpinner } from '../loaders'

export type CheckboxProps = {
  isChecked: boolean
  isLoading?: boolean
  className?: string
  wrapperClassName?: string
  onChange?: (isChecked: boolean) => void
  title?: string | ReactNode
  description?: string | ReactNode
  hoverable?: boolean
  disabled?: boolean
  primary?: boolean
  indeterminate?: boolean
  color?: string
  light?: boolean
  titleClassName?: string
}

export const Checkbox = ({
  isChecked,
  isLoading = false,
  className = '',
  wrapperClassName = '',
  onChange = () => {},
  title = '',
  description = '',
  hoverable = true,
  disabled,
  indeterminate,
  color = 'success',
  light = false,
  titleClassName,
}: CheckboxProps) => {
  const onClick = () => {
    if (!isLoading && !disabled) onChange(!isChecked)
  }

  return (
    <Container $disabled={disabled} $hoverable={hoverable} className={className} onClick={onClick}>
      <CheckboxWrap
        $color={color}
        $disabled={disabled}
        $isChecked={isChecked || !!indeterminate}
        $light={light}
        className={wrapperClassName}
      >
        {isLoading && <LoaderSpinner size={2} />}
        {isChecked && !isLoading && (
          <CheckIcon className='text-white w-3' data-testid='check-icon' />
        )}
        {!isLoading && !!indeterminate && !isChecked && <MinusIcon className='text-white w-3' />}
      </CheckboxWrap>
      <div>
        {title && (
          <Title $disabled={disabled} className={titleClassName}>
            {title}
          </Title>
        )}
        {description && <div className='text-gray-700 ml-3'>{description}</div>}
      </div>
    </Container>
  )
}

const Container = tw.div<{ $disabled?: boolean; $hoverable: boolean }>`
  flex
  items-center
  w-fit
  transition-all
  ${({ $hoverable }) => $hoverable && 'hover:opacity-70 cursor-pointer'}
  ${({ $disabled }) => $disabled && 'hover:opacity-100 cursor-default'}
`

const CheckboxWrap = tw.div<{
  $isChecked: boolean
  $color?: string
  $light?: boolean
  $disabled?: boolean
}>`
  rounded-md
  w-[18px]
  min-w-[18px]
  h-[18px]
  flex
  items-center
  justify-center
  border
  transition-all
  -mt-px
  ${({ $light }) => ($light ? 'border-white' : 'border-dark-blue')}
  ${({ $isChecked, $color }) => $isChecked && `bg-${$color} border-${$color}`}
  ${({ $disabled }) => $disabled && 'opacity-50 hover:opacity-50 cursor-default'}
`

const Title = tw.div<{ $disabled?: boolean }>`
  ml-3
  whitespace-nowrap
  ${({ $disabled }) => $disabled && 'opacity-50'}
`
