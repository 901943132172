import { Form, Modal } from '@components'

import { useAppSelector, useAppThunkDispatch } from '../../../../app/hooks'
import {
  setRetenderModalVisible,
  setTenderLoadModalVisible,
  updateLoad,
} from '../../../../redux/loadsSlice'
import { ModalFooter } from './ModalFooter'

export const RetenderWarningModal = () => {
  const dispatch = useAppThunkDispatch()

  const isModalVisible = useAppSelector(state => state.loads.isRetenderModalVisible)
  const currentCarrier = useAppSelector(state => state.loads.currentCarrier)

  const handleRetender = () => {
    dispatch(setRetenderModalVisible(false))
    if (currentCarrier?.carrierId === -1)
      dispatch(updateLoad({ patchPayload: { loadStatus: 1, network: 2 } }))
    else dispatch(setTenderLoadModalVisible(true))
  }

  return (
    <Modal
      className='!max-w-[500px]'
      isVisible={isModalVisible}
      setVisible={value => dispatch(setRetenderModalVisible(value))}
      showCloseIcon={false}
    >
      <Form className='p-6 text-center' setFormValid={() => {}}>
        <div className='font-bold text-lg mb-4'>Cancel Tender</div>
        <div className='leading-5'>
          This load has already been tendered to a different carrier. This action will remove the
          previous carrier and tender the load to the selected carrier.
        </div>
      </Form>
      <ModalFooter
        cancelButtonLabel='Cancel'
        confirmButtonLabel='Proceed'
        confirmButtonType='danger'
        setModalVisible={value => dispatch(setRetenderModalVisible(value))}
        onSend={handleRetender}
      />
    </Modal>
  )
}
