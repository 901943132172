import { Button } from '@components'
import tw from 'tailwind-styled-components'

export const ActionButtons = ({
  isEditing,
  setEditing = () => {},
  onDelete,
  isSaveDisabled,
  onSave = () => {},
  onCancel = () => {},
  title,
  deletable,
  loading,
  className,
}: {
  isEditing: boolean
  setEditing?: (value: boolean) => void
  onDelete?: () => void
  isSaveDisabled?: boolean
  onSave?: () => void
  onCancel?: () => void
  title?: string
  deletable?: boolean
  loading?: boolean
  className?: string
}) => (
  <Container className={className}>
    <div className='text-sm font-semibold capitalize'>{title}</div>
    <div className='flex justify-end gap-x-4'>
      {isEditing ? (
        <>
          {deletable && <Button noBackground preIcon='delete' type='danger' onClick={onDelete} />}
          <Button
            disabled={isSaveDisabled}
            loading={loading}
            preIcon='check'
            type='success'
            onClick={onSave}
          />
          <Button
            preIcon='close'
            type='secondary'
            onClick={() => {
              onCancel()
              setEditing(false)
            }}
          />
        </>
      ) : (
        <Button preIcon='edit' type='secondary' onClick={() => setEditing(true)} />
      )}
    </div>
  </Container>
)

const Container = tw.div`
  flex
  justify-between 
  items-center
  mb-1
`
