import tw from 'tailwind-styled-components'

import { supportEmail } from '../../../common/constants'
import { Link } from './Link'

export const Help = ({ className }: { className?: string }) => (
  <HelpContainer className={className}>
    Please contact <Link href={`mailto:${supportEmail}`}>{supportEmail}</Link> if you need help
  </HelpContainer>
)

const HelpContainer = tw.div`
  text-center
  mt-3
`
