import * as sentry from '@sentry/react'
import { AxiosError } from 'axios'

/**
 * Formats an Axios error to a payload object.
 * @param error - The Axios error object.
 * @returns The formatted payload object.
 * @throws If the error does not have a response.
 */
export const formatAxiosErrorToPayload = (error: AxiosError<any>): any => {
  if (error.code === 'ERR_CANCELED') return
  if (!error.response) throw error
  const data = error.response?.data as any

  if (data && data.error) return data.error

  if (data instanceof Object)
    return {
      ...data,
      _axiosError: error,
    }

  return {
    message: data,
    _axiosError: error,
  }
}

export enum AlertType {
  Default = 'default',
  Critical = 'critical',
  Quiet = 'quiet',
}

export type AlertErrorOptions = {
  alertType: AlertType
}

export const alertError = (
  error: Error,
  message?: string,
  alertType: AlertType = AlertType.Default,
): void => {
  console.log(message == null ? error : `${message}: ${error}`)
  const useMessage = message == null ? error.message : message
  sentry.withScope(scope => {
    scope.setTag('handler_reported', 'alertError')
    scope.setTag('alert_scope', alertType)
    scope.setTransactionName(useMessage)
    sentry.captureException(error)
  })
}
