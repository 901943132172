import 'react-responsive-modal/styles.css'

import { ReactNode } from 'react'
import { Modal as RModal } from 'react-responsive-modal'
import tw from 'tailwind-styled-components'

export type ModalProps = {
  isVisible: boolean
  setVisible: (isVisible: boolean) => void
  children?: ReactNode
  showCloseIcon?: boolean
  className?: string
  onClose?: () => void
  closeOnOverlayClick?: boolean
  focusTrapped?: boolean
  contentClassName?: string
}

export const Modal = ({
  isVisible,
  setVisible,
  children,
  showCloseIcon = true,
  className = '',
  onClose = () => {},
  closeOnOverlayClick = true,
  focusTrapped = false,
  contentClassName = '',
}: ModalProps) =>
  isVisible ? (
    <RModal
      center
      closeOnEsc={closeOnOverlayClick}
      closeOnOverlayClick={closeOnOverlayClick}
      focusTrapped={focusTrapped}
      open={isVisible}
      showCloseIcon={showCloseIcon}
      classNames={{
        modal: `lg:rounded-lg ${className}`,
      }}
      onClose={() => {
        setVisible(false)
        onClose()
      }}
    >
      <ModalContent className={contentClassName}>{children}</ModalContent>
    </RModal>
  ) : null

const ModalContent = tw.div`
  bg-white
  lg:rounded-lg
`
