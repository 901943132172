import { ReactNode } from 'react'
import tw from 'tailwind-styled-components'

export const TR = ({ children, onClick }: { children: ReactNode; onClick?: () => void }) => (
  <Container $hoverable={Boolean(onClick)} onClick={onClick}>
    {children}
  </Container>
)

const Container = tw.tr<{ $hoverable?: boolean }>`
  bg-white
  hover:bg-lighter-gray
  even:bg-lighter-blue
  even:hover:bg-lighter-gray
  ${({ $hoverable }) => $hoverable && 'cursor-pointer'}
`
