import { ReactNode } from 'react'
import tw from 'tailwind-styled-components'

import { Button, ButtonType } from '../button'

export const MobileActionButtons = ({
  editable = true,
  isEditing,
  setEditing = () => {},
  onCancel = () => {},
  onSave = () => {},
  isSaveButtonDisabled,
  addable,
  addNewText,
  onAdd,
  isAddButtonDisabled,
  withCancelButton = true,
  children,
  loading,
  isAddButtonLoading,
  addButtonType = 'warn',
}: {
  editable?: boolean
  isEditing?: boolean
  setEditing?: (value: boolean) => void
  onCancel?: () => void
  onSave?: () => void
  isSaveButtonDisabled?: boolean
  addable?: boolean
  addNewText?: string
  onAdd?: () => void
  isAddButtonDisabled?: boolean
  withCancelButton?: boolean
  children?: ReactNode
  loading?: boolean
  isAddButtonLoading?: boolean
  addButtonType?: ButtonType
}) => (
  <Container>
    {addable && (
      <Button
        fullWidth
        className='w-full'
        disabled={isAddButtonDisabled}
        loading={isAddButtonLoading}
        type={addButtonType}
        onClick={onAdd}
      >
        {addNewText}
      </Button>
    )}
    {!addable &&
      editable &&
      (isEditing ? (
        <div className='flex w-full'>
          {withCancelButton && (
            <Button
              fullWidth
              className='w-full mr-4'
              type='secondary'
              onClick={() => {
                onCancel()
                setEditing(false)
              }}
            >
              Cancel
            </Button>
          )}
          <Button
            fullWidth
            className='w-full'
            disabled={isSaveButtonDisabled}
            loading={loading}
            type='warn'
            onClick={onSave}
          >
            Save
          </Button>
        </div>
      ) : (
        <Button fullWidth className='w-full' type='link' onClick={() => setEditing(true)}>
          Edit
        </Button>
      ))}
    {!editable && children}
  </Container>
)

const Container = tw.div`
  fixed
  bottom-0
  w-full
  border-t
  border-border-gray
  p-4
  bg-white
  lg:hidden
`
