import { AppLogoImage, Map, Marker, MarkerType } from '@components'
import dayjs from 'dayjs'
import { debounce } from 'lodash-es'
import { useCallback, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import tw from 'tailwind-styled-components'

import { api } from '../../api/api'
import { useAppSelector, useAppThunkDispatch } from '../../app/hooks'
import buffering from '../../assets/images/loading-buffering.gif'
import { formatTimezone, getDocumentTitle } from '../../common/utils/text-helpers'
import { getExternalTrackingData } from '../../redux/externalTrackingSlice'
const formatEventTime = (dateString: string) => dayjs(dateString).format('ddd, MMM D, YYYY, h:mm A')

const EventsHeader = () => (
  <HeaderContainer>
    <EventsHeaderItem>Date & Time</EventsHeaderItem>
    <EventsHeaderItem>Event</EventsHeaderItem>
    <EventsHeaderItem>Location</EventsHeaderItem>
  </HeaderContainer>
)

export const ExternalTrackingScreen = () => {
  const { trackingNumber = '' } = useParams<{ trackingNumber: string }>()
  const dispatch = useAppThunkDispatch()
  const isLoading = useAppSelector(state => state.externalTracking.loading.trackingData)
  const error = useAppSelector(state => state.externalTracking.getTrackingDataError)
  const trackingData = useAppSelector(state => state.externalTracking.trackingData)
  const originCity = useAppSelector(state => state.externalTracking.trackingData.origin.city)
  const destinationCity = useAppSelector(
    state => state.externalTracking.trackingData.destination.city,
  )
  const userTimezone = formatTimezone(dayjs.tz.guess())

  const origin = {
    lat: useAppSelector(state => state.externalTracking.trackingData.origin.latitude),
    lng: useAppSelector(state => state.externalTracking.trackingData.origin.longitude),
  }
  const destination = {
    lat: useAppSelector(state => state.externalTracking.trackingData.destination.latitude),
    lng: useAppSelector(state => state.externalTracking.trackingData.destination.longitude),
  }

  useEffect(() => {
    document.title = getDocumentTitle('Load Tracking')
    document.body.style.backgroundColor = '#f3f4f6'
    const root = document.getElementById('root')
    if (root) {
      root.style.overflow = 'visible'
    }
    requestExternalTrackingData()
  }, [])

  const requestExternalTrackingData = useCallback(
    debounce(() => dispatch(getExternalTrackingData(trackingNumber)), 200),
    [],
  )

  return (
    <MainContainer>
      <div className='flex justify-between items-center'>
        <a href='https://www.exofreight.com' rel='noreferrer' target='_blank'>
          {trackingData.isManaged && trackingData.companyLogo ? (
            <img className='h-12' src={trackingData.companyLogo} />
          ) : trackingData.isManaged && !trackingData.companyLogo ? (
            <div className='font-bold text-2xl'>{trackingData.companyName}</div>
          ) : (
            <AppLogoImage className='h-12' />
          )}
        </a>
        <div className='text-right'>
          <p className='font-bold text-lg'>Tracking History</p>
          <p className='text-gray-500 font-bold'>
            {trackingData.loadId && originCity && destinationCity
              ? `${originCity} → ${destinationCity} | Load ID ${trackingData.loadId}`
              : `Tracking Number ${trackingNumber}`}
          </p>
        </div>
      </div>

      {!isLoading ? (
        error ? (
          <div className='text-center text-red-500 text-lg p-4 mt-[100px]'>{error}</div>
        ) : (
          <>
            <div>
              <Map className='w-full h-full' mapClassName='h-[300px] pt-6' routeAPI={api}>
                {origin && <Marker marker={MarkerType.ORIGIN} position={origin} />}
                {destination && <Marker marker={MarkerType.DESTINATION} position={destination} />}
              </Map>
            </div>
            <EventsHeader />
            {trackingData.events && trackingData.events.length > 0 ? (
              <>
                {trackingData.events.map((event, index) => (
                  <Event key={index}>
                    <EventField>{formatEventTime(event.time)}</EventField>
                    <EventField>{event.name}</EventField>
                    <EventField>{event.location}</EventField>
                  </Event>
                ))}
                <div className='text-sm text-gray-500 mt-[70px] text-center p-4'>
                  {`Times are adjusted to your time zone (${userTimezone}).`}
                </div>
                {!trackingData.isManaged && (
                  <div className='flex gap-2 items-center justify-center p-1 bg-gray-100 rounded-md'>
                    Powered by
                    <a href='https://www.exofreight.com' rel='noreferrer' target='_blank'>
                      <AppLogoImage className='h-8' />
                    </a>
                  </div>
                )}
              </>
            ) : (
              <div className='text-center text-gray-500 p-4'>No events yet.</div>
            )}
          </>
        )
      ) : (
        <div className='flex justify-center items-center h-full mt-[100px]'>
          <img alt='loading' className='w-6 h-6' src={buffering} />
        </div>
      )}
    </MainContainer>
  )
}

const MainContainer = tw.div`
  bg-white
  w-full
  max-w-[600px]
  mx-auto
  p-4
  mt-2
`

const HeaderContainer = tw.div`
  bg-[#f6f8fc]
  border-b
  border-gray-400
  flex
  justify-between
  mt-6
  p-4
`

const EventsHeaderItem = tw.div`
  flex-1
  text-center
  font-bold
  text-[1.1rem]
`

const Event = tw.div`
  flex justify-between border-b border-gray-200 p-3
`

const EventField = tw.div`
  flex-1
  text-center
`
