import { Menu } from '@headlessui/react'
import dayjs from 'dayjs'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import tw from 'tailwind-styled-components'

import { useAppThunkDispatch } from '../../app/hooks'
import bell from '../../assets/images/notifications/bell.png'
import letter from '../../assets/images/notifications/letter.png'
import pen from '../../assets/images/notifications/pen.png'
import truck from '../../assets/images/notifications/truck.png'
import user from '../../assets/images/notifications/user.png'
import x from '../../assets/images/notifications/x.png'
import { NotificationTypes } from '../../common/constants'
import {
  markNotificationsAsRead,
  Notification as NotificationProps,
} from '../../redux/notificationsSlice'

export const Notification = ({ notification }: { notification: NotificationProps }) => {
  const navigate = useNavigate()

  const dispatch = useAppThunkDispatch()

  const [notificationTime, setNotificationTime] = useState(() => {
    const now = dayjs()
    const created = dayjs(notification.createdAt)

    if (now.diff(created, 'hour') < 1) return created.fromNow()
    return created.format('h:mm a')
  })

  useEffect(() => {
    const intervalId = setInterval(() => {
      const now = dayjs()
      const created = dayjs(notification.createdAt)

      setNotificationTime(
        now.diff(created, 'hour') < 1 ? created.fromNow() : created.format('h:mm a'),
      )
    }, 60000)

    return () => clearInterval(intervalId)
  }, [notification.createdAt])

  const data = useMemo(() => {
    switch (notification.notificationType) {
      case NotificationTypes.LOAD_ON_TRACK:
      case NotificationTypes.LOAD_OFF_TRACK:
      case NotificationTypes.LOAD_DELIVERED:
        return {
          leftText: `Load #${notification.loadId} status was`,
          rightText: (
            <div>
              updated to{' '}
              {notification.notificationType === NotificationTypes.LOAD_ON_TRACK && (
                <span className='text-success font-bold'>On Track</span>
              )}
              {notification.notificationType === NotificationTypes.LOAD_OFF_TRACK && (
                <span className='text-error font-bold'>Off Track</span>
              )}
              {notification.notificationType === NotificationTypes.LOAD_DELIVERED && (
                <span className='text-sky font-bold'>Delivered</span>
              )}
            </div>
          ),
          img: bell,
          onClick: () => navigate(`/loads/${notification.loadId}`),
        }
      case NotificationTypes.NEW_USER_JOINED:
        return {
          leftText: notification.userFullName || 'New User',
          rightText: 'has successfully joined your workspace',
          img: user,
          onClick: () => navigate('/account?tab=Team'),
        }
      case NotificationTypes.CARRIER_QUOTED_LOAD:
        return {
          leftText: notification.carrierName || 'Carrier',
          rightText: `quoted load #${notification.loadId}`,
          img: letter,
          onClick: () => navigate(`/loads/${notification.loadId}`),
        }
      case NotificationTypes.CARRIER_EDITED_LOAD_QUOTE:
        return {
          leftText: notification.carrierName || 'Carrier',
          rightText: `updated quote for load #${notification.loadId}`,
          img: pen,
          onClick: () => navigate(`/loads/${notification.loadId}`),
        }
      case NotificationTypes.CARRIER_UPLOADED_LOAD_DOCUMENT:
        return {
          leftText: notification.carrierName || 'Carrier',
          rightText: `uploaded a document for load #${notification.loadId}`,
          img: letter,
          onClick: () => navigate(`/loads/${notification.loadId}`),
        }
      case NotificationTypes.CARRIER_ACCEPTED_INVITATION:
        return {
          leftText: notification.carrierName || 'Carrier',
          rightText: 'has successfully onboarded and is now part of your network',
          img: truck,
          onClick: () => navigate('/carriers'),
        }
      case NotificationTypes.CARRIER_REJECTED_INVITATION:
        return {
          leftText: notification.carrierName || 'Carrier',
          rightText: 'has rejected your invitation',
          img: x,
        }
      case NotificationTypes.CARRIER_REJECTED_LOAD_QUOTE:
        return {
          leftText: notification.carrierName || 'Carrier',
          rightText: `rejected quote for load #${notification.loadId}`,
          img: x,
        }
      case NotificationTypes.CARRIER_ACCEPTED_LOAD_TENDER:
        return {
          leftText: notification.carrierName || 'Carrier',
          rightText: `accepted tender for load #${notification.loadId}`,
          img: bell,
        }
      case NotificationTypes.CARRIER_REJECTED_LOAD_TENDER:
        return {
          leftText: notification.carrierName || 'Carrier',
          rightText: `rejected tender for load #${notification.loadId}`,
          img: x,
        }
      default:
        return {}
    }
  }, [notification.notificationType])

  return (
    <Container $unread={!notification.read}>
      <Menu.Button
        className='w-full p-4'
        onClick={() => {
          if (data.onClick) data.onClick()
          dispatch(markNotificationsAsRead({ notificationId: notification.id }))
        }}
      >
        <div>
          <div className='flex items-center text-left'>
            <img alt='icon' className='w-3 min-w-3 mr-2 lg:hidden' src={data.img} />
            {data.leftText}{' '}
            <img alt='icon' className='w-3 min-w-3 mx-1 max-lg:hidden' src={data.img} />
            {data.rightText}
          </div>
          <div className='font-normal text-dark-gray mt-2 text-left'>{notificationTime}</div>
        </div>
      </Menu.Button>
      <div className='flex items-center gap-4 p-4'>
        {!notification.read && (
          <LinkButton
            onClick={() => dispatch(markNotificationsAsRead({ notificationId: notification.id }))}
          >
            Mark as read
          </LinkButton>
        )}
      </div>
    </Container>
  )
}

const LinkButton = tw.div`
  text-link
  hover:opacity-80
  transition-all
  cursor-pointer
  whitespace-nowrap
`

const Container = tw.div<{ $unread: boolean }>`
  font-semibold
  flex
  items-center
  justify-between
  w-full
  hover:bg-lighter-gray
  ${({ $unread }) => $unread && 'bg-lighter-blue'}
`
