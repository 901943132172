import { useMemo, useState } from 'react'
import tw from 'tailwind-styled-components'

import { NewManifestItem } from '../../../../common/types'
import { ActionButtons } from '../../../ActionButtons'
import { MobileItemButtons } from '../../../MobileItemButtons'
import { DropsList } from './DropsList'
import { ManifestItem } from './ManifestItem'

export const ItemEdit = ({
  item,
  loading,
  setItem,
  isPickup,
  onSave,
  onCancel,
  deletable,
  onDelete = () => {},
  isSaveDisabled,
}: {
  item: NewManifestItem
  loading?: boolean
  setItem: (value: any) => void
  isPickup: boolean
  onSave: () => void
  onCancel: () => void
  deletable?: boolean
  onDelete?: () => void
  isSaveDisabled?: boolean
}) => {
  const [isFormValid, setFormValid] = useState(false)

  const availableQuantity = useMemo(() => {
    let quantity = parseInt((item?.quantity as string) || '0')
    ;(item?.dropoffs || []).forEach((it: any) => {
      quantity -= parseInt(it.quantity || '0')
    })
    return quantity
  }, [item])

  const isSaveButtonDisabled =
    !isFormValid || !!item.dropoffs?.some((dropoff: any) => !dropoff.isValid) || isSaveDisabled

  return (
    <ItemContainer>
      <ActionButtons
        isEditing
        className='mb-4 max-lg:hidden'
        deletable={deletable}
        isSaveDisabled={isSaveButtonDisabled}
        loading={loading}
        onCancel={onCancel}
        onDelete={onDelete}
        onSave={onSave}
      />
      <ManifestItem
        isFirst={false}
        manifestItem={item}
        setFormValid={setFormValid}
        setManifestItem={(data: Partial<NewManifestItem>) => setItem({ ...item, ...data })}
        onDelete={() => setItem(null)}
      />
      {isPickup && (
        <DropsList availableQuantity={availableQuantity} item={item} setItem={setItem} />
      )}
      <MobileItemButtons
        isEditing
        deletable={deletable}
        isSaveButtonDisabled={isSaveButtonDisabled}
        loading={loading}
        onCancel={onCancel}
        onDelete={onDelete}
        onSave={onSave}
      />
    </ItemContainer>
  )
}

const ItemContainer = tw.div`
  border
  border-light-gray
  rounded-lg
  p-4
  mb-4
`
