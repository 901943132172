import './Icon.scss'

export const ShipmentIcon = ({ stroke = '', size = '24' }: { stroke?: string; size?: string }) => (
  <svg
    fill='none'
    height={size}
    viewBox='0 0 20 20'
    width={size}
    xmlns='http://www.w3.org/2000/svg'
  >
    <title>Shipment</title>
    <path
      d='M5 9V3C5 1.4 3.66667 1 3 1M11 16H18M11 6H15C15.5523 6 16 5.55228 16 5V3C16 2.44772 15.5523 2 15 2H11C10.4477 2 10 2.44772 10 3V5C10 5.55228 10.4477 6 11 6ZM8 15C8 16.6569 6.65685 18 5 18C3.34315 18 2 16.6569 2 15C2 13.3431 3.34315 12 5 12C6.65685 12 8 13.3431 8 15ZM12 13H17C17.5523 13 18 12.5523 18 12V10C18 9.44772 17.5523 9 17 9H12C11.4477 9 11 9.44772 11 10V12C11 12.5523 11.4477 13 12 13Z'
      stroke={stroke}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='1.4'
    />
  </svg>
)
