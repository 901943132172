import { Button, Form, Select, TextInput } from '@components'
import { omit } from 'lodash-es'
import { useEffect, useState } from 'react'

import { useAppSelector, useAppThunkDispatch } from '../../../../app/hooks'
import { emptyManifestItem } from '../../../../common/constants'
import { NewManifestItem } from '../../../../common/types'
import { ItemTemplateModal } from '../../../../pages/ItemTemplatesScreen/ItemTemplateModal'
import { getItemTemplates, ItemTemplate } from '../../../../redux/itemTemplateSlice'

export const PickupManifestItem = ({
  manifestItem,
  setFormValid,
  setManifestItem: setItem,
}: {
  manifestItem: NewManifestItem // Starts as a blank manifest item with nothing but an ID
  setFormValid: (value: boolean) => void
  setManifestItem: (newData: Partial<NewManifestItem>) => void
}) => {
  const dispatch = useAppThunkDispatch()
  const itemTemplates = useAppSelector(state => state.itemTemplate.itemTemplates)
  const gettingItemTemplates = useAppSelector(state => state.itemTemplate.loading.getItemTemplates)
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    if (!itemTemplates?.length && !gettingItemTemplates) {
      dispatch(getItemTemplates({ limit: 5 }))
    }
  }, [])

  const onSaved = async (itemTemplate: Required<ItemTemplate>) => {
    setItem({ ...omit(itemTemplate, 'id'), itemTemplateId: itemTemplate.id })
  }

  return (
    <div>
      <Form className='flex gap-3' setFormValid={setFormValid}>
        <Select
          creatable
          required
          sm
          choices={itemTemplates}
          className='w-full'
          field='name'
          label='Item'
          value={manifestItem.createdAt ? manifestItem : null}
          onChange={(itemTemplate: ItemTemplate | null) =>
            itemTemplate
              ? setItem({ ...omit(itemTemplate, 'id'), itemTemplateId: itemTemplate.id })
              : setItem({ ...omit(emptyManifestItem, 'id') })
          }
          onCreateOption={name => {
            setIsVisible(true)
            setItem({ ...omit(emptyManifestItem, 'id'), name })
          }}
          onInputChange={search => {
            dispatch(getItemTemplates({ search, limit: 5 }))
          }}
        />
        <TextInput
          required
          sm
          label='Quantity'
          type='number'
          value={manifestItem.quantity}
          onChange={quantity => setItem({ quantity })}
        />
      </Form>
      <div className='flex justify-between mt-3 rounded bg-slate-100 p-4'>
        {manifestItem.createdAt ? (
          <>
            <div className='flex flex-col gap-3'>
              <div className='flex gap-3'>
                <span>
                  Weight: <strong>{manifestItem.weight || '—'} lbs</strong>
                </span>
                <span>
                  Height: <strong>{manifestItem.height || '—'} ft</strong>
                </span>
                <span>
                  Width: <strong>{manifestItem.width || '—'} ft</strong>
                </span>
                <span>
                  Length: <strong>{manifestItem.length || '—'} ft</strong>
                </span>
              </div>
              <div>Notes: {manifestItem.notes || '—'}</div>
            </div>
            {manifestItem.itemTemplateId && (
              <Button
                noBackground
                xs
                className='col-start-2 row-span-2'
                label='View'
                onClick={() => {
                  setIsVisible(true)
                }}
              />
            )}
          </>
        ) : (
          <div className='italic text-gray-500'>No item selected</div>
        )}
      </div>
      <ItemTemplateModal
        isVisible={isVisible}
        item={{ ...manifestItem, id: manifestItem.itemTemplateId } as ItemTemplate}
        setVisible={setIsVisible}
        onSaved={onSaved}
      />
    </div>
  )
}
