import {
  CurrencyDollarIcon,
  EyeIcon as OpenEyeIcon,
  EyeSlashIcon,
  MagnifyingGlassIcon,
} from '@heroicons/react/24/outline'
import { EnvelopeIcon, PhoneIcon, UserIcon } from '@heroicons/react/24/solid'
import { forwardRef, KeyboardEventHandler, useMemo, useState } from 'react'
import { IMask, IMaskInput } from 'react-imask'
import Skeleton from 'react-loading-skeleton'
import tw from 'tailwind-styled-components'

import { toTransient, Transient } from '../../common'

type Icon = 'search' | 'dollar' | 'email' | 'user' | 'phone'

export type TextInputProps = {
  autoFocus?: boolean
  blue?: boolean
  className?: string
  error?: string | false
  icon?: Icon
  id?: string | null
  label?: string
  labelIcon?: JSX.Element
  labelStyle?: any
  mask?: string | RegExp
  maskOptions?: IMask.AnyMaskedOptions
  name?: string
  onBlur?: (value: string, event: MouseEvent) => void
  onChange?: (value: string) => void
  onFocus?: () => void
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>
  placeholder?: string
  required?: boolean
  right?: any
  sm?: boolean
  md?: boolean
  style?: any
  type?: string
  value?: any
  dataTestId?: string
  disabled?: boolean
  loading?: boolean
  step?: number
  maxLength?: number
  minLength?: number
  length?: number
  min?: number
  max?: number
  validator?: (value: string) => boolean | string | void
  regex?: any
  noStyles?: boolean
  errorClassName?: string
  valueClassName?: string
  unmask?: boolean
  showAsterisk?: boolean
  inputMode?: string
  useLoadingSpinner?: boolean
  onEnter?: () => void
  showRequiredText?: boolean
}

type $TextInputProps = Transient<TextInputProps>

export const TextInput = forwardRef<typeof MaskInput, TextInputProps>(
  (
    {
      autoFocus = false,
      blue = false,
      className = '',
      error = '',
      icon,
      id = null,
      label = '',
      labelStyle = '',
      labelIcon,
      mask = /./, // match any character
      maskOptions,
      name = '',
      onBlur,
      onChange,
      onFocus,
      onKeyDown = () => {},
      placeholder = '',
      required = false,
      right,
      sm = false,
      md = false,
      style = {},
      type = 'text',
      value = '',
      dataTestId = '',
      disabled = false,
      step,
      loading,
      maxLength,
      noStyles,
      errorClassName,
      valueClassName,
      unmask = true,
      showAsterisk = true,
      inputMode,
      useLoadingSpinner,
      onEnter,
      showRequiredText,
    }: TextInputProps,
    ref,
  ): JSX.Element => {
    const [showPassword, setShowPassword] = useState(false)

    const EyeIcon = showPassword ? EyeSlashIcon : OpenEyeIcon

    const renderIcon = () => {
      const props = {
        className: `text-dark-gray absolute ${sm ? 'top-2 w-4 left-2' : 'top-1.5 w-5 left-2'}`,
      }

      switch (icon) {
        case 'search':
          return <MagnifyingGlassIcon {...props} />
        case 'dollar':
          return <CurrencyDollarIcon {...props} />
        case 'email':
          return <EnvelopeIcon {...props} />
        case 'user':
          return <UserIcon {...props} />
        case 'phone':
          return <PhoneIcon {...props} />
        default:
          return <div />
      }
    }

    const computedMask = useMemo(
      () => (!maskOptions && mask ? { mask } : maskOptions || {}),
      [JSON.stringify(maskOptions), mask],
    )
    const $props = toTransient({ blue, icon, sm, md, loading, disabled, noStyles })

    return (
      <Container className={className} style={style}>
        {label && (
          <Label htmlFor={id ?? undefined} {...$props} className={labelStyle}>
            {labelIcon} {label}{' '}
            {required && showAsterisk && (
              <span className='text-error ml-1'>*{showRequiredText && ' required'}</span>
            )}{' '}
            {right}
          </Label>
        )}
        <div className='relative'>
          {icon && renderIcon()}
          {type === 'password' && (
            <IconContainer $md={md} $sm={sm}>
              <EyeIcon className='w-5' onClick={() => setShowPassword(!showPassword)} />
            </IconContainer>
          )}
          {(!loading || useLoadingSpinner) && (
            <MaskInput
              // @ts-ignore
              autoFocus={autoFocus}
              className={valueClassName}
              disabled={disabled}
              inputRef={ref as any}
              maxLength={maxLength}
              name={name}
              placeholder={placeholder}
              step={step}
              type={type === 'password' ? (showPassword ? 'text' : 'password') : type}
              unmask={unmask}
              value={value?.toString()}
              onAccept={(value: any) => onChange && onChange(value)}
              onBlur={(event: MouseEvent) => onBlur && onBlur(value, event)}
              onFocus={() => onFocus && onFocus()}
              onKeyDown={key => {
                if (key.code === 'Enter' && onEnter) onEnter()
                onKeyDown(key)
              }}
              {...computedMask}
              {...$props}
              {...(id && { id })}
              {...(dataTestId && { 'data-testid': dataTestId })}
              {...(inputMode && { inputMode })}
            />
          )}
          {loading && useLoadingSpinner && <Loading {...$props} />}
          {loading && !useLoadingSpinner && <SizedSkeleton {...$props} />}
        </div>
        {!!error && <Error className={errorClassName}>{error}</Error>}
      </Container>
    )
  },
)

const MaskInput = tw(IMaskInput)`
  appearance-none
  border
  rounded-lg
  py-2.5
  px-3
  leading-tight
  focus:outline-none
  focus:border-gray-800
  h-[52px]
  w-full
  transition-all
  placeholder-dark-gray
  box-border
  border-solid
  ${(p: $TextInputProps) => (p.$sm && 'h-8') || (p.$md && 'h-[42px]')}
  ${(p: $TextInputProps) => p.$icon && ((p.$sm && '!pl-8') || (p.$md && '!pl-10') || '!pl-12')}
  ${(p: $TextInputProps) => p.$loading && ((p.$sm && '!pr-8') || (p.$md && '!pr-10') || '!pr-12')}
  ${(p: $TextInputProps) =>
    !p.$noStyles ? 'border-border-gray bg-white' : 'border-none bg-transparent'}
  ${(p: $TextInputProps) => p.$disabled && 'bg-lighter-blue'}
`

const Container = tw.div`
  relative
  flex
  flex-col
`

const Label = tw.label`
  font-poppins
  flex
  items-center
  mb-1
  text-dark-gray
  self-start
  mr-auto
  text-xs
`

const Error = tw.div`
  text-error
  ml-1
  text-xs
  absolute
  -bottom-4
  whitespace-nowrap
`

const SizedSkeleton = tw(Skeleton)`
  ${(p: $TextInputProps) => (p.$sm && 'h-8') || (p.$md && 'h-[42px]')}
`

const Loading = tw.div`
  absolute
  border-solid
  border-[2px]
  rounded-full
  border-gray-300
  !border-t-gray-400
  animate-spin
  ${(p: $TextInputProps) =>
    ((p.$sm || p.$md) && 'w-[15px] h-[15px] top-[calc((100%/2)-8px)] right-2') ||
    'w-[30px] h-[30px] top-[calc((100%/2)-16px)] right-4'}
`

const IconContainer = tw.div`
  absolute
  right-2
  cursor-pointer
  ${({ $sm, $md }: { $sm: boolean; $md: boolean }) =>
    ($sm && 'top-1.5') || ($md && 'top-3') || 'top-4'}
`
