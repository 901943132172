import { DateInput, Select, TextInput, Toggle } from '@components'
import { useEffect } from 'react'

import { useAppSelector, useAppThunkDispatch } from '../../../app/hooks'
import {
  bidType,
  distanceUnit,
  fuelSettings,
  loadStatusChoicesNew,
  modeChoices,
  quotingToolSupportedEquipmentTypes,
  rfpStatuses,
  volumeFrequency,
} from '../../../common/constants'
import { SearchFilters } from '../../../common/types'
import { getUploadsList } from '../../../redux/quotingToolSlice'
import { EquipmentTypeSelect } from '../../Selects'

export const FilterFields = ({
  filtersList,
  localFilters,
  setLocalFilters,
}: {
  filtersList: readonly (keyof SearchFilters)[]
  localFilters: SearchFilters
  setLocalFilters: (value: SearchFilters) => void
}) => {
  const dispatch = useAppThunkDispatch()

  const uploadsList = useAppSelector(state => state.quotingTool.uploadsList)
  const uploadsListLoading = useAppSelector(state => state.quotingTool.loading.uploadsList)
  const contacts = useAppSelector(state => state.quotingTool.contacts)
  const contactsLoading = useAppSelector(state => state.quotingTool.loading.contacts)

  useEffect(() => {
    dispatch(getUploadsList())
  }, [])

  const filterItems: {
    type: keyof SearchFilters
    component: JSX.Element
  }[] = [
    {
      type: 'equipmentType',
      component: (
        <EquipmentTypeSelect
          withLabel
          className='w-full'
          menuPlacement='bottom'
          setValue={(equipmentType: string) => setLocalFilters({ ...localFilters, equipmentType })}
          value={localFilters.equipmentType}
        />
      ),
    },
    {
      type: 'quoteEquipmentType',
      component: (
        <Select
          sm
          choices={quotingToolSupportedEquipmentTypes}
          label='Equipment Type'
          value={localFilters.quoteEquipmentType}
          onChange={(quoteEquipmentType: string) =>
            setLocalFilters({ ...localFilters, quoteEquipmentType })
          }
        />
      ),
    },
    {
      type: 'uploadFile',
      component: (
        <Select
          isMulti
          sm
          choices={uploadsList}
          field='file'
          label='File Name'
          loading={uploadsListLoading}
          value={localFilters.uploadFile}
          onChange={(uploadFile: any) => setLocalFilters({ ...localFilters, uploadFile })}
        />
      ),
    },
    {
      type: 'dateRanges',
      component: (
        <DateInput
          range
          showMonthDropdown
          showYearDropdown
          label='Pickup Date'
          value={localFilters.dateRanges}
          onChange={(dateRanges: any) => setLocalFilters({ ...localFilters, dateRanges })}
        />
      ),
    },
    {
      type: 'dateQuoted',
      component: (
        <DateInput
          range
          showMonthDropdown
          showYearDropdown
          label='Date Quoted'
          value={localFilters.dateQuoted}
          onChange={(dateQuoted: any) => setLocalFilters({ ...localFilters, dateQuoted })}
        />
      ),
    },
    {
      type: 'quoteId',
      component: (
        <TextInput
          sm
          label='Quote #'
          value={localFilters.quoteId}
          onChange={(quoteId: string) => setLocalFilters({ ...localFilters, quoteId })}
        />
      ),
    },
    {
      type: 'originCity',
      component: (
        <TextInput
          sm
          label='Origin City'
          value={localFilters.originCity}
          onChange={(originCity: string) => setLocalFilters({ ...localFilters, originCity })}
        />
      ),
    },
    {
      type: 'originState',
      component: (
        <TextInput
          sm
          label='Origin State'
          mask='aa'
          value={localFilters.originState}
          onChange={(originState: string) =>
            setLocalFilters({ ...localFilters, originState: originState.toUpperCase() })
          }
        />
      ),
    },
    {
      type: 'destinationCity',
      component: (
        <TextInput
          sm
          label='Destination City'
          value={localFilters.destinationCity}
          onChange={(destinationCity: string) =>
            setLocalFilters({ ...localFilters, destinationCity })
          }
        />
      ),
    },
    {
      type: 'destinationState',
      component: (
        <TextInput
          sm
          label='Destination State'
          mask='aa'
          value={localFilters.destinationState}
          onChange={(destinationState: string) =>
            setLocalFilters({ ...localFilters, destinationState: destinationState.toUpperCase() })
          }
        />
      ),
    },
    {
      type: 'loadsSearch',
      component: (
        <TextInput
          sm
          label='Search'
          value={localFilters.loadsSearch}
          onChange={(loadsSearch: string) => setLocalFilters({ ...localFilters, loadsSearch })}
        />
      ),
    },
    {
      type: 'pickupStartDate',
      component: (
        <DateInput
          showMonthDropdown
          showYearDropdown
          label='Pickup Start Date'
          placeholder=''
          value={localFilters.pickupStartDate}
          onChange={(pickupStartDate: any) => setLocalFilters({ ...localFilters, pickupStartDate })}
        />
      ),
    },
    {
      type: 'pickupEndDate',
      component: (
        <DateInput
          showMonthDropdown
          showYearDropdown
          label='Pickup End Date'
          placeholder=''
          value={localFilters.pickupEndDate}
          onChange={(pickupEndDate: any) => setLocalFilters({ ...localFilters, pickupEndDate })}
        />
      ),
    },
    {
      type: 'startDate',
      component: (
        <DateInput
          range
          showMonthDropdown
          showYearDropdown
          label='Start Date'
          placeholder=''
          value={localFilters.startDate}
          onChange={(startDate: any) => setLocalFilters({ ...localFilters, startDate })}
        />
      ),
    },
    {
      type: 'awardAcceptanceDeadline',
      component: (
        <DateInput
          range
          showMonthDropdown
          showYearDropdown
          label='Award Acceptance Deadline'
          placeholder=''
          value={localFilters.awardAcceptanceDeadline}
          onChange={(awardAcceptanceDeadline: any) =>
            setLocalFilters({ ...localFilters, awardAcceptanceDeadline })
          }
        />
      ),
    },
    {
      type: 'carrierBidDeadline',
      component: (
        <DateInput
          range
          showMonthDropdown
          showYearDropdown
          label='Carrier Bid Deadline'
          placeholder=''
          value={localFilters.carrierBidDeadline}
          onChange={(carrierBidDeadline: any) =>
            setLocalFilters({ ...localFilters, carrierBidDeadline })
          }
        />
      ),
    },
    {
      type: 'endDate',
      component: (
        <DateInput
          range
          showMonthDropdown
          showYearDropdown
          label='End Date'
          placeholder=''
          value={localFilters.endDate}
          onChange={(endDate: any) => setLocalFilters({ ...localFilters, endDate })}
        />
      ),
    },
    {
      type: 'pickupEndDate',
      component: (
        <DateInput
          showMonthDropdown
          showYearDropdown
          label='Pickup End Date'
          placeholder=''
          value={localFilters.pickupEndDate}
          onChange={(pickupEndDate: any) => setLocalFilters({ ...localFilters, pickupEndDate })}
        />
      ),
    },
    {
      type: 'deliveryStartDate',
      component: (
        <DateInput
          showMonthDropdown
          showYearDropdown
          label='Delivery Start Date'
          placeholder=''
          value={localFilters.deliveryStartDate}
          onChange={(deliveryStartDate: any) =>
            setLocalFilters({ ...localFilters, deliveryStartDate })
          }
        />
      ),
    },
    {
      type: 'deliveryEndDate',
      component: (
        <DateInput
          showMonthDropdown
          showYearDropdown
          label='Delivery End Date'
          placeholder=''
          value={localFilters.deliveryEndDate}
          onChange={(deliveryEndDate: any) => setLocalFilters({ ...localFilters, deliveryEndDate })}
        />
      ),
    },
    {
      type: 'loadStatuses',
      component: (
        <Select
          extractValue
          isMulti
          sm
          choices={loadStatusChoicesNew}
          field='name'
          label='Load Status'
          menuPlacement='bottom'
          value={localFilters.loadStatuses}
          valueField='key'
          onChange={(loadStatuses: any) => setLocalFilters({ ...localFilters, loadStatuses })}
        />
      ),
    },
    {
      type: 'projectStatus',
      component: (
        <Select
          extractValue
          sm
          choices={['Draft', 'Active', 'Completed']}
          label='Status'
          value={localFilters.projectStatus}
          onChange={(projectStatus: string) => setLocalFilters({ ...localFilters, projectStatus })}
        />
      ),
    },
    {
      type: 'user',
      component: (
        <Select
          extractValue
          sm
          choices={contacts}
          field='name'
          label='Created By'
          loading={contactsLoading}
          value={localFilters.user}
          valueField='userId'
          onChange={(user: { id: number; name: string; userId: number }) =>
            setLocalFilters({ ...localFilters, user })
          }
        />
      ),
    },
    {
      type: 'show',
      component: (
        <Select
          extractValue
          sm
          field='name'
          label='Show'
          value={localFilters.show}
          valueField='key'
          choices={[
            { key: true, name: 'Purchased' },
            { key: false, name: 'Available To Buy' },
          ]}
          onChange={(show: { key: boolean; name: string }) =>
            setLocalFilters({ ...localFilters, show })
          }
        />
      ),
    },
    {
      type: 'refId',
      component: (
        <TextInput
          sm
          label='Reference ID'
          value={localFilters.refId}
          onChange={(refId: string) => setLocalFilters({ ...localFilters, refId })}
        />
      ),
    },
    {
      type: 'invoicedStartDate',
      component: (
        <DateInput
          showMonthDropdown
          showYearDropdown
          label='Invoice Start Date'
          placeholder=''
          value={localFilters.invoicedStartDate}
          onChange={(invoicedStartDate: any) =>
            setLocalFilters({ ...localFilters, invoicedStartDate })
          }
        />
      ),
    },
    {
      type: 'invoicedEndDate',
      component: (
        <DateInput
          showMonthDropdown
          showYearDropdown
          label='Invoice End Date'
          placeholder=''
          value={localFilters.invoicedEndDate}
          onChange={(invoicedEndDate: any) => setLocalFilters({ ...localFilters, invoicedEndDate })}
        />
      ),
    },
    {
      type: 'loadId',
      component: (
        <TextInput
          sm
          label='Load ID'
          value={localFilters.loadId}
          onChange={loadId => setLocalFilters({ ...localFilters, loadId })}
        />
      ),
    },
    {
      type: 'archivedLoads',
      component: (
        <div className='flex items-center mt-2 justify-between'>
          <div className='mr-2 -mt-1'>Show archived loads</div>
          <Toggle
            value={Boolean(localFilters.archivedLoads)}
            onChange={() => {
              setLocalFilters({
                ...localFilters,
                archivedLoads: !localFilters.archivedLoads || false,
              })
            }}
          />
        </div>
      ),
    },
    {
      type: 'archived',
      component: (
        <div className='flex items-center mt-2 justify-between'>
          <div className='mr-2 -mt-1'>Archived</div>
          <Toggle
            value={Boolean(localFilters.archived)}
            onChange={() => {
              setLocalFilters({
                ...localFilters,
                archived: !localFilters.archived || false,
              })
            }}
          />
        </div>
      ),
    },
    {
      type: 'projectId',
      component: (
        <TextInput
          sm
          label='Project ID'
          value={localFilters.projectId}
          onChange={projectId => setLocalFilters({ ...localFilters, projectId })}
        />
      ),
    },
    {
      type: 'projectName',
      component: (
        <TextInput
          sm
          label='Project Name'
          value={localFilters.projectName}
          onChange={projectName => setLocalFilters({ ...localFilters, projectName })}
        />
      ),
    },
    {
      type: 'name',
      component: (
        <TextInput
          sm
          label='Name'
          value={localFilters.name}
          onChange={name => setLocalFilters({ ...localFilters, name })}
        />
      ),
    },
    {
      type: 'id',
      component: (
        <TextInput
          sm
          label='ID'
          value={localFilters.id}
          onChange={id => setLocalFilters({ ...localFilters, id })}
        />
      ),
    },
    {
      type: 'rfpId',
      component: (
        <TextInput
          sm
          label='RFP #'
          value={localFilters.rfpId}
          onChange={rfpId => setLocalFilters({ ...localFilters, rfpId })}
        />
      ),
    },
    {
      type: 'mcNumber',
      component: (
        <TextInput
          sm
          label='MC Number'
          value={localFilters.mcNumber}
          onChange={mcNumber => setLocalFilters({ ...localFilters, mcNumber })}
        />
      ),
    },
    {
      type: 'dotNumber',
      component: (
        <TextInput
          sm
          label='DOT Number'
          value={localFilters.dotNumber}
          onChange={dotNumber => setLocalFilters({ ...localFilters, dotNumber })}
        />
      ),
    },
    {
      type: 'state',
      component: (
        <TextInput
          label='State'
          mask='aa'
          value={localFilters.state}
          onChange={state => setLocalFilters({ ...localFilters, state: state.toUpperCase() })}
        />
      ),
    },
    {
      type: 'city',
      component: (
        <TextInput
          label='City'
          value={localFilters.city}
          onChange={city => setLocalFilters({ ...localFilters, city })}
        />
      ),
    },
    {
      type: 'postalCode',
      component: (
        <TextInput
          label='Zip Code'
          value={localFilters.postalCode}
          onChange={postalCode => setLocalFilters({ ...localFilters, postalCode })}
        />
      ),
    },
    {
      type: 'fuelSetting',
      component: (
        <Select
          sm
          choices={fuelSettings}
          label='Fuel Settings'
          value={localFilters.fuelSetting}
          onChange={(fuelSetting: string) => setLocalFilters({ ...localFilters, fuelSetting })}
        />
      ),
    },
    {
      type: 'distanceUnit',
      component: (
        <Select
          sm
          choices={distanceUnit}
          label='Distance Unit'
          value={localFilters.distanceUnit}
          onChange={(distanceUnit: string) => setLocalFilters({ ...localFilters, distanceUnit })}
        />
      ),
    },
    {
      type: 'volumeFrequency',
      component: (
        <Select
          sm
          choices={volumeFrequency}
          label='Volume Frequency'
          value={localFilters.volumeFrequency}
          onChange={(volumeFrequency: string) =>
            setLocalFilters({ ...localFilters, volumeFrequency })
          }
        />
      ),
    },
    {
      type: 'bidType',
      component: (
        <Select
          sm
          choices={bidType}
          label='Bid Type'
          value={localFilters.bidType}
          onChange={(bidType: string) => setLocalFilters({ ...localFilters, bidType })}
        />
      ),
    },
    {
      type: 'rfpStatus',
      component: (
        <Select
          sm
          choices={rfpStatuses}
          label='Status'
          value={localFilters.rfpStatus}
          onChange={(rfpStatus: string) => setLocalFilters({ ...localFilters, rfpStatus })}
        />
      ),
    },
    {
      type: 'mode',
      component: (
        <Select
          sm
          choices={modeChoices.map(mode => mode.name)}
          label='Mode'
          menuPlacement='bottom'
          value={localFilters.mode}
          onChange={(mode: string) => setLocalFilters({ ...localFilters, mode })}
        />
      ),
    },
    {
      type: 'rfpName',
      component: (
        <TextInput
          label='RFP Name'
          value={localFilters.rfpName}
          onChange={rfpName => setLocalFilters({ ...localFilters, rfpName })}
        />
      ),
    },
    {
      type: 'poNumber',
      component: (
        <TextInput
          sm
          label='PO Number'
          value={localFilters.poNumber}
          onChange={poNumber => setLocalFilters({ ...localFilters, poNumber })}
        />
      ),
    },
    {
      type: 'salesNumber',
      component: (
        <TextInput
          sm
          label='Sales Number'
          value={localFilters.salesNumber}
          onChange={salesNumber => setLocalFilters({ ...localFilters, salesNumber })}
        />
      ),
    },
    {
      type: 'pickupNumber',
      component: (
        <TextInput
          sm
          label='Pick-Up Number'
          value={localFilters.pickupNumber}
          onChange={pickupNumber => setLocalFilters({ ...localFilters, pickupNumber })}
        />
      ),
    },
    {
      type: 'stockNumber',
      component: (
        <TextInput
          sm
          label='Stock Number'
          value={localFilters.stockNumber}
          onChange={stockNumber => setLocalFilters({ ...localFilters, stockNumber })}
        />
      ),
    },
  ]

  return (
    <>
      {filtersList.map((type: keyof SearchFilters, i: number) => (
        <div key={i}>{filterItems.find((x: { type: string }) => x.type === type)?.component}</div>
      ))}
    </>
  )
}
