import { Button, ConfirmationDialog, LoaderSpinner } from '@components'
import { ChevronDownIcon, ChevronUpIcon, XMarkIcon } from '@heroicons/react/24/outline'
import {
  CheckCircleIcon,
  DocumentIcon,
  ExclamationCircleIcon,
  XCircleIcon,
} from '@heroicons/react/24/solid'
import { isEmpty } from 'lodash-es'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import tw from 'tailwind-styled-components'

import { useAppSelector, useAppThunkDispatch } from '../../../app/hooks'
import { setFile, setFileModalVisible } from '../../../redux/commonSlice'
import {
  deleteUploadOrQuote,
  getUploadsList,
  setQuotesFilters,
  setUploadsFilters,
} from '../../../redux/quotingToolSlice'
import { TextWithTooltip } from '../../TextWithTooltip'

export const FileProcessingModal = () => {
  const [isExpanded, setExpanded] = useState(true)
  const [isModalVisible, setModalVisible] = useState(false)

  const navigate = useNavigate()

  const dispatch = useAppThunkDispatch()

  const fileName = useAppSelector(state => state.common.fileName)
  const file = useAppSelector(state => state.common.file)
  const fileLoading = useAppSelector(state => state.common.loading.file)
  const error = useAppSelector(state => state.common.fileError)
  const quote = useAppSelector(state => state.common.multiLaneQuote)
  const isFileModalVisible = useAppSelector(state => state.common.isFileModalVisible)
  const batchPricingUploadId = useAppSelector(state => state.common.batchPricingUploadId)
  const deleteFileLoading = useAppSelector(state => state.quotingTool.loading.deleteUploadOrQuote)
  const uploadsFilters = useAppSelector(state => state.quotingTool.filters.uploads)
  const quotesFilters = useAppSelector(state => state.quotingTool.filters.allQuotes)
  const uploadsList = useAppSelector(state => state.quotingTool.uploadsList) || []

  const uploadFile = uploadsList.find(upload => upload.id === quote?.id)

  const success = !fileLoading && !error
  const partialSuccess = !isEmpty(quote.errors) && !fileLoading && !error

  const Icon = isExpanded ? ChevronDownIcon : ChevronUpIcon

  const cancelUpload = async () => {
    if (batchPricingUploadId)
      await dispatch(deleteUploadOrQuote({ batchPricingUploadIds: [batchPricingUploadId] }))
    dispatch(setFile(null))
    setModalVisible(false)
  }

  useEffect(() => {
    if (fileLoading) {
      dispatch(setFileModalVisible(true))
      setExpanded(true)
    } else dispatch(getUploadsList())
  }, [fileLoading])

  let headerText = 'File is processing...'
  if (error) headerText = 'Your file could not be processed'
  if (success) headerText = 'Your file has been successfully processed'
  if (partialSuccess) headerText = 'Your file has been partially processed'

  const handleCloseModal = () => {
    if (fileLoading) setModalVisible(true)
    else if (error) dispatch(setFile(null))
    else dispatch(setFileModalVisible(false))
  }

  const errorsList = Object.keys(quote.errors || {}).map((key: string) => Number(key) + 1)

  return file && isFileModalVisible ? (
    <>
      <Container>
        <Header>
          <div className='font-semibold text-sm mr-4'>{headerText}</div>
          <div className='flex items-center'>
            <Icon
              className='w-5 hover:opacity-80 cursor-pointer'
              onClick={() => setExpanded(!isExpanded)}
            />
            <CloseIcon onClick={handleCloseModal} />
          </div>
        </Header>
        {isExpanded && (
          <div className='px-6 pb-6 -mt-4'>
            <ResultWrap>
              <div className='flex items-center text-link'>
                <DocumentIcon className='w-6' />
                <div className='ml-2'>
                  <TextWithTooltip className='w-[260px]' limit={40}>
                    {fileName}
                  </TextWithTooltip>
                </div>
              </div>
              {fileLoading && <LoaderSpinner size={5} />}
              {error && <XCircleIcon className='text-error w-6' />}
              {!fileLoading && !error && !partialSuccess && (
                <CheckCircleIcon className='text-success w-6' />
              )}
              {partialSuccess && <ExclamationCircleIcon className='text-brand-accent w-6' />}
            </ResultWrap>
            {error && <Error>{error}</Error>}
            {!isEmpty(quote.errors) && !fileLoading && (
              <div className='mb-4'>
                <Error>We found a problem with the following rows:</Error>
                <div className='leading-5 max-h-[200px] overflow-auto'>{errorsList.join(', ')}</div>
              </div>
            )}
            {success ? (
              <div className='flex'>
                <Button
                  fullWidth
                  className='w-full mr-4'
                  type='secondary'
                  onClick={async () => {
                    dispatch(
                      setUploadsFilters({
                        ...uploadsFilters,
                        uploadFile: uploadFile ? [uploadFile] : [],
                      }),
                    )
                    navigate('/quotes?tab=Uploads')
                  }}
                >
                  Go To Uploads
                </Button>
                <Button
                  fullWidth
                  className='w-full'
                  type='warn'
                  onClick={async () => {
                    dispatch(
                      setQuotesFilters({
                        ...quotesFilters,
                        uploadFile: uploadFile ? [uploadFile] : [],
                      }),
                    )
                    navigate('/quotes?tab=All+Quotes')
                  }}
                >
                  Show Quotes
                </Button>
              </div>
            ) : (
              <Button
                fullWidth
                className='w-full'
                type={fileLoading ? 'warn' : 'danger'}
                onClick={() => (fileLoading ? setModalVisible(true) : dispatch(setFile(null)))}
              >
                {fileLoading ? 'Cancel Upload' : 'Delete File'}
              </Button>
            )}
          </div>
        )}
      </Container>
      <ConfirmationDialog
        cancelButtonText='Continue upload'
        cancelButtonType='secondary'
        confirmButtonLoading={deleteFileLoading}
        confirmButtonText='Cancel'
        confirmButtonType='danger'
        isVisible={isModalVisible}
        setVisible={setModalVisible}
        text='Your upload is not complete. Are you sure you want to cancel the upload?'
        title='Cancel Upload?'
        onConfirm={cancelUpload}
      />
    </>
  ) : null
}

const Container = tw.div`
  absolute
  lg:right-4
  bottom-0
  lg:bottom-[17px]
  bg-white
  border
  border-brand-light
  rounded-lg
  lg:w-[400px]
  z-[9999]
`

const Header = tw.div`
  flex
  justify-between
  items-center
  p-6
`

const CloseIcon = tw(XMarkIcon)`
  w-5
  hover:opacity-80
  cursor-pointer
  ml-2
`

const ResultWrap = tw.div`
  flex
  justify-between
  pb-2
  my-4
  h-10
`

const Error = tw.div`
  text-error
  mb-4
  font-semibold
`
