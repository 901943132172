import { XMarkIcon } from '@heroicons/react/24/solid'
import { useEffect } from 'react'
import tw from 'tailwind-styled-components'

import { Button, ButtonIconType } from '../button'
import type { ModalProps } from './Modal'
import { Modal } from './Modal'

export type AlphaModalProps = ModalProps & {
  title?: string
  showFooter?: boolean
  onCancel?: () => void
  onConfirm?: () => void
  isConfirmButtonDisabled?: boolean
  isConfirmButtonLoading?: boolean
  footer?: JSX.Element | null
  className?: string
  confirmButtonLabel?: string
  closeOnOverlayClick?: boolean
  focusTrapped?: boolean
  showHeader?: boolean
  footerClassName?: string
  confirmIcon?: ButtonIconType | null
}

export const AlphaModal = ({
  title = '',
  isVisible,
  setVisible,
  children,
  showFooter = true,
  showCloseIcon = true,
  confirmIcon = 'arrow-right',
  showHeader = true,
  footer,
  isConfirmButtonDisabled = false,
  isConfirmButtonLoading = false,
  onClose = () => {},
  onCancel = () => {
    setVisible(false)
    onClose()
  },
  onConfirm = () => {
    setVisible(false)
    onClose()
  },
  className = '',
  confirmButtonLabel = 'Ok',
  closeOnOverlayClick = true,
  focusTrapped = false,
  footerClassName,
}: AlphaModalProps) => {
  useEffect(() => {
    if (!isVisible) {
      onCancel()
      onClose()
    }
  }, [isVisible])

  return (
    <ModalContainer
      className={className}
      closeOnOverlayClick={closeOnOverlayClick}
      contentClassName='max-md:absolute max-md:bottom-0 max-md:w-full max-lg:h-full'
      focusTrapped={focusTrapped}
      isVisible={isVisible}
      setVisible={setVisible}
      showCloseIcon={false}
    >
      <>
        {showHeader && (
          <AlphaModalHeader>
            <div>{title}</div>
            {showCloseIcon && (
              <CloseIcon
                onClick={() => {
                  setVisible(false)
                  onClose()
                  onCancel()
                }}
              />
            )}
          </AlphaModalHeader>
        )}
        <AlphaModalContent>{children}</AlphaModalContent>
        {showFooter && (
          <AlphaModalFooter $footerClassName={footerClassName}>
            {footer || (
              <>
                <Button
                  fullWidth
                  short
                  sm
                  className='max-md:w-full max-md:mr-4'
                  label='Cancel'
                  type='secondary'
                  onClick={() => {
                    onCancel()
                    onClose()
                  }}
                />
                <Button
                  fullWidth
                  short
                  sm
                  className='max-md:w-full'
                  disabled={isConfirmButtonDisabled}
                  label={confirmButtonLabel}
                  loading={isConfirmButtonLoading}
                  type='warn'
                  onClick={onConfirm}
                  {...(confirmIcon && { preIcon: confirmIcon })}
                />
              </>
            )}
          </AlphaModalFooter>
        )}
      </>
    </ModalContainer>
  )
}

const ModalContainer = tw(Modal)`
  !p-0
  overflow-hidden
  max-md:h-screen
  max-md:w-screen
  max-h-[calc(100vh-83px)]
  max-w-screen
  md:max-h-[80vh]
  md:min-h-[100px]
  hide-scrollbar
  relative
`

const CloseIcon = tw(XMarkIcon)`
  transition
  cursor-pointer
  hover:opacity-70
  icon
  w-5
  h-5
`

const AlphaModalHeader = tw.div`
  bg-dark-blue
  text-white
  border-l-8
  border-link
  text-sm
  p-3
  flex
  justify-between
  items-center
  font-semibold
  sticky
  top-0
  z-10
  lg:rounded-t-lg
`

const AlphaModalContent = tw.div`
  min-w-[300px]
  min-h-[100px]
  relative
  z-[999]
  max-md:pb-6
`

const AlphaModalFooter = tw.div<{ $footerClassName?: string }>`
  text-white
  flex
  justify-between
  bg-dark-blue
  p-3
  sticky
  -bottom-px
  lg:rounded-b-lg
  ${({ $footerClassName }) => $footerClassName}
`
