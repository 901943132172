import { Tooltip } from '@components'
import {
  DocumentIcon,
  FlagIcon,
  InformationCircleIcon,
  MapPinIcon,
  TruckIcon,
} from '@heroicons/react/24/solid'
import tw from 'tailwind-styled-components'

import { ManualQuote, QuoteLocation, QuotingLocation } from '../../../common/types'
import { QuoteStatus } from '../../Table'
import { QuoteRoute } from './QuoteRoute'

type Header = {
  showQuoteId?: boolean
  quote: {
    id?: number
    equipmentType: string
    origin: QuoteLocation | QuotingLocation | null
    destination: QuoteLocation | QuotingLocation | null
    stopovers?: (QuoteLocation | QuotingLocation | null)[]
    isOD?: boolean
    manualQuote?: ManualQuote
  }
  className?: string
  showStatus?: boolean
}

export const QuoteHeader = ({ showQuoteId, quote, className, showStatus = true }: Header) => (
  <Container className={className}>
    <div className='flex max-lg:flex-col max-lg:gap-y-2 lg:items-center gap-x-8 mr-4'>
      {quote.manualQuote && (
        <Badge>
          <FlagIcon className='w-5' />
          <span className='font-semibold mx-2'>OD</span>
          <Tooltip content='Over-Dimensional'>
            <InformationCircleIcon className='w-5' />
          </Tooltip>
        </Badge>
      )}
      {showQuoteId && (
        <div className='flex items-center gap-x-2'>
          <DocumentIcon className='w-5' />
          <div>Quote #{quote.id}</div>
        </div>
      )}
      {quote.equipmentType && (
        <div className='flex items-center gap-x-2'>
          <TruckIcon className='w-5' />
          <div>{quote.equipmentType}</div>
        </div>
      )}
      <div className='flex items-center gap-x-2'>
        <MapPinIcon className='w-5' />
        <QuoteRoute
          quote={{
            origin: quote.origin,
            destination: quote.destination,
            stops: quote.stopovers,
          }}
        />
      </div>
    </div>
    {showStatus && (
      <div className='max-lg:mt-2'>
        <QuoteStatus value={{ manualQuote: { status: quote.manualQuote?.status } }} />
      </div>
    )}
  </Container>
)

const Container = tw.div`
  font-semibold
  text-sm
  mb-4
  flex
  max-lg:flex-col
  lg:items-center
  justify-between
`

const Badge = tw.div`
  bg-brand-accent
  rounded-lg
  flex
  items-center
  py-2
  px-3
`
