import { Button } from '@components'
import { useEffect, useState } from 'react'
import tw from 'tailwind-styled-components'

import { useAppThunkDispatch } from '../../app/hooks'
import basket from '../../assets/images/empty-basket.png'
import { TutorialSteps } from '../../common/constants'
import { setCurrentStep, setTutorialWizardVisible } from '../../redux/userSlice'

export const EmptyTable = ({
  step,
  pluralVersion,
  title,
  onClick = () => {},
  hideTutorial,
  text,
  description,
  buttonText,
}: {
  step?: TutorialSteps
  title?: string
  pluralVersion?: string
  onClick?: () => void
  hideTutorial?: boolean
  text?: string
  description?: string
  buttonText?: string
}) => {
  const dispatch = useAppThunkDispatch()
  const [isDelayed, setIsDelayed] = useState(false)

  useEffect(() => {
    const timeout = setTimeout(() => setIsDelayed(true), 500)
    return () => clearTimeout(timeout)
  }, [])

  if (!isDelayed) return null

  return (
    <Container>
      <img alt='empty' className='w-[280px] mb-6' src={basket} />
      <div className='mb-2 font-semibold text-lg'>
        {text || `You don't have any ${pluralVersion || `${title}s`} yet`}
      </div>
      <div className='text-sm'>
        {description || `Start by creating a new ${title}`}
        {!hideTutorial && ` or learn how with our step-by-step tutorial`}
      </div>
      <div className='flex flex-col gap-4 mt-6 w-[255px]'>
        <Button
          fullWidth
          className='w-full'
          innerClassName='capitalize'
          type='warn'
          onClick={onClick}
        >
          {buttonText || `Create New ${title}`}
        </Button>
        {!hideTutorial && (
          <Button
            fullWidth
            className='w-full'
            type='light-blue'
            onClick={() => {
              dispatch(setCurrentStep(step))
              dispatch(setTutorialWizardVisible(true))
            }}
          >
            View Tutorial
          </Button>
        )}
      </div>
    </Container>
  )
}

const Container = tw.div`
  text-center
  flex
  flex-col
  items-center
  justify-center
  h-full
  font-poppins
`
