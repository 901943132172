import { useEffect, useRef, useState } from 'react'

// Adds an event listener for the keydown event provided in the first argument. Usage: useKey('Enter', callback)
export const useKey = (keyCode: string, callback: (value: any) => void) => {
  const cb = useRef(callback)

  useEffect(() => {
    cb.current = callback
  })

  useEffect(() => {
    const handle = (event: {
      code: string
      key: string
      ctrlKey: any
      metaKey: any
      preventDefault: () => void
    }) => {
      if (event.code === keyCode) {
        cb.current(event)
      }
      if (keyCode === 'Save' && event.key === 's' && (event.ctrlKey || event.metaKey)) {
        event.preventDefault()
        cb.current(event)
      }
    }

    document.addEventListener('keydown', handle)
    return () => document.removeEventListener('keydown', handle)
  }, [keyCode])
}

export type CountryCode = '52' | '1'

export const useCountryCode = (country: string) => {
  const [countryCode, setCountryCode] = useState<CountryCode>('52')

  const isMexico = country === 'MX' || country == 'MEX'

  useEffect(() => {
    setCountryCode(isMexico ? '52' : '1')
  }, [country])

  return { countryCode }
}
