import './Icon.scss'

export const AvatarDropdownIcon = () => (
  <svg
    className='svg'
    height='12'
    viewBox='0 0 12 12'
    width='12'
    xmlns='http://www.w3.org/2000/svg'
  >
    <rect height='12' rx='6' width='12' />
    <path d='M7.75 5.25L6 7L4.25 5.25' strokeLinecap='round' strokeLinejoin='round' />
  </svg>
)
