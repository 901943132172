import { LoadStatus, LoadStatusUpdateData } from '@common'
import { LoadStatusUpdateModal, Status } from '@components'
import { CubeIcon, CurrencyDollarIcon, TruckIcon } from '@heroicons/react/24/outline'
import { noop } from 'lodash-es'
import { useEffect, useMemo, useState } from 'react'
import tw from 'tailwind-styled-components'

import { useAppSelector, useAppThunkDispatch } from '../../../app/hooks'
import { trackEvent } from '../../../common/tracking'
import { Load } from '../../../common/types'
import { mapLoadStatus } from '../../../common/utils'
import {
  getLoadRoute,
  setCancelTenderModalVisible,
  setNewLoadStatus,
  updateStatus as updateLoadStatus,
} from '../../../redux/loadsSlice'

export const LoadStatusUpdateButton = ({
  className,
  load,
  showUpdateText,
  short,
  getData = () => {},
}: {
  className?: string
  load: Load
  showUpdateText?: boolean
  short?: boolean
  getData?: () => void
}) => {
  const [isLoadStatusModalVisible, setLoadStatusModalVisible] = useState(false)
  const [loadStatus, setLoadStatus] = useState<LoadStatus.ON_TRACK | LoadStatus.OFF_TRACK>(
    LoadStatus.ON_TRACK,
  )

  const dispatch = useAppThunkDispatch()

  const loadRoute = useAppSelector(state => state.loads.loadRoute) || []
  const updateStatusLoading = useAppSelector(state => state.loads.loading.updateStatus)

  const loadTendered = load?.carrier?.id

  useEffect(() => {
    if (isLoadStatusModalVisible) dispatch(getLoadRoute(load?.id))
  }, [isLoadStatusModalVisible])

  const handleClose = () => {
    trackEvent('User closed load status update modal')
    setLoadStatusModalVisible(false)
  }

  const handleSave = (formData: LoadStatusUpdateData) =>
    dispatch(updateLoadStatus(formData))
      .unwrap()
      .then(() => {
        getData()
        handleClose()
      })
      .catch(noop)

  const locationOptions = useMemo(() => {
    if (!load || !loadRoute.length) return null

    let options: { name: string; value: number }[] = []

    if (isLoadStatusModalVisible) {
      options = loadRoute.map((stop, i) => ({
        name: `${stop.stopType === 'Drop' ? 'Drop-Off' : 'Pick-Up'} ${i + 1} (${
          stop.locationName
        })`,
        value: stop.locationId,
      }))
    }
    return { options, loadId: load?.id }
  }, [load, loadRoute, isLoadStatusModalVisible])

  const updateStatus = (status: LoadStatus) => {
    trackEvent('User updated load status', { loadId: load.id, status })
    if ([LoadStatus.ON_TRACK, LoadStatus.OFF_TRACK].includes(status)) {
      setLoadStatus(status as LoadStatus.ON_TRACK | LoadStatus.OFF_TRACK)
      setLoadStatusModalVisible(true)
    } else
      dispatch(updateLoadStatus({ loadId: load?.id, status }))
        .unwrap()
        .then(getData)
        .catch(noop)
  }

  const handleSelect = (newStatusDisplay: string, newStatus: LoadStatus) => {
    if (loadTendered) {
      dispatch(
        setNewLoadStatus({
          isUpdating: true,
          newStatusDisplay,
          newStatus,
        }),
      )
      dispatch(setCancelTenderModalVisible(true))
    } else updateStatus(newStatus)
  }

  const loadStatusOptions = [
    {
      title: (
        <>
          <CubeIcon className='w-4' /> Procurement
        </>
      ),
      options: [
        {
          label: 'Plan',
          onClick: () => handleSelect('Plan', LoadStatus.PLAN),
        },
        { label: 'Tendered', onClick: () => updateStatus(LoadStatus.TENDERED) },
        { label: 'Tender Accepted', onClick: () => updateStatus(LoadStatus.TENDER_ACCEPTED) },
        {
          label: 'Tender Rejected',
          onClick: () => handleSelect('Tender Rejected', LoadStatus.TENDER_REJECTED),
        },
      ],
    },
    {
      title: (
        <>
          <TruckIcon className='w-4' /> In Transit
        </>
      ),
      options: [
        { label: 'On Track', onClick: () => updateStatus(LoadStatus.ON_TRACK) },
        { label: 'Off Track', onClick: () => updateStatus(LoadStatus.OFF_TRACK) },
        { label: 'Delivered', onClick: () => updateStatus(LoadStatus.DELIVERED) },
      ],
    },
    {
      title: (
        <>
          <CurrencyDollarIcon className='w-4' /> Billing
        </>
      ),
      options: [
        { label: 'Ready To Bill', onClick: () => updateStatus(LoadStatus.READY_TO_BILL) },
        {
          label: 'Billing In Progress',
          onClick: () => updateStatus(LoadStatus.BILLING_IN_PROGRESS),
        },
        { label: 'Completed', onClick: () => updateStatus(LoadStatus.COMPLETED) },
      ],
    },
  ]

  return (
    <>
      <StatusContainer
        className={className}
        dropdownOptions={loadStatusOptions}
        labelClassName='w-[156px]'
        short={short}
        showUpdateText={showUpdateText}
        status={mapLoadStatus(load?.newLoadStatusDisplay)}
      />
      <LoadStatusUpdateModal
        handleClose={handleClose}
        handleSave={handleSave}
        isVisible={isLoadStatusModalVisible}
        loading={updateStatusLoading}
        loadStatus={loadStatus}
        locationOptions={locationOptions}
        setVisible={setLoadStatusModalVisible}
      />
    </>
  )
}

const StatusContainer = tw(Status)`
  text-black
`
