import tw from 'tailwind-styled-components'

import emptyBox from '../../../assets/images/emptyBox.png'

export const NoQuoteForSelectedDate = () => (
  <Container>
    <img alt='empty' className='w-20' src={emptyBox} />
    <div className='text-base font-semibold mt-4'>No quote found for this date</div>
    <div className='mt-1'>
      Please select a different date or explore quotes near your entered date
    </div>
  </Container>
)

const Container = tw.div`
  border
  border-border-gray
  rounded-lg
  h-full
  flex
  flex-col
  justify-center
  items-center
  p-6
  font-poppins
  text-center
`
