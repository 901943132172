import { Button, Form, TextInput } from '@components'
import { noop } from 'lodash-es'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import tw from 'tailwind-styled-components'

import { useAppSelector, useAppThunkDispatch } from '../../app/hooks'
import buffering from '../../assets/images/loading-buffering.gif'
import logoBlue from '../../assets/images/login/logo-blue.png'
import { getDocumentTitle, getPhoneMaskOptions } from '../../common/utils'
import { Error, Help, Link, LoginPageContainer } from '../../components/Login/components'
import {
  registerShipper,
  resetLogin,
  setCredentials,
  setRegister,
  setStep,
  verifyShipperInvitation,
} from '../../redux/userSlice'

const phoneMask = getPhoneMaskOptions()

export const RegisterScreen = (): JSX.Element => {
  const { invitationCode = '' } = useParams<{ invitationCode: string }>()
  const dispatch = useAppThunkDispatch()
  const navigate = useNavigate()
  const [isFormValid, setFormValid] = useState(false)
  const register = useAppSelector(state => state.user.register)
  const isRegistering = useAppSelector(state => state.user.loading.registerShipper)
  const isVerifyingInvitation = useAppSelector(state => state.user.loading.verifyShipperInvitation)
  const credentials = useAppSelector(state => state.user.credentials)
  const error = useAppSelector(state => state.user.error)
  const invitationError = useAppSelector(state => state.user.invitationError)
  const invitationDetails = useAppSelector(state => state.user.invitationDetails)

  const onRegister = useCallback(async () => {
    dispatch(setCredentials({ ...credentials, username: '' })) // reset login email so there are no clashes
    dispatch(registerShipper({ invitationCode: invitationCode || null }))
      .unwrap()
      .then(() => {
        dispatch(setStep('CONFIRM'))
        navigate('/')
      })
      .catch(noop)
  }, [])

  useEffect(() => {
    document.title = getDocumentTitle('Register')
    dispatch(resetLogin())
  }, [])

  useEffect(() => {
    if (invitationCode) {
      dispatch(verifyShipperInvitation(invitationCode))
    }
  }, [])

  return (
    <LoginPageContainer>
      <Container>
        {isVerifyingInvitation ? (
          <div className='flex justify-center items-center h-full mt-[100px]'>
            <img alt='loading' className='w-6 h-6' src={buffering} />
          </div>
        ) : invitationError ? (
          <div className='text-xl font-bold text-error'>{invitationError}</div>
        ) : (
          <>
            <div className='flex flex-col items-center mb-1 w-full'>
              <img alt='logo' className='w-[80px]' src={logoBlue} />
              <div className='text-2xl font-semibold mt-6'>
                {invitationDetails ? "You've been invited" : 'Create Account'}
              </div>
              {invitationDetails ? (
                <div className='mt-4 text-sm font-bold'>
                  {invitationDetails.invitedBy} invited you to join the &quot;
                  {invitationDetails.companyName}&quot; workspace.
                </div>
              ) : (
                <TrialBanner>
                  <p>Start your 14-day free trial today</p>
                  <p className='mt-1'>No credit card needed</p>
                </TrialBanner>
              )}
            </div>
            <Form
              autoComplete='off'
              className='flex flex-col gap-4 items-center w-full'
              setFormValid={setFormValid}
            >
              {!invitationDetails && (
                <TextInput
                  required
                  sm
                  className='w-full'
                  label='Company Name'
                  value={register.companyName}
                  onChange={companyName => dispatch(setRegister({ companyName }))}
                />
              )}
              <div className='flex gap-2 w-full'>
                <TextInput
                  required
                  sm
                  className='w-full'
                  label='First Name'
                  value={register.firstName}
                  onChange={firstName => dispatch(setRegister({ firstName }))}
                />
                <TextInput
                  required
                  sm
                  className='w-full'
                  label='Last Name'
                  value={register.lastName}
                  onChange={lastName => dispatch(setRegister({ lastName }))}
                />
              </div>
              <TextInput
                required
                sm
                className='w-full'
                disabled={!!invitationDetails}
                label='Work Email'
                type='email'
                value={invitationDetails ? invitationDetails.email : register.email}
                onChange={email => dispatch(setRegister({ email }))}
              />
              <TextInput
                required
                sm
                className='w-full'
                label='Phone Number'
                maskOptions={phoneMask}
                value={register.phone}
                onChange={phone => dispatch(setRegister({ phone }))}
              />
              <TextInput
                required
                sm
                className='w-full'
                label='What is your role in the company?'
                value={register.roleInCompany}
                onChange={roleInCompany => dispatch(setRegister({ roleInCompany }))}
              />
              {error && <Error className='mb-2 text-sm'>{error}</Error>}
              <Button
                fullWidth
                lg
                className='w-full'
                disabled={!isFormValid}
                loading={isRegistering}
                type='warn'
                onClick={onRegister}
              >
                Create Account
              </Button>
            </Form>
            <div className='text-center text-sm'>
              <div className='mb-4'>
                Have an account?{' '}
                <Link
                  onClick={() => {
                    navigate('/')
                  }}
                >
                  Log in
                </Link>
              </div>
              <Help />
            </div>
          </>
        )}
      </Container>
    </LoginPageContainer>
  )
}

const Container = tw.div`
  flex
  w-full
  md:w-1/2
  lg:w-5/12
  flex-col
  justify-center
  mt-10
  md:mt-0
  items-center
  gap-6
  w-full 
  max-lg:px-12 
  lg:px-20 
  xl:px-24
`

const TrialBanner = tw.div`
  w-full 
  text-sm 
  text-white 
  text-center 
  font-bold 
  mt-4 
  py-4 
  px-2 
  bg-shiny-blue 
  rounded-lg
`
