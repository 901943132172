import { createSlice } from '@reduxjs/toolkit'

import { identifyUser } from '../common/tracking'

type TrackingState = { hasInitializedUser: boolean }

const initialState: TrackingState = {
  hasInitializedUser: false,
}

const trackingSlice = createSlice({
  name: 'trackingInfo',
  initialState,
  reducers: {
    initializeTracking: (state: TrackingState, { payload }) => {
      // This action is set up to be idempotent, so we only identify the user once, and
      // only after the user's information is known.
      if (payload !== undefined && !state.hasInitializedUser) {
        identifyUser(payload.email, payload.name)
        state.hasInitializedUser = true
      }
    },
  },
})

export const { initializeTracking } = trackingSlice.actions

export default trackingSlice.reducer
