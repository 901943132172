import { OffTrackTransitStatus, OnTrackTransitStatus } from './enums'
import { LoadStatusDisplay } from './types'

export const mexicanStates: { [key: string]: string } = {
  AGU: 'Aguascalientes',
  BCN: 'Baja California',
  BCS: 'Baja California Sur',
  CAM: 'Campeche',
  CHP: 'Chiapas',
  CHH: 'Chihuahua',
  CMX: 'Ciudad de México',
  COA: 'Coahuila',
  COL: 'Colima',
  DUR: 'Durango',
  GUA: 'Guanajuato',
  GRO: 'Guerrero',
  HID: 'Hidalgo',
  JAL: 'Jalisco',
  MEX: 'Estado de México',
  MIC: 'Michoacán',
  MOR: 'Morelos',
  NAY: 'Nayarit',
  NLE: 'Nuevo León',
  OAX: 'Oaxaca',
  PUE: 'Puebla',
  QUE: 'Querétaro',
  ROO: 'Quintana Roo',
  SLP: 'San Luis Potosí',
  SIN: 'Sinaloa',
  SON: 'Sonora',
  TAB: 'Tabasco',
  TAM: 'Tamaulipas',
  TLA: 'Tlaxcala',
  VER: 'Veracruz',
  YUC: 'Yucatán',
  ZAC: 'Zacatecas',
}

export const canadianProvinces: { [key: string]: string } = {
  AB: 'Alberta',
  BC: 'British Columbia',
  MB: 'Manitoba',
  NB: 'New Brunswick',
  NL: 'Newfoundland and Labrador',
  NS: 'Nova Scotia',
  NT: 'Northwest Territories',
  NU: 'Nunavut',
  ON: 'Ontario',
  PE: 'Prince Edward Island',
  QC: 'Quebec',
  SK: 'Saskatchewan',
  YT: 'Yukon',
}

// Equipment types that can be added to a carrier's profile.
export const carrierEquipmentSupportedEquipmentTypes = [
  'Flatbed',
  'Van',
  'Power Only',
  'Hot Shot',
  'Reefer',
  'Step Deck',
  'Removable Gooseneck',
  'Conestoga',
  'Load Out',
  'Double Drop',
  'Van Intermodal',
  'Tanker Intermodal',
  'Reefer Intermodal',
  'Straight Box Truck',
]

export const onTrackTransitStatusOptions = [
  {
    value: OnTrackTransitStatus.EN_ROUTE,
    name: 'En Route',
  },
  {
    value: OnTrackTransitStatus.AT_STOP,
    name: 'At Stop',
  },
  {
    value: OnTrackTransitStatus.LOADING,
    name: 'Loading',
  },
  {
    value: OnTrackTransitStatus.UNLOADING,
    name: 'Unloading',
  },
]

export const offTrackTransitStatusOptions = [
  {
    value: OffTrackTransitStatus.BREAK_DOWN,
    name: 'Break Down',
  },
  {
    value: OffTrackTransitStatus.DELAYED_AT_PREVIOUS_STOP,
    name: 'Delayed at Previous Stop',
  },
  {
    value: OffTrackTransitStatus.TRAFFIC,
    name: 'Traffic',
  },
  {
    value: OffTrackTransitStatus.LOCATION_CLOSED,
    name: 'Location Closed',
  },
  {
    value: OffTrackTransitStatus.HOURS_OF_SERVICE,
    name: 'Hours Of Service',
  },
  {
    value: OffTrackTransitStatus.WEATHER,
    name: 'Weather',
  },
  {
    value: OffTrackTransitStatus.CUSTOM_OR_INSPECTIONS,
    name: 'Customs/Inspections', // changed to Customs
  },
  {
    value: OffTrackTransitStatus.OTHER,
    name: 'Other Reason',
  },
]

export const loadInProgressLoadStatuses = [
  LoadStatusDisplay.BOOKED,
  LoadStatusDisplay.CONFIRMED,
  LoadStatusDisplay.PICKUP_ON_TRACK,
  LoadStatusDisplay.PICKUP_OFF_TRACK,
  LoadStatusDisplay.LOADING,
  LoadStatusDisplay.PICKED,
  LoadStatusDisplay.DELIVERY_ON_TRACK,
  LoadStatusDisplay.DELIVERY_OFF_TRACK,
  LoadStatusDisplay.UNLOADING,
  LoadStatusDisplay.ON_TRACK,
  LoadStatusDisplay.OFF_TRACK,
  LoadStatusDisplay.TENDER_ACCEPTED,
]
