import { useAppSelector } from '../../../app/hooks'

export const TenderedToExoTooltipContent = () => {
  const load = useAppSelector(state => state.loads.loadDetails)

  return (
    <div>
      Please contact{' '}
      <a
        className='hover:underline'
        href={`mailto:${load?.customerCompany?.accountManager?.email}`}
      >
        {load?.customerCompany?.accountManager?.email || 'Exo Freight'}
      </a>{' '}
      in order to change carrier or cancel tender
    </div>
  )
}
