import { CityLocation } from '@common'
import { HighlightedMatchingText } from '@components'
import React, { FormEvent, useEffect, useState } from 'react'
import Autosuggest from 'react-autosuggest'
import tw from 'tailwind-styled-components'

import { useAppSelector, useAppThunkDispatch } from '../../app/hooks'
import { AddressFormLocation, HereMapLocation } from '../../common/types'
import { geocode } from '../../redux/locationsSlice'

type Address = {
  address: {
    city: string
    countryCode: string
    countryName: string
    county: string
    label: string
    postalCode: string
    state: string
    stateCode: string
  }
  position: {
    lat: number
    lng: number
  }
}

type AddressAutocompleteProps = {
  location: HereMapLocation | null
  setLocation: (location: AddressFormLocation) => void
  field: string
  label?: string
  required?: boolean
  showAsterisk?: boolean
}

// TODO: move to components; use in CityAutosuggest
export const AddressAutocomplete = ({
  location,
  setLocation,
  field = '',
  label = 'Address',
  required,
  showAsterisk = true,
}: AddressAutocompleteProps) => {
  const [choices, setChoices] = useState<any>([])
  const [query, setQuery] = useState<string>(location?.addressDisplay || '')

  const dispatch = useAppThunkDispatch()
  const addresses = useAppSelector(state => state.locations.addresses)

  useEffect(() => {
    setChoices(addresses)
  }, [addresses])

  const setAddress = (location: Address) => {
    const { position } = location

    setLocation({
      currentPlace: location,
      [field]: {
        ...location?.address,
        state: location?.address?.stateCode,
        position,
        country: location?.address?.countryCode,
      },
    })
  }

  const getSuggestionValue = (suggestion: CityLocation) => suggestion.title || ''

  const renderSuggestion = (suggestion: CityLocation) => (
    <HighlightedMatchingText query={query} text={suggestion.title ?? ''} />
  )

  const onSuggestionsClearRequested = () => setChoices([])

  const onSuggestionSelected = (event: FormEvent, { suggestion }: { suggestion: any }) =>
    setAddress(suggestion)

  const handleChange = (event: FormEvent<HTMLElement>, { newValue }: { newValue: string }) => {
    setQuery(newValue)
    if (!newValue) setLocation({})
  }

  const onSearch = (query: string) => dispatch(geocode({ address: query }))

  const handleBlur = (
    event: React.FocusEvent<HTMLElement>,
    params?: { highlightedSuggestion: any },
  ) => {
    const highlightedSuggestion = params?.highlightedSuggestion
    if (highlightedSuggestion) setAddress(highlightedSuggestion)
    else if (!location?.addressDisplay) setQuery('')
  }

  const inputProps = {
    value: query || '',
    onChange: handleChange,
    onBlur: handleBlur,
  }

  return (
    <>
      {label && (
        <Label>
          {label} {required && showAsterisk && <span className='text-error'>*</span>}
        </Label>
      )}
      <Autosuggest
        focusInputOnSuggestionClick={false}
        getSuggestionValue={getSuggestionValue}
        inputProps={inputProps}
        multiSection={false}
        renderSuggestion={renderSuggestion}
        suggestions={choices}
        theme={{
          suggestionsContainerOpen:
            'border border-border-gray absolute mt-1 rounded-md bg-white w-full overflow-auto max-h-[200px] z-[9] py-1',
          suggestion: 'cursor-pointer py-2 px-2',
          suggestionHighlighted: 'bg-lighter-blue',
        }}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        onSuggestionSelected={onSuggestionSelected}
        onSuggestionsFetchRequested={({ value }: { value: string }) => onSearch(value)}
      />
    </>
  )
}

const Label = tw.label`
  mb-1
  block
  text-dark-gray
  self-start
  font-poppins
  text-xs
`
