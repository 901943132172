import { ReactNode, useEffect, useMemo, useRef, useState } from 'react'
import tw from 'tailwind-styled-components'

import { Loader } from '../loaders'
import { LinkPageHeader } from '../pageheader'

export type Item = {
  id: string
  title: string
  noActions?: boolean
  content?: ReactNode
}

export type MenuSection = Item & {
  items?: Item[]
}

type MobileDetails = {
  loading: boolean
  tab: string
  menuItems: (Item | MenuSection)[]
  children: ReactNode
  id?: number
  mainPageTitle: string
  mainUrl: string
  title?: string
}

export const MobileDetailsContainer = ({
  loading,
  tab,
  menuItems,
  children,
  id,
  mainPageTitle,
  mainUrl,
  title,
}: MobileDetails) => {
  const [titleWidth, setTitleWidth] = useState('0px')

  // Flatten menu items
  const formattedMenuItems = menuItems.reduce<Item[]>((acc, item) => {
    if ('items' in item && item.items) return [...acc, ...item.items]
    else return [...acc, item]
  }, [])

  const sectionTitleRef = useRef<HTMLDivElement | null>(null)

  const menuItem = formattedMenuItems.find(item => item.id === tab)

  const headerData = useMemo(
    () => ({
      title:
        tab === 'main' ? (
          mainPageTitle
        ) : (
          <div
            className='truncate overflow-hidden text-ellipsis'
            style={{ maxWidth: `calc(100vw - ${titleWidth})` }}
          >
            {title}
          </div>
        ),
      to: tab === 'main' ? `/${mainUrl}` : `/${mainUrl}/${id}`,
      noRightContent: tab === 'main',
    }),
    [tab, mainPageTitle, title, mainUrl, id, titleWidth],
  )

  useEffect(() => {
    if (sectionTitleRef.current) {
      const parentWidth = sectionTitleRef.current?.offsetWidth
      setTitleWidth(`${parentWidth + 86}px`)
    }
  }, [tab])

  useEffect(() => {
    document.querySelector('.page-container')?.scrollTo(0, 0)
  }, [tab])

  return (
    <div className='lg:hidden relative'>
      <LinkPageHeader
        bottomBordered
        href={headerData.to}
        loading={loading}
        title={headerData.title}
        rightContent={() =>
          headerData.noRightContent ? <></> : <Title ref={sectionTitleRef}>{menuItem?.title}</Title>
        }
      />
      {loading && <Loader className='max-lg:top-[52px]' />}
      {id && (
        <TabContainer $noActions={menuItem?.noActions} $tab={tab}>
          {children}
        </TabContainer>
      )}
    </div>
  )
}

const Title = tw.div`
  text-base
  font-semibold
  whitespace-nowrap
`

const TabContainer = tw.div<{ $tab: string; $noActions?: boolean }>`
  overflow-y-auto
  flex
  flex-col
  pb-8
  ${({ $tab, $noActions }) =>
    $tab === 'main' || $noActions ? 'h-[calc(100vh-210px)]' : 'h-[calc(100vh-275px)]'}
  ${({ $tab }) => $tab === 'tracking-update' && 'pb-0'}
`
