import { forwardRef, PropsWithChildren } from 'react'
import styled from 'styled-components'
import tw from 'tailwind-styled-components'

type FileInputProps = {
  className?: string
  mimeType?: string
  name?: string
  // TODO: Replace any with File | ArrayBuffer | string | null and fix eslint complaints
  onChange?: (payload: any) => void
  required?: boolean
  base64?: boolean
  value?: File | null
  disabled?: boolean
}

export const FileInput = forwardRef<HTMLInputElement, PropsWithChildren<FileInputProps>>(
  (
    {
      children,
      className = '',
      mimeType = 'application/pdf',
      name = '',
      onChange = () => {},
      base64,
      disabled,
    }: PropsWithChildren<FileInputProps>,
    ref,
  ) => {
    const onImageChange = (event: { preventDefault: Function; target: HTMLInputElement }) => {
      event.preventDefault()

      if (event.target.files) {
        if (base64) {
          const reader = new FileReader()
          const file = event.target.files[0]
          reader.onloadend = () => {
            onChange({ file: reader.result, name: file.name })
          }
          reader.readAsDataURL(file)
        } else onChange(event.target.files[0])
      }

      event.target.value = ''
    }

    return (
      <Container className={className}>
        <ButtonWrap $disabled={disabled}>
          <Input
            ref={ref}
            accept={mimeType}
            data-testid='fileInput'
            disabled={disabled}
            name={name}
            type='file'
            onChange={onImageChange}
          />
          {children}
        </ButtonWrap>
      </Container>
    )
  },
)

const Container = tw.div`
  relative
  overflow-hidden
  inline-block
  w-full
  md:w-fit
`

const ButtonWrap = tw.button<{ $disabled?: boolean }>`
  relative
  bg-transparent
  border-none
  transition-all
  w-full
  ${({ $disabled }) => ($disabled ? 'opacity-50' : 'hover:opacity-70')}
`

const Input = styled.input`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  z-index: 10;
  &::file-selector-button {
    cursor: ${({ disabled }) => (disabled ? '' : 'pointer')};
    width: 100%;
    height: 100%;
  }
`
