/**
 * Masks in this file are to be used to format a string to conform into a specified pattern.
 * The way to use is by calling the resolve function, or pass directly to the mask prop in the TextInput component.
 * See https://imask.js.org/guide.html for documentation
 *
 * Example: phoneMask.resolve('8888888888') -> +1 (888) 888-8888
 */

import { IMask } from 'react-imask'

// Turns a string '8888888888' into '+1 (888) 888-8888'
export const getPhoneMaskOptions = (countryCode = '1') => ({
  mask: `+${countryCode} (000) 000-0000`,
  lazy: true,
})

export const getTimeMaskOptions = () => ({
  overwrite: true,
  mask: 'HH:MM',
  blocks: {
    HH: {
      mask: IMask.MaskedRange,
      from: 0,
      to: 23,
      maxLength: 2,
    },
    MM: {
      mask: IMask.MaskedRange,
      from: 0,
      to: 59,
      maxLength: 2,
    },
  },
})

export const getSimpleNumberMaskOptions = () => ({
  mask: 'num',
  lazy: false,
  blocks: {
    num: {
      mask: Number,
      radix: '.',
      min: -10000,
    },
  },
})

export const getCurrencyMaskOptions = (maxLength = 8, decimalLimit = 2) => ({
  mask: '$num',
  lazy: false,
  blocks: {
    num: {
      mask: Number,
      thousandsSeparator: ',',
      radix: '.',
      normalizeZeros: false,
      scale: decimalLimit,
      max: parseFloat(`${'9'.repeat(maxLength)}.${'9'.repeat(decimalLimit)}`),
      signed: true,
    },
  },
})
