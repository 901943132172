import tw from 'tailwind-styled-components'

export const Link = ({
  children,
  href,
  onClick,
}: {
  children: string
  href?: string
  onClick?: () => void
}) => (
  <LinkContainer {...(href && { href })} {...(onClick && { onClick })}>
    {children}
  </LinkContainer>
)

const LinkContainer = tw.a`
  text-link
  font-semibold
  hover:underline
  transition-all
  cursor-pointer
`
