import tw from 'tailwind-styled-components'

import { getTableOrderDisplay, TableOrder } from '../../common'
import { OrderButton, TableSkeleton } from '../Table'

type ListTableProps = {
  headerItems: { label: string; key: string }[]
  error: string
  items: JSX.Element[]
  tableClassName?: string
  blue?: boolean
  setOrder?: (order: TableOrder) => void
  order?: TableOrder
  loading?: boolean
}

export const ListTable = ({
  headerItems,
  error,
  items,
  tableClassName = '',
  blue,
  setOrder = () => {},
  order,
  loading,
}: ListTableProps) => {
  const changeOrder = (header: { label: string; key: string }) => {
    if (header.key) {
      const newOrder = getTableOrderDisplay(
        headerItems,
        header.label,
        order || { label: '', direction: 'ascending', key: header.key },
      )
      setOrder(
        newOrder.label && newOrder.direction && newOrder.key
          ? newOrder
          : { label: '', direction: 'ascending', key: header.key },
      )
    }
  }

  return (
    <>
      <TableContent className={tableClassName}>
        <Table>
          <thead>
            <tr>
              {headerItems.map(header => (
                <TH
                  key={header.label}
                  $blue={blue}
                  $sortable={!!header.key}
                  onClick={() => changeOrder(header)}
                >
                  <div className='flex items-center'>
                    {header.label}
                    {header.key && (
                      <OrderButton isSorted={header.label === order?.label} order={order} />
                    )}
                  </div>
                </TH>
              ))}
            </tr>
          </thead>
          {!loading && !items.length && (
            <tbody>
              <tr>
                <td>
                  <Error>{error}</Error>
                </td>
              </tr>
            </tbody>
          )}
          <tbody>{loading ? <TableSkeleton length={headerItems.length} /> : items}</tbody>
        </Table>
      </TableContent>
    </>
  )
}

const Error = tw.div`
  text-dark-gray
  absolute
  left-1/2
  mt-4
  -translate-x-1/2
`

const TH = tw.th<{ $blue?: boolean; $sortable: boolean }>`
  overflow-ellipsis
  sticky
  top-0
  bg-white
  z-[1]
  py-5
  px-2
  text-left
  font-semibold
  text-xs
  ${({ $blue }) => $blue && 'bg-lighter-blue'}
  ${({ $sortable }) => $sortable && 'cursor-pointer'}
`

const TableContent = tw.div`
  h-[calc(100vh-481px)]
  w-full
  pr-4
  mt-0
  overflow-auto
  relative
`

const Table = tw.table`
  w-full
`
