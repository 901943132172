import { useRouteError } from 'react-router-dom'

export const RouteErrorBoundary = () => {
  /*
  Dummy ErrorBoundary component to pass to react-router-dom's Route component.
  This is needed so that any errors caught by the router get propegated upwards
  to our global error boundary.
  */
  const error = useRouteError()
  throw error
}
