import { useEffect, useMemo } from 'react'
import tw from 'tailwind-styled-components'

import { useAppSelector, useAppThunkDispatch } from '../../app/hooks'
import logoBlue from '../../assets/images/login/logo-blue.png'
import { getDocumentTitle } from '../../common/utils'
import { LoginPageContainer } from '../../components/Login/components'
import { ConfirmStep, LoginStep, SelectCompanyStep } from '../../components/Login/steps'
import { resetLogin } from '../../redux/userSlice'

export const LoginScreen = (): JSX.Element => {
  const dispatch = useAppThunkDispatch()
  const step = useAppSelector(state => state.user.step)
  const loginData = useAppSelector(state => state.user.loginData)

  useEffect(() => {
    document.title = getDocumentTitle('Login')
  }, [])

  useEffect(() => {
    dispatch(resetLogin())
  }, [step])

  const stepConfig = useMemo(() => {
    switch (step) {
      case 'CONFIRM':
        return {
          title: 'Email Confirmation',
          component: <ConfirmStep />,
          description: (
            <span className='text-sm'>
              Please enter the verification code that we sent to{' '}
              <span className='font-semibold'>{loginData.email || loginData.phone}</span>
            </span>
          ),
        }
      case 'SELECT_COMPANY':
        return {
          title: 'Select Company',
          component: <SelectCompanyStep />,
        }
      case 'LOGIN':
      default:
        return {
          title: 'Log In to Pulse',
          component: <LoginStep />,
        }
    }
  }, [step])

  return (
    <LoginPageContainer>
      <FormContainer>
        <InnerWrap>
          <div className='flex flex-col items-center justify-center gap-4 mb-10 max-lg:pt-10'>
            <img alt='logo' className='w-[80px]' src={logoBlue} />
            <div className='text-3xl font-semibold mt-8 whitespace-nowrap'>{stepConfig.title}</div>
            {stepConfig.description && (
              <div className='w-80 text-center'>{stepConfig.description}</div>
            )}
          </div>
          <div>{stepConfig.component}</div>
        </InnerWrap>
      </FormContainer>
    </LoginPageContainer>
  )
}

const FormContainer = tw.div`
  flex
  h-full
  justify-center
  md:mt-0
  self-center
  w-full
  md:w-1/2
  lg:w-5/12
`

const InnerWrap = tw.div`
  flex
  flex-col
  justify-center
  mx-10
  lg:mx-24
  text-center
  w-full
  xl:w-4/5
  3xl:w-3/5
  max-lg:h-[calc(100vh-82px)]
  overflow-y-auto
  max-lg:pb-10
`
