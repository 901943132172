import { CatchError, formatAxiosErrorToPayload, getErrorString } from '@common'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'

import { api } from '../api/api'
import { keysToCamelCase } from '../common/utils'

type ReportsState = {
  loading: {
    customReports: boolean
  }
  count: {
    customReports: number
  }
  customReports: Array<any>
  metabaseLink: {
    loading: boolean
    url: string
  }
}

const initialState: ReportsState = {
  loading: {
    customReports: false,
  },
  count: {
    customReports: 0,
  },
  customReports: [],
  metabaseLink: {
    loading: true,
    url: '',
  },
}

export const getCustomReports = createAsyncThunk(
  'reports/getCustomReports',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get('/customer/api/customer-company-custom-reports-list/')
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const getMetabaseLink = createAsyncThunk(
  'reports/getMetabaseLink',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get('/customer/api/metabase-url/')
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

const reportsSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getCustomReports.pending, state => {
        state.loading.customReports = true
      })
      .addCase(getCustomReports.fulfilled, (state, { payload }) => {
        const { count, results } = payload
        state.loading.customReports = false
        state.count.customReports = count
        state.customReports = results
      })
      .addCase(getCustomReports.rejected, (state, { payload }) => {
        state.loading.customReports = false
        toast.error(getErrorString(payload, 'Error getting custom reports'))
      })
      .addCase(getMetabaseLink.pending, state => {
        state.metabaseLink.loading = true
      })
      .addCase(getMetabaseLink.fulfilled, (state, { payload }) => {
        state.metabaseLink.loading = false
        state.metabaseLink.url = payload.url
      })
      .addCase(getMetabaseLink.rejected, (state, { payload }) => {
        state.metabaseLink.loading = false
        toast.error(getErrorString(payload, 'Error getting report.'))
      })
  },
})

export default reportsSlice.reducer
