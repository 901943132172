import { Modal } from '@components'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { ReactNode } from 'react'
import tw from 'tailwind-styled-components'

export const ImageModal = ({
  isVisible,
  setVisible,
  children,
}: {
  isVisible: boolean
  setVisible: (value: boolean) => void
  children: ReactNode
}) => (
  <Modal isVisible={isVisible} setVisible={setVisible} showCloseIcon={false}>
    <div className='relative'>
      <CloseIconContainer onClick={() => setVisible(false)}>
        <XMarkIcon className='text-brand-dark' />
      </CloseIconContainer>
      {children}
    </div>
  </Modal>
)

const CloseIconContainer = tw.div`
  absolute
  bg-white
  rounded-lg
  right-4
  top-4
  w-7
  h-7
  flex
  items-center
  justify-center
  hover:opacity-80
  transition-all
  cursor-pointer
`
