import { TextInput } from '@components'
import { debounce } from 'lodash-es'
import { useCallback, useEffect, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import tw from 'tailwind-styled-components'

import { useAppSelector, useAppThunkDispatch } from '../../app/hooks'
import { getCustomerCompanyList } from '../../redux/userSlice'
import { CustomerCompany } from './components'

const SHOULD_ALLOW_FILTER_AFTER = 5

export const CustomerCompanySearch = ({
  authenticate,
  popover,
}: {
  authenticate?: boolean
  popover?: boolean
}) => {
  const dispatch = useAppThunkDispatch()
  const companies = useAppSelector(state => state.user.customerCompanies)
  const count = useAppSelector(state => state.user.customerCompaniesCount)
  const loading = useAppSelector(state => state.user.loading.customerCompanyList)
  const [query, setQuery] = useState('')
  const debouncedGetCustomerCompanyList = useCallback(
    debounce((query: string) => dispatch(getCustomerCompanyList(query)), 100),
    [],
  )

  useEffect(() => {
    debouncedGetCustomerCompanyList(query)
  }, [query])

  const filteringEnabled = count > SHOULD_ALLOW_FILTER_AFTER

  return (
    <>
      {filteringEnabled && (
        <TextInput
          sm
          className='mb-3'
          placeholder='Search your companies'
          value={query}
          onChange={setQuery}
        />
      )}
      <CompaniesContainer $popover={popover}>
        {loading && !count ? (
          <Skeleton className='h-12 mb-3' count={2} />
        ) : companies.length ? (
          companies.map(company => (
            <CustomerCompany
              key={company.id}
              authenticate={authenticate}
              company={company}
              popover={popover}
            />
          ))
        ) : (
          !!count &&
          filteringEnabled && (
            <div className='text-center text-xs text-gray-500 italic'> Not found </div>
          )
        )}
      </CompaniesContainer>
    </>
  )
}

const CompaniesContainer = tw.div<{ $popover?: boolean }>`
  flex
  flex-col
  h-36
  overflow-y-auto
  ${({ $popover }) => !$popover && `gap-2`}
`
