import { ReactNode } from 'react'
import Tooltip from 'react-tooltip-lite'

type TooltipProps = {
  children: ReactNode
  content: ReactNode
  className?: string
  direction?: 'up' | 'down' | 'left' | 'right'
}

const TooltipContainer = ({ children, content, className, direction }: TooltipProps) => (
  // @ts-ignore
  <Tooltip
    className={className}
    content={content}
    direction={direction}
    {...(!content && { isOpen: false })}
  >
    {children}
  </Tooltip>
)

export { TooltipContainer as Tooltip }
