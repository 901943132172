import { Status } from '@components'
import { noop } from 'lodash-es'

import { useAppThunkDispatch } from '../../../app/hooks'
import { getProjectStatusBackground } from '../../../common/utils'
import { updateProjectStatus } from '../../../redux/projectsSlice'

export const ProjectStatus = ({
  id,
  status,
  className,
  showUpdateText = true,
  callback = () => {},
}: {
  id?: number
  status: string
  className?: string
  showUpdateText?: boolean
  callback?: () => void
}) => {
  const dispatch = useAppThunkDispatch()

  const updateStatus = (status: string) => {
    dispatch(updateProjectStatus({ id, status }))
      .then(() => callback())
      .catch(noop)
  }

  const statusOptions = [
    { label: 'Draft', onClick: () => updateStatus('Draft') },
    { label: 'Active', onClick: () => updateStatus('ACTIVE') },
    { label: 'Completed', onClick: () => updateStatus('COMPLETED') },
  ]

  return (
    <Status
      background={getProjectStatusBackground(status)}
      className={className}
      dropdownOptions={statusOptions}
      labelClassName='w-[103px]'
      showUpdateText={showUpdateText}
      status={status}
    />
  )
}
