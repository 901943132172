import { CatchError } from '@common'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { api } from '../api/api'
import { ExternalTrackingData } from '../common/types'
import { keysToCamelCase } from '../common/utils'

type ExternalTrackingState = {
  loading: {
    trackingData: boolean
  }
  getTrackingDataError: string
  trackingData: ExternalTrackingData
}

const initialState: ExternalTrackingState = {
  loading: {
    trackingData: true,
  },
  getTrackingDataError: '',
  trackingData: {
    loadId: 0,
    origin: {
      city: '',
      latitude: 0,
      longitude: 0,
    },
    destination: {
      city: '',
      latitude: 0,
      longitude: 0,
    },
    events: [],
    companyName: '',
    companyLogo: '',
    isManaged: false,
  },
}

type ErrorPayload = {
  status?: number
}

export const getExternalTrackingData = createAsyncThunk<
  ExternalTrackingData,
  string,
  { rejectValue: ErrorPayload }
>(
  'externalTracking/getExternalTrackingData',
  async (trackingNumber: string, { rejectWithValue }) => {
    try {
      const response = await api.get(`loads/api/external-tracking-data/${trackingNumber}/`)
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue({ status: err.response?.status })
    }
  },
)

const externalTrackingSlice = createSlice({
  name: 'externalTracking',
  initialState,
  reducers: {
    setTrackingData(state, { payload }) {
      state.trackingData = payload
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getExternalTrackingData.pending, state => {
        state.loading.trackingData = true
      })
      .addCase(getExternalTrackingData.fulfilled, (state, { payload }) => {
        state.loading.trackingData = false
        state.trackingData = payload
      })
      .addCase(getExternalTrackingData.rejected, (state, { payload }) => {
        state.loading.trackingData = false
        const errorPayload = payload as ErrorPayload
        state.getTrackingDataError =
          errorPayload?.status === 404
            ? 'Invalid tracking number.'
            : 'An error occurred. Try to refresh the page.'
      })
  },
})

export const { setTrackingData } = externalTrackingSlice.actions

export default externalTrackingSlice.reducer
