import { LoaderSpinner } from '@components'
import { Popover } from '@headlessui/react'
import tw from 'tailwind-styled-components'

import { broadcastLogin } from '../../../../../components/src/common/utils'
import { useAppSelector, useAppThunkDispatch } from '../../../app/hooks'
import { getUserToken } from '../../../redux/userSlice'

export const CustomerCompany = ({
  company,
  authenticate = false,
  popover = false,
}: {
  company: { id: number; name: string }
  authenticate?: boolean
  popover?: boolean
}) => {
  const dispatch = useAppThunkDispatch()
  const loading = useAppSelector(state => state.user.loading.getUserToken)
  const companyId = useAppSelector(state => state.user.companyId)

  const selectCompany = async () => {
    if (companyId === company.id) return
    const response = await dispatch(getUserToken(company.id))
    if (response.meta.requestStatus === 'rejected' || !authenticate) return
    broadcastLogin()
  }

  return (
    <CompanyContainer
      $highlighted={companyId === company.id}
      $popover={popover}
      onClick={() => selectCompany()}
      {...(popover && { $as: Popover.Button })}
    >
      <div className='flex items-center'>
        <div className='font-semibold text-left'>{company.name}</div>
      </div>
      {loading === company.id && <LoaderSpinner className='my-0' size={4} />}
    </CompanyContainer>
  )
}

const CompanyContainer = tw.div<{ $popover?: boolean; $highlighted: boolean }>`
  rounded-lg
  p-3
  w-full
  flex
  text-sm
  cursor-pointer
  hover:bg-lighter-blue
  transition-all
  justify-between
  h-14
  items-center
  ${({ $popover }) => !$popover && `border border-light-gray`}
  ${({ $highlighted }) => $highlighted && 'bg-light-blue'}
`
