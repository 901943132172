import styled, { css, keyframes } from 'styled-components'
import tw from 'tailwind-styled-components'

export type CounterProps = {
  count: number
  isLoading?: boolean
  className?: string
}

export const Counter = ({ count, isLoading, className = '' }: CounterProps) => (
  <TWCounter $isLoading={isLoading} $isMoreThanOneDigit={count > 99} className={className}>
    {!isLoading && count}
  </TWCounter>
)

const grayFlash = keyframes`
  from {
    background-color: gray;
  }
  to {
    background-color: lightgray;
  }
`

const StyledCounter = styled.div<{ $isLoading?: boolean }>`
  ${({ $isLoading }) =>
    $isLoading &&
    css`
      animation-name: ${grayFlash};
      animation-duration: 1s;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
    `}
`

const TWCounter = tw(StyledCounter)<{ $isMoreThanOneDigit?: boolean }>`
    flex
    items-center
    justify-center
    rounded-full
    bg-gray-300
    text-[0.7rem]
    h-5
    min-w-[1.25rem]
    max-w-fit

    ${({ $isMoreThanOneDigit }) => $isMoreThanOneDigit && 'px-[0.3rem]'}
`
