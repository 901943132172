import { Menu } from '@headlessui/react'
import { ChevronDownIcon, DocumentIcon } from '@heroicons/react/24/solid'
import tw from 'tailwind-styled-components'

import { useAppSelector, useAppThunkDispatch } from '../../../app/hooks'
import loader from '../../../assets/images/loading-buffering.gif'
import { downloadQuotes } from '../../../redux/quotingToolSlice'
import { EaseInOutTransition } from '../../EaseInOutTransition'

export const ExportQuotesButton = ({ isTemplate }: { isTemplate?: boolean }) => {
  const dispatch = useAppThunkDispatch()

  const loading = useAppSelector(state => state.quotingTool.loading.saveCSV)

  const downloadTemplate = () => dispatch(downloadQuotes({ isTemplate: true }))

  return (
    <div className='z-[9] flex items-center max-lg:hidden'>
      <MenuContainer as='div'>
        {isTemplate ? (
          <MainButton>
            <ButtonText className='border-none px-0' onClick={downloadTemplate}>
              Download Multi-Lane CSV Template
            </ButtonText>
            {loading && <img alt='loading' className='w-4 h-4 mr-2' src={loader} />}
          </MainButton>
        ) : (
          <>
            <MainButton>
              <ButtonText onClick={() => dispatch(downloadQuotes({}))}>
                <DocumentIcon className='w-4' />
                <div className='ml-2'>Export Quote(s)</div>
              </ButtonText>
              {loading ? (
                <div className='flex items-center justify-center px-[11.6px]'>
                  <img alt='loading' className='w-4 h-3.5' src={loader} />
                </div>
              ) : (
                <Menu.Item>
                  <Menu.Button className='py-2 px-2.5'>
                    <Chevron />
                  </Menu.Button>
                </Menu.Item>
              )}
            </MainButton>
            <EaseInOutTransition>
              <Dropdown>
                <Menu.Item>
                  <Option onClick={downloadTemplate}>Download Multi-Lane CSV Template</Option>
                </Menu.Item>
              </Dropdown>
            </EaseInOutTransition>
          </>
        )}
      </MenuContainer>
    </div>
  )
}

const ButtonText = tw.div`
  p-2
  flex
  items-center
  justify-center
  hover:opacity-80
  transition-all
  cursor-pointer
  border-r
  border-brand-dark
  w-full
`

const Chevron = tw(ChevronDownIcon)`
  w-4
  hover:opacity-80
  cursor-pointer
  transition-all
`

const Option = tw(Menu.Button)`
  px-2
  mt-1
  hover:opacity-80
  transition-all
  cursor-pointer
  font-semibold
`

const MainButton = tw.div`
  border
  border-brand-dark
  rounded-md
  flex
  justify-between
  items-center
  font-semibold
  whitespace-nowrap
  min-w-[272px]
`

const Dropdown = tw(Menu.Items)`
  rounded-b-md
  focus:outline-none
  bg-white
  left-auto
  bottom-auto
  absolute
  -mt-2
  border-b
  border-l
  border-r
  border-brand-dark
  right-0
  py-1
  z-[9]
  w-full
`

const MenuContainer = tw(Menu)`
  relative
  inline-block
  text-left
  bg-white
  rounded-md
`
