import { formatFileName } from '@common'
import { getConfirmation, Link } from '@components'
import { DocumentTextIcon } from '@heroicons/react/20/solid'
import { TrashIcon } from '@heroicons/react/24/outline'
import moment from 'moment'
import tw from 'tailwind-styled-components'

import { useAppThunkDispatch } from '../../../app/hooks'
import { LoadDocument } from '../../../common/types'
import { deleteDocument } from '../../../redux/loadsSlice'

export const Document = ({ document }: { document: LoadDocument }) => {
  const dispatch = useAppThunkDispatch()

  const handleDeleteDocument = async () => {
    const response = await getConfirmation({
      message: 'Are you sure you want to delete this document?',
      type: 'danger',
    })
    if (response) dispatch(deleteDocument({ id: document.id }))
  }

  return (
    <Container>
      <div className='flex justify-between'>
        <div className='text-dark-gray'>
          <div>
            {moment(document.timeStamp).format('MMM DD hh:mm A')}{' '}
            {document.uploadedBy && `∙ ${document.uploadedBy}`}
          </div>
          <div className='mt-2'>Type: {document.documentTypeDisplay}</div>
        </div>
        {!document.isOrderDocument && (
          <TrashIcon
            className='icon min-w-5 w-5 h-5 text-error ml-2'
            onClick={handleDeleteDocument}
          />
        )}
      </div>
      <DocumentLink to={document.file}>
        <DocumentTextIcon className='w-5' /> {formatFileName(document.fileName)}
      </DocumentLink>
    </Container>
  )
}

const Container = tw.div`
  mb-3
  last:mb-0
  border
  border-light-gray
  rounded-lg
  p-3
`

const DocumentLink = tw(Link)`
  text-link
  flex gap-2
  items-center
  font-semibold
  mt-2
  w-fit
`
