import { Button } from '@components'
import { Popover } from '@headlessui/react'
import tw from 'tailwind-styled-components'

import { useAppSelector } from '../../app/hooks'
import { CustomerCompanySearch } from '../Login/CustomerCompanySearch'

export const CustomerCompanyPicker = () => {
  const user = useAppSelector(state => state.user.user)
  const customerCompaniesCount = useAppSelector(state => state.user.customerCompaniesCount)
  const tokenLoading = useAppSelector(state => state.user.loading.getUserToken)
  const userLoading = useAppSelector(state => state.user.loading.getUser)

  if (customerCompaniesCount < 2) return null

  return (
    <PickerPopover>
      <Popover.Button>
        <Button
          lg
          className='flex text-xs'
          innerClassName='max-lg:px-3'
          loading={!!tokenLoading || userLoading}
          postIcon='arrow-right-left'
        >
          <CompanyName>{user?.companyName}</CompanyName>
        </Button>
      </Popover.Button>
      <PanelContainer>
        <CustomerCompanySearch popover />
      </PanelContainer>
    </PickerPopover>
  )
}

const CompanyName = tw.div`
  max-lg:max-w-[25vw]
  max-lg:overflow-ellipsis
  max-lg:truncate
`

const PickerPopover = tw(Popover)`
  relative
`
const PanelContainer = tw(Popover.Panel)`
  absolute
  top-[calc(100%+10px)]
  left-auto
  right-0
  flex-col
  bg-white
  rounded-lg
  shadow-lg
  border
  border-light-gray
  overflow-hidden
  p-3
  w-[250px]
  z-[99]
`
