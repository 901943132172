import { AppLogoImage } from '@components'
import tw from 'tailwind-styled-components'

import { Rate } from '../../../common/types'
import { displayDate, formatCurrency } from '../../../common/utils'
import { HalfWidthDetailItem } from '../../DetailItem'

export const CurrentQuoteInfo = ({ quote, className }: { quote: Rate; className?: string }) => (
  <Box className={className}>
    <AppLogoImage className='h-8 mb-4' />
    <Grid>
      <HalfWidthDetailItem label='Quote ID'>{quote.id}</HalfWidthDetailItem>
      <HalfWidthDetailItem label='Pickup Date'>{displayDate(quote.pickupDate)}</HalfWidthDetailItem>
      <HalfWidthDetailItem label='Equipment'>{quote.equipmentType}</HalfWidthDetailItem>
      <HalfWidthDetailItem label='Origin'>{quote.origin}</HalfWidthDetailItem>
      <HalfWidthDetailItem label='Destination'>{quote.destination}</HalfWidthDetailItem>
      <HalfWidthDetailItem label='Miles'>{quote.miles}</HalfWidthDetailItem>
    </Grid>
    <BoxFooter>
      <div className='text-link font-bold'>
        Total Amount <span className='text-base'>{formatCurrency(quote.price)}</span>
        <span className='text-lg font-medium'> / </span>RPM{' '}
        <span className='text-base'>{formatCurrency(Number(quote.price) / quote.miles)}</span>
      </div>
    </BoxFooter>
  </Box>
)

const Box = tw.div`
  bg-white
  border
  border-border-gray
  px-4
  py-3
  rounded-lg
  mt-8
`

const BoxFooter = tw.div`
  flex
  justify-center
  md:justify-end
  items-center
  pt-4
`

const Grid = tw.div`
  grid
  grid-cols-3
  gap-4
  border-b
  border-border-gray
  pb-4
`
