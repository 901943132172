import logoIconBlue from '../../assets/images/logo-icon-blue.svg'
import logoIconWhite from '../../assets/images/logo-icon-white.svg'
import logoNameBlue from '../../assets/images/logo-name-blue.svg'
import logoNameWhite from '../../assets/images/logo-name-white.svg'

/**
 * Renders the application logo image component with the specified color and variant.
 *
 * @param className - The CSS class names for the image.
 * @param onClick - The click event handler for the image (optional).
 * @param color - The color of the logo image, either 'blue' or 'white' (default is 'blue').
 * @param variant - The variant of the logo image, either 'icon' or 'name' (default is 'name').
 * @returns The rendered logo image component.
 */
export const AppLogoImage = ({
  className,
  onClick,
  color = 'blue',
  variant = 'name',
}: {
  className?: string
  onClick?: () => void
  color?: 'blue' | 'white'
  variant?: 'icon' | 'name'
}) => (
  <img
    alt='Pulse logo'
    className={className}
    src={
      variant === 'icon'
        ? color === 'blue'
          ? logoIconBlue
          : logoIconWhite
        : color === 'blue'
          ? logoNameBlue
          : logoNameWhite
    }
    onClick={onClick}
  />
)
