import { formatCountAndPluralize } from '@common'
import { Tooltip } from '@components'
import { ArrowLongRightIcon } from '@heroicons/react/24/outline'
import tw from 'tailwind-styled-components'

import { QuoteLocation, QuotingLocation } from '../../../common/types'

type QuotingRoute = {
  origin: QuoteLocation | QuotingLocation | null
  destination: QuoteLocation | QuotingLocation | null
  stops?: (QuoteLocation | QuotingLocation | null)[]
}

export const StopsCounter = ({
  stops = [],
}: {
  stops: (QuoteLocation | QuotingLocation | null)[]
}) => (
  <Tooltip
    content={
      <div className='leading-6'>
        {stops?.map((stop, i) => (
          <div key={i} className='whitespace-nowrap'>
            Stop {i + 1}: {stop?.city}, {stop?.state}
          </div>
        ))}
      </div>
    }
  >
    <div className='flex items-center whitespace-nowrap'>
      <Container>{formatCountAndPluralize(stops?.length, 'stop')}</Container>
      <span className='px-2'>→</span>
    </div>
  </Tooltip>
)

export const QuoteRoute = ({ quote }: { quote: QuotingRoute }) => (
  <div className='flex items-center whitespace-nowrap'>
    {quote?.origin?.city}, {quote?.origin?.state}
    <span className='px-2'>
      <ArrowLongRightIcon className='w-4 min-w-4' />
    </span>
    {!!quote.stops?.length && <StopsCounter stops={quote.stops} />}
    {quote?.destination?.city}, {quote?.destination?.state}
  </div>
)

const Container = tw.div`
  bg-brand-accent
  rounded-full
  px-3
  py-0.5
  font-semibold
`
