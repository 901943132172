import { Button, DragAndDropFileInput, TextInput } from '@components'
import { TrashIcon } from '@heroicons/react/24/outline'
import { DocumentIcon } from '@heroicons/react/24/solid'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import tw from 'tailwind-styled-components'

import { useAppSelector, useAppThunkDispatch } from '../../../app/hooks'
import { trackEvent } from '../../../common/tracking'
import { CustomerQuote } from '../../../common/types'
import { randomString } from '../../../common/utils'
import {
  createLoad,
  RateBookingDetails,
  reset,
  setRateBookingDetails,
} from '../../../redux/createNewLoadSlice'

export const LoadCreationWithPDF = ({ quote }: { quote: CustomerQuote }) => {
  const navigate = useNavigate()

  const dispatch = useAppThunkDispatch()

  const rateBookingDetails = useAppSelector(state => state.createNewLoad.rateBookingDetails)
  const createLoadLoading = useAppSelector(state => state.createNewLoad.loading.createLoad)

  const documents = rateBookingDetails.documents
  const refId = rateBookingDetails.refId

  const setData = (newData: Partial<RateBookingDetails>) =>
    dispatch(setRateBookingDetails({ ...rateBookingDetails, ...newData }))

  useEffect(() => {
    trackEvent('Quotes - Saw Book Quote With PDF Modal', {
      quoteId: quote?.id,
    })
  }, [])

  const handleCreateLoad = async () => {
    trackEvent('Quotes - Quote Booked using PDF', { quoteId: quote?.id })

    const response = await dispatch(createLoad({ emptyLoad: true }))
    if (response.meta.requestStatus !== 'fulfilled') return
    navigate(`/loads/${response.payload}`)
    dispatch(reset())
  }

  return (
    <div className='p-4 lg:p-10'>
      <div className='font-bold text-base max-lg:w-11/12'>
        Attach at least one load document (e.g. BOL or Purchase Order)
      </div>
      <DocumentsContainer>
        <div className='w-1/2'>
          <DragAndDropFileInput
            isBase64
            className='w-full mt-0'
            fileTypes={['csv', 'dot', 'pdf']}
            header='Upload Load Document(s)'
            helpText='Available formats csv, dot, pdf'
            setBase64={(file: { base64: string; name: string }) => {
              setData({
                documents: [
                  ...documents,
                  { file: file.base64, name: file.name, id: randomString() },
                ],
              })
            }}
          />
        </div>
        <DocumentsList $length={documents.length}>
          {!documents.length && (
            <div className='text-dark-gray'>Selected files will be displayed here</div>
          )}
          {documents.map(document => (
            <Document key={document.id}>
              <div className='flex items-center'>
                <DocumentIcon className='w-5 mr-3' />
                <div className='font-semibold'>{document.name}</div>
              </div>
              <DeleteIcon
                onClick={() =>
                  setData({ documents: documents.filter(doc => doc.id !== document.id) })
                }
              />
            </Document>
          ))}
        </DocumentsList>
      </DocumentsContainer>
      <div className='font-bold text-base mt-6'>Your Reference ID for this load (optional)</div>
      <TextInput sm className='mt-3' value={refId} onChange={refId => setData({ refId })} />
      <div className='flex gap-4 mt-6'>
        <Button
          fullWidth
          className='w-full'
          type='secondary'
          onClick={() => setData({ modalStep: 0 })}
        >
          Go Back
        </Button>
        <Button
          fullWidth
          className='w-full'
          disabled={!documents.length}
          loading={createLoadLoading}
          type='success'
          onClick={handleCreateLoad}
        >
          Book
        </Button>
      </div>
    </div>
  )
}

const DocumentsList = tw.div<{ $length: number }>`
  w-1/2
  ${({ $length }) => !$length && 'flex items-center justify-center'}
`

const Document = tw.div`
  flex
  justify-between
  items-center
  border-b
  border-border-gray
  last:border-none
  p-3
`

const DeleteIcon = tw(TrashIcon)`
  w-5
  text-error
  hover:opacity-80
  transition-all
  cursor-pointer
  stroke-2
`

const DocumentsContainer = tw.div`
  flex
  justify-between
  mt-3
  w-full
  gap-4
`
