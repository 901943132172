export const loadFactoringStatuses = ['PENDING', 'APPROVED', 'REJECTED'] as const
export type LoadFactoringStatus = (typeof loadFactoringStatuses)[number]

export type LoadFactoringRequestForStatusComputation = {
  status: LoadFactoringStatus
  isPaidClosed?: boolean
  archived: boolean
  isFunded?: boolean
  isHold?: boolean
  holdReason?: string
  holdNote?: string
  holdPutBy?: string
  holdPutAt?: string
  rejectionReason?: string
  rejectionNote?: string
  rejectedBy?: string
  rejectedAt?: string
}

export enum LoadStatusDisplay {
  PLAN = 'Plan',
  NEEDS_APPT = 'Needs Appt',
  AVAILABLE = 'Available',
  BOOKED = 'Booked',
  CONFIRMED = 'Confirmed',
  PICKUP_ON_TRACK = 'Pickup On Track',
  PICKUP_OFF_TRACK = 'Pickup Off Track',
  LOADING = 'Loading',
  PICKED = 'Picked',
  DELIVERY_ON_TRACK = 'Delivery On Track',
  DELIVERY_OFF_TRACK = 'Delivery Off Track',
  UNLOADING = 'Unloading',
  DELIVERED = 'Delivered',
  READY_TO_BILL = 'Ready To Bill',
  CLAIM = 'Claim',
  DRAFT = 'Draft',
  QUOTED = 'Quoted',
  BILLING_IN_PROGRESS = 'Billing In Progress',
  COMPLETED = 'Completed',
  APPT_REQUESTED = 'Appt Requested',
  MARTERIAL_NOT_READY = 'Material Not Ready',
  ON_TRACK = 'On Track',
  OFF_TRACK = 'Off Track',
  TENDER_ACCEPTED = 'Tender Accepted',
}
