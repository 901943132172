import {
  CatchError,
  formatAxiosErrorToPayload,
  formatDateFilterForBackend,
  formatDateForBackend,
  getErrorString,
} from '@common'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { isEmpty } from 'lodash'
import { toast } from 'react-toastify'

import { api } from '../api/api'
import { RootState } from '../app/store'
import { initialFilters, initialSearchParams } from '../common/constants'
import {
  ManualQuoteFile,
  MultiStopQuotingParams,
  QuoteItem,
  QuotesSearchData,
} from '../common/types'
import { getStringFromArray, keysToCamelCase, keysToSnakeCase } from '../common/utils'

type QuotesState = {
  limit: {
    allQuotes: number
    uploads: number
  }
  offset: {
    allQuotes: number
    uploads: number
  }
  filters: {
    allQuotes: any
    uploads: any
  }
  count: {
    allQuotes: number
    uploads: number
  }
  loading: {
    allQuotes: boolean
    uploads: boolean
    deleteUploadOrQuote: boolean
    uploadsList: boolean
    saveCSV: boolean
    renameUpload: boolean
    contacts: boolean
    currentQuote: boolean
  }
  allQuotes: QuoteItem[]
  uploads: Array<any>
  searchData: QuotesSearchData
  searchParams: MultiStopQuotingParams
  uploadsList: Array<any>
  contacts: Array<any>
  currentQuote: QuoteItem | null
}

const initialState: QuotesState = {
  limit: {
    allQuotes: 50,
    uploads: 50,
  },
  offset: {
    allQuotes: 0,
    uploads: 0,
  },
  filters: {
    allQuotes: initialFilters,
    uploads: initialFilters,
  },
  count: {
    allQuotes: 0,
    uploads: 0,
  },
  loading: {
    allQuotes: false,
    uploads: false,
    deleteUploadOrQuote: false,
    uploadsList: false,
    saveCSV: false,
    renameUpload: false,
    contacts: false,
    currentQuote: false,
  },
  allQuotes: [],
  uploads: [],
  searchData: {
    equipmentType: '',
    origin: null,
    destination: null,
    dates: [null, null],
    pickupDate: null,
    refId: '',
  },
  searchParams: initialSearchParams,
  uploadsList: [],
  contacts: [],
  currentQuote: null,
}

const formatFileName = (file = '') => file.split('.').slice(1).join('.')

export const getUploads = createAsyncThunk(
  'quotingTool/getUploads',
  async (_, { getState, rejectWithValue }) => {
    const {
      filters: { uploads: filters },
      limit: { uploads: limit },
      offset: { uploads: offset },
    } = (getState() as RootState).quotingTool

    try {
      const response = await api.get('/pricing/get-uploaded-files/', {
        params: {
          limit,
          offset,
          ordering: '-created_at',
          id__in: getStringFromArray(filters.uploadFile) || null,
          created_at_after: formatDateForBackend(filters.dateQuoted[0]),
          created_at_before: formatDateForBackend(filters.dateQuoted[1]),
          submitted_by: filters.user?.userId || null,
        },
      })
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const getUploadsList = createAsyncThunk(
  'quotingTool/getUploadsList',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get('/pricing/get-uploaded-files/', {
        params: {
          limit: 500,
          offset: 0,
          ordering: '-created_at',
        },
      })
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const getContacts = createAsyncThunk(
  'quotingTool/getContacts',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get('/accounts/list-customer-contacts-external/', {
        params: {
          limit: 500,
        },
      })
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const deleteUploadOrQuote = createAsyncThunk(
  'quotingTool/deleteUploadOrQuote',
  async (payload: any, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.delete('/pricing/delete-shipper-portal-quotes/', {
        data: keysToSnakeCase(payload),
      })
      dispatch(getUploads())
      dispatch(getAllQuotes())
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const renameUpload = createAsyncThunk(
  'quotingTool/renameUpload',
  async ({ id, name }: { id: number; name: string }, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.post('/pricing/rename-file/', {
        batch_pricing_upload_id: id,
        filename: name,
      })
      dispatch(getUploads())
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const getAllQuotes = createAsyncThunk(
  'quotingTool/getAllQuotes',
  async (_, { getState, rejectWithValue }) => {
    const {
      filters: { allQuotes: filters },
      limit: { allQuotes: limit },
      offset: { allQuotes: offset },
    } = (getState() as RootState).quotingTool

    try {
      const response = await api.get('/pricing/get-shipper-portal-quotes/', {
        params: {
          limit,
          offset,
          ordering: '-created_at',
          batch_pricing_upload__in: getStringFromArray(filters.uploadFile) || null,
          ...formatDateFilterForBackend(filters.dateRanges, 'load_pickup_date'),
          equipment_type__iexact: filters.quoteEquipmentType || null,
          created_at__lte: formatDateForBackend(filters.dateQuoted[1]) || null,
          created_at__gte: formatDateForBackend(filters.dateQuoted[0]) || null,
          origin_city: filters.originCity || null,
          origin_state: filters.originState || null,
          destination_city: filters.destinationCity || null,
          destination_state: filters.destinationState || null,
          id: filters.quoteId || null,
          created_by: filters.user?.userId || null,
          customer_reference_id__iexact: filters.refId || null,
          ...(!isEmpty(filters.show) && { booked: filters.show }),
        },
      })
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const getQuoteDetails = createAsyncThunk(
  'quotingTool/getQuoteDetails',
  async (id: string | number, { rejectWithValue }) => {
    try {
      const response = await api.get(`/pricing/get-shipper-portal-quotes/${id}/`)
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const downloadQuotes = createAsyncThunk(
  'quotingTool/downloadQuotes',
  async (
    { id = undefined, isTemplate }: { id?: number; isTemplate?: boolean },
    { getState, rejectWithValue },
  ) => {
    const {
      filters: { allQuotes: filters },
      limit: { allQuotes: limit },
      offset: { allQuotes: offset },
    } = (getState() as RootState).quotingTool

    let params

    if (id) params = { quote_id: id }
    else
      params = {
        limit,
        offset,
        batch_pricing_upload__in: getStringFromArray(filters.uploadFile) || null,
        load_pickup_date__lte: formatDateForBackend(filters.dateRanges[0]) || null,
        load_pickup_date__gte: formatDateForBackend(filters.dateRanges[1]) || null,
        equipment_type__iexact: filters.equipmentType || null,
        created_by__lte: formatDateForBackend(filters.dateQuoted[0]) || null,
        created_by__gte: formatDateForBackend(filters.dateQuoted[1]) || null,
        origin_city__icontains: filters.originCity || null,
        origin_state: filters.originState || null,
        destination_city__icontains: filters.destinationCity || null,
        destination_state: filters.destinationState || null,
        id: filters.quoteId || null,
        created_by: filters.user?.userId || null,
        booked:
          (filters.show?.id === 1 && true) ||
          (filters.show?.id === 2 && false) ||
          (!filters.show?.id && null),
      }

    try {
      const response = await api.get('/pricing/get-shipper-portal-quotes/?download', {
        params,
      })
      return keysToCamelCase({ data: response.data, isSingle: id, isTemplate })
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

const quotingToolSlice = createSlice({
  name: 'quotingTool',
  initialState,
  reducers: {
    setQuotesLimit(state, { payload }) {
      state.limit.allQuotes = payload
    },
    setQuotesOffset(state, { payload }) {
      state.offset.allQuotes = payload
    },
    setQuotesFilters(state, { payload }) {
      state.filters.allQuotes = payload
    },
    setUploadsLimit(state, { payload }) {
      state.limit.uploads = payload
    },
    setUploadsOffset(state, { payload }) {
      state.offset.uploads = payload
    },
    setUploadsFilters(state, { payload }) {
      state.filters.uploads = payload
    },
    setSearchData(state, { payload }) {
      state.searchData = payload
    },
    setSearchParams(state, { payload }) {
      state.searchParams = payload
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getUploads.pending, state => {
        state.loading.uploads = true
      })
      .addCase(getUploads.fulfilled, (state, { payload }) => {
        const { count, results } = payload
        state.loading.uploads = false
        state.count.uploads = count
        state.uploads = results.map((quote: any) => ({
          ...quote,
          file: formatFileName(quote.file),
        }))
      })
      .addCase(getUploads.rejected, (state, { payload }) => {
        state.loading.uploads = false
        toast.error(getErrorString(payload, 'Failed to get uploaded files'))
      })
      .addCase(getUploadsList.pending, state => {
        state.loading.uploadsList = true
      })
      .addCase(getUploadsList.fulfilled, (state, { payload }) => {
        const { results } = payload
        state.loading.uploadsList = false
        state.uploadsList = results.map((quote: any) => ({
          ...quote,
          file: formatFileName(quote.file),
        }))
      })
      .addCase(getUploadsList.rejected, state => {
        state.loading.uploadsList = false
      })
      .addCase(getAllQuotes.pending, state => {
        state.loading.allQuotes = true
      })
      .addCase(getAllQuotes.fulfilled, (state, { payload }) => {
        const { count, results } = payload
        state.loading.allQuotes = false
        state.count.allQuotes = count
        state.allQuotes = results
      })
      .addCase(getAllQuotes.rejected, (state, { payload }) => {
        state.loading.allQuotes = false
        toast.error(getErrorString(payload, 'Failed to get quotes'))
      })
      .addCase(deleteUploadOrQuote.pending, state => {
        state.loading.deleteUploadOrQuote = true
      })
      .addCase(deleteUploadOrQuote.fulfilled, state => {
        state.loading.deleteUploadOrQuote = false
        toast.success('Successfully deleted file')
      })
      .addCase(deleteUploadOrQuote.rejected, (state, { payload }) => {
        state.loading.deleteUploadOrQuote = false
        toast.error(getErrorString(payload, 'Failed to delete file'))
      })
      .addCase(downloadQuotes.pending, state => {
        state.loading.saveCSV = true
      })
      .addCase(downloadQuotes.fulfilled, (state, { payload }) => {
        const { data, isSingle, isTemplate } = payload
        state.loading.saveCSV = false
        const fileName = data.split(',')?.[22]
        const template =
          'Pickup Date Start,Pickup Date End,Origin City,Origin State,Origin Postal Code,Destination City,Destination State,Destination Postal Code,Equipment Type,Reference ID\n' +
          '2023-01-05,2023-01-06,Detroit,MI,48226,Royal Oak,MI,48067,Flatbed,'
        const hiddenElement = document.createElement('a')
        hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURI(
          isTemplate ? template : data,
        )}`
        hiddenElement.target = '_blank'
        hiddenElement.download = isTemplate
          ? 'template.csv'
          : isSingle
            ? fileName || 'quote.csv'
            : 'all_quotes.csv'
        hiddenElement.click()
        !isTemplate && toast.success(`Successfully exported ${isSingle ? 'quote' : 'quotes'}`)
      })
      .addCase(downloadQuotes.rejected, (state, { payload }) => {
        state.loading.saveCSV = false
        toast.error(getErrorString(payload, 'Failed to export quotes'))
      })
      .addCase(renameUpload.pending, state => {
        state.loading.renameUpload = true
      })
      .addCase(renameUpload.fulfilled, state => {
        state.loading.renameUpload = false
        toast.success('Successfully renamed upload')
      })
      .addCase(renameUpload.rejected, (state, { payload }) => {
        state.loading.renameUpload = false
        toast.error(getErrorString(payload, 'Failed to rename upload'))
      })
      .addCase(getContacts.pending, state => {
        state.loading.contacts = true
      })
      .addCase(getContacts.fulfilled, (state, { payload }) => {
        state.loading.contacts = false
        state.contacts = payload.results
      })
      .addCase(getContacts.rejected, (state, { payload }) => {
        state.loading.contacts = false
        toast.error(getErrorString(payload, 'Failed to get contacts'))
      })
      .addCase(getQuoteDetails.pending, state => {
        state.loading.currentQuote = true
      })
      .addCase(getQuoteDetails.fulfilled, (state, { payload }) => {
        const formatFiles = (type: 'SPEC' | 'COMMODITY') => {
          const files = payload.manualQuote?.files
            .filter((file: ManualQuoteFile) => file.fileType === type)
            .map((file: ManualQuoteFile) => ({ ...file, name: file.fileName }))

          if (type === 'SPEC') return files?.[0] || null
          return files
        }

        state.loading.currentQuote = false
        state.currentQuote = {
          ...payload,
          item: {
            height: payload.manualQuote?.height,
            weight: payload.manualQuote?.weight,
            quantity: payload.manualQuote?.pieces,
            width: payload.manualQuote?.width,
            length: payload.manualQuote?.length,
            name: payload.manualQuote?.commodity,
          },
          specs: formatFiles('SPEC'),
          commodity: formatFiles('COMMODITY'),
          isInsured: payload.manualQuote?.isInsuredByCustomer,
          loadValue: payload.manualQuote?.insuranceValue,
          notes: payload.manualQuote?.notes,
        }
      })
      .addCase(getQuoteDetails.rejected, (state, { payload }) => {
        state.loading.currentQuote = false
        toast.error(getErrorString(payload, 'Failed to get quote details'))
      })
  },
})

export const {
  setQuotesFilters,
  setQuotesLimit,
  setQuotesOffset,
  setUploadsFilters,
  setUploadsOffset,
  setUploadsLimit,
  setSearchData,
  setSearchParams,
} = quotingToolSlice.actions

export default quotingToolSlice.reducer
