import { Button, TextInput } from '@components'
import { useEffect } from 'react'

import { useAppSelector, useAppThunkDispatch } from '../../../app/hooks'
import { trackEvent } from '../../../common/tracking'
import { CustomerQuote } from '../../../common/types'
import {
  confirmQuoteEDI,
  RateBookingDetails,
  setRateBookingDetails,
} from '../../../redux/createNewLoadSlice'
import { CurrentQuoteInfo } from './CurrentQuoteInfo'

export const EDIModal = ({ quote }: { quote: CustomerQuote }) => {
  const dispatch = useAppThunkDispatch()

  const rateBookingDetails = useAppSelector(state => state.createNewLoad.rateBookingDetails)
  const loading = useAppSelector(state => state.createNewLoad.loading.createLoad)

  const refId = rateBookingDetails.refId

  const setData = (newData: Partial<RateBookingDetails>) =>
    dispatch(setRateBookingDetails({ ...rateBookingDetails, ...newData }))

  useEffect(() => {
    trackEvent('Quotes - Saw Book Quote EDI Modal', {
      quoteId: quote?.id,
    })
  }, [])

  return (
    <div className='p-10'>
      <div className='font-bold text-base'>
        Please confirm your order details and forward this load over EDI at least 4 hours before the
        desired pickup time
      </div>
      <CurrentQuoteInfo
        className='bg-lighter-blue mt-3 mb-8'
        quote={{ ...quote, price: Number(quote.price) }}
      />
      <div className='font-bold text-base mt-6'>Your Reference ID for this load (required)</div>
      <TextInput sm className='mt-3 mb-6' value={refId} onChange={refId => setData({ refId })} />
      <div className='flex justify-between gap-4'>
        <Button
          fullWidth
          className='w-full'
          type='secondary'
          onClick={() => setData({ modalStep: 0 })}
        >
          Go Back
        </Button>
        <Button
          fullWidth
          className='w-full'
          disabled={!refId}
          loading={loading}
          type='success'
          onClick={() => {
            trackEvent('Quotes - Quote booked by EDI', { quoteId: quote?.id })
            dispatch(confirmQuoteEDI())
          }}
        >
          Confirm
        </Button>
      </div>
    </div>
  )
}
