import { ReactNode } from 'react'

import carrier1 from './images/carrier/1.png'
import carrier2 from './images/carrier/2.png'
import carrier3 from './images/carrier/3.png'
import carrier4 from './images/carrier/4.png'
import carrier5 from './images/carrier/5.png'
import facility1 from './images/facility/1.png'
import facility2 from './images/facility/2.png'
import facility3 from './images/facility/3.png'
import item1 from './images/item/1.png'
import item2 from './images/item/2.png'
import item3 from './images/item/3.png'
import lane1 from './images/lane/1.png'
import lane2 from './images/lane/2.png'
import lane3 from './images/lane/3.png'
import lane4 from './images/lane/4.png'
import lane5 from './images/lane/5.png'
import lane6 from './images/lane/6.png'
import lane7 from './images/lane/7.png'
import lane8 from './images/lane/8.png'
import load1 from './images/load/1.png'
import load2 from './images/load/2.png'
import load3 from './images/load/3.png'
import load4 from './images/load/4.png'
import load5 from './images/load/5.png'
import load6 from './images/load/6.png'
import project1 from './images/project/1.png'
import project2 from './images/project/2.png'
import project3 from './images/project/3.png'
import project4 from './images/project/4.png'
import project5 from './images/project/5.png'
import project6 from './images/project/6.png'
import project7 from './images/project/7.png'
import project8 from './images/project/8.png'
import project9 from './images/project/9.png'
import rfp1 from './images/rfp/1.png'
import rfp2 from './images/rfp/2.png'
import rfp3 from './images/rfp/3.png'
import rfp4 from './images/rfp/4.png'
import rfp5 from './images/rfp/5.png'
import rfp6 from './images/rfp/6.png'
import rfp7 from './images/rfp/7.png'
import rfp8 from './images/rfp/8.png'
import rfp9 from './images/rfp/9.png'
import rfp10 from './images/rfp/10.png'
import rfp11 from './images/rfp/11.png'

type TutorialStepContent = {
  title: string
  text: ReactNode
  image: string
  imageClassName?: string
}

type TutorialSteps = {
  [key: string]: TutorialStepContent[]
}

export const tutorialSteps: TutorialSteps = {
  INVITE_CARRIER: [
    {
      title: 'Invite Carrier',
      text: (
        <ol className='list-decimal ml-3'>
          <li>
            Navigate to the <span className='font-semibold'>Carriers</span> tab
          </li>
          <li>
            Click the <span className='font-semibold'>Invite Carrier</span> button
          </li>
        </ol>
      ),
      image: carrier1,
    },
    {
      title: 'Complete Carrier Details',
      text: (
        <div>
          <ol className='list-decimal ml-3'>
            <li>Fill in the required carrier details</li>
            <li>
              Once done, click the <span className='font-semibold'>Invite</span> button to send the
              invitation
            </li>
          </ol>
        </div>
      ),
      image: carrier2,
      imageClassName: 'px-8',
    },
    {
      title: 'Onboarding Email Sent',
      text: (
        <ol className='list-decimal ml-3'>
          <li>The carrier will receive an onboarding email</li>
          <li>
            The carrier needs to complete the onboarding process by following the instructions in
            the email
          </li>
        </ol>
      ),
      image: carrier3,
      imageClassName: 'px-20 pb-16',
    },
    {
      title: 'Carrier Marked as Invited',
      text: (
        <div>
          In Pulse, the carrier will be marked as <span className='font-semibold'>Invited</span>{' '}
          while they complete their onboarding process.
        </div>
      ),
      image: carrier4,
    },
    {
      title: 'Carrier Becomes Active',
      text: (
        <ol className='list-decimal ml-3'>
          <li>
            Once the onboarding process is complete, the system will verify the carrier&apos;s
            MC/DOT number and background check
          </li>
          <li>
            The carrier will then be marked as <span className='font-semibold'>Active</span> and
            will be ready to receive load assignments
          </li>
        </ol>
      ),
      image: carrier5,
    },
  ],
  CREATE_FACILITY: [
    {
      title: 'Create Facility',
      text: (
        <ol className='list-decimal ml-3'>
          <li>
            Navigate to the <span className='font-semibold'>Facility</span> tab
          </li>
          <li>
            Click the <span className='font-semibold'>Create Facility</span> button
          </li>
        </ol>
      ),
      image: facility1,
    },
    {
      title: 'Enter Facility Details',
      text: (
        <ol className='list-decimal ml-3'>
          <li>
            <span className='font-semibold'>Fill in the required fields</span>: Facility Name and
            Address. Optionally, you can add Location Notes, Hours, and Contact Name for any
            additional information carriers may need
          </li>
          <li>
            Once completed, click <span className='font-semibold'>Save</span>
          </li>
        </ol>
      ),
      image: facility2,
      imageClassName: 'px-10',
    },
    {
      title: 'Edit or Archive a Facility',
      text: (
        <ol className='list-decimal ml-3'>
          <li>
            To update or archive a facility, go to the{' '}
            <span className='font-semibold'>Facility Details</span> tab
          </li>
          <li>
            Click the <span className='font-semibold'>Edit</span> or{' '}
            <span className='font-semibold'>Archive</span> icon to make changes or archive the
            facility
          </li>
        </ol>
      ),
      image: facility3,
    },
  ],
  CREATE_ITEM: [
    {
      title: 'Create an Item',
      text: (
        <ol className='list-decimal ml-3'>
          <li>
            Navigate to the <span className='font-semibold'>Items</span> tab
          </li>
          <li>
            Click the <span className='font-semibold'>Create Item</span> button
          </li>
        </ol>
      ),
      image: item1,
    },
    {
      title: 'Enter Item Details',
      text: (
        <div>
          <ol className='list-decimal ml-3 mb-6'>
            <li>
              Input the <span className='font-semibold'>item&apos;s dimensions</span>. Optionally,
              you can upload an image or attach specifications
            </li>
            <li>
              After completing the details, click <span className='font-semibold'>Save</span>
            </li>
          </ol>
          The item will now be added to your item list.
        </div>
      ),
      image: item2,
      imageClassName: 'px-10',
    },
    {
      title: 'Add Items to Load',
      text: (
        <ol className='list-decimal ml-3 mb-6'>
          <li>
            Go to the <span className='font-semibold'>Load Details</span> for the specific load
          </li>
          <li>
            Click <span className='font-semibold'>Add Item</span> under the stop’s details
          </li>
          <li>Search for the item by name, and its dimensions will automatically populate</li>
        </ol>
      ),
      image: item3,
    },
  ],
  CREATE_TENDER_LOAD: [
    {
      title: 'Create & Tender Load',
      text: (
        <ol className='list-decimal ml-3'>
          <li>
            In the left-hand menu, click the <span className='font-semibold'>New Load</span> button
          </li>
          <li>
            Fill out the load details, including the Pick-up at and Drop-off at fields—enter an
            address, or use city, state, and ZIP code details
          </li>
          <li>
            Click the <span className='font-semibold'>Save</span> button
          </li>
        </ol>
      ),
      image: load1,
    },
    {
      title: 'Review Load Details',
      text: (
        <ol className='list-decimal ml-3'>
          <li>
            You&apos;ll be directed to the Load Details page with the load status set to{' '}
            <span className='font-semibold'>Plan</span>
          </li>
          <li>Ensure all addresses are complete, and pick-up/drop-off dates are set</li>
          <li>Follow information banners for guidance on any missing details</li>
        </ol>
      ),
      image: load2,
    },
    {
      title: 'Request Quotes',
      text: (
        <ol className='list-decimal ml-3'>
          <li>
            Once all required information is filled out, navigate to the{' '}
            <span className='font-semibold'>Quote & Tender</span> tab
          </li>
          <li>
            Select the carriers from whom you want to request quotes, either individually or all at
            once
          </li>
          <li>
            Click <span className='font-semibold'>Request Quotes</span>
          </li>
        </ol>
      ),
      image: load3,
    },
    {
      title: 'Carrier Quote Submission',
      text: (
        <ol className='list-decimal ml-3'>
          <li>Carriers will receive an email about your quote request</li>
          <li>
            They will submit their quotes through the{' '}
            <span className='font-semibold'>Carrier Portal</span>
          </li>
          <li>You’ll receive an email with the carrier&apos;s rate once the quote is submitted</li>
        </ol>
      ),
      image: load4,
      imageClassName: 'px-10',
    },
    {
      title: 'Review Rates and Tender Load',
      text: (
        <ol className='list-decimal ml-3'>
          <li>
            Go to the <span className='font-semibold'>Quote & Tender</span> tab to review submitted
            rates
          </li>
          <li>
            Once you select your preferred rate, click the{' '}
            <span className='font-semibold'>More</span> menu and select{' '}
            <span className='font-semibold'>Tender Load</span>
          </li>
        </ol>
      ),
      image: load5,
    },
    {
      title: 'Carrier Approval',
      text: (
        <ol className='list-decimal ml-3'>
          <li>
            After tendering the load, the carrier will receive an email to approve or reject the
            tender
          </li>
          <li>
            If the carrier approves, the load status will be updated to{' '}
            <span className='font-semibold'>Tender Accepted</span>, and the carrier will be assigned
          </li>
        </ol>
      ),
      image: load6,
    },
  ],
  CREATE_PROJECT: [
    {
      title: 'Create Project',
      text: (
        <ol className='list-decimal ml-3'>
          <li>
            Go to the <span className='font-semibold'>Project</span> tab
          </li>
          <li>Fill out the project details</li>
          <li>
            Click <span className='font-semibold'>Create Project</span>
          </li>
        </ol>
      ),
      image: project1,
    },
    {
      title: 'Create Loads',
      text: (
        <ol className='list-decimal ml-3'>
          <li>
            After creating the project, you&apos;ll be taken to the{' '}
            <span className='font-semibold'>Project Details</span> page
          </li>
          <li>
            Click <span className='font-semibold'>Create Loads</span> and fill in the load details
          </li>
          <li>
            Specify how many loads you want to create, then click{' '}
            <span className='font-semibold'>Save</span>
          </li>
        </ol>
      ),
      image: project2,
    },
    {
      title: 'Request Quotes',
      text: (
        <ol className='list-decimal ml-3'>
          <li>
            On the left-hand side, go to <span className='font-semibold'>Request Quotes</span>
          </li>
          <li>Select All Loads or check individual lanes</li>
          <li>
            Click <span className='font-semibold'>Request Quotes</span>
          </li>
        </ol>
      ),
      image: project3,
    },
    {
      title: 'Select Carriers',
      text: (
        <ol className='list-decimal ml-3'>
          <li>Choose the carriers you would like to request quotes from</li>
          <li>
            You can select them individually or click{' '}
            <span className='font-semibold'>Select All</span>
          </li>
          <li>
            Click <span className='font-semibold'>Request Quotes</span>. The project will then be
            marked as <span className='font-semibold'>Active</span>
          </li>
        </ol>
      ),
      image: project4,
    },
    {
      title: 'Carrier Notification',
      text: (
        <ol className='list-decimal ml-3'>
          <li>
            The carriers will receive an email notifying them of the quote request for this project
          </li>
          <li>
            Carriers will submit their quotes through the{' '}
            <span className='font-semibold'>Carrier Portal</span>
          </li>
        </ol>
      ),
      image: project5,
      imageClassName: 'px-10',
    },
    {
      title: 'Receive Quote Notifications',
      text: <div>Once all quotes are received, you will get an email notification.</div>,
      image: project6,
      imageClassName: 'px-10',
    },
    {
      title: 'Monitor Quotes & Assign a Carrier',
      text: (
        <ol className='list-decimal ml-3'>
          <li>
            Track the quotes through the Quotes column, where you can see the number of quotes sent
            and received
          </li>
          <li>Once all quotes are in, you can rate them and assign a carrier for each lane</li>
        </ol>
      ),
      image: project7,
    },
    {
      title: 'Review Rates and Tender Load',
      text: (
        <ol className='list-decimal ml-3'>
          <li>
            Go to the <span className='font-semibold'>Load Details</span> page and open the{' '}
            <span className='font-semibold'>Quote & Tender</span> tab
          </li>
          <li>
            Complete any missing information, then assign the carrier with the preferred rate by
            selecting <span className='font-semibold'>Tender Load</span>
          </li>
        </ol>
      ),
      image: project8,
    },
    {
      title: 'Project Completion',
      text: (
        <div>
          The project will be marked as <span className='font-semibold'>Completed</span> once all
          loads have been assigned and hauled.
        </div>
      ),
      image: project9,
    },
  ],
  CREATE_CONTRACT_LANE: [
    {
      title: 'Create Contract Lane',
      text: (
        <div>
          Go to the <span className='font-semibold'>Contract Lanes</span> tab, fill in the lane
          details, and click <span className='font-semibold'>Create</span>.
        </div>
      ),
      image: lane1,
    },
    {
      title: 'Add Contracted Carriers',
      text: (
        <div>
          To add contracted carriers, go to lane details. On the right-hand side, click{' '}
          <span className='font-semibold'>Add Carrier</span>, select a carrier from your list, enter
          the price, and click <span className='font-semibold'>Save</span>. The carrier is now added
          and ready. You can add as many contracted carriers as needed.
        </div>
      ),
      image: lane2,
    },
    {
      title: 'Review Contracted Carriers',
      text: (
        <div>
          Review all contracted carriers on the right-hand side.{' '}
          <span className='font-semibold'>
            You can reorder them by clicking the up/down arrows.
          </span>{' '}
          The waterfall tender will be sent to Carrier 1 and will proceed to the last carrier on the
          list.
        </div>
      ),
      image: lane3,
    },
    {
      title: 'Link Contract Lane to Load',
      text: (
        <div>
          <span className='font-semibold'>Create a load</span> and enter its details. Our system
          will automatically check for a matching contract lane. If a match is found, you’ll see the{' '}
          <span className='font-semibold'>Link Contract Lane</span> button in the carrier info
          section.
        </div>
      ),
      image: lane4,
    },
    {
      title: 'Select Contract Lane',
      text: (
        <div>
          Click <span className='font-semibold'>Link Contract Lane</span>. A popup will display a
          list of lanes. <span className='font-semibold'>Select a lane</span> from the list.
        </div>
      ),
      image: lane5,
    },
    {
      title: 'Start Waterfalling the Load',
      text: (
        <div>
          After linking the contract lane, begin tendering the load to contracted carriers by
          clicking <span className='font-semibold'>Start Waterfall</span>.
        </div>
      ),
      image: lane6,
    },
    {
      title: 'Monitor Tender Status',
      text: (
        <div>
          Once the waterfall starts, we send a tender to each carrier sequentially. Check the
          waterfall statuses in the carrier info:
          <ul className='list-disc list-inside'>
            <li>
              <span className='font-semibold'>Awaiting Carrier</span>: The carrier has not yet
              approved or rejected the tender.
            </li>
            <li>
              <span className='font-semibold'>Declined</span>: The carrier has rejected the tender.
            </li>
          </ul>
        </div>
      ),
      image: lane7,
    },
    {
      title: 'Confirm Carrier Assignment',
      text: (
        <div>
          When a carrier <span className='font-semibold'>approves the tender</span>, their
          information will replace the dedicated carriers list, and they will be assigned to the
          load. Load status becomes <span className='font-semibold'>Tender Accepted</span>.
        </div>
      ),
      image: lane8,
    },
  ],
  CREATE_RFP: [
    {
      title: 'Create RFP',
      text: (
        <ol className='list-decimal ml-3'>
          <li>
            Go to the <span className='font-semibold'>RFP</span> tab
          </li>
          <li>
            Click <span className='font-semibold'>Create New RFP</span>
          </li>
          <li>Fill in the required RFP details</li>
          <li>
            Once completed, click <span className='font-semibold'>Create</span> to finalize the new
            RFP
          </li>
        </ol>
      ),
      image: rfp1,
    },
    {
      title: 'Create Lanes',
      text: (
        <div>
          After creating the RFP, you&apos;ll be taken to the{' '}
          <span className='font-semibold'>RFP details</span> page.
          <ol className='list-decimal my-6 ml-3'>
            <li>
              Navigate to the <span className='font-semibold'>Lanes</span> tab
            </li>
            <li>
              Click <span className='font-semibold'>Create Lanes</span> and fill out the lane
              details
            </li>
            <li>
              Click <span className='font-semibold'>Create Lane</span> to save it
            </li>
          </ol>
          Repeat this process for as many lanes as your RFP requires, or use the{' '}
          <span className='font-semibold'>Import Lanes</span> option to upload lanes in bulk.
        </div>
      ),
      image: rfp2,
    },
    {
      title: 'Add Carriers',
      text: (
        <ol className='list-decimal ml-3'>
          <li>
            After completing lane creation, navigate to the{' '}
            <span className='font-semibold'>Carriers</span> tab
          </li>
          <li>
            Click <span className='font-semibold'>Add Carrier</span>
          </li>
          <li>
            You can either select <span className='font-semibold'>All Carriers</span> or choose
            individual carriers
          </li>
          <li>
            Once selected, click <span className='font-semibold'>Add</span> to finalize your
            selection
          </li>
        </ol>
      ),
      image: rfp3,
      imageClassName: 'py-10',
    },
    {
      title: 'Status "Not Invited"',
      text: (
        <div>
          After adding carriers, all carriers will have the status{' '}
          <span className='font-semibold'>Not Invited</span>. This indicates that while carriers
          have been added to the RFP,{' '}
          <span className='font-semibold'>the RFP has not yet been published</span>.
        </div>
      ),
      image: rfp4,
    },
    {
      title: 'Publish RFP',
      text: (
        <div>
          Now it&apos;s time to publish your RFP.
          <ol className='list-decimal mt-6 ml-3'>
            <li>
              Go to the top-right corner next to the status and click{' '}
              <span className='font-semibold'>Publish RFP</span>
            </li>
            <li>
              Confirm that you want to publish, and{' '}
              <span className='font-semibold'>your RFP will go live</span>
            </li>
          </ol>
        </div>
      ),
      image: rfp5,
    },
    {
      title: 'Carrier Receives RFP',
      text: (
        <ol className='list-decimal ml-3'>
          <li>
            After you publish the RFP, carriers will receive{' '}
            <span className='font-semibold'>an email notification</span>
          </li>
          <li>
            They will log into the <span className='font-semibold'>Carrier Portal</span> to either
            accept or reject the RFP
          </li>
          <li>
            Once the carrier has entered their rates, they will click{' '}
            <span className='font-semibold'>Submit Rates</span> to complete the process
          </li>
        </ol>
      ),
      image: rfp6,
    },
    {
      title: 'RFP Submission Notification',
      text: (
        <div>
          Once a carrier has entered their rates, you will receive an email notifying you that the
          carrier has completed the RFP submission.
        </div>
      ),
      image: rfp7,
    },
    {
      title: 'Award Carriers',
      text: (
        <div>
          The RFP status will change to <span className='font-semibold'>Bid Evaluation</span> based
          on the deadline you set, indicating that it&apos;s time to award the carriers. You can
          also manually change the status to <span className='font-semibold'>Bid Evaluation</span>{' '}
          to close submissions.
          <ol className='list-decimal mt-6 ml-3'>
            <li>
              After the status is updated to <span className='font-semibold'>Bid Evaluation</span>,
              go to each lane and start awarding carriers as{' '}
              <span className='font-semibold'>Contracted Carriers</span> (Waterfall Carriers)
            </li>
            <li>
              The sequence for the waterfall will be Tendered{' '}
              <span className='font-semibold'>Carrier 1, Carrier 2, Carrier 3,</span> etc. Rearrange
              the carriers by clicking the arrows as needed
            </li>
          </ol>
        </div>
      ),
      image: rfp8,
    },
    {
      title: 'Finalize Awards',
      text: (
        <div>
          After adding carriers to all lanes, go back to the RFP details page and click{' '}
          <span className='font-semibold'>Award Carriers</span> to publish the changes and notify
          the carriers that they have been assigned lanes.
        </div>
      ),
      image: rfp9,
    },
    {
      title: 'Carrier Notification',
      text: (
        <div>
          After you click <span className='font-semibold'>Award Carriers</span>, the carriers will
          receive an email prompting them to log into the Carrier Portal. They can then view the
          lanes they have been awarded and their rank in the waterfall sequence.
        </div>
      ),
      image: rfp10,
    },
    {
      title: 'Convert RFP to Contract Lanes',
      text: (
        <div>
          Now you can convert your RFP to contract lanes by clicking{' '}
          <span className='font-semibold'>Convert to Contract Lanes</span>. All lanes will then
          appear in the Contract Lane tab.{' '}
          <div className='text-dark-gray mt-6'>
            For guidance on how to use the Contract Lane feature, check out the Contract Lane
            tutorial.
          </div>
        </div>
      ),
      image: rfp11,
    },
  ],
}
