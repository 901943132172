import { useEffect } from 'react'
import tw from 'tailwind-styled-components'

import { Pagination } from './Pagination'

export const PaginationContainer = ({
  pageSize,
  setSize,
  setActive,
  setOffset,
  count,
  active,
  offset,
}: {
  pageSize: number
  setSize: Function
  setOffset: Function
  setActive: Function
  count: number
  active: any
  offset: number
}) => {
  useEffect(() => {
    setActive(offset ? Math.floor((offset + pageSize) / pageSize) : 1)
  }, [offset])

  return (
    <Container>
      <SelectContainer>
        Showing{' '}
        <span className='font-medium'>
          <Selector
            value={pageSize}
            onChange={(e: any) => {
              setSize(Number(e.target.value))
              setOffset(0)
              setActive(1)
            }}
          >
            {[5, 10, 20, 50, 100].map(pageSizeVal => (
              <option key={pageSizeVal} value={pageSizeVal}>
                {pageSizeVal}
              </option>
            ))}
          </Selector>
        </span>{' '}
        of <span className='font-medium'>{count}</span>
      </SelectContainer>
      <PaginationWrap>
        <Pagination
          active={active}
          count={count}
          offset={offset}
          pageSize={pageSize}
          setOffset={setOffset}
          size={Math.ceil(count / pageSize)}
          step={2}
          onClickHandler={(clickedActive: any) => {
            setActive(parseInt(clickedActive))
            setOffset(pageSize * (parseInt(clickedActive) - 1))
          }}
        />
      </PaginationWrap>
    </Container>
  )
}

const Container = tw.div`
  lg:pt-3
  lg:flex
  items-center
  justify-between
  px-4
  mt-2
  mb-4
`

const SelectContainer = tw.div`
  font-poppins
  hidden
  lg:flex
  gap-x-2
  text-sm
  text-dark-gray
`

const Selector = tw.select`
  focus-visible:outline-none
  outline-none
  cursor-pointer
`

const PaginationWrap = tw.div`
  relative
  z-0
  inline-flex
  rounded-md
  -space-x-px
`
