import { DocumentIcon } from '@heroicons/react/24/outline'
import { CloudArrowUpIcon } from '@heroicons/react/24/solid'
import { useCallback, useMemo } from 'react'
import { FileUploader } from 'react-drag-drop-files'
import tw from 'tailwind-styled-components'

export const DragAndDropFileInput = ({
  setFile = () => {},
  helpText = '',
  file = null,
  fileTypes = undefined,
  className = '',
  header = 'Upload File',
  isBase64 = false,
  setBase64 = () => {},
  multiple,
}: {
  setFile?: (file: any) => void
  helpText?: string
  file?: File | null
  fileTypes?: Array<string>
  className?: string
  header?: string
  isBase64?: boolean
  setBase64?: (file: { base64: string; name: string }) => void
  multiple?: boolean
}): JSX.Element => {
  const handleDelete = () => setFile(null)

  const handleChange = useCallback(
    (newFile: File | null) => {
      if (!isBase64 || !newFile) {
        setFile(newFile)
        return
      }

      const reader = new FileReader()
      reader.onloadend = () => {
        const base64data = reader.result as string
        setBase64({ base64: base64data, name: newFile.name })
      }
      reader.readAsDataURL(newFile)
    },
    [setFile, isBase64],
  )

  const fileSize = useMemo(() => {
    if (!file?.size) return '0 MB'
    if ((file.size / 1024 / 1024).toFixed(2) !== '0.00')
      return `${(file.size / 1024 / 1024).toFixed(2)} MB`
    else if ((file.size / 1024).toFixed(2) !== '0.00') return `${(file.size / 1024).toFixed(2)} KB`
    return `${file.size.toFixed(2)} B`
  }, [file?.size])

  return (
    <FileUploader
      fileOrFiles={file}
      handleChange={handleChange}
      multiple={multiple}
      name='file'
      types={fileTypes}
      onTypeError={() => alert('Invalid filetype.')}
    >
      <Container $file={file} className={className}>
        {!file && (
          <>
            {header && <div className='text-sm mb-2'>{header}</div>}
            <CloudArrowUpIcon className='text-link w-16' />
            <div className='mt-3'>
              Drag and drop files here or <span className='underline'>Browse</span>
            </div>
            <div className='text-dark-gray mt-2'>{helpText}</div>
          </>
        )}
        {file && (
          <div className='flex items-center'>
            <div className='flex flex-row md:gap-x-3'>
              <DocumentIcon className='w-5' />
              <div className='flex-col text-left text-xs whitespace-nowrap'>
                <div>{file.name}</div>
                <div>Size: {fileSize}</div>
              </div>
            </div>
            <div className='ml-auto'>
              <button className='text-link text-sm bg-transparent	border-0' onClick={handleDelete}>
                Delete
              </button>
            </div>
          </div>
        )}
      </Container>
    </FileUploader>
  )
}

const Container = tw.div<{ $file: File | null }>`
  border
  border-solid
  p-3
  flex
  flex-col
  justify-center
  cursor-pointer
  self-end
  text-black
  border-[#e5e7eb]
  rounded-lg
  font-poppins
  ${({ $file }) => !$file && 'p-6 items-center bg-lighter-blue border-dashed border-link'}
`
