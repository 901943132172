import { ReactNode } from 'react'
import tw from 'tailwind-styled-components'

import { randomString } from '../../common'

export type RadioInputProps = {
  className?: string
  checked: boolean
  onChange: () => void
  id?: string
  label?: string
  disabled?: boolean
  description?: ReactNode
}

export const RadioInput = ({
  className,
  checked,
  onChange,
  id,
  label,
  disabled,
  description,
}: RadioInputProps) => {
  const inputId = id || randomString()

  return (
    <InputContainer className={className}>
      <Input
        $disabled={disabled}
        checked={checked}
        className='mt-1.5'
        disabled={disabled}
        id={inputId}
        type='radio'
        onChange={onChange}
      />
      <Label $disabled={disabled} htmlFor={inputId}>
        {label}
        {description}
      </Label>
    </InputContainer>
  )
}

const InputContainer = tw.div`
  flex
  items-start
`

const Input = tw.input<{ $disabled?: boolean }>`
  ${({ $disabled }) => !$disabled && 'cursor-pointer'}
`

const Label = tw.label<{ $disabled?: boolean }>`
  pl-2
  mt-1
  ${({ $disabled }) => !$disabled && 'text-dark-blue cursor-pointer'}
`
