import { CSSProperties, ReactNode } from 'react'
import tw from 'tailwind-styled-components'

import loader from '../../assets/images/loading-buffering.gif'
import { toTransient, Transient } from '../../common'
import { ButtonIcon } from './ButtonIcon'
import { getButtonClassNamesByType } from './helpers'
import { ButtonIconType, ButtonType } from './types'

export type ButtonProps = {
  type?: ButtonType
  children?: ReactNode
  preIcon?: ButtonIconType
  postIcon?: ButtonIconType
  onClick?: React.MouseEventHandler<HTMLDivElement>
  className?: string
  noBackground?: boolean
  to?: string
  disabled?: boolean
  xxs?: boolean
  xs?: boolean
  sm?: boolean
  label?: string
  lg?: boolean
  fullWidth?: boolean
  loading?: boolean
  wrapperStyle?: CSSProperties
  innerStyle?: CSSProperties
  innerClassName?: string
  touchable?: boolean
  mobileFullWidth?: boolean
  short?: boolean
  dataTestId?: string
  id?: string | null
  backgroundColor?: string
  buttonAttrs?: React.ButtonHTMLAttributes<HTMLButtonElement>
  hideLabelOnMobile?: boolean
}

type $ButtonProps = Transient<ButtonProps>

export const Button = ({
  type = 'primary',
  children,
  preIcon,
  postIcon,
  onClick = () => {},
  className = '',
  noBackground = false,
  disabled = false,
  xxs = false,
  xs = false,
  sm = false,
  label = '',
  lg = false,
  fullWidth = false,
  loading = false,
  innerClassName,
  wrapperStyle = {},
  innerStyle = {},
  touchable = true,
  mobileFullWidth = false,
  short = false,
  dataTestId,
  id = null,
  backgroundColor = '',
  buttonAttrs = {},
  hideLabelOnMobile = false,
}: ButtonProps) => {
  const additionalInnerClassNames = getButtonClassNamesByType(type, noBackground, backgroundColor)

  return (
    <ButtonWrapper
      {...toTransient({ sm, lg })}
      className={className}
      style={wrapperStyle}
      onClick={e => !disabled && !loading && touchable && onClick(e)}
    >
      <ButtonInner
        {...toTransient({
          disabled,
          touchable,
          xxs,
          xs,
          sm,
          lg,
          short,
          fullWidth,
          mobileFullWidth,
          noBackground,
          children,
          label,
          hideLabelOnMobile,
        })}
        {...buttonAttrs}
        className={`${innerClassName} ${additionalInnerClassNames}`}
        disabled={disabled}
        style={innerStyle}
        tabIndex={0}
        {...(dataTestId && { 'data-testid': dataTestId })}
        {...(id && { id })}
      >
        {preIcon && (
          <span className={`flex button-pre-icon ${loading && 'invisible'}`} data-testid='pre-icon'>
            <ButtonIcon icon={preIcon} {...{ sm, lg }} />
          </span>
        )}
        {(children || label) && (
          <ButtonLabel {...toTransient({ hideLabelOnMobile, loading })}>
            {children || label}
          </ButtonLabel>
        )}
        {postIcon && (
          <span
            className={`flex button-post-icon ${loading && 'invisible'}`}
            data-testid='post-icon'
          >
            <ButtonIcon icon={postIcon} {...{ sm, lg }} />
          </span>
        )}
        {loading && <img alt='loading' className='w-4 h-4 absolute' src={loader} />}
      </ButtonInner>
    </ButtonWrapper>
  )
}

const ButtonLabel = tw.span<$ButtonProps>`
  whitespace-nowrap
  ${({ $loading }) => $loading && 'invisible'}
  ${({ $hideLabelOnMobile }) => $hideLabelOnMobile && 'hidden lg:block'}
`

const ButtonWrapper = tw.div<$ButtonProps>`
  rounded-lg
  text-xs
  box-border
  relative
  ${({ $lg }) => $lg && 'text-sm'}
`

const ButtonInner = tw.button<$ButtonProps>`
  button
  flex
  items-center
  justify-center
  rounded-lg
  font-semibold
  overflow-hidden
  tracking-wide
  font-poppins
  box-border
  gap-2
  md:gap-4

  focus:opacity-70
  focus-visible:outline-none

  disabled:border-gray-300
  disabled:bg-light-gray
  disabled:text-dark-gray
  disabled:pointer-events-none

  ${({ $noBackground }: $ButtonProps) => $noBackground && '!bg-transparent'}

  ${({ $touchable, $disabled }: $ButtonProps) =>
    $touchable && !$disabled && 'hover:opacity-70 transition-all cursor-pointer'}

  ${({ $xxs, $xs, $lg }: $ButtonProps) =>
    ($xxs && 'h-5 px-4') || ($xs && 'h-6 px-4') || ($lg && 'h-10 px-6') || 'h-8 px-4'}
  
  ${({ $short }: $ButtonProps) => $short && '!md:px-4'}
  
  ${({ $fullWidth, $mobileFullWidth }: $ButtonProps) =>
    $fullWidth ? 'w-full' : !$mobileFullWidth && 'w-fit'}
    
  ${({ $mobileFullWidth }: $ButtonProps) => $mobileFullWidth && 'w-full md:w-fit'}
  
  ${({ $children, $label }: $ButtonProps) => !$children && !$label && 'w-8'}
  
  ${({ $hideLabelOnMobile }) => $hideLabelOnMobile && 'px-2'}
`
