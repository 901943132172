/* Keep ./sentry at the top to ensure it's imported before
   anything that it would instrument */
import './sentry'
import './index.scss'
import './api/middleware'
import 'react-loading-skeleton/dist/skeleton.css'

import { BackStackProvider } from '@common'
import dayjs, { Dayjs } from 'dayjs'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import relativeTIme from 'dayjs/plugin/relativeTime'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
// @ts-ignore
import dayjsBusinessDays from 'dayjs-business-days'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { RouterProvider } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'

import { persistor, store } from './app/store'
import { isProduction } from './common/constants'
import reportWebVitals from './reportWebVitals'
import { router } from './router'

dayjs.extend(dayjsBusinessDays)
dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTIme)

type DateType = string | number | Date | Dayjs

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const container = document.getElementById('root')!
const root = createRoot(container)

// disable logs on prod
if (isProduction) {
  console.log = () => {}
  console.error = () => {}
  console.debug = () => {}
}

declare module 'dayjs' {
  interface Dayjs {
    businessDaysAdd(compared: DateType, withoutSuffix?: boolean): any
    nextBusinessDay(): any
    businessDaysSubtract(compared: DateType, withoutSuffix?: boolean): any
    isBusinessDay(): boolean
    isSameOrAfter(compared: DateType, withoutSuffix?: boolean): string
    isSameOrBefore(compared: DateType, withoutSuffix?: boolean): string
  }
}

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BackStackProvider router={router}>
          <RouterProvider router={router} />
        </BackStackProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
