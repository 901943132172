import { ChevronRightIcon } from '@heroicons/react/24/solid'
import { ReactNode } from 'react'
import tw from 'tailwind-styled-components'

export const MenuItem = ({
  title,
  onClick,
  isClickable = true,
  isInner,
  isSectionTitle,
  content,
}: {
  title: string
  onClick: () => void
  isClickable?: boolean
  isInner?: boolean
  isSectionTitle?: boolean
  content?: ReactNode
}) => (
  <Container
    $isClickable={isClickable}
    $isInner={isInner}
    $isSectionTitle={isSectionTitle}
    onClick={() => isClickable && onClick()}
  >
    {title}
    <div className='flex gap-4 items-center'>
      {content}
      {isClickable && <ChevronRightIcon className='w-5' />}
    </div>
  </Container>
)

const Container = tw.div<{ $isClickable: boolean; $isInner?: boolean; $isSectionTitle?: boolean }>`
  ${({ $isClickable, $isInner, $isSectionTitle }) => `
    flex justify-between text-sm font-semibold
    ${!$isClickable && 'text-dark-gray'}
    ${$isSectionTitle ? 'pt-4 pb-2' : 'py-4'}
    ${$isInner ? 'py-2.5 last:mb-2 pl-4' : 'border-t border-border-gray'}
  `}
`
