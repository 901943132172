import { ConfirmationDialog } from '@components'
import { Link } from '@components'
import { XMarkIcon } from '@heroicons/react/24/solid'
import { noop } from 'lodash-es'
import { useMemo, useState } from 'react'
import tw from 'tailwind-styled-components'

import { useAppSelector, useAppThunkDispatch } from '../../../app/hooks'
import { updateLoad } from '../../../redux/loadsSlice'

export const ContractLaneBadge = ({
  className,
  deletable = true,
  id,
}: {
  className?: string
  id?: number
  deletable?: boolean
}) => {
  const [isModalVisible, setModalVisible] = useState(false)

  const dispatch = useAppThunkDispatch()

  const load = useAppSelector(state => state.loads.loadDetails)
  const loading = useAppSelector(state => state.loads.loading.updateLoad)
  const tenderedCarriers = useAppSelector(state => state.loads.tenderedCarriers)

  const isWaterfallActive = useMemo(
    () => tenderedCarriers.some(carrier => carrier.status !== 'CANCELLED'),
    [tenderedCarriers],
  )

  const laneId = id || load?.contractLaneId

  if (!laneId) return null

  const unlinkContractLane = () =>
    dispatch(updateLoad({ patchPayload: { contractLaneId: null } }))
      .unwrap()
      .then(() => setModalVisible(false))
      .catch(noop)

  return (
    <Container className={className}>
      <Link className='hover:text-dark-blue' to={`/contract-lanes/${laneId}`}>
        Contract Lane #{laneId}
      </Link>
      {!isWaterfallActive && deletable && (
        <XMarkIcon
          className='w-4 hover:opacity-80 cursor-pointer ml-2'
          onClick={() => setModalVisible(true)}
        />
      )}
      <ConfirmationDialog
        confirmButtonLoading={loading}
        confirmButtonType='danger'
        isVisible={isModalVisible}
        setVisible={setModalVisible}
        title={`Are you sure you want to unlink Contract Lane #${laneId}?`}
        onConfirm={unlinkContractLane}
      />
    </Container>
  )
}

const Container = tw.div`
  border
  border-brand-dark
  rounded-md
  px-2
  py-1
  font-semibold
  flex
  items-center
  justify-between
  w-fit
`
