import { useEffect, useMemo } from 'react'

import { LatitudeLongitude, LatLng } from '../../common'
import { useMap } from './MapContext'
import destinationMarker from './markers/destinationMarker.png'
import originMarker from './markers/originMarker.png'
import stopMarker from './markers/stopMarker.png'

export enum MarkerType {
  ORIGIN = 'origin',
  STOP = 'stop',
  DESTINATION = 'destination',
}

export type Position = LatLng | LatitudeLongitude

type MarkerData = {
  isSelected?: boolean
}

const DEFAULT_MARKER_SIZE = { w: 24, h: 24 }

const isLatitudeLongitude = (position: Position): position is LatitudeLongitude =>
  'latitude' in position && 'longitude' in position

const normalizePosition = (position: Position | null): { lat: number; lng: number } => {
  if (!position) {
    return { lat: 0, lng: 0 }
  } else if (isLatitudeLongitude(position)) {
    return { lat: position.latitude, lng: position.longitude }
  } else {
    return { lat: position.lat ?? 0, lng: position.lng ?? 0 }
  }
}

export const Marker = ({
  marker,
  position,
  data = {},
  html = false,
  onClick,
}: {
  marker: MarkerType | string
  position: Position | null
  data?: MarkerData
  html?: boolean
  onClick?: (e: any) => void
}) => {
  const { map } = useMap()
  const normalizedPosition = useMemo(() => normalizePosition(position), [position])

  useEffect(() => {
    const { lat, lng } = normalizedPosition
    if (!map || !lat || !lng) return

    const createMarkerIcon = (markerType: string) => {
      switch (markerType) {
        case MarkerType.ORIGIN:
          return originMarker
        case MarkerType.DESTINATION:
          return destinationMarker
        case MarkerType.STOP:
          return stopMarker
        default:
          return markerType // assuming it's a valid path or URL
      }
    }

    const mapMarker = html
      ? new H.map.DomMarker({ lat, lng }, { icon: new H.map.DomIcon(marker), data })
      : new H.map.Marker(
          { lat, lng },
          {
            icon: new H.map.Icon(createMarkerIcon(marker), { size: DEFAULT_MARKER_SIZE }),
            data: { ...data, type: marker },
          },
        )

    if (onClick) {
      mapMarker.addEventListener('tap', onClick)
    }

    map.addObject(mapMarker)

    return () => {
      if (onClick) {
        mapMarker.removeEventListener('tap', onClick)
      }
      try {
        map.removeObject(mapMarker)
      } catch (e) {
        // Swallow error
      }
    }
  }, [map, normalizedPosition, marker, data, html, onClick])

  return null
}
