import { Tooltip } from '@components'
import { ExclamationTriangleIcon } from '@heroicons/react/24/solid'
import { useMemo } from 'react'

import { useAppSelector } from '../../../../app/hooks'
import { TenderedToExoTooltipContent } from '../TenderedToExoTooltipContent'

export const DropdownItemTooltip = ({ text }: { text: string }) => {
  const load = useAppSelector(state => state.loads.loadDetails)
  const isTenderDisabled = useAppSelector(state => state.loads.isTenderDisabled)

  const isTendered = load?.carrier?.name
  const isTenderedToExo = load?.network === 2
  const isTenderPending = load?.carrier?.tenderPending

  const tooltipContent = useMemo(() => {
    if (isTenderDisabled)
      return (
        <>
          Load information is incomplete.
          <br /> Fill out the required fields to tender this load.
        </>
      )
    if (!isTenderPending && !isTenderedToExo)
      return 'This load is already tendered. Please cancel the tender to re-tender this load.'
    if (isTenderedToExo) return <TenderedToExoTooltipContent />
  }, [isTenderedToExo, isTenderPending, isTendered])

  return (
    <Tooltip className='w-full' content={tooltipContent}>
      <div className='flex items-center'>
        {text}
        <ExclamationTriangleIcon className='text-error w-4 ml-2' />
      </div>
    </Tooltip>
  )
}
