import { useEffect, useState } from 'react'
import tw from 'tailwind-styled-components'

export const ProgressBar = ({
  total,
  amount,
  title,
  className,
}: {
  total: number
  amount: number
  title?: string
  className?: string
}) => {
  const [percentage, setPercentage] = useState(0)

  useEffect(() => {
    let calculatedPercentage = (amount / total) * 100
    if (calculatedPercentage > 99 && amount < total) {
      calculatedPercentage = 99
    }
    setPercentage(calculatedPercentage)
  }, [total, amount])

  return (
    <div className={className}>
      <div className='flex items-center justify-between mb-2 text-sm'>
        <div className='text-dark-gray capitalize'>Total {title}</div>
        <div className='font-bold lowercase'>
          {amount} of {total} {title} ({Math.round(percentage)}% used)
        </div>
      </div>
      <BarContainer>
        <Bar style={{ width: `${percentage}%` }}></Bar>
      </BarContainer>
    </div>
  )
}

const BarContainer = tw.div`
  w-full
  h-4
  bg-lighter-gray
  border
  border-gray-200
  rounded-full
  overflow-hidden
`

const Bar = tw.div`
  h-full
  bg-success
  transition-width
  duration-700
  ease-in-out
  rounded-full
`
