import { currencyFormatter } from '@common'
import { LoaderSpinner, Status } from '@components'
import { CurrencyDollarIcon, DocumentIcon, TruckIcon } from '@heroicons/react/24/solid'
import dayjs from 'dayjs'
import { useEffect } from 'react'
import tw from 'tailwind-styled-components'

import { useAppSelector, useAppThunkDispatch } from '../../../../app/hooks'
import { LoadNotification, LoadNotificationType } from '../../../../common/types'
import { displayDateTime } from '../../../../common/utils'
import { getNotifications } from '../../../../redux/loadsSlice'

export const Notifications = () => {
  const dispatch = useAppThunkDispatch()

  const load = useAppSelector(state => state.loads.loadDetails)
  const notifications = useAppSelector(state => state.loads.notifications)
  const notificationsLoading = useAppSelector(state => state.loads.loading.notifications)

  useEffect(() => {
    if (load?.id) dispatch(getNotifications(load.id))
  }, [load?.id, load?.loadStatus, load?.network, load?.carrier])

  useEffect(() => {
    // Request notifications every 5 minutes
    // TODO: replace with WS
    const interval = setInterval(() => load?.id && dispatch(getNotifications(load.id)), 300000)

    return () => {
      clearTimeout(interval)
    }
  }, [load?.id])

  return (
    <div className='p-4 pb-0'>
      {notificationsLoading && <LoaderSpinner />}
      {notifications.length === 0 && !notificationsLoading && (
        <div className='text-dark-gray text-center'>No notifications</div>
      )}
      {notifications.map((notification, index) => {
        switch (notification.notificationType) {
          case LoadNotificationType.LoadStatusEvent:
            return <LoadStatusUpdate key={index} notification={notification} />
          case LoadNotificationType.CarrierDocumentUpdate:
            return <CarrierDocumentUpdate key={index} notification={notification} />
          case LoadNotificationType.LoadTenderRequest:
            return <LoadTenderRequest key={index} notification={notification} />
          default:
            return null
        }
      })}
    </div>
  )
}

const CreatedTime = ({ createdAt }: { createdAt: string }) => (
  <p className='text-gray-400 text-right'>{displayDateTime(dayjs(createdAt))}</p>
)

const LoadStatusUpdate = ({ notification }: { notification: LoadNotification }) => (
  <NotificationBox>
    <h3 className='flex font-bold'>
      <TruckIcon className='w-4 mr-2 shrink-0' /> Load Status Update
    </h3>
    <p>
      <Label>Status</Label>
      <Value>
        {notification.statusEvent?.status}
        {notification.statusEvent?.transitStatus && (
          <> - {notification.statusEvent.transitStatus}</>
        )}
      </Value>
    </p>
    {notification.statusEvent?.locationName && (
      <p>
        <Label>Headed To</Label>
        <Value>{notification.statusEvent?.locationName}</Value>
      </p>
    )}
    {notification.statusEvent?.createdBy && (
      <p>
        <Label>Updated By</Label>
        <Value>{notification.statusEvent?.createdBy}</Value>
      </p>
    )}
    {notification.statusEvent?.eventTime && (
      <p>
        <Label>Date & Time</Label>
        <Value>{displayDateTime(notification.statusEvent?.eventTime)}</Value>
      </p>
    )}
    {notification.statusEvent?.notes && (
      <p>
        <Label>Notes</Label>
        <Value>{notification.statusEvent?.notes}</Value>
      </p>
    )}
    <CreatedTime createdAt={notification.createdAt} />
  </NotificationBox>
)

const CarrierDocumentUpdate = ({ notification }: { notification: LoadNotification }) => (
  <NotificationBox>
    <h3 className='flex font-bold'>
      <DocumentIcon className='w-4 mr-2 shrink-0' /> Load Document Uploaded
    </h3>
    {notification.loadUpload?.documentType && (
      <p>
        <Label>Document Type</Label>
        <Value>{notification.loadUpload?.documentType}</Value>
      </p>
    )}
    {notification.loadUpload?.uploadedBy && (
      <p>
        <Label>Uploaded By</Label>
        <Value>
          {notification.loadUpload?.uploadedBy}
          {notification.loadUpload?.uploadedByAccount && (
            <> — {notification.loadUpload?.uploadedByAccount}</>
          )}
        </Value>
      </p>
    )}
    <CreatedTime createdAt={notification.createdAt} />
  </NotificationBox>
)

const LoadTenderRequest = ({ notification }: { notification: LoadNotification }) => (
  <NotificationBox>
    <div className='flex justify-between'>
      <h3 className='flex font-bold items-center'>
        <CurrencyDollarIcon className='w-4 mr-2 shrink-0' /> Load Tender Request
      </h3>
      <Status status={notification.tenderRequest?.status ?? 'Pending'} />
    </div>
    <p>
      <Label>Price</Label>
      <Value>
        {currencyFormatter.format(notification.tenderRequest?.carrierContractRate ?? 0)}
      </Value>
    </p>
    <p>
      <Label>Name</Label>
      <Value>{notification.tenderRequest?.carrierContact}</Value>
    </p>
    <p>
      <Label>Company</Label>
      <Value>{notification.tenderRequest?.carrierName}</Value>
    </p>
    <CreatedTime createdAt={notification.createdAt} />
  </NotificationBox>
)

const NotificationBox = tw.div`
  bg-gray-50
  border
  border-light-gray
  flex
  flex-col
  gap-2
  mb-4
  p-4
  rounded-lg
`

const Label = tw.span`
  font-normal
  pr-2
  text-dark-gray
`

const Value = tw.span`
  font-semibold
  text-brand-dark
`
