import { CatchError, formatAxiosErrorToPayload, getErrorString } from '@common'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import * as sentry from '@sentry/react'
import { omit } from 'lodash-es'
import { toast } from 'react-toastify'

import { api } from '../api/api'
import { RootState } from '../app/store'
import { NotificationTypes } from '../common/constants'
import { trackEvent } from '../common/tracking'
import { PresetActionType, SearchFilters } from '../common/types'
import { getPresetKey, keysToCamelCase, keysToSnakeCase } from '../common/utils'
import { initializeTracking } from './trackingSlice'

type TutorialStep = {
  [key: string]: string
}

export type ShipperProfile = {
  name?: string
  phone?: string | null
  email?: string | null
}

export type UserProfile = {
  companyId: number
  companyName: string
  email: string
  featureFlags: { [key: string]: boolean }
  portalQuotingEnabled: boolean
  firstName: string
  lastName: string
  potrEdiId: number
  accountOwner: {
    name: string
    phone: string
    email: string
  }
  phone: string
  companyEmailDomain?: string | null
  isFirstLogin: boolean
  roleInCompany: string
  appNotifications: NotificationTypes[]
  emailNotifications: NotificationTypes[]
}

export type ShipperAddress = {
  address?: string
  city?: string
  stateProvinceRegion?: string
  postalCode?: string
  country?: string
}

export type BillingDetails = {
  subscription: {
    productName: string
    productDescription?: string
    currentPeriodStart: string
    currentPeriodEnd: string
    trialEnd: string
    flatPrice: number
    includedLoads: number
    usedLoads: number
    overagePrice: number
    perUnitPrice: number
  }
  stripeCustomerPortalUrl: string
}

export type InvitationDetails = {
  invitedBy: string
  companyId: number
  companyName: string
  email: string
}

export type TeamDetails = {
  contacts: {
    id: number
    firstName: string
    lastName: string
    email: string
  }[]
  invitations: string[]
}

type LoginState = {
  error: string
  invitationError: string
  user: UserProfile | null
  presets: any
  presetsLoading: boolean
  credentials: { username: string; codeId: string }
  invitationDetails: InvitationDetails | null
  register: {
    companyName: string
    firstName: string
    lastName: string
    email: string
    phone: string
    roleInCompany: string
  }
  step: 'LOGIN' | 'CONFIRM' | 'SELECT_COMPANY'
  companyId: number
  loading: {
    customerCompanyList: boolean
    checkEmail: boolean
    verifyOTP: boolean
    sendOTP: boolean
    registerShipper: boolean
    sendShipperInvitation: boolean
    cancelShipperInvitation: boolean
    deleteCustomerContact: boolean
    verifyShipperInvitation: boolean
    updateShipperProfile: boolean
    getUserToken: number | false
    getUser: boolean
    getBillingDetails: boolean
    getTeamDetails: boolean
    markStepAsComplete: boolean
    updateUserProfile: boolean
  }
  shipperProfile: ShipperProfile | null
  shipperAddress: ShipperAddress | null
  shipperBillingDetails: BillingDetails | null
  shipperTeamDetails: TeamDetails | null
  customerCompanies: Array<{ id: number; name: string }>
  customerCompaniesCount: number
  showFilters: boolean
  availableSteps: TutorialStep[]
  userCompletedSteps: string[]
  isTutorialWizardVisible: boolean
  currentStep: string
  loginData: { phone: string; email: string }
}

const initialState: LoginState = {
  error: '',
  invitationError: '',
  user: null,
  presets: null,
  presetsLoading: false,
  credentials: {
    username: import.meta.env.VITE_LOGIN ?? '',
    codeId: '',
  },
  invitationDetails: null,
  register: {
    companyName: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    roleInCompany: '',
  },
  step: 'LOGIN',
  companyId: 0,
  loading: {
    customerCompanyList: false,
    checkEmail: false,
    verifyOTP: false,
    sendOTP: false,
    registerShipper: false,
    sendShipperInvitation: false,
    cancelShipperInvitation: false,
    deleteCustomerContact: false,
    verifyShipperInvitation: false,
    updateShipperProfile: false,
    getUserToken: false,
    getUser: false,
    getBillingDetails: false,
    getTeamDetails: false,
    markStepAsComplete: false,
    updateUserProfile: false,
  },
  shipperProfile: null,
  shipperBillingDetails: null,
  shipperTeamDetails: null,
  shipperAddress: null,
  customerCompanies: [],
  customerCompaniesCount: 0,
  showFilters: false,
  availableSteps: [],
  userCompletedSteps: [],
  isTutorialWizardVisible: false,
  currentStep: '',
  loginData: { phone: '', email: '' },
}

export const checkContact = createAsyncThunk(
  'user/checkContact',
  async (_, { getState, rejectWithValue }) => {
    const {
      loginData: { email, phone },
    } = (getState() as RootState).user
    return api
      .post('/shipper/api/check-contact-exists/', {
        ...(phone && { phone }),
        ...(email && { email }),
      })
      .then(({ data }) => data)
      .catch((err: CatchError) => rejectWithValue(formatAxiosErrorToPayload(err)))
  },
)

export const sendOTP = createAsyncThunk(
  'user/sendOTP',
  async (_, { rejectWithValue, getState }) => {
    const {
      loginData: { email, phone },
    } = (getState() as RootState).user
    try {
      return api
        .post('/shipper/users/contact-verification/', {
          ...(email && { email }),
          ...(phone && { phone }),
        })
        .then(({ data }) => keysToCamelCase(data))
        .then(({ codeId }) => codeId as string)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const verifyOTP = createAsyncThunk(
  'user/verifyOTP',
  async (otp: string, { rejectWithValue, getState }) => {
    const {
      credentials: { codeId },
    } = (getState() as RootState).user
    try {
      return api
        .post(`/shipper/users/contact-verification/${codeId}/${otp}/`)
        .then(({ data }) => keysToCamelCase(data.user))
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const registerShipper = createAsyncThunk(
  'user/registerShipper',
  async ({ invitationCode }: { invitationCode?: string | null }, { rejectWithValue, getState }) => {
    const { register } = (getState() as RootState).user
    const payload = invitationCode ? { ...register, invitationCode } : { ...register }

    try {
      const response = await api.post(`/shipper/api/register/`, keysToSnakeCase(payload))
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const sendShipperInvitation = createAsyncThunk(
  'user/sendShipperInvitation',
  async (email: string, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.post(
        `/accounts/api/send-customer-contact-invitation/`,
        keysToSnakeCase({ email }),
      )
      dispatch(getShipperTeamDetails())
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const cancelShipperInvitation = createAsyncThunk(
  'user/cancelShipperInvitation',
  async (email: string, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.post(
        `/accounts/api/cancel-customer-contact-invitation/`,
        keysToSnakeCase({ email }),
      )
      dispatch(getShipperTeamDetails())
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const deleteCustomerContact = createAsyncThunk(
  'user/deleteCustomerContact',
  async (contactId: number, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.post(
        `/accounts/api/delete-customer-contact/`,
        keysToSnakeCase({ contactId }),
      )
      dispatch(getShipperTeamDetails())
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const verifyShipperInvitation = createAsyncThunk(
  'user/verifyShipperInvitation',
  async (code: string, { rejectWithValue }) => {
    try {
      const response = await api.post(
        `/accounts/api/verify-customer-contact-invitation/`,
        keysToSnakeCase({ code }),
      )
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const logout = createAsyncThunk('user/logout', async () => api.post('/api/logout/'))

export const getUserToken = createAsyncThunk(
  'user/getUserToken',
  async (companyId: number, { rejectWithValue }) => {
    try {
      const response = await api.post('shipper/api/company-token/', keysToSnakeCase({ companyId }))
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const getCustomerCompanyList = createAsyncThunk(
  'user/getCustomerCompanyList',
  async (query: string | undefined, { rejectWithValue }) => {
    try {
      return await api
        .get('/accounts/api/customer-company-list/', {
          params: { ...(query && { query }) },
        })
        .then(({ data }) => data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const checkIfUserIsAuthenticated = createAsyncThunk<{
  authenticated: boolean
  customerCompanyId: number | null
}>('user/checkIfUserIsAuthenticated', async () =>
  api.get('/shipper/users/check-auth/').then(({ data }) => keysToCamelCase(data)),
)

export const getUser = createAsyncThunk('user/getUser', async (_, { dispatch }) =>
  api.get('/customer/api/customer-profile/').then(({ data }) => {
    trackEvent('Received user information')
    sentry.setUser({ email: data['email'] })
    dispatch(
      initializeTracking({
        email: data['email'],
        name: `${data['first_name']} ${data['last_name']}`,
      }),
    )
    return data
  }),
)

export const updateCompletedSteps = createAsyncThunk(
  'user/updateCompletedSteps',
  async (payload: string[], { dispatch }) =>
    api.patch('/customer/api/customer-profile/', { completed_steps: payload }).then(({ data }) => {
      dispatch(getUser())
      return keysToCamelCase(data)
    }),
)

export const markStepAsComplete = createAsyncThunk(
  'user/markStepAsComplete',
  async (payload: string, { dispatch }) =>
    api
      .patch('/customer/api/customer-profile/', { new_completed_step: payload })
      .then(({ data }) => {
        dispatch(getUser())
        return keysToCamelCase(data)
      }),
)

export const updateShipperProfile = createAsyncThunk(
  'profile/updateShipperProfile',
  async (
    {
      companyDetail,
      location,
    }: {
      companyDetail?: ShipperProfile
      location?: ShipperAddress
    },
    { rejectWithValue },
  ) => {
    try {
      const response = await api.patch(
        `shipper/api/shipper-company-profile/`,
        keysToSnakeCase({
          companyDetail: omit(companyDetail, 'companyLogo'),
          ...(location && { location }),
        }),
      )
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const updateUserProfile = createAsyncThunk(
  'profile/updateUserProfile',
  async (
    userDetails: {
      firstName?: string
      lastName?: string
      phone?: string
      roleInCompany?: string
    },
    { rejectWithValue },
  ) => {
    try {
      const response = await api.patch(
        `accounts/api/update-customer-contact/`,
        keysToSnakeCase(userDetails),
      )
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const getShipperProfile = createAsyncThunk('user/getShipperProfile', async () =>
  api.get('shipper/api/shipper-company-profile/').then(({ data }) => keysToCamelCase(data)),
)

export const getShipperBillingDetails = createAsyncThunk(
  'user/getShipperBillingDetails',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get('billing/api/pulse-customer-billing-details/')
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const getShipperTeamDetails = createAsyncThunk(
  'user/getShipperTeamDetails',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get('accounts/api/customer-team-details/')
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const getPresets = createAsyncThunk('user/getPresets', async () =>
  api.get('/accounts/api/preset/').then(({ data }) => data),
)

export const savePresets = createAsyncThunk(
  'user/savePresets',
  async (
    {
      presets,
      actionType,
    }: {
      presets: any
      actionType?: PresetActionType
    },
    { dispatch, rejectWithValue },
  ) => {
    try {
      await api.put('/accounts/api/preset/', presets)
      await dispatch(getPresets())
      return actionType
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const setPresets = createAsyncThunk(
  'user/setPresets',
  async (
    {
      id,
      title,
      filters,
      actionType,
    }: { id: string; title: string; filters: SearchFilters; actionType?: PresetActionType },
    { getState, dispatch },
  ) => {
    const { presets = {} } = (getState() as RootState).user

    const presetKey = getPresetKey() // E.g. 'loads'
    const activePresets = presets?.[presetKey]?.activePresets || []

    const payload = {
      ...presets,
      [presetKey]: {
        ...presets?.[presetKey],
        presets: {
          ...presets?.[presetKey]?.presets,
          [id]: {
            title,
            filters,
          },
        },
        ...(actionType === 'created' && {
          activePresets: [...activePresets, id],
          selectedPreset: id,
        }),
      },
    }

    dispatch(savePresets({ presets: payload, actionType }))
  },
)

// selected preset is the one currently applied
export const setSelectedPreset = createAsyncThunk(
  'user/setSelectedPreset',
  async (id: string, { getState, dispatch }) => {
    const { presets = {} } = (getState() as RootState).user

    const presetKey = getPresetKey()

    const payload = {
      ...presets,
      [presetKey]: {
        ...presets?.[presetKey],
        selectedPreset: id,
      },
    }

    dispatch(savePresets({ presets: payload }))
  },
)

// active presets are the ones marked as checked in the presets dropdown
export const setActivePresets = createAsyncThunk(
  'user/setActivePresets',
  async (id: string, { getState, dispatch }) => {
    const { presets = {} } = (getState() as RootState).user

    const presetKey = getPresetKey()
    const activePresets = presets?.[presetKey]?.activePresets || []

    const payload = {
      ...presets,
      [presetKey]: {
        ...presets?.[presetKey],
        activePresets: activePresets.includes(id)
          ? activePresets.filter((presetId: string) => presetId !== id)
          : [...activePresets, id],
      },
    }

    dispatch(savePresets({ presets: payload }))
  },
)

export const setMultipleActivePresets = createAsyncThunk(
  'user/setMultipleActivePresets',
  async (ids: Array<string>, { getState, dispatch }) => {
    const { presets = {} } = (getState() as RootState).user

    const presetKey = getPresetKey()
    const selectedPreset = presets?.[presetKey]?.selectedPreset

    const payload = {
      ...presets,
      [presetKey]: {
        ...presets?.[presetKey],
        activePresets: ids,
        selectedPreset: !ids.includes(selectedPreset) ? '' : selectedPreset,
      },
    }

    dispatch(savePresets({ presets: payload }))
  },
)

export const deletePreset = createAsyncThunk(
  'user/deletePreset',
  async (data: string, { getState, dispatch }) => {
    const { presets = {} } = (getState() as RootState).user

    const presetKey = getPresetKey()
    const current = presets?.[presetKey]
    const currentPresets = omit(current.presets, data)

    const payload = {
      ...presets,
      [presetKey]: {
        presets: currentPresets,
        selectedPreset: '',
        activePresets: current.activePresets.filter((presetId: string) => presetId !== data),
      },
    }

    dispatch(savePresets({ presets: payload, actionType: 'deleted' }))
  },
)

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    resetLogin(state) {
      state.error = ''
    },
    setCredentials(state, { payload }) {
      state.credentials = payload
    },
    setRegister(state, { payload }) {
      state.register = {
        ...state.register,
        ...payload,
      }
    },
    setStep(state, { payload }: { payload: LoginState['step'] }) {
      state.step = payload
    },
    setCompanyId(state, { payload }) {
      state.companyId = payload
    },
    resetCustomerCompanies(state) {
      state.customerCompanies = []
    },
    setShowFilters(state, { payload }) {
      state.showFilters = payload
    },
    setTutorialWizardVisible(state, { payload }) {
      state.isTutorialWizardVisible = payload
    },
    setCurrentStep(state, { payload }) {
      state.currentStep = payload
    },
    setError(state, { payload }) {
      state.error = payload
    },
    patchCredentials: (state, { payload }) => {
      state.loginData = { ...state.loginData, ...payload }
    },
    setUser: (state, { payload }) => {
      state.user = { ...state.user, ...payload }
    },
  },
  extraReducers(builder) {
    builder
      .addCase(verifyOTP.pending, state => {
        state.loading.verifyOTP = true
        state.error = ''
      })
      .addCase(verifyOTP.fulfilled, (state, { payload }) => {
        state.loading.verifyOTP = false
        state.user = { ...state.user, ...payload }
        state.credentials = {
          username: import.meta.env.VITE_LOGIN ?? '',
          codeId: '',
        }
      })
      .addCase(verifyOTP.rejected, (state, { payload }: any) => {
        state.loading.verifyOTP = false
        state.error = getErrorString(payload, 'Could not verify your code')
      })
      .addCase(sendOTP.pending, state => {
        state.loading.sendOTP = true
        state.error = ''
      })
      .addCase(sendOTP.fulfilled, (state, { payload }) => {
        state.loading.sendOTP = false
        state.credentials.codeId = payload
      })
      .addCase(sendOTP.rejected, (state, { payload }) => {
        state.loading.sendOTP = false
        state.error = getErrorString(payload, 'Failed to send confirmation email')
      })
      .addCase(getUser.pending, state => {
        state.loading.getUser = true
      })
      .addCase(getUser.fulfilled, (state, { payload }) => {
        state.loading.getUser = false
        state.user = { ...state.user, ...keysToCamelCase(payload) }
        state.userCompletedSteps = payload.completed_steps
        state.availableSteps = payload.available_steps
      })
      .addCase(getUser.rejected, state => {
        state.loading.getUser = false
        toast.error('Failed to get user')
      })
      .addCase(getPresets.pending, state => {
        state.presetsLoading = true
      })
      .addCase(getPresets.fulfilled, (state, { payload }) => {
        state.presetsLoading = false
        state.presets = payload
      })
      .addCase(getPresets.rejected, state => {
        state.presetsLoading = false
        toast.error('Failed to get presets')
      })
      .addCase(savePresets.pending, state => {
        state.presetsLoading = true
      })
      .addCase(savePresets.fulfilled, (state, { payload }) => {
        state.presetsLoading = false
        payload && toast.success(`Successfully ${payload} preset`)
      })
      .addCase(savePresets.rejected, (state, { payload }) => {
        state.presetsLoading = false
        toast.error(getErrorString(payload, 'Failed to update'))
      })
      .addCase(getCustomerCompanyList.pending, state => {
        state.loading.customerCompanyList = true
      })
      .addCase(getCustomerCompanyList.fulfilled, (state, { payload }) => {
        state.customerCompanies = payload.companies
        state.customerCompaniesCount = payload.count
        state.loading.customerCompanyList = false
      })
      .addCase(getCustomerCompanyList.rejected, state => {
        state.loading.customerCompanyList = false
      })
      .addCase(checkContact.pending, state => {
        state.loading.checkEmail = true
        state.error = ''
      })
      .addCase(checkContact.fulfilled, state => {
        state.loading.checkEmail = false
      })
      .addCase(checkContact.rejected, (state, { payload }) => {
        state.loading.checkEmail = false
        state.error = getErrorString(payload, 'Failed to check email')
      })
      .addCase(registerShipper.pending, state => {
        state.loading.registerShipper = true
        state.error = ''
      })
      .addCase(registerShipper.fulfilled, (state, { payload }) => {
        state.credentials.codeId = payload.codeId
        state.loading.registerShipper = false
      })
      .addCase(registerShipper.rejected, (state, { payload }) => {
        state.loading.registerShipper = false
        state.error = getErrorString(payload, 'Failed to register')
      })
      .addCase(sendShipperInvitation.pending, state => {
        state.loading.sendShipperInvitation = true
      })
      .addCase(sendShipperInvitation.fulfilled, state => {
        state.loading.sendShipperInvitation = false
        toast.success('Invitation sent successfully')
      })
      .addCase(sendShipperInvitation.rejected, (state, { payload }) => {
        state.loading.sendShipperInvitation = false
        toast.error(getErrorString(payload, 'Failed to send invitation'))
      })
      .addCase(cancelShipperInvitation.pending, state => {
        state.loading.cancelShipperInvitation = true
      })
      .addCase(cancelShipperInvitation.fulfilled, state => {
        state.loading.cancelShipperInvitation = false
        toast.success('Invitation canceled successfully')
      })
      .addCase(cancelShipperInvitation.rejected, (state, { payload }) => {
        state.loading.cancelShipperInvitation = false
        toast.error(getErrorString(payload, 'Failed to cancel invitation'))
      })
      .addCase(deleteCustomerContact.pending, state => {
        state.loading.deleteCustomerContact = true
      })
      .addCase(deleteCustomerContact.fulfilled, state => {
        state.loading.deleteCustomerContact = false
        toast.success('User deleted successfully')
      })
      .addCase(deleteCustomerContact.rejected, (state, { payload }) => {
        state.loading.deleteCustomerContact = false
        toast.error(getErrorString(payload, 'Failed to delete user'))
      })
      .addCase(verifyShipperInvitation.pending, state => {
        state.loading.verifyShipperInvitation = true
      })
      .addCase(verifyShipperInvitation.fulfilled, (state, { payload }) => {
        state.invitationDetails = payload
        state.loading.verifyShipperInvitation = false
      })
      .addCase(verifyShipperInvitation.rejected, (state, { payload }) => {
        state.loading.verifyShipperInvitation = false
        state.invitationError = getErrorString(
          payload,
          'Failed to verify the invitation code, please try to refresh the page. If the issue is not resolved please ask your admin to invite you again.',
        )
      })
      .addCase(getShipperBillingDetails.pending, state => {
        state.loading.getBillingDetails = true
      })
      .addCase(getShipperBillingDetails.fulfilled, (state, { payload }) => {
        state.shipperBillingDetails = payload
        state.loading.getBillingDetails = false
      })
      .addCase(getShipperBillingDetails.rejected, (state, { payload }) => {
        toast.error(getErrorString(payload, 'Failed to get billing details'))
        state.loading.getBillingDetails = false
      })
      .addCase(getShipperTeamDetails.pending, state => {
        state.loading.getTeamDetails = true
      })
      .addCase(getShipperTeamDetails.fulfilled, (state, { payload }) => {
        state.shipperTeamDetails = payload
        state.loading.getTeamDetails = false
      })
      .addCase(getShipperTeamDetails.rejected, (state, { payload }) => {
        toast.error(getErrorString(payload, 'Failed to get team details'))
        state.loading.getTeamDetails = false
      })
      .addCase(getShipperProfile.fulfilled, (state, { payload }) => {
        state.shipperProfile = payload.companyDetail
        state.shipperAddress = payload.location
      })
      .addCase(getShipperProfile.rejected, (state, { payload }) => {
        toast.error(getErrorString(payload, 'Failed to get account details'))
      })
      .addCase(updateShipperProfile.pending, state => {
        state.loading.updateShipperProfile = true
      })
      .addCase(updateShipperProfile.fulfilled, state => {
        state.loading.updateShipperProfile = false
        toast.success('Successfully updated company details')
      })
      .addCase(updateShipperProfile.rejected, (state, { payload }) => {
        state.loading.updateShipperProfile = false
        toast.error(getErrorString(payload, 'Failed to update company details'))
      })
      .addCase(updateUserProfile.pending, state => {
        state.loading.updateUserProfile = true
      })
      .addCase(updateUserProfile.fulfilled, state => {
        state.loading.updateUserProfile = false
        toast.success('Successfully updated account details')
      })
      .addCase(updateUserProfile.rejected, (state, { payload }) => {
        state.loading.updateUserProfile = false
        toast.error(getErrorString(payload, 'Failed to update account details'))
      })
      .addCase(getUserToken.pending, (state, { meta }) => {
        state.loading.getUserToken = meta.arg
      })
      .addCase(getUserToken.fulfilled, (state, { meta }) => {
        state.loading.getUserToken = false
        state.companyId = meta.arg
      })
      .addCase(getUserToken.rejected, state => {
        state.loading.getUserToken = false
      })
      .addCase(markStepAsComplete.pending, state => {
        state.loading.markStepAsComplete = true
      })
      .addCase(markStepAsComplete.fulfilled, state => {
        state.loading.markStepAsComplete = false
      })
      .addCase(markStepAsComplete.rejected, state => {
        state.loading.markStepAsComplete = false
      })
  },
})

export const {
  resetLogin,
  setCredentials,
  setStep,
  setCompanyId,
  resetCustomerCompanies,
  setRegister,
  setShowFilters,
  setTutorialWizardVisible,
  setCurrentStep,
  setError,
  patchCredentials,
  setUser,
} = userSlice.actions

export default userSlice.reducer
