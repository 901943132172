import { Button, ButtonType, ConfirmationDialog, Link, TextInput } from '@components'
import { CalendarDaysIcon } from '@heroicons/react/24/solid'
import dayjs from 'dayjs'
import { useCallback, useEffect, useState } from 'react'
import tw from 'tailwind-styled-components'

import { useAppSelector, useAppThunkDispatch } from '../../../app/hooks'
import odQuotePlaceholder from '../../../assets/images/quotingTool/odQuotePlaceholder.png'
import { trackEvent } from '../../../common/tracking'
import { CustomerQuote } from '../../../common/types'
import { displayDateWithWeekDay, formatCurrency } from '../../../common/utils'
import { setSingleLaneQuote } from '../../../redux/commonSlice'
import { confirmQuoteEDI, setQuoteDetails } from '../../../redux/createNewLoadSlice'
import { getAllQuotes } from '../../../redux/quotingToolSlice'
import { LoadCreationModal } from './LoadCreationModal'

type TransientProps = {
  $booked?: boolean
  $hasLoad: boolean
  $today: boolean
  $isEDI: boolean
  $isMainQuote?: boolean
}

const QuoteBottomContent = ({ quote }: { quote: CustomerQuote }) => {
  const dispatch = useAppThunkDispatch()

  const handleButtonClick = useCallback(() => {
    dispatch(setQuoteDetails(quote))
  }, [quote])

  if (!quote.confirmed && !quote.reserved && !quote.load)
    return (
      <Button
        fullWidth
        className='mt-2 w-full'
        innerClassName='px-2'
        type='warn'
        onClick={handleButtonClick}
      >
        Create Load
      </Button>
    )

  if (quote.confirmed || (!quote.confirmed && quote.load && quote.manualQuote))
    return (
      <LoadLink className='text-base font-bold' to={`/loads/${quote.load}`}>
        Load #{quote.load?.toString()}
      </LoadLink>
    )

  if (!quote.confirmed && quote.reserved) {
    return <div className='text-sm font-semibold mt-2'>Awaiting EDI Tender</div>
  }

  return <></>
}

export const Quote = ({
  quote,
  className,
  onConfirm,
  bookButtonType,
  isMainQuote,
  isOD,
  showButtons = true,
}: {
  quote: CustomerQuote
  className?: string
  onConfirm?: () => void
  bookButtonType?: ButtonType
  isMainQuote?: boolean
  isOD?: boolean
  showButtons?: boolean
}) => {
  const [isModalVisible, setModalVisible] = useState(false)
  const [isRefIDModalVisible, setRefIDModalVisible] = useState(false)
  const [isConfirmModalVisible, setConfirmModalVisible] = useState(false)
  const [refId, setRefId] = useState('')

  const dispatch = useAppThunkDispatch()

  const bookLoading = useAppSelector(state => state.createNewLoad.loading.createLoad)
  const singleLaneQuote = useAppSelector(state => state.common.singleLaneQuote)
  const isBookQuoteModalVisible = useAppSelector(
    state => state.createNewLoad.isBookQuoteModalVisible,
  )
  const createLoadLoading = useAppSelector(state => state.createNewLoad.loading.createLoad)

  const isToday = dayjs(quote.pickupDate).isSame(dayjs(), 'day')
  const isTodayAndNotPurchased = isToday && !quote.rowBooked
  const isPast = dayjs(quote.pickupDate).isBefore(dayjs(), 'day')

  const currentHourInEST = dayjs.tz(dayjs(), 'America/New_York').format('HH')
  const isTodayAndPast2EST = isToday && Number(currentHourInEST) >= 14
  const isRefIdSaveDisabled = !refId
  const rpm = Number(quote.price) / quote.miles

  const transientProps = {
    $booked: quote.reserved,
    $hasLoad: quote.confirmed || (!quote.confirmed && !!quote.load && !!quote.manualQuote) || false,
    $today: isTodayAndNotPurchased,
    $isEDI: quote.reserved ?? false,
    $isMainQuote: isMainQuote,
  }

  useEffect(() => {
    if (!createLoadLoading) setModalVisible(isBookQuoteModalVisible)
  }, [isBookQuoteModalVisible, quote?.load, createLoadLoading])

  useEffect(() => {
    if (!isConfirmModalVisible) setRefId('')
  }, [isConfirmModalVisible])

  const handleConfirmRefId = () => {
    if (!isRefIdSaveDisabled) {
      setRefIDModalVisible(false)
      setConfirmModalVisible(true)
      trackEvent('Entered reference ID and clicked Save')
    }
  }

  const handleBook = async () => {
    trackEvent('Clicked to finalize booking')
    const referenceId = quote.refId || refId
    const response = await dispatch(confirmQuoteEDI({ ...quote, refId: referenceId }))
    if (response.meta.requestStatus === 'rejected') {
      setConfirmModalVisible(false)
      setRefIDModalVisible(true)
      return
    }
    dispatch(getAllQuotes())
    setConfirmModalVisible(false)
    if (quote.id === singleLaneQuote?.quoteId)
      dispatch(
        setSingleLaneQuote({
          ...singleLaneQuote,
          quotes: singleLaneQuote?.quotes.map((slQuote: CustomerQuote) => ({
            ...slQuote,
            reserved: slQuote.pickupDate === quote.pickupDate,
            refId: referenceId,
          })),
        }),
      )
  }

  return (
    <>
      <Box className={className} {...transientProps}>
        <Day {...transientProps}>
          <CalendarDaysIcon className='w-5 text-dark-gray mr-2' />
          {displayDateWithWeekDay(quote.pickupDate)}
        </Day>
        {isOD && !quote.price && (
          <div className='flex flex-col items-center'>
            <img alt='empty' className='w-[95px] my-4' src={odQuotePlaceholder} />
            <PlaceholderText>
              Our Pulse expert will review the information and respond within 24 hours
            </PlaceholderText>
          </div>
        )}
        {!!quote.price && (
          <>
            <Total {...transientProps}>Total {formatCurrency(quote.price)}</Total>
            <Rpm $bold={isTodayAndNotPurchased || quote.confirmed} $hasLoad={quote.confirmed}>
              RPM {formatCurrency(rpm)}
            </Rpm>
          </>
        )}
        {!quote.rowBooked &&
          !isPast &&
          !isTodayAndPast2EST &&
          !quote.confirmed &&
          showButtons &&
          !quote.load && (
            <Button
              fullWidth
              className='mt-3 w-full'
              innerClassName='px-2'
              type={bookButtonType || 'primary'}
              onClick={() => {
                trackEvent('Clicked Buy Now')
                if (onConfirm) onConfirm()
                else setModalVisible(true)
              }}
            >
              Book This Rate
            </Button>
          )}
        {(quote.reserved ||
          quote.confirmed ||
          (!quote.confirmed && quote.load && quote.manualQuote)) && (
          <QuoteBottomContent quote={quote} />
        )}
      </Box>
      <ConfirmationDialog
        confirmButtonText='Save'
        confirmButtonType='primary'
        isConfirmButtonDisabled={isRefIdSaveDisabled}
        isVisible={isRefIDModalVisible}
        setVisible={setRefIDModalVisible}
        title='Information required'
        onConfirm={handleConfirmRefId}
      >
        <>
          <div className='mb-3'>Please enter Reference ID to complete your purchase</div>
          <TextInput
            sm
            value={refId}
            onChange={(refId: string) => setRefId(refId)}
            onKeyDown={(event: any) => event.code === 'Enter' && handleConfirmRefId()}
          />
        </>
      </ConfirmationDialog>
      <ConfirmationDialog
        confirmButtonLoading={bookLoading}
        confirmButtonText='Confirm & Book'
        confirmButtonType='success'
        isVisible={isConfirmModalVisible}
        setVisible={setConfirmModalVisible}
        title='Quote Summary'
        onConfirm={handleBook}
      >
        <div>
          <div className='text-center font-semibold'>Please review your quote details</div>
          <Row>
            <Label>Ref ID</Label>
            <Value>{quote.refId || refId}</Value>
          </Row>
          <Row>
            <Label>Origin</Label>
            <Value>{quote.origin}</Value>
          </Row>
          <Row>
            <Label>Destination</Label>
            <Value>{quote.destination}</Value>
          </Row>
          <Row>
            <Label>Pickup Date</Label>
            <Value>{displayDateWithWeekDay(quote.pickupDate)}</Value>
          </Row>
          <Row>
            <Label>RPM</Label>
            <Value>{formatCurrency(rpm)}</Value>
          </Row>
          <Row>
            <div className='font-semibold'>Total Amount</div>
            <Value className='text-base'>{formatCurrency(quote.price)}</Value>
          </Row>
        </div>
      </ConfirmationDialog>
      <LoadCreationModal
        isModalVisible={isModalVisible}
        quote={quote}
        setModalVisible={setModalVisible}
      />
    </>
  )
}

const Box = tw.div<TransientProps>`
  border
  border-dashed
  border-border-gray
  rounded-lg
  flex
  flex-col
  items-center
  justify-between
  min-h-32
  p-4
  min-w-[210px]
  min-h-[162px]
  ${({ $hasLoad, $today, $booked, $isEDI }) =>
    ($isEDI && 'bg-orange-bg border-brand-accent') ||
    ($hasLoad && 'bg-success-bg border-success') ||
    ($today && !$booked && 'bg-white') ||
    ($booked && 'bg-success text-white')}
  ${({ $isMainQuote }) => $isMainQuote && 'border-solid'}
`

const Rpm = tw.div<{ $bold?: boolean; $hasLoad?: boolean }>`
  mt-2
  text-sm
  ${({ $bold }) => $bold && 'font-semibold'}
  ${({ $hasLoad }) => $hasLoad && 'text-dark-gray'}
`

const Day = tw.div<TransientProps>`
  whitespace-nowrap
  text-brand-dark
  text-sm
  flex
  items-center
  ${({ $booked, $hasLoad }) => $booked && !$hasLoad && 'text-white'}
  ${({ $hasLoad, $isEDI }) => ($hasLoad || $isEDI) && 'font-semibold text-brand-dark'}
`

const Total = tw.div<TransientProps>`
  font-bold
  mt-2
  text-xl
  whitespace-nowrap
  ${({ $today, $booked }) => ($today && !$booked && 'text-success') || ($booked && 'text-white')}
  ${({ $hasLoad, $isEDI }) => ($hasLoad || $isEDI) && 'text-brand-dark'}
  ${({ $isMainQuote }) => $isMainQuote && 'text-2xl'}
`

const Row = tw.div`
  flex
  justify-between
  mt-4
`

const Label = tw.div`
  text-dark-gray
`

const Value = tw.div`
  font-semibold
`

const LoadLink = tw(Link)`
  text-link
  text-sm
  font-semibold
  hover:underline mt-1
`

const PlaceholderText = tw.div`
  font-bold
  text-link
  text-center
  text-base
`
