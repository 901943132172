import { Button } from '@components'
import tw from 'tailwind-styled-components'

import { useAppSelector, useAppThunkDispatch } from '../../app/hooks'
import { setCurrentStep, setTutorialWizardVisible } from '../../redux/userSlice'
import welcome from './images/welcome.png'

export const WelcomeStep = () => {
  const dispatch = useAppThunkDispatch()

  const user = useAppSelector(state => state.user.user)

  return (
    <Container>
      <img alt='welcome' className='w-[332px] mb-10' src={welcome} />
      <div className='font-semibold text-2xl mb-4'>
        Hey {user?.firstName},
        <br />
        Welcome to Pulse!
      </div>
      <div className='text-sm leading-6'>
        <div className='font-semibold mb-4'>Ready to become an expert?</div>Start our quick tutorial
        to unlock
        <br />
        all the features and make the most of your experience with Pulse
      </div>
      <div className='mt-10 flex gap-4'>
        <Button type='secondary' onClick={() => dispatch(setTutorialWizardVisible(false))}>
          Skip Tutorial
        </Button>
        <Button type='light-blue' onClick={() => dispatch(setCurrentStep('START'))}>
          Explore Pulse
        </Button>
      </div>
    </Container>
  )
}

const Container = tw.div`
  text-center
  flex
  flex-col
  items-center
  justify-center
  px-8
  pb-10
  lg:w-[800px]
`
