import { LatLng } from '@common'
import { LoaderSpinner, Map, Marker, MarkerType } from '@components'
import tw from 'tailwind-styled-components'

import { api } from '../../../api/api'
import { useAppSelector } from '../../../app/hooks'
import { CustomerQuote, DayQuote, QuoteItem } from '../../../common/types'
import { displayCityAndState } from '../../../common/utils'
import { NoQuoteForSelectedDate } from './NoQuoteForSelectedDate'
import { ODQuoteInfo } from './ODQuoteInfo'
import { Quote } from './Quote'
import { QuoteHeader } from './QuoteHeader'
import { QuotesPlaceholder } from './QuotesPlaceholder'

export const QuoteDetails = ({
  currentQuote,
  showQuoteId = true,
  loadPickupDate,
  showOtherQuotes = true,
  showButtons = true,
  containerClassName,
}: {
  currentQuote?: QuoteItem | null
  showQuoteId?: boolean
  loadPickupDate?: string
  showOtherQuotes?: boolean
  showButtons?: boolean
  containerClassName?: string
}) => {
  const singleLaneLoading = useAppSelector(state => state.common.loading.singleLaneQuote)
  const currentQuoteLoading = useAppSelector(state => state.quotingTool.loading.currentQuote)
  const singleLaneQuote = useAppSelector(state => state.common.singleLaneQuote)

  const loading = singleLaneLoading || currentQuoteLoading

  const quote = currentQuote || singleLaneQuote

  const pickupDate = loadPickupDate || quote?.loadPickupDate

  // Displayed for quotes without special requirements
  const quotesNearPickupDate = quote?.quotes?.filter(
    (dayQuote: DayQuote) => dayQuote?.pickupDate !== pickupDate,
  )

  const quoteWithSelectedPickupDate =
    quote?.quotes?.find((quote: DayQuote) => quote?.pickupDate === pickupDate) || null

  if (!quote?.id && !loading) return <QuotesPlaceholder />

  if (loading)
    return (
      <Loader
        className={currentQuoteLoading ? 'max-lg:hidden left-[50%]' : 'max-lg:hidden left-[60%]'}
      />
    )

  return (
    <div className='flex items-center justify-center'>
      <Container className={containerClassName}>
        <QuoteHeader quote={quote} showQuoteId={showQuoteId} />
        <div className='flex max-lg:flex-col w-full gap-x-4'>
          <Map
            className='w-full rounded-lg max-lg:mb-4'
            mapClassName='h-[224px] w-full rounded-lg'
            routeAPI={api}
          >
            {quote.origin && <Marker marker={MarkerType.ORIGIN} position={quote.origin} />}
            {quote.destination && (
              <Marker marker={MarkerType.DESTINATION} position={quote.destination} />
            )}
            {quote.stopovers?.map((stop: LatLng, index: number) => (
              <Marker key={index} marker={MarkerType.STOP} position={stop} />
            ))}
          </Map>
          <div className='w-full'>
            {quoteWithSelectedPickupDate || quote.manualQuote ? (
              <Quote
                isMainQuote
                bookButtonType='success'
                className='w-full h-full'
                isOD={quote.manualQuote}
                quote={{
                  ...quoteWithSelectedPickupDate,
                  miles: quote.miles,
                  id: quote.id,
                  refId: quote.customerReferenceId,
                  origin: displayCityAndState(quote.origin?.city, quote.origin?.state),
                  destination: displayCityAndState(
                    quote.destination?.city,
                    quote.destination?.state,
                  ),
                  originLocation: quote.origin,
                  destinationLocation: quote.destination,
                  rowBooked: quote.quotes?.some(
                    (quote: CustomerQuote) => quote.reserved || quote.load || quote.confirmed,
                  ),
                  equipmentType: quote.equipmentType,
                  load: quote.load,
                  ...(quote.manualQuote && {
                    pickupDate: quote.loadPickupDate,
                    price: parseFloat(quote.manualQuote.customerPrice),
                  }),
                  manualQuote: quote.manualQuote,
                  stopovers: quote.stopovers,
                }}
                showButtons={
                  showButtons &&
                  (quote.manualQuote ? !!parseFloat(quote.manualQuote.customerPrice) : true)
                }
              />
            ) : (
              <NoQuoteForSelectedDate />
            )}
          </div>
        </div>
        {!!quotesNearPickupDate.length && showOtherQuotes && (
          <div className='mt-8'>
            <SectionHeader>Quotes Near Your Entered Date</SectionHeader>
            <QuotesList>
              {quotesNearPickupDate.map((dayQuote: CustomerQuote) => (
                <div key={dayQuote.pickupDate} className='mr-2'>
                  <Quote
                    className='mr-2'
                    quote={{
                      ...dayQuote,
                      miles: quote.miles,
                      id: quote.id,
                      refId: quote.customerReferenceId || dayQuote.refId,
                      origin: displayCityAndState(quote.origin?.city, quote.origin?.state),
                      destination: displayCityAndState(
                        quote.destination?.city,
                        quote.destination?.state,
                      ),
                      rowBooked: quote.quotes?.some(
                        (quote: CustomerQuote) => quote.reserved || quote.load || quote.confirmed,
                      ),
                      equipmentType: quote.equipmentType,
                      load: quote.load,
                    }}
                  />
                </div>
              ))}
            </QuotesList>
          </div>
        )}
        {quote.manualQuote && (
          <div className='mt-8'>
            <SectionHeader>Item Details</SectionHeader>
            <ODQuoteInfo data={quote} />
          </div>
        )}
      </Container>
    </div>
  )
}

const QuotesList = tw.div`
  flex
  flex-nowrap
  overflow-auto 
  hide-scrollbar
`

const SectionHeader = tw.div`
  font-bold
  text-base
  mb-6
`

const Container = tw.div`
  p-4
  lg:py-6
  bg-white
  rounded-lg
  m-4
  pb-6
  border
  border-border-gray
  mb-6
  max-w-[1000px]
  lg:px-10
  max-lg:w-full
  lg:min-w-[50vw]
  max-lg:h-[calc(100vh-245px)]
  max-lg:overflow-auto
`

const Loader = tw(LoaderSpinner)`
  absolute
  top-[45%]
`
