import { XMarkIcon } from '@heroicons/react/24/outline'
import { useEffect, useState } from 'react'

import offTrackImg from '../../assets/images/customer-support.svg'
import onTrackImg from '../../assets/images/location_happy.svg'
import {
  LoadStatus,
  LoadStatusUpdateData,
  offTrackTransitStatusOptions,
  onTrackTransitStatusOptions,
} from '../../common'
import { AlphaModal, Button, DateInput, Form, Select, TextArea, TextInput } from '..'

export const LoadStatusUpdateModal = ({
  isCarrier = false,
  isVisible,
  loading,
  loadStatus,
  locationOptions,
  handleClose,
  handleSave,
  setVisible,
  withDateTime,
}: {
  isCarrier?: boolean
  isVisible: boolean
  loading: boolean
  loadStatus: LoadStatus.ON_TRACK | LoadStatus.OFF_TRACK
  locationOptions: { options: { name: string; value: number }[]; loadId: number } | null
  handleClose: () => void
  handleSave: (data: LoadStatusUpdateData) => void
  setVisible: (visible: boolean) => void
  withDateTime?: boolean
}) => {
  const [formData, setFormData] = useState({
    loadId: 0,
    locationId: 0,
    notes: '',
    status: '',
    transitStatus: '',
    date: null,
    time: '',
  })

  const isOnTrack = loadStatus === LoadStatus.ON_TRACK
  const stopLabel = getStopLabel(isOnTrack, isCarrier)
  const statusLabel = getStatusLabel(isOnTrack, isCarrier)
  const statusOptions = isOnTrack ? onTrackTransitStatusOptions : offTrackTransitStatusOptions
  const isFormValid = Boolean(formData.loadId && formData.locationId && formData.transitStatus)

  useEffect(() => {
    if (locationOptions) {
      const { loadId } = locationOptions

      setFormData({
        ...formData,
        loadId,
        status: loadStatus,
      })
    }
    if (!isVisible)
      setFormData({
        ...formData,
        date: null,
        time: '',
        notes: '',
        locationId: 0,
        transitStatus: '',
      })
  }, [locationOptions, loadStatus, statusOptions, isVisible])

  return (
    <AlphaModal
      className='w-[90%] lg:w-[580px]'
      isVisible={isVisible}
      setVisible={setVisible}
      showFooter={false}
      showHeader={false}
    >
      <div className='flex flex-col gap-4 p-8'>
        <div className='flex items-center'>
          <h1 className='text-2xl font-bold text-center w-full'>
            Status Update:{' '}
            {isOnTrack ? (
              <span className='text-success'>On Track</span>
            ) : (
              <span className='text-red-400'>Off Track</span>
            )}
          </h1>
          <XMarkIcon className='cursor-pointer w-6' onClick={handleClose} />
        </div>
        <div className='flex justify-center'>
          <img alt='' className='h-48' src={isOnTrack ? onTrackImg : offTrackImg} />
        </div>
        <div className='flex gap-4'>
          <div className='w-1/2'>
            <Select
              extractValue
              required
              sm
              choices={locationOptions?.options || []}
              field='name'
              label={stopLabel}
              value={formData.locationId}
              valueField='value'
              onChange={(locationId: number) => setFormData({ ...formData, locationId })}
            />
          </div>
          <div className='w-1/2'>
            <Select
              extractValue
              required
              sm
              choices={statusOptions}
              field='name'
              label={statusLabel}
              value={formData.transitStatus}
              valueField='value'
              onChange={(transitStatus: string) => setFormData({ ...formData, transitStatus })}
            />
          </div>
        </div>
        {withDateTime && (
          <Form className='flex gap-4'>
            <DateInput
              className='w-full'
              label='Date'
              value={formData.date}
              onChange={(date: any) => setFormData({ ...formData, date })}
            />
            <TextInput
              sm
              className='w-full'
              label='Time'
              type='time'
              unmask={false}
              value={formData.time}
              onChange={time => setFormData({ ...formData, time })}
            />
          </Form>
        )}
        <TextArea
          sm
          className='w-full'
          label='Optionally provide some notes or explanation for our team'
          value={formData.notes}
          onChange={notes => setFormData({ ...formData, notes })}
        />
        <Button
          fullWidth
          lg
          className='w-full'
          disabled={!isFormValid || loading}
          loading={loading}
          type='success'
          onClick={() => handleSave(formData)}
        >
          Save
        </Button>
      </div>
    </AlphaModal>
  )
}

const getStopLabel = (isOnTrack: boolean, isCarrier: boolean) => {
  if (isOnTrack) {
    return isCarrier ? 'Where are you heading to now?' : 'Where are they heading to now?'
  }
  return isCarrier ? 'Where were you headed?' : 'Where were they headed?'
}

const getStatusLabel = (isOnTrack: boolean, isCarrier: boolean) => {
  if (isOnTrack) {
    return isCarrier ? 'What is your status?' : 'What is their status?'
  }
  return isCarrier
    ? 'Please select a reason you are off track'
    : 'Please select a reason they are off track'
}
