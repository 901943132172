import { useMemo } from 'react'
import tw from 'tailwind-styled-components'

import { toTransient, Transient } from '../../common'

export type ToggleProps = {
  value: boolean
  onChange?: (value: boolean) => void
  className?: string
  disabled?: boolean
  size?: 'xs' | 'sm' | 'md' | 'lg'
  square?: boolean
}

type $ToggleProps = Transient<ToggleProps>

export const Toggle = ({
  value = false,
  onChange = () => {},
  className = '',
  disabled = false,
  size = 'xs',
  square,
}: ToggleProps) => {
  const width = useMemo(
    () =>
      size == 'xs' ? 'w-[30px]' : size == 'sm' ? 'w-10' : size == 'md' ? 'w-[50px]' : 'w-[60px]',
    [size],
  )

  const $props = useMemo(
    () =>
      toTransient({
        value,
        disabled,
        size,
        width,
        square,
      }),
    [size, value],
  )

  return (
    <Switch {...$props} className={`${className} ${width}`}>
      <SwitchCheckbox
        data-testid='switch-checkbox'
        {...$props}
        checked={value}
        type='checkbox'
        onChange={() => !disabled && onChange(!value)}
      />
      <SwitchButton {...$props} />
    </Switch>
  )
}

const Switch = tw.label<$ToggleProps>`
  cursor-pointer
  rounded-full
  border-2
  border-gray-500
  border-solid
  relative
  transition-colors
  duration-200
  box-content
  block
  select-none
  whitespace-nowrap
  uppercase
  
  ${($p: $ToggleProps) =>
    $p.$size == 'xs' ? 'h-3' : $p.$size == 'sm' ? 'h-4' : $p.$size == 'md' ? 'h-5' : 'h-6'}
  ${($p: $ToggleProps) => $p.$value && 'border-success bg-success'}
  ${($p: $ToggleProps) => !$p.$value && 'border-dark-gray bg-dark-gray'}
  ${($p: $ToggleProps) => $p.$disabled && 'opacity-50 cursor-default'}
  ${($p: $ToggleProps) => $p.$square && 'rounded-md'}
`

const SwitchButton = tw.div<$ToggleProps>`
  absolute
  z-2
  duration-200
  bg-white
  shadow-md
  rounded-full
  left-[4%]
  peer-checked:left-[calc(96%)]
  peer-checked:-translate-x-full
  peer-active:w-3/5

  ${($p: $ToggleProps) =>
    $p.$size == 'xs'
      ? `top-[10%] h-2.5 w-2.5`
      : $p.$size == 'sm'
        ? `top-[12%] h-3 w-3`
        : $p.$size == 'md'
          ? `top-[14%] h-3.5 w-3.5`
          : `top-[12%] h-[17px] w-[17px]`}
  ${($p: $ToggleProps) => $p.$square && 'rounded-sm'}
`

const SwitchCheckbox = tw.input`
  h-0
  w-0
  absolute
  hidden
  peer
`
