import { MapPinIcon } from '@heroicons/react/24/solid'
import React, { FormEvent, forwardRef, useEffect, useMemo, useState } from 'react'
import Autosuggest from 'react-autosuggest'
import tw from 'tailwind-styled-components'

import {
  CityLocation,
  countryAbbreviationToName,
  formatPopularLocation,
  getNormalizedQuery,
  getPopularCitiesSearchResults,
  getStateNameFromCode,
  mapCountryCode,
} from '../../common'
import { HighlightedMatchingText } from '../highlightedMatchingText'
import { LoaderSpinner } from '../loaders'

export type PopularCityLocation = [string, string, string, number, number]

export type CityAutocompleteProps = {
  placeholder?: string
  label?: string
  className?: string
  required?: boolean
  countries: string[]
  choices: CityLocation[]
  disabled?: boolean
  popularCities: PopularCityLocation[]
  setChoices: (choices: CityLocation[]) => void
  onGeocode: (query: string) => void
  cities: CityLocation[]
  value: CityLocation | null
  onChange: (value: CityLocation | null) => void
  onInputChange?: (query: string) => void
  loading?: boolean
  id?: string // provide id if there is more than one CityAutocomplete on the page
  style?: React.CSSProperties
  error?: string
  errorClassname?: string
  showAsterisk?: boolean
  withIcon?: boolean
}

export const CityAutosuggest = forwardRef<HTMLInputElement, CityAutocompleteProps>(
  (
    {
      value = null,
      placeholder = '',
      label = '',
      onChange,
      className = '',
      required = false,
      countries = [],
      choices = [],
      setChoices,
      popularCities,
      onGeocode,
      cities = [],
      disabled,
      loading,
      id,
      style = {},
      error,
      errorClassname,
      showAsterisk = true,
      withIcon,
    },
    ref,
  ) => {
    const [query, setQuery] = useState<string>(value?.title || '')
    const [inputFocused, setInputFocused] = useState(false)
    useEffect(() => {
      setQuery(value === null ? '' : value?.title || '')
    }, [value])

    useEffect(() => {
      const results = cities.map(city => ({
        ...city,
        title: `${city.city}, ${
          city.postalCode ? `${city.postalCode}, ` : ''
        }${getStateNameFromCode(city.state, city.country)}, ${countryAbbreviationToName(
          city.country,
        )}`,
        country: mapCountryCode(city.country),
      }))
      setChoices(results)
    }, [cities])

    const isIncompletePostalCode = (query: string): boolean => {
      const usZipIncomplete = /^(\d{1,4}|\d{5}-\d{1,3})$/
      const canadaPostalIncomplete = /^([A-Za-z]\d[A-Za-z]|\d[A-Za-z]|\d|[A-Za-z]\d[A-Za-z]\s)$/
      const mexicoPostalIncomplete = /^\d{1,4}$/

      return (
        usZipIncomplete.test(query) ||
        canadaPostalIncomplete.test(query) ||
        mexicoPostalIncomplete.test(query)
      )
    }

    const onSearch = (query: string) => {
      if (query) {
        const newQuery = getNormalizedQuery(query)

        if (isIncompletePostalCode(newQuery)) {
          setChoices([])
          return
        }

        const searchResults = getPopularCitiesSearchResults(popularCities, newQuery, countries)

        const results: CityLocation[] = (searchResults as PopularCityLocation[]).map(
          formatPopularLocation,
        )
        if (results.length < 3) onGeocode(newQuery)
        else setChoices(results)
      }
    }

    const getSuggestionValue = (suggestion: CityLocation) => suggestion.title || ''

    const renderSuggestion = (suggestion: CityLocation) => (
      <HighlightedMatchingText query={query} text={suggestion.title ?? ''} />
    )

    const onSuggestionsClearRequested = () => setChoices([])

    const onSuggestionSelected = (event: FormEvent, { suggestion }: { suggestion: CityLocation }) =>
      onChange({ ...suggestion, country: mapCountryCode(suggestion.country) })

    const handleChange = (event: FormEvent<HTMLElement>, { newValue }: { newValue: string }) => {
      setQuery(newValue)
      if (!newValue) onChange(null)
    }

    const handleBlur = (
      event: React.FocusEvent<HTMLElement>,
      params?: { highlightedSuggestion: CityLocation },
    ) => {
      const highlightedSuggestion = params?.highlightedSuggestion
      if (highlightedSuggestion)
        onChange({
          ...highlightedSuggestion,
          country: mapCountryCode(highlightedSuggestion.country),
        })
      else if (value === null) setQuery('')
      setInputFocused(false)
    }

    const InputComponent: React.FC<React.InputHTMLAttributes<HTMLInputElement>> = useMemo(
      () => props => (
        <div className='relative'>
          <AutocompleteInput ref={ref} $disabled={disabled} {...props} />
          {loading && inputFocused && (
            <LoaderSpinner className='absolute top-2.5 right-1.5' size={3} />
          )}
        </div>
      ),
      [loading, value, ref],
    )

    const inputProps = {
      placeholder,
      value: query || '',
      onChange: handleChange,
      onBlur: handleBlur,
      onFocus: () => setInputFocused(true),
      disabled,
    }

    return (
      <div className={`relative ${className}`} style={style}>
        {label && (
          <Label>
            {label} {required && showAsterisk && <span className='text-error'>*</span>}
          </Label>
        )}
        {withIcon && <MapPin $label={label} />}
        <Autosuggest
          focusInputOnSuggestionClick={false}
          getSuggestionValue={getSuggestionValue}
          id={id}
          inputProps={inputProps}
          multiSection={false}
          renderInputComponent={InputComponent}
          renderSuggestion={renderSuggestion}
          suggestions={loading ? [] : choices}
          theme={{
            input: withIcon ? '!pl-7' : '',
            suggestionsContainerOpen:
              'border border-border-gray absolute mt-1 rounded-md bg-white w-full overflow-auto max-h-[200px] z-[9] py-1',
            suggestion: 'cursor-pointer py-2 px-2',
            suggestionHighlighted: 'bg-lighter-blue',
          }}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          onSuggestionSelected={onSuggestionSelected}
          onSuggestionsFetchRequested={({ value }: { value: string }) => onSearch(value)}
        />
        {!!error && <Error className={errorClassname}>{error}</Error>}
      </div>
    )
  },
)

const MapPin = tw(MapPinIcon)<{ $label: string }>`
  text-dark-gray
  absolute
  left-1.5
  z-[8]
  w-4
  ${({ $label }) => ($label ? 'top-7' : 'top-2')}
`

const Error = tw.div`
  text-error
  ml-1
  text-xs
  absolute
  -bottom-4
  whitespace-nowrap
`

const AutocompleteInput = tw.input<{ $disabled?: boolean }>`
  border
  border-border-gray
  rounded-lg
  py-2
  px-3
  w-full
  transition-all
  focus:border-dark-blue
  placeholder:text-dark-gray
  h-8
  ${({ $disabled }) => $disabled && 'bg-lighter-blue'}
`

const Label = tw.label`
  mb-1
  block
  text-dark-gray
  self-start
  font-poppins
  text-xs
`
