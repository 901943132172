import { XMarkIcon } from '@heroicons/react/24/solid'
import tw from 'tailwind-styled-components'

import { Button } from '../button'
import { Form } from '../form'
import { Modal } from './Modal'

type FormModalProps = {
  isVisible?: boolean
  setVisible?: (isVisible: boolean) => void
  children?: React.ReactNode
  onClose?: () => void
  onCancel?: () => void
  onSave?: () => void
  title?: string
  className?: string
  contentClassName?: string
  modalClassName?: string
  setFormValid?: (isValid: boolean) => void
  isFormValid?: boolean
  isSaving?: boolean
}

export const FormModal = ({
  isVisible = true,
  setVisible = () => {},
  onClose = () => setVisible(false),
  onCancel = () => onClose(),
  onSave = () => onClose(),
  children,
  title = '',
  className = '',
  contentClassName = '',
  modalClassName = '',
  isFormValid = true,
  setFormValid = () => {},
  isSaving = false,
}: FormModalProps) => (
  <FormModalContainer
    className={modalClassName}
    isVisible={isVisible}
    setVisible={setVisible}
    showCloseIcon={false}
    onClose={() => {
      setVisible(false)
      onClose()
    }}
  >
    <div className={`flex flex-col px-5 py-6 h-full justify-between gap-6 ${className}`}>
      <div className='flex justify-between'>
        <div className='font-bold text-xl'>{title}</div>
        <CloseIcon onClick={() => onClose()} />
      </div>
      <Form setFormValid={setFormValid}>
        <div className={`flex flex-col gap-3 ${contentClassName}`}>{children}</div>
      </Form>
      <div className='flex gap-2 justify-start w-full'>
        <Button
          fullWidth
          className='basis-1/2'
          innerClassName='rounded-md'
          label='Cancel'
          type='secondary'
          onClick={() => onCancel()}
        />
        <Button
          fullWidth
          className='basis-1/2'
          disabled={!isFormValid}
          innerClassName='rounded-md'
          label='Save'
          loading={isSaving}
          type='success'
          onClick={() => onSave()}
        />
      </div>
    </div>
  </FormModalContainer>
)

const FormModalContainer = tw(Modal)`
    min-w-80
`

const CloseIcon = tw(XMarkIcon)`
  transition
  cursor-pointer
  hover:opacity-70
  stroke-current
  icon
  w-5
  h-5
`
