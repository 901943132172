import { AxiosInstance } from 'axios'
import { debounce } from 'lodash-es'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { useDeepDependencies } from '../../common'
import { MarkerType } from './Marker'
import { computeBoundingBox, isPoint, metersToMiles, onRouteRequest } from './utils'

type MarkerByType = {
  origin?: H.geo.Point
  destination?: H.geo.Point
  stops: H.geo.Point[]
  routeLines: H.map.Polyline[]
  selectedMarker?: H.geo.Point
}

export const useRouteRequest = (map: H.Map | null, markers: MarkerByType, api?: AxiosInstance) => {
  const [routeData, setRouteData] = useState<{ distance: number; polylines: string[] } | undefined>(
    undefined,
  )
  const { origin, destination, stops, routeLines } = markers

  const getRoute = async (origin: H.geo.Point, destination: H.geo.Point, stops: H.geo.Point[]) => {
    if (!api || !origin || !destination) return
    const response = await onRouteRequest({ api, origin, destination, stops })

    // Sum distance of sections, rounding to nearest mile
    const distance = response.data.routes.reduce(
      (total: number, route: any) =>
        total +
        route.sections.reduce(
          (routeTotal: number, section: any) =>
            routeTotal + Math.round(metersToMiles(section.summary.length)),
          0,
        ),
      0,
    )

    const polylines = response.data.routes.reduce(
      (total: string[], route: any) =>
        total.concat(route.sections.map((section: any) => section.polyline)),
      [],
    )

    setRouteData({ distance, polylines })
  }

  const getRouteDebounced = useCallback(
    debounce(
      (origin: H.geo.Point, destination: H.geo.Point, stops: H.geo.Point[]) =>
        getRoute(origin, destination, stops),
      200,
    ),
    [],
  )

  // Request route when origin, destination, or stops change
  useEffect(
    () => {
      if (!map || !api || !origin || !destination) return
      getRouteDebounced(origin, destination, stops)
    },
    useDeepDependencies([origin, destination, stops]),
  )

  // Draw or remove route polylines based on origin, destination, and route polylines
  useEffect(() => {
    if (!map) return

    routeLines.forEach(routeLine => map.removeObject(routeLine))

    if (routeData?.polylines) {
      routeData.polylines.forEach(polyline => {
        const linestring = H.geo.LineString.fromFlexiblePolyline(polyline)
        const routeLine = new H.map.Polyline(linestring, {
          style: {
            lineWidth: 4,
            strokeColor: 'rgba(0, 128, 255, 0.7)',
          },
          data: {},
        })
        map.addObject(routeLine)
      })
    }
  }, [routeLines, routeData?.polylines])

  return { routeDistance: routeData?.distance }
}

export const useMapEvents = (map: H.Map | null, zoom: number) => {
  // Use useMemo to calculate markers and points only when map objects change
  const { markersByType, mapPoints } = useMemo(() => {
    const markersByType: MarkerByType = {
      origin: undefined,
      destination: undefined,
      stops: [],
      routeLines: [],
      selectedMarker: undefined,
    }
    if (!map) return { markersByType, mapPoints: [] }

    const objects = map.getObjects() as H.map.Marker[] | H.map.Polyline[]

    objects.forEach((object: any) => {
      const { isSelected, type } = object.getData()
      const point = object.getGeometry() as H.geo.Point

      if (isSelected) markersByType.selectedMarker = point
      if (object instanceof H.map.Marker) {
        if (type === MarkerType.ORIGIN) markersByType.origin = point
        else if (type === MarkerType.DESTINATION) markersByType.destination = point
        else if (type === MarkerType.STOP) markersByType.stops.push(point)
      } else if (object instanceof H.map.Polyline) {
        markersByType.routeLines.push(object)
      }
    })

    return {
      markersByType,
      mapPoints: objects.map(object => object.getGeometry()).filter(isPoint) as H.geo.Point[],
    }
  }, [map?.getObjects()])

  // Adjust view based on markers
  useEffect(() => {
    if (map && mapPoints.length) {
      if (markersByType.selectedMarker) {
        map.setCenter(markersByType.selectedMarker, true)
      } else {
        const boundingBox = mapPoints.length > 1 ? computeBoundingBox(mapPoints) : null
        if (boundingBox) {
          map.getViewModel().setLookAtData({ bounds: boundingBox }, true)
        } else {
          map.setZoom(zoom)
          map.setCenter(mapPoints[0], true)
        }
      }
    }
  }, [markersByType, mapPoints])

  return { markersByType }
}
