// Mixpanel documentation:
// https://developer.mixpanel.com/docs/javascript-quickstart?projectToken=5f3855549423149ae299d5372460b39e

import mixpanel from 'mixpanel-browser'

import { isProduction } from './constants'

const mixpanelID = '5f3855549423149ae299d5372460b39e'

let isTrackingEnabled = false

if (isProduction) {
  // This is running in production.
  isTrackingEnabled = true
  mixpanel.init(mixpanelID, { ignore_dnt: true })
}

// We use email to identify users in mixpanel and connect their actions across front-end,
// back-end and multiple devices.
// TODO: hook this up to redux events about logging in.
export function identifyUser(userEmail: string, userName: string, force_track = false) {
  if (!isTrackingEnabled && !force_track) return
  // console.log(`Tracking with mixpanel: identifying user ${userEmail}`)
  mixpanel.identify(userEmail)
  mixpanel.people.set({ $email: userEmail, $name: userName })
}

// You can pass information into extra_data in an optional dict.
//
// Example call:
// trackEvent('View Load', {'load_id': 8311})
export function trackEvent(event: string, extra_data?: any, force_track = false) {
  event = `Shipper Portal - ${event}`
  // console.log('Tracking with mixpanel: ', event)
  if (!isTrackingEnabled && !force_track) {
    return
  }
  mixpanel.track(event, extra_data)
}

export function resetTracking() {
  if (isTrackingEnabled) {
    mixpanel.reset()
  }
}
