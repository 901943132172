import { CatchError, formatAxiosErrorToPayload, getErrorString, keysToSnakeCase } from '@common'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import { toast } from 'react-toastify'

import { api } from '../api/api'
import { NotificationTypes } from '../common/constants'
import { keysToCamelCase } from '../common/utils'

export type Notification = {
  id: number
  createdAt: string
  notificationType: NotificationTypes
  read: boolean
  loadId: number | null
  carrierName: string | null
  userFullName: string | null
}

type NotificationsState = {
  loading: {
    getNotifications: boolean
    updateNotificationsSettings: boolean
    loadMoreNotifications: boolean
  }
  notifications: Notification[]
  unreadNotificationsCount: number
  count: number
  isLoadMoreButtonVisible: boolean
}

const initialState: NotificationsState = {
  loading: {
    getNotifications: false,
    updateNotificationsSettings: false,
    loadMoreNotifications: false,
  },
  notifications: [],
  unreadNotificationsCount: 0,
  count: 0,
  isLoadMoreButtonVisible: false,
}

export const getNotifications = createAsyncThunk(
  'notifications/getNotifications',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get('notifications/api/list-customer-app-notifications/', {
        params: { page: 1 },
      })
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const loadMoreNotifications = createAsyncThunk(
  'notifications/loadMoreNotifications',
  async ({ page }: { page?: number }, { rejectWithValue }) => {
    try {
      const response = await api.get('notifications/api/list-customer-app-notifications/', {
        params: { page },
      })
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const updateNotificationsSettings = createAsyncThunk(
  'notifications/updateNotificationsSettings',
  async (
    {
      appNotifications,
      emailNotifications,
    }: { appNotifications?: string[]; emailNotifications?: string[] },
    { rejectWithValue },
  ) => {
    try {
      const response = await api.patch(
        'accounts/api/update-customer-contact/',
        keysToSnakeCase({
          ...(appNotifications && { appNotifications }),
          ...(emailNotifications && { emailNotifications }),
        }),
      )
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const getUnreadNotificationsCount = createAsyncThunk(
  'notifications/getUnreadNotificationsCount',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get('notifications/api/customer-app-notifications-unread-count/')
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const markNotificationsAsRead = createAsyncThunk(
  'notifications/markNotificationAsRead',
  async ({ notificationId }: { notificationId?: number }, { rejectWithValue }) => {
    try {
      await api.patch(
        'notifications/api/update-customer-app-notifications/',
        keysToSnakeCase({
          ...(notificationId ? { readNotificationIds: notificationId } : { markAllRead: true }),
        }),
      )
      return notificationId
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    addNewNotif(state, { payload }) {
      state.notifications = [
        {
          id: payload.notificationId,
          createdAt: dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSSSSZ'),
          notificationType: payload.event,
          read: false,
          loadId: payload.loadId,
          carrierName: payload.carrierName,
          userFullName: payload.userFullName,
        },
        ...state.notifications,
      ]
      state.unreadNotificationsCount += 1
      state.count += 1
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getNotifications.pending, state => {
        state.loading.getNotifications = true
        state.isLoadMoreButtonVisible = false
      })
      .addCase(getNotifications.fulfilled, (state, { payload }) => {
        const { count, results, next } = payload
        state.loading.getNotifications = false
        state.notifications = results
        state.count = count
        state.isLoadMoreButtonVisible = !!next
      })
      .addCase(getNotifications.rejected, (state, { payload }) => {
        state.loading.getNotifications = false
        state.isLoadMoreButtonVisible = false
        toast.error(getErrorString(payload, 'Failed to load notifications'))
      })
      .addCase(loadMoreNotifications.pending, state => {
        state.loading.loadMoreNotifications = true
      })
      .addCase(loadMoreNotifications.fulfilled, (state, { payload }) => {
        const { count, results } = payload
        state.loading.loadMoreNotifications = false
        state.notifications = [...state.notifications, ...results]
        state.count = count
      })
      .addCase(loadMoreNotifications.rejected, (state, { payload }) => {
        state.loading.loadMoreNotifications = false
        toast.error(getErrorString(payload, 'Failed to load notifications'))
      })
      .addCase(updateNotificationsSettings.pending, state => {
        state.loading.updateNotificationsSettings = true
      })
      .addCase(updateNotificationsSettings.fulfilled, state => {
        state.loading.updateNotificationsSettings = false
      })
      .addCase(updateNotificationsSettings.rejected, (state, { payload }) => {
        state.loading.updateNotificationsSettings = false
        toast.error(getErrorString(payload, 'Failed to update notifications settings'))
      })
      .addCase(getUnreadNotificationsCount.fulfilled, (state, { payload }) => {
        state.unreadNotificationsCount = payload.unreadCount
      })
      .addCase(markNotificationsAsRead.fulfilled, (state, { payload }) => {
        if (payload) {
          const notification = state.notifications.find(n => n.id === payload)
          if (notification) {
            notification.read = true
            state.unreadNotificationsCount = Math.max(state.unreadNotificationsCount - 1, 0)
          }
        } else {
          state.notifications.forEach(n => (n.read = true))
          state.unreadNotificationsCount = 0
        }
      })
  },
})

export const { addNewNotif } = notificationsSlice.actions

export default notificationsSlice.reducer
