import tw from 'tailwind-styled-components'

import { IndeterminateCheckbox } from '../IndeterminateCheckbox'

type IndeterminateCheckboxInputProps = {
  title: string
  isChecked: boolean
  onChange: () => void
  someSelected?: boolean
  primary?: boolean
  className?: string
  disabled?: boolean
  contentClassName?: string
}

export const IndeterminateCheckboxInput = ({
  title,
  isChecked,
  onChange,
  someSelected,
  primary,
  className = '',
  disabled,
  contentClassName = '',
}: IndeterminateCheckboxInputProps) => (
  <Container $disabled={disabled} className={className} onClick={() => !disabled && onChange()}>
    <IndeterminateCheckbox
      disabled={disabled}
      isChecked={isChecked}
      primary={primary}
      someSelected={someSelected}
    />
    <Content className={contentClassName}>{title}</Content>
  </Container>
)

const Content = tw.span`
  ml-4
`

const Container = tw.div<{ $disabled?: boolean }>`
  flex
  items-center
  hover:opacity-70
  cursor-pointer
  w-fit
  ${({ $disabled }) => $disabled && 'cursor-not-allowed opacity-50 hover:opacity-50'}
`
