import { formatBoolean } from '@common'
import { Link } from '@components'
import { DocumentIcon } from '@heroicons/react/24/solid'
import { useCallback } from 'react'
import tw from 'tailwind-styled-components'

import { MultiStopQuotingParams, ODQuote } from '../../../common/types'
import { formatCurrency } from '../../../common/utils'

const Item = ({
  label,
  value,
  withIcon,
  link,
}: {
  label?: string
  value?: string
  withIcon?: boolean
  link?: string
}) => {
  const Value = useCallback(
    () => <div className='text-right font-semibold'>{value || '—'}</div>,
    [],
  )

  return (
    <ItemContainer>
      <div>{label}</div>
      <div className='flex items-center'>
        {link ? (
          <Link className='flex items-center' to={link}>
            {withIcon && <DocumentIcon className='w-5 mr-2' />}
            <Value />
          </Link>
        ) : (
          <Value />
        )}
      </div>
    </ItemContainer>
  )
}

export const ODQuoteInfo = ({ data }: { data: MultiStopQuotingParams | ODQuote }) => (
  <>
    {data.item?.name && <Item label='Item Name' value={data.item.name} />}
    {data.item?.weight && <Item label='Weight' value={data.item.weight} />}
    {data.item?.quantity && <Item label='Quantity' value={data.item.quantity} />}
    {(data.item?.width || data.item?.length || data.item?.height) && (
      <Item
        label='W x L x H'
        value={`${data.item?.width || '—'} x ${data.item?.length || '—'} x ${
          data.item?.height || '—'
        }`}
      />
    )}
    {data.specs && (
      <Item withIcon label='Specs File' link={data.specs?.file} value={data.specs?.name} />
    )}
    {!!data.commodity?.length &&
      data.commodity.map(file => (
        <Item
          key={file.id}
          withIcon
          label='Commodity Photo'
          value={file.file?.name || file.name}
          {...(typeof file.file === 'string' && { link: file.file })}
        />
      ))}
    <Item label='Load Insured?' value={formatBoolean(!!data.isInsured)} />
    <Item label='Load Value' value={formatCurrency(data.loadValue)} />
    <Item label='Notes' value={data.notes} />
  </>
)

const ItemContainer = tw.div`
  flex
  justify-between
  mb-3
  h-4
`
