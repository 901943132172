import tw from 'tailwind-styled-components'

export const AppLogo = ({
  type = 'light',
  className,
  onClick,
  withText = true,
  size = 42,
}: {
  type?: 'light' | 'dark'
  className?: string
  onClick?: () => void
  withText?: boolean
  size?: number
}) => (
  <Container $type={type} className={className} onClick={onClick}>
    <svg
      preserveAspectRatio='xMidYMid meet'
      style={{ width: `${size}px`, height: `${size}px` }}
      viewBox='0 0 100 125'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        clipRule='evenodd'
        d='M90.3699 26.5461L45.1849 0L0 26.5461V66.6466V79.6384V121.998L20.3332 109.572V91.5842L20.3343 91.5849V67.2136V58.7423V38.973L45.186 24.8527L70.0378 38.973V67.2136L45.186 81.3339L24.8528 69.7809V94.2395L45.1849 106.185L90.3699 79.6384V26.5461Z'
        fill={type === 'light' ? '#FFFFFF' : '#0099FF'}
        fillRule='evenodd'
      />
    </svg>
    {withText && <div className='text-2xl font-outfit'>Pulse</div>}
  </Container>
)

const Container = tw.div<{ $type: 'light' | 'dark' }>`
  flex
  gap-2
  items-center
  text-white
  font-semibold
  ${({ $type }) => ($type === 'light' ? 'text-white' : 'text-brand-dark')}
`
