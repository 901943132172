import { useCallback, useEffect, useState } from 'react'
import { singletonHook } from 'react-singleton-hook'

import { ConfirmDialogProps } from './ConfirmDialog'

const getInitialState = (): ConfirmDialogProps & {
  isAwaitingConfirm: boolean
  didConfirm: boolean
  resolveConfirmDialog: (didConfirm: boolean) => void
} => ({
  isVisible: false,
  setVisible: () => {},
  onConfirm: () => {},
  isAwaitingConfirm: false,
  didConfirm: false,
  message: '',
  actionLabel: 'Confirm',
  cancelLabel: 'Cancel',
  type: 'success',
  loading: false,
  resolveConfirmDialog: () => {},
  description: '',
})

let setConfirmDialogState: React.Dispatch<
  React.SetStateAction<ReturnType<typeof getInitialState>>
> = () => {}

/**
 * This hook is used to manage the state of the ConfirmDialog component.
 * It resets the state when the dialog is closed or the confirmation button is engaged.
 */
export const useConfirmDialogState = () => {
  const [
    {
      isVisible,
      isAwaitingConfirm,
      didConfirm,
      message,
      actionLabel,
      cancelLabel,
      type,
      loading,
      resolveConfirmDialog,
      description,
    },
    setState,
  ] = useState(getInitialState())

  useEffect(() => {
    if (!isAwaitingConfirm || (isAwaitingConfirm && isVisible && !didConfirm)) return

    if (didConfirm) {
      resolveConfirmDialog(true)
    } else if (!isVisible) {
      resolveConfirmDialog(false)
    }

    setState(getInitialState())
  }, [didConfirm, isVisible, isAwaitingConfirm])

  const setVisible = useCallback(
    (isVisible: boolean) =>
      setState(state => ({
        ...state,
        isVisible,
      })),
    [],
  )

  const onConfirm = useCallback(
    () =>
      setState(state => ({
        ...state,
        didConfirm: true,
      })),
    [],
  )

  setConfirmDialogState = setState

  return {
    isVisible,
    setVisible,
    onConfirm,
    isAwaitingConfirm,
    didConfirm,
    message,
    actionLabel,
    cancelLabel,
    type,
    loading,
    resolveConfirmDialog,
    description,
  }
}

// Creates a singleton instance of the hook, so that the state is shared across all components
export const useConfirmDialogStateSingleton = singletonHook(
  getInitialState(),
  useConfirmDialogState,
)

/**
 * This hook is used to trigger the ConfirmDialog component.
 * @param message The message to display in the dialog
 * @param actionLabel The label of the confirmation button
 * @param cancelLabel The label of the exit button
 * @param type The type of the dialog, 'success' or 'danger'
 * @returns getConfirmation A function that triggers the dialog
 */
export const getConfirmation = ({
  message,
  actionLabel,
  cancelLabel,
  type,
  loading,
  description,
}: Partial<ConfirmDialogProps>) =>
  new Promise<boolean>(resolve =>
    setConfirmDialogState(state => ({
      ...state,
      message,
      ...(actionLabel ? { actionLabel } : {}),
      ...(cancelLabel ? { cancelLabel } : {}),
      ...(type ? { type } : {}),
      ...(typeof loading === 'boolean' ? { loading } : {}),
      isVisible: true,
      isAwaitingConfirm: true,
      resolveConfirmDialog: resolve,
      description,
    })),
  )
