import { Status } from '@components'

import { formatPhone } from '../../../../common/utils'
import { DetailItem } from '../../../DetailItem'

export const Contact = ({
  contact,
}: {
  contact: { name?: string; phone?: string; email?: string }
}) => (
  <>
    <div className='flex justify-between items-center mt-3'>
      <div className='font-bold'>{contact.name}</div>
      <Status background='bg-white' status='dispatch' />
    </div>
    <div className='flex justify-between flex-col'>
      <DetailItem className='mt-1' label='Phone'>
        <span>{formatPhone(contact.phone)}</span>
      </DetailItem>
      <DetailItem className='mt-0' label='Email'>
        <span>{contact.email}</span>
      </DetailItem>
    </div>
  </>
)
