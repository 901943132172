import { CheckIcon, MinusIcon } from '@heroicons/react/24/outline'
import tw from 'tailwind-styled-components'

type IndeterminateCheckboxProps = {
  primary?: boolean
  isChecked: boolean
  onChange?: () => void
  someSelected?: boolean
  disabled?: boolean
  className?: string
}

// This checkbox can have three states: checked, unchecked, and indeterminate.
// It's indeterminate if it's primary (e.g. 'Select all' as opposed to a simple checkbox that triggers a single value)
// Display: check icon when all options are selected, minus icon if some options are selected, no icon if no items are selected
export const IndeterminateCheckbox = ({
  primary,
  isChecked,
  onChange,
  someSelected,
  disabled,
  className = '',
}: IndeterminateCheckboxProps) => (
  <CheckboxContainer
    $checked={isChecked || (primary && someSelected)}
    $className={className}
    $disabled={disabled}
    data-testid='checkbox'
    onClick={onChange}
  >
    {!!primary && !!someSelected && !isChecked && <MinusIcon className='text-white h-4 w-4' />}
    {isChecked && <CheckIcon className='text-white h-4 w-4' />}
  </CheckboxContainer>
)

const CheckboxContainer = tw.div<{ $checked?: boolean; $disabled?: boolean; $className?: string }>`
  rounded-md
  h-6
  w-6
  cursor-pointer
  flex
  items-center
  justify-center
  transition-all
  bg-white
  border
  border-brand-dark
  ${({ $checked }) => $checked && 'bg-success border-success'}
  ${({ $disabled }) => $disabled && 'cursor-not-allowed'}
  ${({ $className }) => $className}
`
