import { Button } from '@components'
import { noop } from 'lodash-es'

import { useAppSelector, useAppThunkDispatch } from '../../app/hooks'
import { markStepAsComplete, setCurrentStep, setTutorialWizardVisible } from '../../redux/userSlice'
import { tutorialSteps } from './steps'

export const Step = () => {
  const dispatch = useAppThunkDispatch()

  const currentStep = useAppSelector(state => state.user.currentStep)
  const loading = useAppSelector(state => state.user.loading.markStepAsComplete)

  const currentStepContent = tutorialSteps[currentStep] || []

  return (
    <div className='lg:w-[1000px]'>
      <div className='max-h-[60vh] overflow-auto p-8'>
        {currentStepContent.map((substep, i) => (
          <div
            key={substep.title}
            className='flex justify-between items-center gap-6 mb-4 last:mb-0'
          >
            <div className='flex flex-col gap-6'>
              <div className='text-sky font-semibold'>
                Step {i + 1}/{currentStepContent.length}
              </div>
              <div className='font-semibold text-base'>{substep.title}</div>
              <div className='leading-5'>{substep.text}</div>
            </div>
            <div className='flex justify-end w-[600px] min-w-[600px]'>
              <img alt='image' className={substep.imageClassName} src={substep.image} />
            </div>
          </div>
        ))}
      </div>
      <div className='flex gap-4 justify-center my-6'>
        <Button
          innerClassName='!border-none'
          type='secondary'
          onClick={() => dispatch(setTutorialWizardVisible(false))}
        >
          Skip Tutorial
        </Button>
        <Button type='secondary' onClick={() => dispatch(setCurrentStep('START'))}>
          Go Back
        </Button>
        <Button
          loading={loading}
          type='success'
          onClick={() =>
            dispatch(markStepAsComplete(currentStep))
              .unwrap()
              .then(() => dispatch(setCurrentStep('START')))
              .catch(noop)
          }
        >
          Complete Step
        </Button>
      </div>
    </div>
  )
}
