import { Action } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { ThunkDispatch } from 'redux-thunk'

import type { AppDispatch, RootState } from './store'

export type ThunkAppDispatch = ThunkDispatch<RootState, void, Action>

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export const useAppThunkDispatch = () => useDispatch<ThunkAppDispatch>()
