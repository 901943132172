import { Menu } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import tw from 'tailwind-styled-components'

import conestoga from '../../../assets/images/equipmentTypes/images/conestoga.png'
import doubledrop from '../../../assets/images/equipmentTypes/images/doubledrop.png'
import flatbed from '../../../assets/images/equipmentTypes/images/flatbed.png'
import hotshot from '../../../assets/images/equipmentTypes/images/hotshot.png'
import reefer from '../../../assets/images/equipmentTypes/images/reefer.png'
import rgn from '../../../assets/images/equipmentTypes/images/rgn.png'
import stepdeck from '../../../assets/images/equipmentTypes/images/stepdeck.png'
import van from '../../../assets/images/equipmentTypes/images/van.png'
import { EaseInOutTransition } from '../../EaseInOutTransition'

const images = [flatbed, reefer, van, stepdeck, doubledrop, rgn, conestoga, hotshot]

export const EquipmentTypeHint = () => (
  <MenuContainer as='div'>
    <Menu.Item>
      <MainButton>?</MainButton>
    </Menu.Item>
    <EaseInOutTransition>
      <Dropdown>
        <Header>
          <div>equipment guide</div>
          <Menu.Item>
            <CloseIcon />
          </Menu.Item>
        </Header>
        <DropdownBody>
          {images.map(image => (
            <img key={image} alt='equipment type' className='w-full py-4' src={image} />
          ))}
        </DropdownBody>
      </Dropdown>
    </EaseInOutTransition>
  </MenuContainer>
)

const DropdownBody = tw.div`
  py-4
  px-6
  h-[482px]
  overflow-y-auto
`

const CloseIcon = tw(XMarkIcon)`
  w-5
  hover:opacity-70
  transition-all
  cursor-pointer
`

const Header = tw.div`
  bg-brand-dark
  uppercase
  text-white
  font-bold
  text-sm
  p-3
  rounded-t-md
  border-l-8
  border-link
  flex
  justify-between
  items-center
`

const MenuContainer = tw(Menu)`
  inline-block
  text-left
  z-[999]
  absolute
  bottom-4
  -left-[60px]
`

const MainButton = tw(Menu.Button)`
  hover:opacity-80
  transition-all
  cursor-pointer
  text-2xl
  bg-brand-dark
  text-white
  rounded-full
  px-4
  py-1
  shadow-lg
`

const Dropdown = tw(Menu.Items)`
  origin-top-right
  rounded-md
  shadow-lg
  ring-1
  ring-black
  ring-opacity-5
  focus:outline-none
  bg-white
  absolute
  right-0
  bottom-0
  left-14
  w-96
  h-[526px]
`
