import tw from 'tailwind-styled-components'

type ProgressCircleProps = {
  progress: number
  color?: string
  circleColor?: string
  size?: number
  strokeWidth?: number
}

export const ProgressCircle = ({
  progress,
  color = '#FFC940',
  circleColor = '#0099FF',
  size = 50,
  strokeWidth = 10,
}: ProgressCircleProps) => {
  const validProgress = Math.min(100, Math.max(0, progress))

  const radius = (size - strokeWidth) / 2
  const circumference = 2 * Math.PI * radius
  const offset = circumference - (validProgress / 100) * circumference

  return (
    <Container height={size} style={{ width: `${size}px` }} width={size}>
      <circle
        cx={size / 2}
        cy={size / 2}
        fill='none'
        r={radius}
        stroke={circleColor}
        strokeWidth={strokeWidth}
      />
      <circle
        cx={size / 2}
        cy={size / 2}
        fill='none'
        r={radius}
        stroke={color}
        strokeDasharray={circumference}
        strokeDashoffset={offset}
        strokeLinecap='round'
        strokeWidth={strokeWidth}
        style={{ transition: 'stroke-dashoffset 0.5s ease' }}
        transform={`rotate(-90 ${size / 2} ${size / 2})`}
      />
      <text
        dy='.4em'
        fill='#000000'
        fontSize='0.8em'
        fontWeight='600'
        textAnchor='middle'
        x='50%'
        y='50%'
      >
        {`${Math.round(validProgress || 0)}%`}
      </text>
    </Container>
  )
}

const Container = tw.svg`
  relative
`
