import tw from 'tailwind-styled-components'

import { useAppSelector } from '../../../../app/hooks'
import { NewManifestItem } from '../../../../common/types'
import { DetailItem } from '../../../DetailItem'

export const ItemView = ({ item, isPickup }: { item: NewManifestItem; isPickup: boolean }) => {
  const loadStops = useAppSelector(state => state.loads.loadStops)
  const from =
    loadStops.find(stop => stop.order === Number(item.from) - 1)?.location?.name || 'Origin'

  if (!isPickup)
    return (
      <div className='flex w-full'>
        <StyledDetailItem className='w-2/5' label='Item Name'>
          {item.name}
        </StyledDetailItem>
        <StyledDetailItem className='w-2/5' label='From'>
          {from || ''}
        </StyledDetailItem>
        <StyledDetailItem className='w-1/5 justify-end' label='Qt'>
          {item.quantity}
        </StyledDetailItem>
      </div>
    )
  return (
    <div className='flex max-lg:flex-col justify-between w-full'>
      <StyledDetailItem className='lg:w-2/5' label='Item Name'>
        {item.name}
      </StyledDetailItem>
      <div className='flex max-lg:grid max-lg:grid-cols-3 lg:w-3/5'>
        <StyledDetailItem label='Qt'>{item.quantity}</StyledDetailItem>
        <StyledDetailItem extraText='ft' label='L'>
          {item.length}
        </StyledDetailItem>
        <StyledDetailItem extraText='ft' label='W'>
          {item.width}
        </StyledDetailItem>
        <StyledDetailItem extraText='ft' label='H'>
          {item.height}
        </StyledDetailItem>
        <StyledDetailItem extraText='lbs' label='Wt'>
          {item.weight}
        </StyledDetailItem>
        <StyledDetailItem extraText='F' label='Temp'>
          {item.temperature}
        </StyledDetailItem>
      </div>
    </div>
  )
}

const StyledDetailItem = tw(DetailItem)`
  justify-start
`
