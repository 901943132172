import './TextWithTooltip.scss'

import { Tooltip } from '@components'
import tw from 'tailwind-styled-components'

export const TextWithTooltip = ({
  children = '',
  limit = 20,
  className,
}: {
  children: string
  limit?: number
  className?: string
}) => (
  <Container className={className}>
    {children?.length > limit ? (
      // @ts-ignore
      <Tooltip content={<div style={{ maxWidth: '300px' }}>{children}</div>}>
        <div className='w-full overflow-ellipsis truncate'>{children}</div>
      </Tooltip>
    ) : (
      <div>{children}</div>
    )}
  </Container>
)

const Container = tw.div`
  whitespace-nowrap
  w-24
`
