import { Transition as TailWindTransition } from '@headlessui/react'
import { forwardRef, HTMLAttributes, ReactNode } from 'react'

type EaseInOutTransitionProps = {
  children: ReactNode
  show?: boolean
} & HTMLAttributes<HTMLDivElement>

export const EaseInOutTransition = forwardRef<HTMLDivElement, EaseInOutTransitionProps>(
  ({ children, show, ...props }: EaseInOutTransitionProps, ref) => (
    <TailWindTransition
      ref={ref}
      enter='transition ease-out duration-100'
      enterFrom='transform opacity-0 scale-95'
      enterTo='transform opacity-100 scale-100 z-10 relative'
      leave='transition ease-in duration-75'
      leaveFrom='transform opacity-100 scale-100 z-10 relative'
      leaveTo='transform opacity-0 scale-95'
      show={show}
      {...props}
    >
      {children}
    </TailWindTransition>
  ),
)
