import { getConfirmation } from '@components'
import { isEqual, noop } from 'lodash-es'
import { useMemo, useState } from 'react'
import tw from 'tailwind-styled-components'

import { useAppSelector, useAppThunkDispatch } from '../../../../app/hooks'
import { NewManifestItem } from '../../../../common/types'
import { deleteManifestItem, updateManifestItem } from '../../../../redux/loadsSlice'
import { ActionButtons } from '../../../ActionButtons'
import { MobileItemButtons } from '../../../MobileItemButtons'
import { ItemEdit } from './ItemEdit'
import { ItemView } from './ItemView'

export const Item = ({ item, isPickup }: { item: NewManifestItem; isPickup: boolean }) => {
  const [isEditing, setEditing] = useState(false)
  const [updatedItem, setUpdatedItem] = useState(item)

  const dispatch = useAppThunkDispatch()

  const loading = useAppSelector(state => state.loads.loading.updateManifestItem)

  const changesMade = useMemo(
    () => !isEqual(JSON.stringify(item), JSON.stringify(updatedItem)),
    [item, updatedItem],
  )

  const resetItem = () => {
    setUpdatedItem(item)
    setEditing(false)
  }

  const deleteItem = async () => {
    const response = await getConfirmation({
      message: 'Are you sure you want to delete this item?',
      type: 'danger',
    })
    if (response) dispatch(deleteManifestItem(item.itemMappingKey || ''))
  }

  const updateItem = () =>
    dispatch(
      updateManifestItem({
        name: updatedItem?.name,
        quantity: Number(updatedItem?.quantity),
        itemTemplateId: updatedItem?.itemTemplateId,
        pickupStopRef: updatedItem.pickupStopRef,
        drops: (updatedItem?.dropoffs || []).map((dropoff: any) => ({
          stopRef: dropoff.value === 'Destination' ? 'DESTINATION' : dropoff.value,
          quantity: Number(dropoff.quantity),
        })),
        itemMappingKey: updatedItem.itemMappingKey,
      }),
    )
      .then(() => resetItem())
      .catch(noop)

  return isEditing ? (
    <ItemEdit
      deletable
      isPickup={isPickup}
      isSaveDisabled={!changesMade}
      item={updatedItem}
      loading={loading}
      setItem={setUpdatedItem}
      onCancel={resetItem}
      onDelete={deleteItem}
      onSave={updateItem}
    />
  ) : (
    <Container>
      <ItemView isPickup={isPickup} item={item} />
      {isPickup && (
        <>
          <ActionButtons
            className='max-lg:hidden'
            isEditing={isEditing}
            isSaveDisabled={false}
            setEditing={setEditing}
          />
          <MobileItemButtons isEditing={isEditing} setEditing={setEditing} />
        </>
      )}
    </Container>
  )
}

const Container = tw.div`
  bg-lighter-gray
  rounded-lg
  border
  border-light-gray
  p-4
  mb-4
  lg:flex
  justify-between
  items-start
`
