import { AxiosInstance } from 'axios'

import { LatLng } from '../../common'

export const metersToMiles = (meters: number) => meters / 1609.344

export const isPoint = (position: any): position is { lat: number; lng: number } =>
  'lat' in position && 'lng' in position

export const onRouteRequest = async ({
  api,
  origin,
  destination,
  stops = [],
}: {
  api: AxiosInstance
  origin: LatLng
  destination: LatLng
  stops?: LatLng[]
}) => {
  const via = stops.reduce((v: string, stop: LatLng) => `${v}&via=${stop.lat},${stop.lng}`, '')
  return await api.get('/locations/api/here-map-route-proxy/', {
    params: {
      origin: `${origin.lat},${origin.lng}`,
      destination: `${destination.lat},${destination.lng}${via}`,
    },
  })
}

export const computeBoundingBox = (points: H.geo.Point[]) => {
  const boundingBox = H.geo.Rect.coverPoints(points)
  if (!boundingBox) return null

  // Increase the bounding box for better map visibility
  const heightExpansion = (boundingBox.getTop() - boundingBox.getBottom()) * 0.15
  const widthExpansion = (boundingBox.getRight() - boundingBox.getLeft()) * 0.1

  return new H.geo.Rect(
    boundingBox.getTop() + heightExpansion,
    boundingBox.getLeft() - widthExpansion,
    boundingBox.getBottom() - heightExpansion,
    boundingBox.getRight() + widthExpansion,
  )
}
