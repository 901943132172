import { broadcastLogout } from '@common'
import axios from 'axios'
import Cookies from 'js-cookie'

// after every response, check if it's a 401, and if so, redirect to login
axios.interceptors.response.use(
  response => response,
  async error => {
    const authedRouteFailed = error.response?.status === 401 && location.pathname !== '/'
    const csrfFailed = error.response?.status === 403 && !Cookies.get('csrftoken3')

    // log out if response is a 401, or if we're missing the CSRF token
    if (authedRouteFailed || csrfFailed) {
      broadcastLogout()
    }
    return Promise.reject(error)
  },
)
