import {
  AddButton,
  Button,
  DateInput,
  DeleteButton,
  Form,
  Select,
  TextInput,
  Toggle,
  Tooltip,
} from '@components'
import { InformationCircleIcon } from '@heroicons/react/24/solid'
import dayjs from 'dayjs'
import { isEqual } from 'lodash-es'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import tw from 'tailwind-styled-components'

import { useAppSelector, useAppThunkDispatch } from '../../../app/hooks'
import { initialSearchParams, quotingToolSupportedEquipmentTypes } from '../../../common/constants'
import { trackEvent } from '../../../common/tracking'
import { MultiStopQuotingParams } from '../../../common/types'
import { randomString } from '../../../common/utils'
import { getSingleLaneQuote } from '../../../redux/commonSlice'
import { getContacts, setSearchParams } from '../../../redux/quotingToolSlice'
import { DimensionsBanner } from './DimensionsBanner'
import { LocationItem } from './LocationItem'
import { MultiLaneQuote } from './MultiLaneQuote'

export const SearchForm = () => {
  const [isFormValid, setFormValid] = useState(false)
  const [isBulkUploadModalOpen, setBulkUploadModalOpen] = useState(false)

  const navigate = useNavigate()

  const dispatch = useAppThunkDispatch()

  const data = useAppSelector(state => state.quotingTool.searchParams)
  const loading = useAppSelector(state => state.common.loading.singleLaneQuote)

  useEffect(() => {
    if (!data?.pickupDate)
      setData({ pickupDate: new Date(dayjs().nextBusinessDay().format('YYYY-MM-DD')) })
  }, [])

  const isSearchButtonDisabled =
    !isFormValid ||
    !data.origin?.isValid ||
    !data.destination?.isValid ||
    data.stops?.some(stop => !stop.isValid) ||
    loading

  const handleSearch = async () => {
    if (!data.isOD) {
      trackEvent('Searched for single lane quote(s)')
      await dispatch(getSingleLaneQuote())
      dispatch(getContacts())
    } else {
      navigate('/quotes/new-od-quote')
    }
  }

  const setData = (newData: Partial<MultiStopQuotingParams>) =>
    dispatch(setSearchParams({ ...data, ...newData }))

  const businessDaysAdd = (days: number, dateObj?: Date): Date => {
    const date = dateObj ? dayjs(dateObj) : dayjs()
    return new Date(date.businessDaysAdd(days).format('YYYY-MM-DD'))
  }

  const addNewStop = () => {
    const newStop = { id: randomString(), isValid: false, location: null, stopType: 'Pickup' }
    setData({
      stops: [...(data.stops || []), newStop],
    })
  }

  return (
    <div className='lg:w-1/5 lg:min-w-[280px]'>
      <div className='lg:h-[calc(100vh-236px)] lg:overflow-auto p-4'>
        <div className='mb-4'>
          <SectionTitle>Special Requirements</SectionTitle>
          <div className='flex justify-between items-center'>
            <div>Over-Dimensional</div>
            <Toggle square value={!!data.isOD} onChange={() => setData({ isOD: !data.isOD })} />
          </div>
        </div>
        <SectionTitle>Load Details</SectionTitle>
        <Form className='grid gap-y-3' setFormValid={setFormValid}>
          <Select
            sm
            choices={quotingToolSupportedEquipmentTypes}
            required={!data.isOD}
            showAsterisk={false}
            value={data.equipmentType}
            label={
              <>
                Equipment Type
                {!!data.isOD && (
                  <div className='flex items-center'>
                    <span className='ml-1'>(optional)</span>
                    <Tooltip content='We may quote a different equipment type if we believe it is necessary or more cost effective'>
                      <InformationCircleIcon className='w-5 ml-1 -mt-1 text-brand-dark' />
                    </Tooltip>
                  </div>
                )}
              </>
            }
            onChange={(equipmentType: string) => setData({ equipmentType })}
          />
          <DimensionsBanner />
          <DateInput
            required
            label='Pickup Date'
            maxDate={businessDaysAdd(36)}
            minDate={new Date()}
            showAsterisk={false}
            value={data.pickupDate}
            onChange={pickupDate => setData({ pickupDate })}
          />
          {typeof data.refId === 'string' && (
            <>
              <TextInput
                sm
                label='Reference ID'
                value={data.refId}
                onChange={refId => setData({ refId })}
              />
              <DeleteButton className='mt-1' onClick={() => setData({ refId: null })} />
            </>
          )}
          {data.refId === null && (
            <AddButton
              buttonText='Add Reference ID'
              className='mt-1'
              onClick={() => setData({ refId: '' })}
            />
          )}
        </Form>
        <SectionTitle className='mt-4'>Route</SectionTitle>
        <div className='border-l border-brand-dark pl-4'>
          <LocationItem
            required
            className='pb-4'
            id='origin'
            label='Origin'
            type='origin'
            value={data.origin}
            setFormValid={isValid =>
              isValid !== data.origin?.isValid && setData({ origin: { ...data.origin, isValid } })
            }
            setLocation={location =>
              setData({
                origin: {
                  ...data.origin,
                  location: location ? { ...data.origin.location, ...location } : null,
                },
              })
            }
          />
          {data.stops?.map((stop, i) => (
            <LocationItem
              key={i}
              required
              id={stop.id}
              label={`Stop ${i + 1}`}
              type='stop'
              value={stop}
              setFormValid={isValid => {
                if (isValid !== stop.isValid) {
                  const tempStops = [...(data.stops || [])]
                  tempStops[i] = { ...tempStops[i], isValid }
                  setData({ stops: tempStops })
                }
              }}
              setLocation={location => {
                const tempStops = [...(data.stops || [])]
                tempStops[i] = { ...tempStops[i], location }
                setData({ stops: tempStops })
              }}
              setStopType={stopType => {
                const tempStops = [...(data.stops || [])]
                tempStops[i] = { ...tempStops[i], stopType }
                setData({ stops: tempStops })
              }}
              onDelete={() => {
                const tempStops = (data.stops || []).filter(loc => stop.id !== loc.id)
                setData({ stops: tempStops })
              }}
            />
          ))}
        </div>
        <LocationItem
          required
          className='ml-4'
          id='destination'
          label='Destination'
          type='destination'
          value={data.destination}
          setFormValid={isValid =>
            isValid !== data.destination?.isValid &&
            setData({ destination: { ...data.destination, isValid } })
          }
          setLocation={location =>
            setData({
              destination: {
                ...data.destination,
                location: location ? { ...data.destination.location, ...location } : null,
              },
            })
          }
        />
        {(data.stops?.length || 0) < 2 && <AddButton buttonText='Add Stop' onClick={addNewStop} />}
        {!isEqual(data, initialSearchParams) && (
          <DeleteButton
            buttonText='Clear All'
            onClick={() => dispatch(setSearchParams(initialSearchParams))}
          />
        )}
      </div>
      <ButtonsContainer>
        <BulkUploadButton onClick={() => setBulkUploadModalOpen(true)}>
          Upload Multi-Lane CSV
        </BulkUploadButton>
        <Button
          fullWidth
          className='w-full'
          disabled={isSearchButtonDisabled}
          type='warn'
          onClick={handleSearch}
        >
          {data.isOD ? 'Next' : 'Get Quote'}
        </Button>
      </ButtonsContainer>
      <MultiLaneQuote isVisible={isBulkUploadModalOpen} setVisible={setBulkUploadModalOpen} />
    </div>
  )
}

const ButtonsContainer = tw.div`
  p-4
  flex
  flex-col
  items-center
  max-lg:pb-8
  max-lg:sticky
  max-lg:bottom-0
  bg-white
`

const SectionTitle = tw.div`
  font-bold
  text-base
  mb-3
`

const BulkUploadButton = tw.button`
  text-center
  mb-4
  font-semibold
  text-link
  hover:underline
  text-sm
`
