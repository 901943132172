import { broadcastLogin } from '@common'
import { Button, Form, TextInput } from '@components'
import { noop } from 'lodash-es'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import { useAppSelector, useAppThunkDispatch } from '../../../app/hooks'
import buffering from '../../../assets/images/loading-buffering.gif'
import { isLocal, isPreview } from '../../../common/constants'
import { sendOTP, setCompanyId, setStep, verifyOTP } from '../../../redux/userSlice'
import { Error, Link } from '../components'

export const ConfirmStep = () => {
  const dispatch = useAppThunkDispatch()
  const error = useAppSelector(state => state.user.error)
  const email = useAppSelector(state => state.user.credentials.username)
  const isVerifyingOTP = useAppSelector(state => state.user.loading.verifyOTP)
  const isSendingOTP = useAppSelector(state => state.user.loading.sendOTP)
  const [isFormValid, setFormValid] = useState(false)
  const [otp, setOTP] = useState(isLocal || isPreview ? '987654' : '')
  const [countdown, setCountdown] = useState(30)

  const onConfirm = async () => {
    const response = await dispatch(verifyOTP(otp))
    if (response.meta.requestStatus === 'rejected') return
    localStorage.setItem('lastAuthedEmail', email)

    if (response.payload.customerCompanyId) {
      dispatch(setCompanyId(response.payload.customerCompanyId))
      broadcastLogin()
    } else {
      dispatch(setStep('SELECT_COMPANY'))
    }
  }

  useEffect(() => {
    if (countdown > 0) {
      const timer = setTimeout(() => setCountdown(countdown - 1), 1000)
      return () => clearTimeout(timer) // Cleanup timeout
    }
  }, [countdown])

  const handleResendOTP = () => {
    dispatch(sendOTP())
      .unwrap()
      .then(() => {
        toast.success('Resent confirmation email')
        setCountdown(30)
      })
      .catch(noop)
  }

  return (
    <Form autoComplete='off' className='flex flex-col items-center' setFormValid={setFormValid}>
      <TextInput
        required
        sm
        className='w-full mb-6'
        label='Verification Code'
        value={otp}
        onChange={setOTP}
      />
      {error && <Error className='text-sm'>{error}</Error>}
      <Button
        fullWidth
        lg
        className='w-full'
        disabled={!isFormValid}
        loading={isVerifyingOTP}
        type='primary'
        onClick={onConfirm}
      >
        Confirm
      </Button>
      <div className='flex flex-col items-center gap-4 mt-4 text-sm'>
        {countdown > 0 ? (
          <p className='text-blue-gray'>Didn&apos;t get the code? Resend in {countdown}</p>
        ) : (
          <Link onClick={handleResendOTP}>Resend Confirmation Email</Link>
        )}
        {isSendingOTP && (
          <div className='flex justify-center'>
            <img alt='loading' className='w-5 h-5' src={buffering} />
          </div>
        )}
        <Link onClick={() => dispatch(setStep('LOGIN'))}>Log In Page</Link>
      </div>
    </Form>
  )
}
