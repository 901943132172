import './Select.scss'

import { MagnifyingGlassIcon, PlusCircleIcon } from '@heroicons/react/24/outline'
import { InformationCircleIcon, TruckIcon } from '@heroicons/react/24/solid'
import { isArray, isEmpty, isObject, uniqBy } from 'lodash-es'
import { forwardRef, ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import Select, { components, MenuPlacement, OptionProps, Props as RSelectProps } from 'react-select'
import CreatableSelect from 'react-select/creatable'
import tw from 'tailwind-styled-components'

import { toTransient } from '../../common'
import { HighlightedMatchingText } from '../highlightedMatchingText'
import { Tooltip } from '../tooltip'

type Icon = 'search' | 'truck'

export type SelectDropdownProps = {
  choices: any[] | readonly any[]
  field?: string // label field for choices, defaults to 'label' if not specified
  valueField?: string // value field for choices, defaults to 'value' if not specified
  extractValue?: boolean
  label?: string | ReactNode
  onChange: Function
  value?: any
  className?: string
  xs?: boolean
  sm?: boolean
  md?: boolean
  isMulti?: boolean
  isClearable?: boolean
  closeMenuOnSelect?: boolean
  placeholder?: string
  required?: boolean
  labelStyle?: string
  blue?: boolean
  disabled?: boolean
  labelIcon?: JSX.Element
  isSearchable?: boolean
  addable?: boolean
  addableText?: string
  onAdd?: () => void
  onInputChange?: (value: string) => void
  onCreateOption?: (value: string) => void
  loading?: boolean
  openMenuOnClick?: boolean
  hideDropdownIndicator?: boolean
  menuPlacement?: MenuPlacement
  creatable?: boolean
  noStyles?: boolean
  onFocus?: () => void
  onBlur?: () => void
  icon?: Icon
  style?: React.CSSProperties
  error?: string
  errorClassName?: string
  validator?: (value: any) => string | undefined
  onKeyDown?: ({ keyCode }: { keyCode: number }) => void
  autoFocus?: boolean
  openMenuOnFocus?: boolean
  noOptionsMessage?: () => void
  listLabel?: string
  showAsterisk?: boolean
  onValueNotFound?: () => void
  descriptionField?: string
  showRequiredText?: boolean
  maxMenuHeight?: number
}

const HighlightedOption = ({
  label,
  inputValue,
  className,
}: {
  label: string
  inputValue: string
  className?: string
}) => (
  <HighlightedOptionContainer className={className}>
    <HighlightedMatchingText query={inputValue} text={label} />
  </HighlightedOptionContainer>
)

const getNestedValue = (obj: any, path?: string) =>
  path?.split('.').reduce((current, key) => current && current[key], obj)

const SelectDropdown = forwardRef<Select, SelectDropdownProps>(
  (
    {
      choices = [],
      field = 'label',
      valueField = 'value',
      descriptionField,
      extractValue = false,
      label,
      onChange,
      value = '',
      className = '',
      xs,
      sm,
      md,
      isMulti = false,
      isClearable = true,
      closeMenuOnSelect = true,
      placeholder = '',
      labelStyle,
      required,
      disabled,
      blue,
      labelIcon,
      isSearchable = true,
      addable,
      addableText = '',
      onAdd = () => {},
      onInputChange = () => {},
      onCreateOption,
      loading,
      openMenuOnClick = true,
      hideDropdownIndicator = false,
      menuPlacement = 'auto',
      creatable,
      noStyles,
      onFocus,
      onBlur,
      icon,
      style = {},
      error = '',
      errorClassName = '',
      onKeyDown = () => {},
      autoFocus,
      openMenuOnFocus = openMenuOnClick,
      noOptionsMessage,
      listLabel,
      showAsterisk = true,
      onValueNotFound = () => {},
      showRequiredText,
      maxMenuHeight,
    }: SelectDropdownProps,
    ref,
  ) => {
    const [menuOpen, setMenuOpen] = useState(false)
    const [selectionMade, setSelectionMade] = useState(false)
    const [cachedOptions, setCachedOptions] = useState<any[]>([])

    const handleSelect = (selectedValue: any) => {
      setSelectionMade(true)
      setTimeout(() => setSelectionMade(false), 100)

      if (extractValue || !isObject(choices[0])) {
        if (isMulti) return onChange(selectedValue?.map((v: any) => v[valueField]))
        return onChange(selectedValue?.[valueField] ?? null)
      }

      return onChange(selectedValue)
    }

    const $props = toTransient({ blue, xs, sm, md })

    const computedOptions = useMemo(
      () =>
        !isObject(choices[0])
          ? choices.map(choice => ({
              [field]: choice,
              [valueField]: choice,
            }))
          : choices,
      [choices],
    )

    useEffect(() => {
      const newOptions = uniqBy([...computedOptions, ...cachedOptions], valueField)
      if (newOptions.length !== cachedOptions.length) setCachedOptions(newOptions)
    }, [computedOptions, cachedOptions])

    const computedValue = useMemo(() => {
      let computedValue = value
      if (isMulti && isArray(value) && !isObject(value[0]))
        computedValue = value
          .map((v: any) => cachedOptions.find((c: any) => String(c[valueField]) === String(v)))
          .filter(Boolean)
      if (!isObject(value))
        computedValue = cachedOptions.find(c => String(c[valueField]) === String(value))

      return isEmpty(computedValue) ? '' : computedValue
    }, [cachedOptions, value])

    useEffect(() => {
      if (extractValue && !isEmpty(value) && isEmpty(computedValue)) {
        onValueNotFound()
      }
    }, [value, computedValue])

    const MenuList = (props: any) => (
      <>
        {addable && (
          <AddNewOption onClick={onAdd}>
            <PlusCircleIcon className='w-5 mr-2' />
            <span>Add new {addableText}</span>
          </AddNewOption>
        )}
        {listLabel && <ListLabel>{listLabel}</ListLabel>}
        <components.MenuList {...props} />
      </>
    )

    const Option = (
      props: OptionProps & { data: { rightComponent: JSX.Element; tooltipText: string } },
    ) => (
      <components.Option {...props}>
        <OptionContainer>
          <div className='flex items-center overflow-hidden'>
            <HighlightedOption inputValue={props.selectProps.inputValue} label={props.label} />
            {props.data.tooltipText && (
              <Tooltip content={props.data.tooltipText}>
                <InformationCircleIcon className='text-error w-4 ml-2' />
              </Tooltip>
            )}
          </div>
          {props.data.rightComponent}
        </OptionContainer>
        {descriptionField && getNestedValue(props.data, descriptionField) && (
          <HighlightedOption
            className='text-dark-gray mt-1'
            inputValue={props.selectProps.inputValue}
            label={getNestedValue(props.data, descriptionField)}
          />
        )}
      </components.Option>
    )

    const props = {
      ref,
      classNamePrefix: 'react-select',
      closeMenuOnSelect,
      getOptionLabel: (option: any) => option?.[field] ?? option.label ?? 'LABEL NOT CONFIGURED',
      isClearable,
      isLoading: loading,
      isMulti,
      isSearchable,
      menuPlacement,
      menuShouldScrollIntoView: false,
      openMenuOnClick,
      // Filter out possible values that need to be displayed, but shouldn't appear as selectable options
      options: computedOptions.filter(choice => !choice?.hidden),
      placeholder,
      value: computedValue,
      noOptionsMessage,
      components: {
        MenuList,
        Option,
        LoadingIndicator: () => null,
        ...(hideDropdownIndicator && { DropdownIndicator: () => null }),
      },
      getOptionValue: (option: any) => option?.[valueField] ?? option?.[field] ?? option ?? '',
      styles: {
        ...colorStyles,
        ...(noStyles && {
          control: (styles: any) => ({
            ...styles,
            backgroundColor: 'transparent',
            border: 'none',
          }),
        }),
        ...(icon && { valueContainer: (styles: any) => ({ ...styles, marginLeft: '20px' }) }),
      },
      onBlur,
      onFocus,
      onChange: handleSelect,
      onInputChange: (query: string) => {
        // make sure the return of onInputChange isn't returned back
        onInputChange(query)
      },
      onCreateOption: onCreateOption || onChange,
      onKeyDown: ({ keyCode }: { keyCode: number }) => {
        onKeyDown({ keyCode })
        keyCode === 13 && setMenuOpen(false)
      },
      autoFocus,
      openMenuOnFocus,
      onMenuOpen: () => {
        if (!selectionMade) setMenuOpen(true)
      },
      onMenuClose: () => {
        if (!selectionMade) setMenuOpen(false)
      },
      menuIsOpen: menuOpen,
      createOptionPosition: 'first',
      isValidNewOption: () => true,
      formatCreateLabel: (inputValue: string) => (
        <div className='flex items-center text-dark-gray'>
          <PlusCircleIcon className='w-5 mr-2' />
          <span>Add new {inputValue && `"${inputValue}"`}</span>
        </div>
      ),
      // Search by both display and description fields
      ...(descriptionField && {
        filterOption: (value: any, inputValue: any) => {
          const input = inputValue.toLowerCase()
          return (
            value.data[field]?.toLowerCase().includes(input) ||
            getNestedValue(value.data, descriptionField)?.toLowerCase()?.includes(input)
          )
        },
      }),
      maxMenuHeight,
    }

    const Icon = useCallback(() => {
      const props = {
        className: `text-dark-gray absolute left-2 z-10 w-4 ${label ? 'top-7' : 'top-2'}`,
      }

      switch (icon) {
        case 'search':
          return <MagnifyingGlassIcon {...props} />
        case 'truck':
          return <TruckIcon {...props} />
        default:
          return <></>
      }
    }, [icon])

    const SelectComponent = creatable ? CreatableSelect : Select

    return (
      <Container className={className} {...$props} style={style}>
        {label && (
          <Label className={labelStyle}>
            {labelIcon} {label}{' '}
            {required && showAsterisk && (
              <span className='text-error ml-1 whitespace-nowrap'>
                * {showRequiredText && 'required'}
              </span>
            )}
          </Label>
        )}
        <Icon />
        {disabled ? (
          <DisabledSelect>{computedValue?.[field] ?? computedValue ?? ''}</DisabledSelect>
        ) : (
          // @ts-ignore
          <SelectComponent
            key={
              /** fix issue where clearing the select does not clear the value displayed in the input */
              computedValue ? 'withvalue' : 'withoutvalue'
            }
            {...props}
          />
        )}
        {!!error && <Error className={errorClassName}>{error}</Error>}
      </Container>
    )
  },
)

const OptionContainer = tw.div`
  flex
  items-center
  justify-between
  w-full
`

const ListLabel = tw.div`
  text-dark-gray
  text-sm
  px-4
  pb-2
  pt-3
  font-semibold
`

const Container = tw.div`
  relative
  ${(p: any) =>
    (p.$xs && 'multiple-select-dropdown--xs') ||
    (p.$sm && 'multiple-select-dropdown--sm') ||
    (p.$md && 'multiple-select-dropdown--md') ||
    'multiple-select-dropdown'}
  ${(p: any) => (p.$blue ? 'blue' : '')}
`

const Label = tw.label`
  flex
  items-center
  text-dark-gray
  mb-1
  self-start
  text-xs
  font-poppins
  text-left
`

const Error = tw.div`
  text-error
  ml-1
  text-xs
  absolute
  -bottom-4
  whitespace-nowrap
`

const AddNewOption = tw.div`
  flex
  items-center
  text-dark-gray
  p-2
  cursor-pointer
  hover:bg-lighter-blue
  transition-all
`

const DisabledSelect = tw.div`
  bg-lighter-blue
  p-1
  pl-3
  rounded-lg
  border
  border-border-gray
  h-8
  flex
  items-center
`

const HighlightedOptionContainer = tw.div`
  whitespace-nowrap
  overflow-hidden
  text-ellipsis
`

export { SelectDropdown as Select }

const colorStyles: RSelectProps['styles'] = {
  option: (styles: any, { data, isDisabled, isFocused, isSelected }: any) => ({
    ...styles,
    backgroundColor: isDisabled
      ? undefined
      : isSelected
        ? '#EDF1F8'
        : isFocused
          ? '#F6F8FC'
          : undefined,
    cursor: isDisabled ? 'not-allowed' : 'pointer',
    color: data.rightComponent ? '#E87070' : 'black',
    fontWeight: isSelected ? '700' : '400',

    ':active': {
      ...styles[':active'],
      backgroundColor: !isDisabled ? (isSelected ? data.color : '#EDF1F8') : undefined,
    },
  }),
  control: (styles: any, { isFocused }: any) => ({
    ...styles,
    borderColor: isFocused ? '#152437 !important' : '#CCCCCC',

    ':hover': {
      borderColor: isFocused ? '#152437' : '#CCCCCC',
    },
  }),
}
