import { Menu } from '@headlessui/react'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid'
import tw from 'tailwind-styled-components'

import { LoaderSpinner } from '../loaders'
import { EaseInOutTransition } from '../transitions'

export const DropdownButton = ({
  buttonLabel,
  onClick,
  className,
  items = [],
  buttonLabelClassName,
  position = 'bottom',
  type,
  buttonClassName,
  disabled,
  loading,
}: {
  buttonLabel?: string
  onClick: () => void
  items: { label: string; onClick: () => void }[]
  className?: string
  buttonLabelClassName?: string
  position?: 'top' | 'bottom'
  type?: string
  buttonClassName?: string
  disabled?: boolean
  loading?: boolean
}) => {
  const Icon = position === 'bottom' ? ChevronDownIcon : ChevronUpIcon

  return (
    <MenuContainer $disabled={disabled} $type={type} as='div' className={className}>
      <MainButton $disabled={disabled} $type={type} className={buttonClassName}>
        <ButtonText
          $disabled={disabled}
          className={buttonLabelClassName}
          onClick={() => !disabled && !loading && onClick()}
        >
          {buttonLabel}
        </ButtonText>
        <Menu.Item disabled={disabled}>
          {loading ? (
            <div className='border-l border-brand-dark h-8 min-w-[33px]'>
              <LoaderSpinner className='mt-2.5' size={3} />
            </div>
          ) : (
            <Chevron $disabled={disabled}>
              <Icon className='w-4 min-w-4' />
            </Chevron>
          )}
        </Menu.Item>
      </MainButton>
      <EaseInOutTransition>
        <Dropdown $position={position} $type={type}>
          {items.map(({ label, onClick }) => (
            <Menu.Item key={label}>
              <Option onClick={() => onClick()}>{label}</Option>
            </Menu.Item>
          ))}
        </Dropdown>
      </EaseInOutTransition>
    </MenuContainer>
  )
}

const Chevron = tw(Menu.Button)<{ $disabled?: boolean }>`
  p-2
  border-l
  border-brand-dark
  ${({ $disabled }) => ($disabled ? 'border-gray-300 cursor-default' : 'cursor-pointer')}
`

const ButtonText = tw.div<{ $disabled?: boolean }>`
  flex
  items-center
  justify-center
  hover:opacity-80
  transition-all
  w-full
  ${({ $disabled }) => ($disabled ? 'hover:opacity-100 cursor-default' : 'cursor-pointer')}
`

const Option = tw(Menu.Button)`
  pb-2
  pt-1
  px-2
  mt-1
  hover:opacity-80
  transition-all
  cursor-pointer
  font-semibold
  w-full
  text-right
  whitespace-nowrap
`

const MainButton = tw.div<{ $type?: string; $disabled?: boolean }>`
  border
  rounded-lg
  flex
  items-center
  font-semibold
  whitespace-nowrap
  justify-between
  h-8
  ${({ $type }) => ($type === 'warn' ? 'border-brand-accent' : 'border-brand-dark')}
  ${({ $disabled }) => $disabled && 'border-gray-300'}
`

const Dropdown = tw(Menu.Items)<{
  $position?: 'top' | 'bottom'
  $type?: string
}>`
  focus:outline-none
  left-auto
  bottom-auto
  absolute
  border-l
  border-r
  right-0
  py-1
  z-[9]
  w-full
  flex
  flex-col
  items-end
  ${({ $position }) =>
    $position === 'top'
      ? 'border-t rounded-t-lg -mt-[69px]'
      : 'border-b rounded-b-none -mt-[7px] rounded-b-lg'}
  ${({ $type }) =>
    $type === 'warn' ? 'bg-brand-accent border-brand-accent' : 'bg-white border-brand-dark'}
`

const MenuContainer = tw(Menu)<{ $type?: string; $disabled?: boolean }>`
  relative
  inline-block
  text-left
  rounded-lg
  w-full
  font-poppins
  ${({ $type }) => ($type === 'warn' ? 'bg-brand-accent' : 'bg-white')}
  ${({ $disabled }) => $disabled && 'bg-light-gray text-dark-gray'}
`
