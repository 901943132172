import { useWindowSize } from '@common'
import { AlphaModal, ConfirmDialog, TextInput } from '@components'
import { Menu } from '@headlessui/react'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline'
import { useEffect, useMemo, useState } from 'react'
import tw from 'tailwind-styled-components'

import { useAppSelector, useAppThunkDispatch } from '../../../app/hooks'
import { getPresetKey, useKey } from '../../../common/utils'
import { setShowFilters } from '../../../redux/userSlice'
import { EaseInOutTransition } from '../../EaseInOutTransition'

export const PresetButton = ({
  onApply,
  onSaveAsNewPreset,
  onSaveChanges,
  onDelete,
  currentPresetTitle,
  isSaveChangesButtonDisabled,
}: {
  onApply: () => void
  onSaveAsNewPreset: (title: string) => void
  onSaveChanges: () => void
  onDelete: () => void
  currentPresetTitle?: string
  isSaveChangesButtonDisabled: boolean
}) => {
  const [isVisible, setVisible] = useState(false)
  const [isConfirmModalVisible, setConfirmModalVisible] = useState(false)
  const [title, setTitle] = useState('')
  const [width, height] = useWindowSize()

  const dispatch = useAppThunkDispatch()

  useKey('Enter', onApply)
  useKey('NumpadEnter', onApply)
  useKey('Save', () => {
    if (selectedPresetId) onSaveChanges()
    else setVisible(true)
  })

  useEffect(() => {
    setTitle('')
  }, [isVisible])

  const preset = useAppSelector(state => state.user.presets?.[getPresetKey()]) || {}
  const loading = useAppSelector(state => state.user.presetsLoading)

  const selectedPresetId = preset?.selectedPreset

  const elem: any = document.getElementById('preset-button')
  const rect = elem?.getBoundingClientRect()

  const isTopDirection = useMemo(
    () => height - rect?.y <= 170 || width <= 768,
    [width, height, rect?.y],
  )

  const handleApplyFilters = () => {
    onApply()
    if (width <= 768) dispatch(setShowFilters(false))
  }

  const onConfirm = async () => {
    if (title) {
      await onSaveAsNewPreset(title)
      setVisible(false)
    }
  }

  const Icon = isTopDirection ? ChevronUpIcon : ChevronDownIcon

  return (
    <>
      <Menu as='div' className='w-full lg:w-fit' id='preset-button'>
        <MenuButtonContainer data-testid='menu-button'>
          <ApplyButton onClick={handleApplyFilters}>Apply Filters</ApplyButton>
          <div className='lg:w-px lg:bg-white lg:h-[60%]' />
          <Menu.Item>
            <Menu.Button className='py-2 px-4 lg:px-3'>
              <Icon className='w-4' />
            </Menu.Button>
          </Menu.Item>
        </MenuButtonContainer>
        <EaseInOutTransition>
          <Dropdown $top={isTopDirection}>
            {selectedPresetId && (
              <div>
                <DropdownOption
                  $disabled={isSaveChangesButtonDisabled}
                  onClick={() => !isSaveChangesButtonDisabled && onSaveChanges()}
                >
                  Save Changes
                </DropdownOption>
              </div>
            )}
            <div>
              <DropdownOption onClick={() => setVisible(true)}>
                Save As {selectedPresetId && 'New'} Preset
              </DropdownOption>
            </div>
            {selectedPresetId && (
              <div>
                <DropdownOption
                  className='hover:text-error'
                  onClick={() => setConfirmModalVisible(true)}
                >
                  Delete Preset
                </DropdownOption>
              </div>
            )}
          </Dropdown>
        </EaseInOutTransition>
      </Menu>
      <AlphaModal
        focusTrapped
        className='w-[500px]'
        confirmButtonLabel='Save'
        isConfirmButtonDisabled={!title}
        isConfirmButtonLoading={loading}
        isVisible={isVisible}
        setVisible={setVisible}
        title='New Preset'
        onConfirm={onConfirm}
      >
        <div className='p-6'>
          <TextInput
            sm
            label='Preset Name'
            value={title}
            onChange={(title: string) => setTitle(title)}
            onKeyDown={event => event.code === 'Enter' && onConfirm()}
          />
        </div>
      </AlphaModal>
      <ConfirmDialog
        isVisible={isConfirmModalVisible}
        loading={loading}
        setVisible={setConfirmModalVisible}
        text={`preset ${currentPresetTitle}`}
        onConfirm={onDelete}
      />
    </>
  )
}

const DropdownOption = tw(Menu.Button)<{ $disabled?: boolean }>`
  cursor-pointer
  my-2
  text-left
  transition-all
  ${({ $disabled }) =>
    $disabled ? 'opacity-70 hover:opacity-70 cursor-not-allowed' : 'hover:opacity-80'}
`

const ApplyButton = tw.span`
  py-2
  px-4
  lg:px-3
  w-full
  lg:w-[104px]
  cursor-pointer
`

const MenuButtonContainer = tw.div`
  rounded-lg
  h-8
  bg-brand-dark
  text-white
  font-semibold
  flex
  justify-between
  items-center
  whitespace-nowrap
  w-full
`

const Dropdown = tw(Menu.Items)`
  origin-top-right
  absolute
  bg-brand-dark
  text-white
  uppercase
  text-left
  px-4
  lg:px-3
  font-semibold
  w-[calc(100%-114px)]
  lg:w-[145px]
  whitespace-nowrap
  ${({ $top }: { $top: boolean }) =>
    $top ? 'py-2 rounded-t-lg -mt-[73px]' : 'pt-3 pb-2 -mt-[7px] rounded-b-lg'}
`
