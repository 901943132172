import {
  ArchiveBoxIcon,
  ArrowLeftOnRectangleIcon,
  ArrowsRightLeftIcon,
  CheckCircleIcon,
  ChevronUpIcon,
  ClipboardIcon,
  Cog6ToothIcon,
  DocumentIcon,
  EllipsisHorizontalCircleIcon,
  ExclamationTriangleIcon,
  EyeIcon,
  MagnifyingGlassIcon,
  PencilIcon,
  TrashIcon,
  TruckIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import {
  ArrowDownTrayIcon,
  ArrowPathIcon,
  ArrowRightCircleIcon,
  ArrowUpTrayIcon,
  CheckCircleIcon as CheckSolidIcon,
  CheckIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  EnvelopeIcon,
  FireIcon,
  PlusCircleIcon,
  PlusIcon,
} from '@heroicons/react/24/solid'

import { ButtonIconType } from './types'

type IconProps = {
  className?: string
  icon?: ButtonIconType
  sm: boolean
  lg: boolean
}

export const ButtonIcon = ({ className = '', icon, lg }: IconProps) => {
  const enhancedClassName = `${className} ${(lg && 'w-5') || 'w-4'}`

  switch (icon) {
    case 'arrow-right':
      return <ArrowRightCircleIcon className={enhancedClassName} />
    case 'check-circle':
      return <CheckCircleIcon className={enhancedClassName} />
    case 'plus-circle':
      return <PlusCircleIcon className={enhancedClassName} />
    case 'delete':
      return <TrashIcon className={enhancedClassName} />
    case 'edit':
      return <PencilIcon className={enhancedClassName} />
    case 'check-solid':
      return <CheckSolidIcon className={enhancedClassName} />
    case 'close':
      return <XMarkIcon className={enhancedClassName} />
    case 'search':
      return <MagnifyingGlassIcon className={enhancedClassName} />
    case 'download':
      return <ArrowDownTrayIcon className={enhancedClassName} />
    case 'upload':
      return <ArrowUpTrayIcon className={enhancedClassName} />
    case 'truck':
      return <TruckIcon className={enhancedClassName} />
    case 'chevron-down':
      return <ChevronDownIcon className={enhancedClassName} />
    case 'chevron-right':
      return <ChevronRightIcon className={enhancedClassName} />
    case 'chevron-up':
      return <ChevronUpIcon className={enhancedClassName} />
    case 'plus':
      return <PlusIcon className={enhancedClassName} />
    case 'document':
      return <DocumentIcon className={enhancedClassName} />
    case 'check':
      return <CheckIcon className={enhancedClassName} />
    case 'more':
      return <EllipsisHorizontalCircleIcon className={enhancedClassName} />
    case 'archive':
      return <ArchiveBoxIcon className={enhancedClassName} />
    case 'settings':
      return <Cog6ToothIcon className={enhancedClassName} />
    case 'fire':
      return <FireIcon className={enhancedClassName} />
    case 'clipboard':
      return <ClipboardIcon className={enhancedClassName} />
    case 'envelope':
      return <EnvelopeIcon className={enhancedClassName} />
    case 'exclamation-triangle':
      return <ExclamationTriangleIcon className={enhancedClassName} />
    case 'arrow-path':
      return <ArrowPathIcon className={enhancedClassName} />
    case 'logout':
      return <ArrowLeftOnRectangleIcon className={enhancedClassName} />
    case 'view':
      return <EyeIcon className={enhancedClassName} />
    case 'arrow-right-left':
      return <ArrowsRightLeftIcon className={enhancedClassName} />
    default:
      return <div />
  }
}
