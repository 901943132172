import { CityLocation } from '@common'
import { CityAutosuggest } from '@components'
import { useEffect, useState } from 'react'

import { useAppSelector, useAppThunkDispatch } from '../../app/hooks'
import { getCities, getPopularCities } from '../../redux/locationsSlice'

export const CityAutocomplete = ({
  required,
  countries = ['US', 'CA', 'MX'],
  value,
  placeholder,
  onChange,
  label,
  disabled,
  className,
  id,
  showAsterisk = true,
  withIcon,
}: {
  required?: boolean
  countries?: string[]
  value: CityLocation | null
  placeholder?: string
  onChange: (value: CityLocation | null) => void
  label?: string
  disabled?: boolean
  className?: string
  id?: string
  showAsterisk?: boolean
  withIcon?: boolean
}) => {
  const [choices, setChoices] = useState<CityLocation[]>([])

  const dispatch = useAppThunkDispatch()

  const popularCities = useAppSelector(state => state.locations.popularCities)
  const cities = useAppSelector(state => state.locations.cities)
  const citiesLoading = useAppSelector(state => state.locations.loading.cities)

  useEffect(() => {
    dispatch(getPopularCities())
  }, [])

  return (
    <CityAutosuggest
      choices={choices}
      cities={cities}
      className={className}
      countries={countries}
      disabled={disabled}
      id={id}
      label={label}
      loading={citiesLoading}
      placeholder={placeholder}
      popularCities={popularCities}
      required={required}
      setChoices={setChoices}
      showAsterisk={showAsterisk}
      value={value}
      withIcon={withIcon}
      onChange={onChange}
      onGeocode={query => dispatch(getCities({ query, countries }))}
    />
  )
}
