import { alertError, AlertType, CatchError } from '@common'
import {
  createListenerMiddleware,
  isAnyOf,
  isRejected,
  isRejectedWithValue,
} from '@reduxjs/toolkit'
import * as sentry from '@sentry/react'

const rejectedThunkListener = createListenerMiddleware()

const sendNormalizedNetworkError = (error: Error) => {
  sentry.withScope(scope => {
    // Fingerprint all the network errors the same so they end
    // up merged in sentry. We can still monitor if there are
    // a lot of such errors to know if something is really wrong
    scope.setFingerprint(['network-error'])
    sentry.captureException(error)
  })
}

/**
 * Reports all `Rejected` thunks, including `RejectedWithValue`
 * Supplying an `_axiosError` in the payload will report that error
 */
rejectedThunkListener.startListening({
  matcher: isAnyOf(isRejected, isRejectedWithValue),
  effect: async (action: any) => {
    try {
      const axiosError = action.payload?._axiosError
      const isNetworkError =
        axiosError?.message === 'Network Error' || action.error?.message === 'Network Error'

      if (action.meta.rejectedWithValue && axiosError != null) {
        isNetworkError
          ? sendNormalizedNetworkError(axiosError)
          : alertError(axiosError, `${action.type} - ${action.error.message}`)
      } else if (!action.meta.rejectedWithValue) {
        // We get a SerializedError from CreateAsyncThunk and convert it to an error
        // This is still a limited version of the error
        const error = new Error(`${action.type} - ${action.error.message}`)
        error.stack = action.error.stack ?? error.stack
        error.name = action.error.name
        isNetworkError ? sendNormalizedNetworkError(error) : alertError(error)
      } else {
        const error = new Error(`${action.type} - ${action.payload.message ?? 'Error'}`)
        isNetworkError
          ? sendNormalizedNetworkError(error)
          : alertError(error, error.message, AlertType.Quiet)
      }
    } catch (err: CatchError) {
      //pass
    }
  },
})

export const rejectedThunkListenerMiddleware = rejectedThunkListener.middleware
