import { Select } from '@components'

import { equipmentTypeChoices } from '../../common/constants'

export const EquipmentTypeSelect = ({
  value,
  setValue,
  withLabel,
  withPlaceholder,
  required,
  className = '',
  showAsterisk = true,
  menuPlacement = 'auto',
  ...props
}: {
  value?: string
  setValue: (value: string) => void
  withLabel?: boolean
  withPlaceholder?: boolean
  required?: boolean
  className?: string
  showAsterisk?: boolean
  menuPlacement?: 'top' | 'bottom' | 'auto'
}) => {
  const equipmentTypes = equipmentTypeChoices?.map(type => type.name)

  const text = 'Equipment Type'

  return (
    <Select
      sm
      choices={equipmentTypes}
      className={className}
      menuPlacement={menuPlacement}
      required={required}
      showAsterisk={showAsterisk}
      value={value || {}}
      onChange={setValue}
      {...(withLabel && { label: text })}
      {...(withPlaceholder && { placeholder: text })}
      {...props}
    />
  )
}
