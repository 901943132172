import buffering from '../../assets/images/loading-buffering.gif'

export type LoaderSpinnerProps = {
  className?: string
  size?: number
}

export const LoaderSpinner = ({ className = 'my-4', size = 6 }: LoaderSpinnerProps) => (
  <div className={`flex items-center justify-center ${className}`}>
    <img alt='loading' className={`w-${size} h-${size}`} src={buffering} />
  </div>
)
