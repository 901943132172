import { MultiStopQuotingParams, NewLoadLocation, NewManifestItem, SearchFilters } from './types'

export const supportEmail = 'support@shipperpulse.com'

const environment = import.meta.env.VITE_ENVIRONMENT
export const isLocal = environment === 'local'
export const isPreview = environment === 'preview'
export const isStage = environment === 'stage'
export const isProduction = environment === 'production'

// SENTRY_ENV when defined, otherwise use VITE_ENVIRONMENT when Stage or Prod
// otherwise null
export const sentryEnv = import.meta.env.VITE_SENTRY_ENV
  ? import.meta.env.VITE_SENTRY_ENV.toLowerCase()
  : ((isProduction || isStage) && environment) || null

export const initialFilters: SearchFilters = {
  equipmentType: '',
  uploadFile: [],
  dateRanges: [null, null],
  dateQuoted: [null, null],
  quoteId: '',
  originCity: '',
  originState: '',
  destinationCity: '',
  destinationState: '',
  loadsSearch: '',
  pickupStartDate: null,
  pickupEndDate: null,
  deliveryStartDate: null,
  deliveryEndDate: null,
  loadStatuses: [],
  user: {},
  refId: '',
  show: {},
  isReceived: null,
  invoicedStartDate: null,
  invoicedEndDate: null,
  loadId: null,
  name: '',
  mcNumber: '',
  dotNumber: '',
  startDate: [null, null],
  endDate: [null, null],
  id: '',
  projectStatus: '',
  projectId: '',
  projectName: '',
  archivedLoads: false,
  rfpId: '',
  rfpStatus: '',
  bidType: '',
  awardAcceptanceDeadline: [null, null],
  volumeFrequency: '',
  distanceUnit: '',
  fuelSetting: '',
  carrierBidDeadline: [null, null],
  archived: false,
  mode: '',
  rfpName: '',
  pickupNumber: '',
  poNumber: '',
  salesNumber: '',
  stockNumber: '',
}

export const emptyManifestItem: NewManifestItem = {
  name: '',
  weight: '',
  quantity: '',
  length: '',
  width: '',
  height: '',
  notes: '',
  file: '',
  files: [],
  id: '',
  isValid: false,
  manifest: '',
  itemId: '',
  createdAt: null,
}

export const emptyLocation: NewLoadLocation = {
  type: '',
  location: {},
  contactPhone: '',
  contactName: '',
  date: '',
  earlyTime: '',
  lateTime: '',
  isValid: false,
  items: [],
  shipmentNumber: '',
  notes: '',
}

export const officePhone = '17028403285,1'

export const specialRequirementsList = [
  { id: 0, name: 'Haz Mat' },
  { id: 1, name: 'High Value' },
  { id: 2, name: 'Team Load' },
  { id: 3, name: 'Over Dimensional' },
]

export const quotingToolSupportedEquipmentTypes = [
  'Flatbed',
  'Step Deck',
  'Removable Gooseneck',
  'Conestoga',
  'Hot Shot',
  'Reefer',
  'Van',
]

export const equipmentDescriptions: Array<{
  name: string
  weight?: string
  topDeck?: string
  mainDeck?: string
  rearDeck?: string
  maxPallets?: string
  insideTrailerDimensions?: string
}> = [
  {
    name: 'Flatbed',
    weight: '46—48,000 lbs',
    topDeck: 'N/A',
    mainDeck: "48'—53'L x 102''W x 8'6''H",
    rearDeck: 'N/A',
  },
  {
    name: 'Step Deck',
    weight: '44—47,000 lbs',
    topDeck: "10'—12'L x 102'W x 8'6''H",
    mainDeck: "38'—31'L x 102''W x 10'H",
    rearDeck: 'N/A',
  },
  {
    name: 'Double Drop',
    weight: '37—40,000 lbs',
    topDeck: "10'L x 102''W x 8'6''H",
    mainDeck: "24'—29'L x 102''W x 11'6''H",
    rearDeck: "10'L x 8'6''W x 10'2''H",
  },
  {
    name: 'Removable Gooseneck',
    weight: '37—40,000 lbs',
    topDeck: 'N/A',
    mainDeck: "24'—29'L x 102''W x 11'6''H",
    rearDeck: "10'L x 8'6''W x 10'2''H",
  },
  {
    name: 'Conestoga',
    weight: '44—46,500 lbs',
    topDeck: 'N/A',
    mainDeck: "47'—52'L x 102''W x 8'—8'2''H",
    rearDeck: 'N/A',
  },
  {
    name: 'Hot Shot',
    weight: '10—12,000 lbs',
    topDeck: 'N/A',
    mainDeck: "30'—40'L x 100''—102''W x 9'6''—10'H",
    rearDeck: 'N/A',
  },
  {
    name: 'Reefer',
    weight: '44,000 lbs',
    maxPallets: '26',
    insideTrailerDimensions: "51'6''L x 8'W x 8'5''H",
  },
  {
    name: 'Van',
    weight: '44—45,000 lbs',
    maxPallets: '26',
    insideTrailerDimensions: "53'L x 8'2''W x 9'H",
  },
]

export const equipmentTypeChoices = [
  {
    id: 'Removable Gooseneck',
    name: 'Removable Gooseneck',
  },
  {
    id: 'Van',
    name: 'Van',
  },
  {
    id: 'Reefer',
    name: 'Reefer',
  },
  {
    id: 'Flatbed',
    name: 'Flatbed',
  },
  {
    id: 'Conestoga',
    name: 'Conestoga',
  },
  {
    id: 'Step Deck',
    name: 'Step Deck',
  },
  {
    id: 'Hot Shot',
    name: 'Hot Shot',
  },
  {
    id: 'Power Only',
    name: 'Power Only',
  },
  {
    id: 'Load Out',
    name: 'Load Out',
  },
  {
    id: 'Double Drop',
    name: 'Double Drop',
  },
  {
    id: 'Flatbed or Step Deck',
    name: 'Flatbed or Step Deck',
  },
  {
    id: 'Truck Order Not Used',
    name: 'Truck Order Not Used',
  },
  {
    id: 'Wrecker',
    name: 'Wrecker',
  },
  {
    id: 'Van or Reefer',
    name: 'Van or Reefer',
  },
  {
    id: 'Any',
    name: 'Any',
  },
  {
    id: 'Unknown',
    name: 'Unknown',
  },
  {
    id: 'Flatbed or Step Deck or Van',
    name: 'Flatbed or Step Deck or Van',
  },
  {
    id: 'Van Intermodal',
    name: 'Van Intermodal',
  },
  {
    id: 'Tanker Intermodal',
    name: 'Tanker Intermodal',
  },
  {
    id: 'Reefer Intermodal',
    name: 'Reefer Intermodal',
  },
  {
    id: 'Straight Box Truck',
    name: 'Straight Box Truck',
  },
]

export const modeChoices = [
  {
    id: 1,
    name: 'FTL',
  },
  {
    id: 2,
    name: 'LTL',
  },
  {
    id: 3,
    name: 'PARTIAL',
  },
]

export const loadStatusChoicesNew = [
  {
    key: 'PLAN',
    name: 'Plan',
  },
  {
    key: 'TENDERED',
    name: 'Tendered',
  },
  {
    key: 'TENDER+ACCEPTED',
    name: 'Tender Accepted',
  },
  {
    key: 'TENDER+REJECTED',
    name: 'Tender Rejected',
  },
  {
    key: 'ON+TRACK',
    name: 'On Track',
  },
  {
    key: 'OFF+TRACK',
    name: 'Off Track',
  },
  {
    key: 'DELIVERED',
    name: 'Delivered',
  },
  {
    key: 'READY+TO+BILL',
    name: 'Ready To Bill',
  },
  {
    key: 'BILLING+IN+PROGRESS',
    name: 'Billing In Progress',
  },
  {
    key: 'COMPLETED',
    name: 'Completed',
  },
]

export const initialSearchParams: MultiStopQuotingParams = {
  equipmentType: '',
  refId: null,
  origin: {
    isValid: false,
    location: null,
  },
  destination: {
    isValid: false,
    location: null,
  },
  stops: [],
  isOD: false,
  specs: null,
  commodity: [],
  item: {
    name: '',
    weight: '',
    quantity: '',
    width: '',
    length: '',
    height: '',
  },
  isInsured: false,
  loadValue: '',
  notes: '',
}

export const initialDropoff = { value: '', label: '', quantity: '', isValid: false }

export const mobileLoadMenuItems = [
  { id: 'actions', title: 'Actions' },
  { id: 'contract-lane', title: 'Contract Lane' },
  {
    id: 'overview',
    title: 'Overview',
    items: [
      { id: 'info', title: 'Load Details' },
      { id: 'carrier', title: 'Carrier Info' },
      { id: 'route', title: 'Route' },
      { id: 'notifications', title: 'Notifications', noActions: true },
      { id: 'documents', title: 'Documents' },
    ],
  },
  { id: 'tender', title: 'Quote & Tender', noActions: true },
]

export const mobileLaneMenuItems = [
  { id: 'actions', title: 'Actions' },
  { id: 'carriers', title: 'Carriers' },
  { id: 'documents', title: 'Documents' },
]

export const genericDocumentMimeType =
  'image/jpeg,image/png,image/bmp,image/webp,image/gif,application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel.sheet.macroEnabled.12,text/csv'

export const fuelSettings = ['With Fuel', 'Without Fuel']

export const volumeFrequency = [
  'Spot Quote',
  'Daily',
  'Weekly',
  'Monthly',
  'Quarterly',
  'Yearly',
  'Total Volume',
]

export const distanceUnit = ['Miles', 'KM']

export const bidType = ['Award Primary', 'Rates On File']

export const rfpStatuses = ['Draft', 'Rates Requested', 'Bid Evaluation', 'Awarded']

export const countriesMapped: { [key: string]: string } = { USA: 'US', CAN: 'CA', MEX: 'MX' }

export enum RFPStatusOptions {
  DRAFT = 'DRAFT',
  RATES_REQUESTED = 'RATES_REQUESTED',
  BID_EVALUATION = 'BID_EVALUATION',
  AWARDED = 'AWARDED',
}

export enum RFPCarrierStatusOptions {
  INVITED = 'INVITED',
  INVITATION_ACCEPTED = 'INVITATION_ACCEPTED',
  INVITATION_REJECTED = 'INVITATION_REJECTED',
  IN_PROGRESS = 'IN_PROGRESS',
  RATES_SUBMITTED = 'RATES_SUBMITTED',
  AWARDED = 'AWARDED',
}

export enum TutorialSteps {
  INVITE_CARRIER = 'INVITE_CARRIER',
  CREATE_FACILITY = 'CREATE_FACILITY',
  CREATE_ITEM = 'CREATE_ITEM',
  CREATE_TENDER_LOAD = 'CREATE_TENDER_LOAD',
  CREATE_PROJECT = 'CREATE_PROJECT',
  CREATE_CONTRACT_LANE = 'CREATE_CONTRACT_LANE',
  CREATE_RFP = 'CREATE_RFP',
}

export enum NotificationTypes {
  NEW_USER_JOINED = 'NEW_USER_JOINED',
  CARRIER_REJECTED_INVITATION = 'CARRIER_REJECTED_INVITATION',
  CARRIER_ACCEPTED_INVITATION = 'CARRIER_ACCEPTED_INVITATION',
  CARRIER_UPLOADED_LOAD_DOCUMENT = 'CARRIER_UPLOADED_LOAD_DOCUMENT',
  CARRIER_EDITED_LOAD_QUOTE = 'CARRIER_EDITED_LOAD_QUOTE',
  CARRIER_QUOTED_LOAD = 'CARRIER_QUOTED_LOAD',
  LOAD_DELIVERED = 'LOAD_DELIVERED',
  LOAD_OFF_TRACK = 'LOAD_OFF_TRACK',
  LOAD_ON_TRACK = 'LOAD_ON_TRACK',
  CARRIER_REJECTED_LOAD_QUOTE = 'CARRIER_REJECTED_LOAD_QUOTE',
  CARRIER_ACCEPTED_LOAD_TENDER = 'CARRIER_ACCEPTED_LOAD_TENDER',
  CARRIER_REJECTED_LOAD_TENDER = 'CARRIER_REJECTED_LOAD_TENDER',
}

export const ORDER_TYPE_ACCEPTED_TENDER = 3
export const EXO_NETWORK = 2
export const PRIVATE_NETWORK = 4
export const LOAD_STATUS_QUOTED = 20
