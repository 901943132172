import { Button, DropdownButton, Status, Tooltip } from '@components'
import tw from 'tailwind-styled-components'

import { useAppSelector, useAppThunkDispatch } from '../../../../app/hooks'
import { LoadCarrierContact } from '../../../../common/types'
import {
  setCancelTenderModalVisible,
  setCurrentCarrier,
  tenderLoad,
} from '../../../../redux/loadsSlice'
import { TenderedToExoTooltipContent } from '../TenderedToExoTooltipContent'
import { Contact } from './Contact'

export const CarrierBox = ({
  carrier,
  contact,
  onClick,
  className = 'mb-4',
}: {
  carrier: any
  contact?: LoadCarrierContact
  onClick: () => void
  settingDetails?: boolean
  className?: string
}) => {
  const dispatch = useAppThunkDispatch()

  const load = useAppSelector(state => state.loads.loadDetails)

  const isTenderedToExo = load?.network === 2

  return (
    <Container $tenderPending={carrier.tenderPending} className={className}>
      {carrier.tenderPending && (
        <div className='mb-3'>
          <Status className='bg-brand-accent lg:ml-auto' status='Awaiting Carrier Approval' />
        </div>
      )}
      <div className='flex justify-between items-center'>
        <div className='font-bold uppercase'>{carrier.name || carrier.carrierCompanyName}</div>
      </div>
      {contact && <Contact contact={contact} />}
      <div className='max-lg:hidden'>
        {isTenderedToExo ? (
          <Tooltip content={<TenderedToExoTooltipContent />}>
            <Button
              disabled
              fullWidth
              className='mt-2'
              postIcon='exclamation-triangle'
              type='secondary'
            >
              Change Carrier
            </Button>
          </Tooltip>
        ) : (
          <DropdownButton
            buttonLabel='Change Carrier'
            className='mt-2'
            items={[
              ...(!isTenderedToExo && load?.carrier?.tenderPending
                ? [
                    {
                      label: 'Resend Tender',
                      onClick: () =>
                        dispatch(
                          tenderLoad({ carrierId: load?.carrier?.id, amount: load?.carrierPrice }),
                        ),
                    },
                  ]
                : []),
              {
                label: 'Cancel Tender',
                onClick: () => {
                  dispatch(setCurrentCarrier({ carrierId: load?.carrier?.id }))
                  dispatch(setCancelTenderModalVisible(true))
                },
              },
            ]}
            onClick={onClick}
          />
        )}
      </div>
    </Container>
  )
}

const Container = tw.div<{ $tenderPending?: boolean }>`
  justify-center
  items-center
  p-4
  lg:w-full
  md:w-11/12
  rounded-lg
  lg:border-[1.5px]
  ${({ $tenderPending }) =>
    $tenderPending
      ? 'lg:border-dashed lg:border-icon-gray lg:bg-lighter-gray'
      : 'lg:border-brand-light'}
`
