import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@heroicons/react/24/outline'
import { times } from 'lodash-es'
import { useEffect } from 'react'
import tw from 'tailwind-styled-components'

import { PageButton } from '../TableButton'

export const Pagination = ({
  active: activePage,
  size,
  step,
  onClickHandler,
  offset,
  setOffset,
  pageSize,
  count,
}: {
  active: number
  size: number
  step: number
  onClickHandler: Function
  offset: number
  setOffset: Function
  pageSize: number
  count: number
}): JSX.Element => {
  useEffect(() => {
    if (offset < 0) setOffset(pageSize)
  }, [])

  const active = Math.floor(activePage)

  const showingNumbers = step * 2 + 1
  let startNumber = 2
  let startArrayNumber = step

  let needStartDots = false
  let needEndDots = false

  if (active > step) {
    startArrayNumber = active - step
    needStartDots = active > step + startNumber
  }

  if (size > showingNumbers) {
    needEndDots = size > active + step + 1
    if (size < active + step + 1) startArrayNumber = size - showingNumbers
  }

  let contentNumber

  const iconClassName = 'h-4 w-4 lg:h-5 lg:w-5'

  const renderLeftButtons = () => (
    <>
      <PageButton
        className='mr-2 hidden md:block'
        disabled={!offset}
        onClick={() => {
          setOffset(0)
          onClickHandler(1)
        }}
      >
        <span className='sr-only'>First</span>
        <ChevronDoubleLeftIcon aria-hidden='true' className={iconClassName} />
      </PageButton>
      <PageButton
        className='mr-4'
        disabled={!offset}
        onClick={() => {
          setOffset(offset - pageSize)
          onClickHandler(active - 1)
        }}
      >
        <span className='sr-only'>Previous</span>
        <ChevronLeftIcon aria-hidden='true' className={iconClassName} />
      </PageButton>
    </>
  )

  const renderRightButtons = () => (
    <>
      <PageButton
        className='ml-4 mr-2'
        disabled={offset >= count - pageSize}
        onClick={() => {
          setOffset(offset + pageSize)
          onClickHandler(active + 1)
        }}
      >
        <span className='sr-only'>Next</span>
        <ChevronRightIcon aria-hidden='true' className={iconClassName} />
      </PageButton>
      <PageButton
        className='hidden md:block'
        disabled={offset >= count - pageSize}
        onClick={() => {
          setOffset(count - pageSize)
          onClickHandler(size)
        }}
      >
        <span className='sr-only'>Last</span>
        <ChevronDoubleRightIcon aria-hidden='true' className={iconClassName} />
      </PageButton>
    </>
  )

  return (
    <List>
      {renderLeftButtons()}
      {size > showingNumbers + startNumber ? (
        <>
          <PageItem
            $active={active === 1}
            onClick={(e: any) => onClickHandler(e.currentTarget.textContent)}
          >
            1
          </PageItem>

          {needStartDots && <span>...</span>}
          {times(showingNumbers, (i: number) => (
            <PageItem
              key={i++}
              {...((contentNumber = needStartDots ? startArrayNumber : startNumber) as {})}
              {...(startNumber++ as {})}
              {...(startArrayNumber++ as {})}
              $active={active === contentNumber}
              onClick={(e: any) => onClickHandler(e.currentTarget.textContent)}
            >
              {contentNumber}
            </PageItem>
          ))}
          {needEndDots && <span>...</span>}
          <PageItem
            $active={active === size}
            onClick={(e: any) => onClickHandler(e.currentTarget.textContent)}
          >
            {size}
          </PageItem>
        </>
      ) : (
        ((startArrayNumber = 1),
        times(size, (i: number) => (
          <PageItem
            key={i++}
            $active={active === startArrayNumber}
            onClick={(e: any) => onClickHandler(e.currentTarget.textContent)}
          >
            {startArrayNumber++}
          </PageItem>
        )))
      )}
      {renderRightButtons()}
    </List>
  )
}

const PageItem = tw.li`
  cursor-pointer
  transition-all
  py-1.5
  px-3
  mx-1
  md:mx-1
  rounded-md
  flex
  items-center
  justify-center
  hover:bg-lighter-blue
  hover:text-brand-dark
  text-sm
  md:text-base
  hover:font-bold
  ${({ $active }: { $active: boolean }) => $active && 'bg-blue-50 text-brand-dark font-bold'}
`

const List = tw.ul`
  flex
  items-center
  p-0
  static
  text-base
  text-dark-gray
`
