import { ReactNode } from 'react'
import tw from 'tailwind-styled-components'

export const Link = ({
  children,
  to,
  className = '',
}: {
  children: ReactNode
  to: string
  className?: string
}) =>
  children ? (
    <A className={className} href={to} rel='noreferrer' target='_blank'>
      {children}
    </A>
  ) : (
    <>—</>
  )

const A = tw.a`
  hover:text-link
  transition-all
`
