export type LoadNotification = {
  notificationType: LoadNotificationType
  createdAt: string
  statusEvent: StatusEvent | null
  loadUpload: LoadUpload | null
  tenderRequest: TenderRequest | null
}

export type LoadUpload = {
  uploadedBy: string
  documentType: string
  uploadedByAccount: string
}

export enum LoadNotificationType {
  CarrierDocumentUpdate = 'CARRIER_DOCUMENT_UPDATE',
  LoadStatusEvent = 'LOAD_STATUS_EVENT',
  LoadTenderRequest = 'LOAD_TENDER_REQUEST',
}

export type StatusEvent = {
  createdBy: string
  status: string
  eventTime: string
  transitStatus: null | string
  locationName: string
  notes: null | string
}

export type TenderRequest = {
  carrierName: string
  carrierContractRate: number
  carrierContact: string
  sentAt: string
  status: string
}
