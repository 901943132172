import equal from 'fast-deep-equal'
import { isEqual } from 'lodash-es'
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { createBrowserRouter, Location } from 'react-router-dom'

// Determine window width and height. Usage: const [width, height] = useWindowSize()
export const useWindowSize = () => {
  const [size, setSize] = useState([0, 0])
  useLayoutEffect(() => {
    const updateSize = () => setSize([window.innerWidth, window.innerHeight])
    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])
  return size
}

// Determine if window has loaded. Usage: const windowLoaded = useWindowLoaded()
export const useWindowLoaded = () => {
  const [windowLoaded, setWindowLoaded] = useState(false)

  useLayoutEffect(() => {
    if (document.readyState === 'complete') {
      setWindowLoaded(true)
    } else {
      const onPageLoad = () => setWindowLoaded(true)
      window.addEventListener('load', onPageLoad)
      return () => window.removeEventListener('load', onPageLoad)
    }
  }, [])

  return windowLoaded
}

// Evaluate a function and track its return value. Usage: const value = useTrackValue(() => someFunction())
// Useful when you want to track a value that is updated outside react (not prop or state)
export const useTrackValue = <T>(getValue: () => T, wait = 100) => {
  const [value, setValue] = useState<T>(getValue())

  useEffect(() => {
    const interval = setInterval(() => {
      const newVal = getValue()
      if (!equal(value, newVal)) {
        setValue(newVal)
      }
    }, wait)
    return () => clearInterval(interval)
  }, [value])

  return value
}

// keeps track of the browser history stack
export const useBrowserBackStack = (router: ReturnType<typeof createBrowserRouter>) => {
  const [backStack, setBackStack] = useState<Location[]>([])

  useEffect(() => {
    router.subscribe(({ historyAction, location }) => {
      setBackStack(backStack => {
        switch (historyAction) {
          case 'POP':
            return backStack.slice(0, -1)
          case 'PUSH':
            if (location.key === backStack.at(-1)?.key) return backStack
            return [...backStack, location]
          default:
            return [...backStack.slice(0, -1), location]
        }
      })
    })
  }, [setBackStack])
  return backStack
}

// like useEffect, but debounced. usefull for effects that are triggered many times in a short period of time
export const useDebouncedEffect = (func: () => void, deps: any[], wait = 100) => {
  useEffect(() => {
    const timeout = setTimeout(func, wait)
    return () => clearTimeout(timeout)
  }, deps)
}

// returns true if the window width is less than 768px (mobile breakpoint)
export const useIsMobile = () => {
  const [width] = useWindowSize()
  return useMemo(() => (width && width < 768) || false, [width])
}

export const useDeepDependencies = <T>(dependencies: T) => {
  /*
  Performs a deep compare of dependencies and returns a signal (int)
  that increments whenever the dependencies change. This is meant to be used with React hooks
  that have object dependencies.
  */

  const ref = useRef({
    dependencies,
    signal: 0,
  })

  const dependenciesChanged = !isEqual(ref.current.dependencies, dependencies)
  if (dependenciesChanged) {
    ref.current = {
      dependencies,
      signal: ref.current.signal + 1,
    }
  }
  return [ref.current.signal]
}

// Get the last value of the variable before it was changed. Usage: const prevValue = usePrevious(value)
export const usePrevious = (value: any) => {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  }, useDeepDependencies(value))
  return ref.current
}
