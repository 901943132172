import { useEffect } from 'react'

const AUTH_CHANNEL = 'auth'

/**
 * Used to broadcast login/logout events to other tabs
 * Will also handle the navigation to the appropriate page for the current tab
 *
 * @param onLogout - Callback to be called when a logout event is received
 */
export const useAuthBroadcast = (
  onLogin: () => Promise<void> | void,
  onLogout: () => Promise<void> | void,
) => {
  useEffect(() => {
    const bc = new BroadcastChannel(AUTH_CHANNEL)
    bc.onmessage = async e => {
      if (e.data.event === 'LOGIN') {
        onLogin()
      } else if (e.data.event === 'LOGOUT') {
        onLogout()
      }
    }

    return () => bc.close()
  }, [])
}

/**
 * Broadcasts a login event to other tabs
 * Should only be called after an explicit successful login (e.g. after credentials/form submit)
 * Should not be called after a secondary login (e.g. after a token refresh)
 */
export const broadcastLogin = () => {
  const bc = new BroadcastChannel(AUTH_CHANNEL)
  bc.postMessage({ event: 'LOGIN' })
  bc.close()
}

/**
 * Broadcasts a logout event to other tabs
 * Should only be called after an explicit successful logout (e.g. after logout button click)
 * Should not be called after a secondary logout (e.g. after a token refresh expires)
 */
export const broadcastLogout = () => {
  const bc = new BroadcastChannel(AUTH_CHANNEL)
  bc.postMessage({ event: 'LOGOUT' })
  bc.close()
}
