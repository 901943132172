import { Map, Marker, MarkerType } from '@components'

import { api } from '../../../api/api'
import { useAppSelector } from '../../../app/hooks'
import { QuotingLocation } from '../../../common/types'
import { QuoteHeader } from './QuoteHeader'

export const ODQuoteHeader = ({
  className,
  showStatus = true,
}: {
  className?: string
  showStatus?: boolean
}) => {
  const data = useAppSelector(state => state.quotingTool.searchParams)

  return (
    <div className={className}>
      <QuoteHeader
        className='mb-6'
        showStatus={showStatus}
        quote={{
          ...data,
          origin: data.origin.location,
          destination: data.destination.location,
          stopovers: data.stops?.map(stop => stop.location),
        }}
      />
      <Map className='w-full rounded-lg' mapClassName='h-[224px] w-full rounded-lg' routeAPI={api}>
        {data.origin.location && (
          <Marker marker={MarkerType.ORIGIN} position={data.origin.location} />
        )}
        {data.destination.location && (
          <Marker marker={MarkerType.DESTINATION} position={data.destination.location} />
        )}
        {data.stops?.map((stop: { location: QuotingLocation | null }, index) => (
          <Marker key={index} marker={MarkerType.STOP} position={stop?.location} />
        ))}
      </Map>
    </div>
  )
}
