import { Checkbox } from '@components'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { useEffect, useMemo, useState } from 'react'
import tw from 'tailwind-styled-components'

import { useAppSelector, useAppThunkDispatch } from '../../../app/hooks'
import { equipmentDescriptions } from '../../../common/constants'
import { camelCaseToWords } from '../../../common/utils'
import { setDoNotShowEquipmentBanner } from '../../../redux/commonSlice'

export const DimensionsBanner = () => {
  const [showBanner, setShowBanner] = useState(true)
  const [doNotShowBannerCheckbox, setDoNotShowBannerCheckbox] = useState(false)

  const dispatch = useAppThunkDispatch()

  const searchParams = useAppSelector(state => state.quotingTool.searchParams)
  const doNotShowBanner = useAppSelector(state => state.common.doNotShowEquipmentBanner) || false

  const bannerEquipmentType = useMemo(
    () =>
      equipmentDescriptions.find(description => description.name === searchParams.equipmentType),
    [searchParams.equipmentType],
  )

  useEffect(() => {
    if (!doNotShowBanner) setShowBanner(true)
  }, [searchParams.equipmentType])

  const handleCloseBanner = () => {
    if (doNotShowBannerCheckbox) dispatch(setDoNotShowEquipmentBanner(true))
    setShowBanner(false)
  }

  if (!bannerEquipmentType || !showBanner || doNotShowBanner) return <></>

  return (
    <Banner>
      <div className='flex justify-between items-start'>
        <div className='w-full'>
          <div className='font-semibold mb-3'>{bannerEquipmentType.name} Guide</div>
          <div className='grid grid-cols-2 gap-3'>
            {Object.entries(bannerEquipmentType).map(
              ([key, value]) =>
                key !== 'name' && (
                  <div key={key}>
                    <Label>{camelCaseToWords(key)}</Label>
                    <div>{value}</div>
                  </div>
                ),
            )}
          </div>
        </div>
        <CloseIcon onClick={handleCloseBanner} />
      </div>
      <div className='flex justify-end mt-4'>
        <Checkbox
          isChecked={doNotShowBannerCheckbox}
          title='Do not show again'
          onChange={() => setDoNotShowBannerCheckbox(!doNotShowBannerCheckbox)}
        />
      </div>
    </Banner>
  )
}

const Banner = tw.div`
  bg-brand-light
  rounded-lg
  p-4
`

const Label = tw.div`
  text-dark-gray
  font-semibold
  mb-1
`

const CloseIcon = tw(XMarkIcon)`
  w-5
  hover:opacity-70
  transition-all
  cursor-pointer
`
